import React, { Component } from "react";
import "./modals.css";
import { Button, Modal, Message } from "semantic-ui-react";
import * as CONSTANT from "../../constants/UniversalConstants";

class CrisisShareSimulationModal extends Component {
  state = { copySuccess: "", simulationLink: "" };

  componentDidMount() {
    this.setRedirection();
  }

  setRedirection = () => {
    if (this.props.updateTransactionData) {
      const transactionData = {
        paymentId: "",
        purchasedSimulationName: CONSTANT.MEDICA_NAME,
      };
      this.props.updateTransactionData(transactionData);
    }
  };

  handleCopyToClipboard = () => {
    const translate = this.props.translate;
    var fakeInput = document.createElement("input");
    document.body.appendChild(fakeInput);
    fakeInput.setAttribute("value", this.props.shareLink);
    fakeInput.select();
    document.execCommand("copy");
    document.body.removeChild(fakeInput);
    this.setState({ copySuccess: translate("ShareModal:copySuccess") });
  };

  render() {
    const translate = this.props.translate;
    let shareLabel = translate("CrisisShareModal:ShareJoinLInk");

    return (
      <div>
        <Modal id="shareSimulationModal" size="tiny" open={this.props.open} onClose={this.props.handleSecondaryButton}>
          <Modal.Header>{this.props.titleModal}</Modal.Header>
          <Modal.Content>
            <label>{shareLabel}</label>
            <Message visible={true} info={this.props.messageType} negative={!this.props.messageType}>
              <Message.Header>{this.props.shareLink}</Message.Header>
            </Message>
            <label>{this.state.copySuccess}</label>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={!this.props.messageType}
              content={translate("ShareModal:copy")}
              onClick={this.handleCopyToClipboard}
            />
            <Button content={translate(this.props.titleSecondaryButton)} onClick={this.props.handleSecondaryButton} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default CrisisShareSimulationModal;
