import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoadingComponent from "../helpers/LoadingComponent";
import { logout } from "../../services/AuthService";

class LogoutComponent extends Component {
  componentDidMount() {
    logout();
  }

  render() {
    const translate = this.props.t;
    return <LoadingComponent fullSize={true} image={true} message={translate("loadingPage")} />;
  }
}

export default withTranslation("AuthComponents")(LogoutComponent);
