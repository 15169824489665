export const GET_SIMULATION_DETAILS_START = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_DETAILS_START";
export const GET_SIMULATION_DETAILS_FAIL = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_DETAILS_FAILED";
export const GET_SIMULATION_DETAILS_SUCCESS = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_DETAILS_SUCCESS";
export const ADVANCE_SIMULATION_START = "ABOGADOS_SIMULATION_DETAILS/ADVANCE_SIMULATION_START";
export const ADVANCE_SIMULATION_FAIL = "ABOGADOS_SIMULATION_DETAILS/ADVANCE_SIMULATION_FAILED";
export const ADVANCE_SIMULATION_SUCCESS = "ABOGADOS_SIMULATION_DETAILS/ADVANCE_SIMULATION_SUCCESS";
export const RESET_REQUESTS_STATE = "ABOGADOS_SIMULATION_DETAILS/RESET_REQUESTS_STATE";
export const GET_REGISTERED_STUDENTS_START = "ABOGADOS_SIMULATION_DETAILS/GET_REGISTERED_STUDENTS_START";
export const GET_REGISTERED_STUDENTS_SUCCESS = "ABOGADOS_SIMULATION_DETAILS/GET_REGISTERED_STUDENTS_SUCCESS";
export const GET_REGISTERED_STUDENTS_FAIL = "ABOGADOS_SIMULATION_DETAILS/GET_REGISTERED_STUDENTS_FAIL";
export const GET_SIMULATION_STANDINGS_START = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_STANDINGS_START";
export const GET_SIMULATION_STANDINGS_SUCCESS = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_STANDINGS_SUCCESS";
export const GET_SIMULATION_STANDINGS_FAIL = "ABOGADOS_SIMULATION_DETAILS/GET_SIMULATION_STANDINGS_FAIL";
