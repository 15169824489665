import React, { Component } from "react";
import { Accordion, Container, Divider, Grid, Icon, List, Message } from "semantic-ui-react";
import { faq, studentFaq } from "../../constants/FAQ_DATA_STRUCTURE";
import * as ROUTES from "../../constants/Routes";
import MedicaImage from "../../assets/medica-logo-transparent.png";
import SimunationImage from "../../assets/simunation-logo.png";
import CrisisImage from "../../assets/crisis-logo.png";
import SupplyGrainImage from "../../assets/supplychain-logo.png";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import { withTranslation } from "react-i18next";
import ProductOption from "./ProductOption";
import FaqTag from "./FaqTag";

import "./ProcessimLabsFaq.css";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const productImages = {
  Medica: MedicaImage,
  Simunation: SimunationImage,
  Bindz: CrisisImage,
  SupplyGrain: SupplyGrainImage,
};

const productNames = {
  Medica: "Medica",
  Simunation: "Simunation",
  Bindz: "Bindz",
  SupplyGrain: "Supply Grain",
};

class ProcessimLabsFaq extends Component {
  state = {
    productSelected: "",
    tagSelected: "",
  };

  onSelectProduct = (id) => {
    this.setState({ productSelected: id, tagSelected: "" });
  };

  onSelectTag = (id) => {
    this.setState({ tagSelected: id });
  };

  render() {
    const { t, i18n, isStudent } = this.props;
    const { productSelected, tagSelected } = this.state;
    const language = ["en", "es"].includes(i18n.language) ? i18n.language : "en";
    const FaqData = isStudent ? studentFaq : faq;
    let faqProducts;

    if (productSelected.length === 0) {
      faqProducts = (
        <List as={Grid} columns={2} selection verticalAlign="middle">
          {Object.keys(FaqData).map((x) => {
            return (
              <ProductOption
                key={x}
                id={x}
                title={productNames[x]}
                image={productImages[x]}
                onSelect={this.onSelectProduct}
                backgroundColor={FaqData[x].avatarBackgroundColor}
              />
            );
          })}
        </List>
      );
    } else {
      faqProducts = (
        <List as={Grid} columns={1} selection verticalAlign="middle">
          <ProductOption
            showBack
            id={productSelected}
            title={productNames[productSelected]}
            image={productImages[productSelected]}
            backgroundColor={FaqData[productSelected].avatarBackgroundColor}
            onSelect={() => this.onSelectProduct("")}>
            <Icon name="angle left" size="huge" />
          </ProductOption>
        </List>
      );
    }

    let questionTags = null;
    if (productSelected.length > 0) {
      questionTags = FaqData[productSelected].faq
        .map((x) => x.section)
        .filter(onlyUnique)
        .map((x, index) => (
          <FaqTag
            key={`${productSelected}-${index}`}
            tagId={x}
            onSelect={this.onSelectTag}
            isSelected={x === tagSelected}
            tagTitle={t(x)}
          />
        ));
      questionTags.unshift(
        <FaqTag
          key={`${productSelected}-all`}
          tagId={"All"}
          onSelect={this.onSelectTag}
          isSelected={"All" === tagSelected}
          tagTitle={t("All")}
        />
      );
    }

    let faqQuestions = null;
    const studentFaqLink = `${window.location.origin}${ROUTES.STUDENT_FAQ}`;
    if (productSelected.length > 0 && tagSelected.length > 0) {
      const selectedQuestions =
        tagSelected === "All"
          ? FaqData[productSelected].faq
          : FaqData[productSelected].faq.filter((x) => x.section === tagSelected);

      const questionPanels = selectedQuestions.map((x, index) => ({
        key: `question${index}`,
        title: {
          content: x.question[language],
        },
        content: {
          content: <Message>{x.answer[language].replace("{studentFaq}", studentFaqLink)}</Message>,
        },
      }));
      faqQuestions = <Accordion fluid styled panels={questionPanels} />;
    }
    return (
      <Container>
        <HeaderComponent translate={t} showLanguageSelector />
        <MainTitleComponent title={t("title")} />
        <Divider hidden />
        {faqProducts}
        <Divider hidden />
        <List horizontal>{questionTags}</List>
        <Divider hidden />
        {faqQuestions}
      </Container>
    );
  }
}

export default withTranslation("FAQ")(ProcessimLabsFaq);
