import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import openPopUpIcon from "../../assets/pop-out-button.svg";
import VisChart from "./VisChart";

const DashBoardContainer = (props) => {
  const { handleGraphModal, data, selectedTeams, t: translate } = props;

  const openButton = (plot) => {
    return (
      <button className="openChartModalButton" onClick={() => handleGraphModal(plot)}>
        <img height={12} src={openPopUpIcon} alt="openPopUpIcon" />
      </button>
    );
  };

  return (
    <>
      {data.map((plot) => {
        return (
          <Grid.Column className="specialGraphColumn" key={plot.index}>
            <div className="chartContent">
              <h5 id="chartTitle">
                {translate(plot.name)} {openButton(plot)}
              </h5>
              <VisChart plot={plot} selectedTeams={selectedTeams} translate={translate} />
            </div>
          </Grid.Column>
        );
      })}
    </>
  );
};

export default withTranslation("CrisisCommon")(DashBoardContainer);
