import "semantic-ui-css/semantic.min.css";
import "./styles/styles.css";
import "./styles/uiCustomStyles.css";
import "./styles/main.css";
import "../node_modules/react-vis/dist/style.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import history from "./constants/History";
import configureStore from "./store/configureStore";
import i18n from "./translations/i18n";
import App from "./App";
import * as serviceWorker from "./registerServiceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing, Integrations } from "@sentry/tracing";
import * as CONSTANT from "./constants/UniversalConstants";

const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

if (window.location.host === "app.processimlabs.com") {
  Sentry.init({
    dsn: "https://0f436b703f0e43be86081ac368629c2f@sentry.io/1500570",
    release: `appprocessimlabs@${CONSTANT.APP_VERSION_NUMBER}`,
    dist: CONSTANT.APP_DISTRIBUTION_NUMBER,
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    normalizeDepth: 4,
    tracesSampleRate: 1.0,
  });
}

/* eslint-disable no-unused-vars */
const integrationsIgnorePattern = Integrations;
/* eslint-enable no-unused-vars */

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ConnectedRouter>
  </Provider>,
  rootElement
);

serviceWorker.unregister();
