import * as actions from "./action-types";

export const initialState = {
  loadingSimulations: false,
  data: null,
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_SIMULATIONS:
      return {
        ...state,
        loadingSimulations: true,
        data: null,
        message: "",
        showVideoToBuy: null,
      };
    case actions.GET_SIMULATIONS_SUCCESSFUL:
      return {
        ...state,
        loadingSimulations: false,
        message: "",
        data: action.payload.data,
      };
    case actions.GET_SIMULATIONS_FAILED:
      return {
        ...state,
        loadingSimulations: false,
        ...action.payload,
      };
    default:
      return state;
  }
}
