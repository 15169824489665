import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Grid, Image } from "semantic-ui-react";
import NavigationButton from "../helpers/NavigationButton";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import PurchasedSimunationComponent from "./PurchasedSimunationComponent";
import HeaderComponent from "../header/HeaderComponent";
import ShareSimulationModal from "../modals/ShareModal";
import simunationLogo from "../../assets/simunation-logo-transparent.png";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import { preventBack } from "../../services/NavigationService";
import { prepareSimulationData, handleStorage } from "../../services/SimunationQuestionnaireService";
import {
  checkStorageSimunationInformation,
  updateSimulationInformation,
  resetSimunationQuestionnaireStore,
  createSimulation,
  checkStorageSimunationCreated,
  simunationConfirmPayment,
} from "../../store/SimunationQuestionnaire/actions";
import { getSimulations } from "../../store/Simulations/actions";
import { getStorage, setStorage, removeStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

class PurchasedSimunationScreen extends Component {
  constructor(props) {
    super(props);

    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }

    this.state = {
      showForm: false,
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    preventBack();
    await this.verifyPayment();
    const checkStorage = this.props.checkStorageSimunationInformation();
    checkStorage.then((isChecked) => {
      if (isChecked) {
        this.setState({
          showForm: true,
        });
      }
    });
    this.props.checkStorageSimunationCreated();
  }

  verifyPayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  createSimulation = (postData) => {
    this.props.createSimulation(postData);
  };

  handleContinue = async () => {
    const purchasedData = this.refs.purchasedSimunation.handleContinue();
    if (purchasedData) {
      const saving = await this.props.updateSimulationInformation(purchasedData);
      handleStorage(null, purchasedData);
      setStorage(LOCALSTORAGE.requestedSimulation, purchasedData.simulationName);
      if (saving) {
        const simulationData = prepareSimulationData(this.props.simunationQuestionnaire);
        this.createSimulation(simulationData);
      }
    }
  };

  successRedirection = () => {
    const { language, t, getSimulations } = this.props;
    const translate = t;
    getSimulations(language, translate);
    removeStorage(LOCALSTORAGE.simulationSimunationCreated);
    const requestedSimulation = getStorage(LOCALSTORAGE.requestedSimulation);
    this.props.history.push({
      pathname: ROUTES.PURCHASED_SIMUNATION_DETAIL,
      search: requestedSimulation,
    });
    this.props.resetSimunationQuestionnaireStore();
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;
    const { showForm } = this.state;
    const {
      simulationName,
      simulationCreatedData,
      createSimulationErrorMessage,
      loadingCreateSimulation,
      createdSimulationMessage,
    } = this.props.simunationQuestionnaire;
    let loader = null;
    let messagesComponent = null;
    let navigationButton = null;
    let purchasedSimunationComponent = null;
    let shareSimulationModal = null;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    if (showForm) {
      purchasedSimunationComponent = (
        <PurchasedSimunationComponent
          ref="purchasedSimunation"
          simulationName={simulationName}
          translate={translate}
          handleContinue={this.handleContinue}
        />
      );
    }

    if (loadingCreateSimulation) {
      loader = (
        <Grid.Column width={16}>
          <LoadingComponent message={translate("creatingSimulation")} />
        </Grid.Column>
      );
    } else {
      navigationButton = (
        <Grid.Column width={16}>
          <NavigationButton action={this.handleContinue} label={translate("continue")} />
        </Grid.Column>
      );
    }

    if (createSimulationErrorMessage !== "") {
      messagesComponent = <MessagesComponent message={createSimulationErrorMessage} />;
    }

    if (simulationCreatedData && simulationCreatedData.outcome) {
      handleStorage(simulationCreatedData.payload, null);
      shareSimulationModal = (
        <ShareSimulationModal
          translate={translate}
          open={simulationCreatedData.outcome}
          shareLink={simulationCreatedData.payload}
          titleModal={translate(createdSimulationMessage)}
          titleSecondaryButton={translate("ModifyModal:accept")}
          handleSecondaryButton={() => this.successRedirection()}
          messageType={true}
          isSimunation={true}
        />
      );
    }

    return (
      <Container>
        {shareSimulationModal}
        <HeaderComponent translate={translate} />
        <Grid className="customGrid">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.SIMUNATION_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.SIMUNATION_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column className="formColumn" mobile={16} computer={8} tablet={8}>
            {purchasedSimunationComponent}
          </Grid.Column>
          <Grid.Column className="logoColumn" only="tablet computer" computer={8} tablet={8}>
            <Image className="simunationLogo" src={simunationLogo} />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            {messagesComponent}
            {loader}
            {navigationButton}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(simunationConfirmPayment(intentId)),
  checkStorageSimunationInformation: () => dispatch(checkStorageSimunationInformation()),
  updateSimulationInformation: (data) => dispatch(updateSimulationInformation(data)),
  getSimulations: (language, translate) => dispatch(getSimulations(language, translate)),
  resetSimunationQuestionnaireStore: () => dispatch(resetSimunationQuestionnaireStore()),
  createSimulation: (postData) => dispatch(createSimulation(postData)),
  checkStorageSimunationCreated: () => dispatch(checkStorageSimunationCreated()),
});

const mapStateToProps = (state) => {
  return {
    userRole: state.user.userProfile.role,
    language: state.user.userProfile.language,
    confirmPaymentStatus: state.simunationQuestionnaire.confirmPayment,
    simunationQuestionnaire: {
      numberOfStudents: state.simunationQuestionnaire.numberOfStudents,
      amountByStudent: state.simunationQuestionnaire.amountByStudent,
      discountCode: state.simunationQuestionnaire.discountCode.discountCode,
      paymentId: state.simunationQuestionnaire.paymentId,
      simulationName: state.simunationQuestionnaire.simulationName,
      advanceSimunation: state.simunationQuestionnaire.advanceSimunation,
      loadingCreateSimulation: state.simunationQuestionnaire.loadingCreateSimulation,
      simulationCreatedData: state.simunationQuestionnaire.simulationCreatedData,
      createSimulationErrorMessage: state.simunationQuestionnaire.createSimulationErrorMessage,
      createdSimulationMessage: state.simunationQuestionnaire.createdSimulationMessage,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("Purchased", "ModifyModal", "Common")(withRouter(PurchasedSimunationScreen))
);
