import * as actions from "./action-types";

export const initialState = {
  buyForm: {
    numberOfStudents: 0,
    isInstitution: null,
  },
  payment: {
    paymentSummaryId: "",
  },
  discountCode: {
    discountActivated: false,
    loadingDiscountCode: null,
    discountedPrice: null,
    discountCodeMessage: "",
    discountCode: "",
    paidWithDiscountCode: false,
  },
  createSimulation: {
    loading: false,
    errorMessage: "",
    simulationId: "",
    simulationCreated: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.COMMIT_PURCHASE_FORM_DATA:
      return {
        ...state,
        buyForm: { ...action.payload },
      };
    case actions.VALIDATE_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          loadingDiscountCode: true,
          discountCodeMessage: "",
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          discountActivated: true,
          loadingDiscountCode: false,
          discountCodeMessage: "",
          ...action.payload,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId,
        },
        discountCode: {
          ...state.discountCode,
          discountActivated: true,
          loadingDiscountCode: false,
          discountCodeMessage: "",
          discountedPrice: action.payload.discountedPrice,
          discountCode: action.payload.discountCode,
          paidWithDiscountCode: true,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_FAILED:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          loadingDiscountCode: false,
          discountCodeMessage: action.payload,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_RESET:
      return {
        ...state,
        discountCode: {
          ...initialState.discountCode,
        },
      };
    case actions.RESTORE_STORAGE_PURCHASE_INFORMATION:
      return {
        ...state,
        buyForm: {
          numberOfStudents: action.payload.numberOfStudents,
          isInstitution: action.payload.isInstitution ?? false,
        },
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId ?? "",
        },
        discountCode: {
          discountActivated: action.payload.discountActivated ?? false,
          discountCode: action.payload.discountCode ?? "",
          discountedPrice: action.payload.discountedPrice ?? null,
        },
      };
    case actions.UPDATE_TRANSACTION_DATA:
      return {
        ...state,
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_START:
      return {
        ...state,
        createSimulation: {
          ...state.createSimulation,
          errorMessage: "",
          loading: true,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_SUCCESS:
      return {
        ...state,
        createSimulation: {
          loading: false,
          simulationId: action.payload.simulationId,
          errorMessage: "",
          simulationCreated: true,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_FAIL:
      return {
        ...state,
        createSimulation: {
          ...state.createSimulation,
          loading: false,
          errorMessage: action.payload,
        },
      };
    case actions.RESET_CREATE_SIMULATION_DEFAULTS:
      return {
        ...state,
        createSimulation: { ...initialState["createSimulation"] },
      };
    case actions.RESET_ABOGADOS_BUY_SIMULATION_DEFAULTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
