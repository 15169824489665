import React, { Component, Fragment } from "react";
import { Accordion } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./FAQContent.css";
const panels = [
  {
    key: 0,
    title: "areYouTryingToJoinSimulationQuestion",
    content: "areYouTryingToJoinSimulationResponse",
  },
  {
    key: 1,
    title: "haveYouAlreadyJoinedATeamQuestion",
    content: "haveYouAlreadyJoinedATeamResponse",
  },
  {
    key: 2,
    title: "doYouWantToChangeTeamNameQuestion",
    content: "doYouWantToChangeTeamNameResponse",
  },
  {
    key: 3,
    title: "areYouTryingToCreateSecondTeamQuestion",
    content: "areYouTryingToCreateSecondTeamResponse",
  },
  {
    key: 4,
    title: "doYouWantToSwitchTeamsQuestion",
    content: "doYouWantToSwitchTeamsResponse",
  },
  {
    key: 5,
    title: "canIBePartOfTwoTeamsQuestion",
    content: "canIBePartOfTwoTeamsResponse",
  },
  {
    key: 6,
    title: "canIAccessTheAppBeforeSimulationStartsQuestion",
    content: "canIAccessTheAppBeforeSimulationStartsResponse",
  },
  {
    key: 7,
    title: "howDoIKnowSimulationStartsEndsQuestion",
    content: "howDoIKnowSimulationStartsEndsResponse",
  },
  {
    key: 8,
    title: "howDoISwitchFromAPreviousSimulationQuestion",
    content: "howDoISwitchFromAPreviousSimulationResponse",
  },
];

class FAQContent extends Component {
  state = { activeIndex: null };

  changeQuestion = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { translate } = this.props;
    const { activeIndex } = this.state;
    const downIcon = <FontAwesomeIcon icon={faChevronDown} color="#0379ba" size="lg" />;
    const upIcon = <FontAwesomeIcon icon={faChevronUp} color="#0379ba" size="lg" />;

    return (
      <Accordion className="faqAccordion" fluid styled>
        {panels.map((item, index) => {
          const active = activeIndex === index;
          let icon = downIcon;
          if (active) icon = upIcon;
          return (
            <Fragment key={index}>
              <Accordion.Title
                className={active ? "activeItem" : null}
                active={active}
                index={index}
                onClick={this.changeQuestion}>
                {translate(item.title)}
                {icon}
              </Accordion.Title>
              <Accordion.Content active={active}>
                <p>{translate(item.content)}</p>
              </Accordion.Content>
            </Fragment>
          );
        })}
        <p>
          {translate("contactUs")}
          <strong>{translate("supportEmail")}</strong>
        </p>
      </Accordion>
    );
  }
}

export default FAQContent;
