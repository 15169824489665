import * as actions from "./action-types";

export const initialState = {
  paymentIntent: {
    loading: false,
    errorMessage: "",
    clientSecret: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: {
          loading: true,
          errorMessage: "",
          clientSecret: "",
        },
      };
    case actions.GET_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          loading: false,
          errorMessage: "",
          clientSecret: action.payload.clientSecret,
        },
      };
    case actions.GET_PAYMENT_INTENT_FAILED:
      return {
        ...state,
        paymentIntent: {
          loading: false,
          errorMessage: action.payload,
          clientSecret: "",
        },
      };

    default:
      return state;
  }
}
