import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DropDownComponent from "../helpers/DropDownComponent";
import { setUserLanguage } from "../../store/User/actions";
import { processLanguageAndSave } from "../utilities/LanguageUtilities";
import "./helpers.css";

class LanguageSelector extends Component {
  state = {
    language: "",
  };

  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (prevProps.language !== language) {
      this.setState({
        language: language,
      });
    }
  }

  onSelectLanguage = (value) => {
    const { setUserLanguage } = this.props;
    const languageSelected = value;
    processLanguageAndSave(languageSelected, setUserLanguage);
  };

  render() {
    const { language } = this.props;
    const languageOptions = [
      { key: "English", text: "English", value: "en" },
      { key: "Español", text: "Español", value: "es" },
    ];
    let languageText = "";

    switch (language) {
      case "es":
        languageText = "Español";
        break;
      case "en":
        languageText = "English";
        break;
      default:
        languageText = "English";
        break;
    }

    return (
      <div className="languageSelector">
        <DropDownComponent options={languageOptions} onSelection={this.onSelectLanguage} text={languageText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.user.userProfile.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserLanguage: (lang) => dispatch(setUserLanguage(lang)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(withTranslation("Common")(LanguageSelector));
