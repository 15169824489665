import { sendGetRequest, sendPostRequest, sendDeleteRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";
import { getLanguagePrefix } from "../components/utilities/LanguageUtilities";
import moment from "moment";

export const getScenarioInformation = () => {
  return sendGetRequest(FETCHAPI.GET_MEDICA_SCENARIO_INFORMATION, "");
};

export const getProfessorScenarioList = () => {
  return sendGetRequest(FETCHAPI.GET_PROFESSOR_SCENARIOS, "");
};

export const formatProfessorScenarioList = (professorScenarios, language) => {
  const professorScenariosList = professorScenarios.map((scenario) => {
    return {
      ...scenario,
      createdAt: dateFormatter(scenario.createdAt, language),
      lastUsedDate: dateFormatter(scenario.lastUsedDate, language),
    };
  });

  return professorScenariosList;
};

const dateFormatter = (date, language) => {
  const languagePrefix = getLanguagePrefix(language);
  return moment(date).locale(languagePrefix).format(`MMMM D, YYYY`);
};

export const professorCreateNewScenario = (scenarioData) => {
  return sendPostRequest(FETCHAPI.PROFESSOR_CREATE_NEW_SCENARIO, scenarioData);
};

export const professorDeleteScenario = (scenarioId) => {
  return sendDeleteRequest(FETCHAPI.PROFESSOR_DELETE_SCENARIO, scenarioId);
};
