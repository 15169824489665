import _ from "lodash";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight, faSort } from "@fortawesome/free-solid-svg-icons";
import { Grid, Table } from "semantic-ui-react";
import DefaultButton from "../helpers/DefaultButton";

class StandingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: null,
      standingsList: props.simulationStandings,
      direction: null,
      expandedRows: [],
    };
  }

  renderItemCaret(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <FontAwesomeIcon fixedWidth={true} className="icon" icon={faChevronDown} size="lg" color={"#0379ba"} />;
    } else {
      return <FontAwesomeIcon fixedWidth={true} className="icon" icon={faChevronRight} size="lg" color={"#0379ba"} />;
    }
  }

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItem(item) {
    const translate = this.props.translate;
    const clickCallback = () => this.handleRowClick(item.standing);
    let caretCell = <Table.Cell />;
    let tableRowOnClick = null;

    if (item.teammates?.length > 0) {
      tableRowOnClick = clickCallback;
      caretCell = <Table.Cell collapsing>{this.renderItemCaret(item.standing)}</Table.Cell>;
    }

    const itemRows = [
      <Table.Row textAlign="center" onClick={tableRowOnClick} key={"row-data-" + item.standing}>
        {caretCell}
        <Table.Cell>{item.standing}</Table.Cell>
        <Table.Cell>{item.team}</Table.Cell>
        <Table.Cell>$ {item.cash.toFixed(2)}</Table.Cell>
        <Table.Cell>{item.teamLink}</Table.Cell>
      </Table.Row>,
    ];

    if (this.state.expandedRows.includes(item.standing)) {
      itemRows.push(
        <Table.Row key={"row-expanded-" + item.standing}>
          <Table.Cell colSpan="10">
            <Table textAlign="center" basic>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell className="team-members-table__header-cell">
                    {translate("firstName")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="team-members-table__header-cell">
                    {translate("lastName")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="team-members-table__header-cell">{translate("email")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{item.teammates.map(this.renderItemDetails.bind(this))}</Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      );
    }

    return itemRows;
  }

  renderItemDetails(item, index) {
    return (
      <Table.Row key={`team_member_${index}`} columns={3}>
        <Table.Cell>
          <span>{item.firstName}</span>
        </Table.Cell>

        <Table.Cell>
          <span>{item.lastName}</span>
        </Table.Cell>

        <Table.Cell>
          <span>{item.email}</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  handleSort = (clickedColumn) => () => {
    const { column, standingsList, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        standingsList: _.sortBy(standingsList, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      standingsList: standingsList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  numberWithCommas = (cash) => {
    return `${Math.round(cash).toLocaleString()}.00`;
  };

  render() {
    const translate = this.props.translate;
    const { column, direction, standingsList } = this.state;
    const arrowsIcon = <FontAwesomeIcon className="tableIcon" icon={faSort} size="lg" color="#FFF" />;
    return (
      <div id="standingsTable" className="tableContainer" ref={(ref) => (this.topTable = ref)}>
        <Grid columns={2}>
          <Grid.Column>
            <p>{`${translate("totalTeams")}: ${standingsList.length - 1}`}</p>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <DefaultButton title={translate("share")} action={this.props.openShareModalHandler} />
          </Grid.Column>
        </Grid>

        <Table unstackable sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell sorted={column === "standing" ? direction : null} onClick={this.handleSort("standing")}>
                <div className="headerCell">
                  {translate("standings").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "team" ? direction : null} onClick={this.handleSort("team")}>
                <div className="headerCell">
                  {translate("Common:team").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "cash" ? direction : null} onClick={this.handleSort("cash")}>
                <div className="headerCell">
                  {translate("cash").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "cash" ? direction : null} onClick={this.handleSort("cash")}>
                <div className="headerCell">
                  {translate("joinTeamLink").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {standingsList.map((standing) => {
              return this.renderItem(standing);
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default StandingsComponent;
