import React, { Component } from "react";
import { connect } from "react-redux";
import Payment from "../payment";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";
import * as API_PATHS from "../../constants/FetchApi.js";
import {
  checkIfStudentHasPaid,
  crisisResetDiscountCode,
  crisisValidateDiscountCode,
  restoreCrisisPurchaseInformationFromStorage,
  updateTransactionCrisisData,
} from "../../store/CrisisBuySimulation/actions";
import { getStorage, getStorageOrDefault, setStorage } from "../../services/StorageService";

class PaymentCrisis extends Component {
  getSuccessRoute(studentPayment) {
    if (studentPayment) return ROUTES.CRISIS_JOIN_SIMULATION;
    return ROUTES.CRISIS_CREATE_SIMULATION;
  }

  getSimulationId() {
    return getStorageOrDefault(LOCAL_STORAGE.crisisJoinSimulationId);
  }

  resetDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const crisisQuestionnaire = getStorage(LOCAL_STORAGE.crisisQuestionnaire);
    delete crisisQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.crisisQuestionnaire, crisisQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const {
      discountCodeStatus,
      studentPayment,
      studentHasAlreadyPaid,
      questionnaire,
      checkStorageInformation,
      checkIfStudentHasPaid,
      updateTransactionData,
      validateDiscountCode,
    } = this.props;

    const paymentIntentAPI = API_PATHS.CRISIS_GET_PAYMENT_INTENT;
    const productPrice = process.env.REACT_APP_CRISIS_PRICE;
    const successRoute = this.getSuccessRoute(studentPayment);
    const simulationId = this.getSimulationId();
    return (
      <Payment
        simulatorType={"Bindz"}
        studentPayment={studentPayment}
        simulationId={simulationId}
        studentHasAlreadyPaid={studentHasAlreadyPaid}
        discountCodeStatus={discountCodeStatus}
        questionnaire={questionnaire}
        checkStorageInformation={checkStorageInformation}
        checkIfStudentHasPaid={checkIfStudentHasPaid}
        updateTransactionData={updateTransactionData}
        validateDiscountCode={validateDiscountCode}
        resetDiscountCode={this.resetDiscountCode}
        productPrice={productPrice}
        paymentIntentAPI={paymentIntentAPI}
        successRoute={successRoute}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkIfStudentHasPaid: (simulationId) => dispatch(checkIfStudentHasPaid(simulationId)),
  checkStorageInformation: () => dispatch(restoreCrisisPurchaseInformationFromStorage()),
  updateTransactionData: (data) => dispatch(updateTransactionCrisisData(data)),
  validateDiscountCode: (discountCode) => dispatch(crisisValidateDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(crisisResetDiscountCode()),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      language: state.user.userProfile.language,
    },
    studentHasAlreadyPaid: state.crisisBuySimulation.studentHasAlreadyPaid,
    discountCodeStatus: state.crisisBuySimulation.discountCode,
    questionnaire: {
      numberOfStudents: state.crisisBuySimulation.buyForm.numberOfStudents,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCrisis);
