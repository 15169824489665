import * as actions from "./action-types";

export const initialState = {
  simulationDetails: {
    data: null,
    outcome: null,
  },
  simulationStandings: {
    payload: null,
    outcome: null,
  },
  simulationRegisteredStudents: {
    payload: null,
    outcome: null,
  },
  advanceSimunationSuccess: null,
  loadingDetails: null,
  loadingAdvanceSimunation: null,
  loadingRegisteredStudents: null,
  loadingStandings: null,
  getSimulationDetailsError: "",
  getStandingsError: "",
  getRegisteredStudentsError: "",
  advanceSimunationErrorMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_SIMUNATION_DETAILS:
      return {
        ...state,
        loadingDetails: true,
        getSimulationDetailsError: "",
      };
    case actions.GET_SIMUNATION_DETAILS_SUCCESSFUL:
      return {
        ...state,
        simulationDetails: action.payload,
        loadingDetails: false,
      };
    case actions.GET_SIMUNATION_DETAILS_FAILED:
      return {
        ...state,
        getSimulationDetailsError: action.payload.message,
        loadingDetails: false,
      };
    case actions.ADVANCE_SIMUNATION:
      return {
        ...state,
        loadingAdvanceSimunation: true,
        advanceSimunationErrorMessage: "",
        advanceSimunationSuccess: false,
      };
    case actions.ADVANCE_SIMUNATION_SUCCESSFUL:
      return {
        ...state,
        advanceSimunationSuccess: true,
        loadingAdvanceSimunation: false,
      };
    case actions.ADVANCE_SIMUNATION_FAILED:
      return {
        ...state,
        advanceSimunationErrorMessage: action.payload.message,
        loadingAdvanceSimunation: false,
      };
    case actions.RESET_ADVANCE_SIMUNATION_DATA:
      return {
        ...state,
        advanceSimunationSuccess: null,
        advanceSimunationErrorMessage: "",
        loadingAdvanceSimunation: null,
      };
    case actions.GET_SIMUNATION_STANDINGS:
      return {
        ...state,
        loadingStandings: true,
        getStandingsError: "",
      };
    case actions.GET_SIMUNATION_STANDINGS_SUCCESSFUL:
      return {
        ...state,
        simulationStandings: action.payload,
        loadingStandings: false,
      };
    case actions.GET_SIMUNATION_STANDINGS_FAILED:
      return {
        ...state,
        getStandingsError: action.payload.message,
        loadingStandings: false,
      };
    case actions.GET_SIMUNATION_REGISTERED_STUDENTS:
      return {
        ...state,
        loadingRegisteredStudents: true,
        getRegisteredStudentsError: "",
      };
    case actions.GET_SIMUNATION_REGISTERED_STUDENTS_SUCCESSFUL:
      return {
        ...state,
        simulationRegisteredStudents: action.payload,
        loadingRegisteredStudents: false,
      };
    case actions.GET_SIMUNATION_REGISTERED_STUDENTS_FAILED:
      return {
        ...state,
        getRegisteredStudentsError: action.payload.message,
        loadingRegisteredStudents: false,
      };
    default:
      return state;
  }
}
