import React, { Component } from "react";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as ROUTES from "../../constants/Routes";
import Products from "./Products";
import MedicaLogo from "../../assets/medica-logo-transparent.png";
import SimunationLogo from "../../assets/simunation-logo.png";
import CrisisLogo from "../../assets/crisis-logo.png";
import AbogadosLogo from "../../assets/abogados-logo.png";
import SupplyChainLogo from "../../assets/supplychain-logo.png";
import CreambowLogo from "../../assets/creambow-logo.png";

const CATALOGUE_LIST = [
  {
    key: 1,
    logo: MedicaLogo,
    name: CONSTANT.MEDICA_NAME,
    color: "#EFEFEF",
    flavorText: "medicaFlavor",
    topics: "medicaTopics",
    route: ROUTES.MEDICA_BUY_QUESTIONNAIRE,
  },
  {
    key: 2,
    logo: SimunationLogo,
    name: CONSTANT.SIMUNATION_NAME,
    color: "#7e217e",
    flavorText: "simunationFlavor",
    topics: "simunationTopics",
    route: ROUTES.SIMUNATION_BUY_QUESTIONNAIRE,
  },
  {
    key: 3,
    logo: CrisisLogo,
    name: CONSTANT.CRISIS_NAME,
    color: "#18B1B9",
    flavorText: "bindzFlavor",
    topics: "bindzTopics",
    route: ROUTES.CRISIS_BUY_QUESTIONNAIRE,
  },
  {
    key: 4,
    logo: SupplyChainLogo,
    name: CONSTANT.SUPPLY_CHAIN_NAME,
    color: "#006F5D",
    flavorText: "supplyGrainFlavor",
    topics: "supplyGrainTopics",
    route: ROUTES.SUPPLY_CHAIN_BUY_QUESTIONNAIRE,
  },
  {
    key: 5,
    logo: AbogadosLogo,
    name: CONSTANT.ABOGADOS_NAME,
    color: "#3a4149",
    flavorText: "abogadosFlavor",
    topics: "abogadosTopics",
    route: ROUTES.ABOGADOS_BUY_QUESTIONNAIRE,
  },
  {
    key: 6,
    logo: CreambowLogo,
    name: CONSTANT.CREAM_BOW_NAME,
    color: "#FAF9F9",
    flavorText: "creambowFlavor",
    topics: "creambowTopics",
    route: ROUTES.CREAMBOW_BUY_QUESTIONNAIRE,
  },
];

class CatalogueComponent extends Component {
  state = {
    catalogueList: CATALOGUE_LIST,
  };

  render() {
    const { catalogueList } = this.state;
    const translate = this.props.translate;
    return (
      <div className="homeMiddle">
        <Products productsList={catalogueList} translate={translate} history={this.props.history} />
      </div>
    );
  }
}

export default CatalogueComponent;
