import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import TutorialsComponent from "./TutorialsComponent";
import VideoViewerModal from "../modals/VideoViewerModal";
import { getTutorials } from "../../store/Tutorials/actions";
import * as CONSTANTS from "../../constants/UniversalConstants";
import "./TutorialsStyles.css";

class TutorialsScreen extends Component {
  state = {
    openVideoViewerModal: false,
    selectedUrlTutorial: "",
  };

  componentDidMount() {
    const { getTutorials } = this.props;
    getTutorials();
  }

  handleVideoViewerModal = (url) => {
    this.setState({
      openVideoViewerModal: !this.state.openVideoViewerModal,
      selectedUrlTutorial: url,
    });
  };

  getUserLanguage = (userLanguage) => {
    switch (userLanguage) {
      case CONSTANTS.LANGUAGES_LIST[1]:
        return "Spanish";
      default:
        return "English";
    }
  };

  render() {
    const { t, tutorialsData, language } = this.props;
    const { openVideoViewerModal, selectedUrlTutorial } = this.state;
    const { tutorials, loadingTutorials, tutorialsErrorMessage } = tutorialsData;
    const translate = t;
    const userLanguage = this.getUserLanguage(language);

    let mainComponent = null;
    let videoViewerModal = null;

    if (openVideoViewerModal) {
      videoViewerModal = (
        <VideoViewerModal
          openVideoViewerModal={openVideoViewerModal}
          handleVideoViewerModal={this.handleVideoViewerModal}
          url={selectedUrlTutorial}
          isTutorial={true}
        />
      );
    }

    if (loadingTutorials) {
      mainComponent = <LoadingComponent message={translate("loadingTutorials")} />;
    } else {
      if (tutorials) {
        mainComponent = tutorials
          .filter((section) => section.language === userLanguage)
          .map((section, index) => {
            return (
              <TutorialsComponent
                key={index}
                translate={translate}
                tutorials={section.tutorials}
                handleVideoViewerModal={this.handleVideoViewerModal}
              />
            );
          });
      } else {
        mainComponent = <MessagesComponent message={translate(tutorialsErrorMessage)} />;
      }
    }

    return (
      <>
        {videoViewerModal}
        <Container>
          <HeaderComponent translate={translate} showCompleteMenu={true} />
          <MainTitleComponent title="Videos" />
          {mainComponent}
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTutorials: () => dispatch(getTutorials()),
});

const mapStateToProps = (state) => {
  return {
    tutorialsData: {
      tutorials: state.tutorials.tutorials,
      loadingTutorials: state.tutorials.loadingTutorials,
      tutorialsErrorMessage: state.tutorials.tutorialsErrorMessage,
    },
    language: state.user.userProfile.language,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("TutorialsScreen", "Common")(withRouter(TutorialsScreen))
);
