import * as API_PATHS from "../../constants/FetchApi";
import * as actions from "./action-types";
import * as medicaService from "../../services/MedicaService";
import axios from "axios";
import * as actionTypes from "./action-types";
import { sendGetWithParamsRequest } from "../../services/FetchService";

export const getSimulationDetails = (simulationId, language, translate) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_DETAILS });
    axios
      .all([medicaService.getMedicaDetail(simulationId), medicaService.getMedicaLink(simulationId)])
      .then(
        axios.spread(function (medicaDetailResponse, medicaLinkResponse) {
          const response = medicaService.configureMedicaDetails(
            medicaDetailResponse,
            medicaLinkResponse,
            language,
            translate
          );
          if (response.outcome) {
            dispatch({
              type: actions.GET_MEDICA_DETAILS_SUCCESSFUL,
              payload: response,
            });
          } else {
            dispatch({
              type: actions.GET_MEDICA_DETAILS_FAILED,
              payload: response,
            });
          }
        })
      )
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchDetails",
          };
          dispatch({
            type: actions.GET_MEDICA_DETAILS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getStandings = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_STANDINGS });
    const standingsResponse = medicaService.getStandings(simulationName);
    standingsResponse
      .then((standings) => {
        if (standings) {
          const data = medicaService.prepareCustomResponse(standings, actions.GET_MEDICA_STANDINGS, "noStandingsList");
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchStandings",
          };
          dispatch({
            type: actions.GET_MEDICA_STANDINGS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getRegisteredStudents = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_REGISTERED_STUDENTS });
    const studentsResponse = medicaService.getRegisteredStudents(simulationName);
    studentsResponse
      .then((students) => {
        if (students) {
          const data = medicaService.prepareCustomResponse(
            students,
            actions.GET_MEDICA_REGISTERED_STUDENTS,
            "notStudentList"
          );
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchStudents",
          };
          dispatch({
            type: actions.GET_MEDICA_REGISTERED_STUDENTS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getTeams = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_TEAMS });
    const teamsResponse = medicaService.getTeams(simulationName);
    teamsResponse
      .then((teams) => {
        if (teams) {
          const data = medicaService.prepareCustomResponse(teams, actions.GET_MEDICA_TEAMS, "noTeamsList");
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchingTeams",
          };
          dispatch({
            type: actions.GET_MEDICA_TEAMS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getProfessorScenarios = () => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_PROFESSOR_SCENARIOS });
    const getScenariosResponse = medicaService.getProfessorScenarios();
    getScenariosResponse
      .then((response) => {
        if (response) {
          const data = medicaService.prepareCustomResponse(response, actions.GET_PROFESSOR_SCENARIOS, "");
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorGettingScenarios",
          };
          dispatch({
            type: actions.GET_PROFESSOR_SCENARIOS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const createTeam = (team) => {
  return async function (dispatch) {
    dispatch({ type: actions.CREATE_MEDICA_TEAM });
    const teamResponse = medicaService.createTeam(team);
    teamResponse
      .then((response) => {
        if (response) {
          const data = medicaService.prepareCustomResponse(response, actions.CREATE_MEDICA_TEAM, "");
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorCreatingTeam",
          };
          dispatch({
            type: actions.CREATE_MEDICA_TEAM_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const joinTeam = (team) => {
  return async function (dispatch) {
    dispatch({ type: actions.JOIN_MEDICA_TEAM });
    const teamResponse = medicaService.joinTeam(team);
    if (teamResponse) {
      teamResponse
        .then((response) => {
          if (response) {
            const data = medicaService.prepareJoinTeamResponse(response, actions.JOIN_MEDICA_TEAM, "");
            dispatch(data);
          }
        })
        .catch((error) => {
          if (error) {
            const response = {
              message: "errorJoiningTeam",
            };
            dispatch({
              type: actions.JOIN_MEDICA_TEAM_FAILED,
              payload: response,
            });
          }
        });
    } else {
      const response = {
        message: "errorJoiningTeam",
      };
      dispatch({
        type: actions.JOIN_MEDICA_TEAM_FAILED,
        payload: response,
      });
    }
  };
};

export const advanceSimulation = (simulationExecution) => {
  return async function (dispatch) {
    dispatch({ type: actions.ADVANCE_MEDICA_SIMULATION });
    const advanceSimulationResponse = medicaService.advanceSimulation(simulationExecution);
    advanceSimulationResponse
      .then((response) => {
        if (response) {
          const data = medicaService.prepareAdvanceResponse(response, actions.ADVANCE_MEDICA_SIMULATION);
          dispatch(data);
        }
      })
      .catch((error) => {
        let response = "";
        if (error.code === 500) {
          response = {
            message: "errorSimultaneousChangeRequest",
          };
        } else {
          response = {
            message: "errorAdvancingSimulation",
          };
        }

        dispatch({
          type: actions.ADVANCE_MEDICA_SIMULATION_FAILED,
          payload: response,
        });
      });
  };
};

export const checkIfStudentHasPaid = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actions.CHECK_STUDENT_PAYMENT });
    const studentHasPaidResponse = medicaService.checkForPreviousPayment(simulationId);

    studentHasPaidResponse
      .then((response) => {
        if (response.data.outcome) {
          if (response.data.message === "This simulation has already finished") {
            dispatch({
              type: actions.CHECK_STUDENT_PAYMENT_FAILED,
              payload: "simulationAlreadyFinished",
            });
          } else {
            const studentPaid = response.data.payload.studentPaid;
            dispatch({
              type: actions.CHECK_STUDENT_PAYMENT_SUCCESSFUL,
              payload: studentPaid,
            });
          }
        } else {
          dispatch({
            type: actions.CHECK_STUDENT_PAYMENT_SUCCESSFUL,
            payload: false,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actions.CHECK_STUDENT_PAYMENT_FAILED,
          payload: "checkPreviousPaymentError",
        });
      });
  };
};

export const resetAdvanceSimulationProps = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_ADVANCE_SIMULATION_PROPS });
  };
};

export const sendPushNotification = (pushData) => {
  return async function (dispatch) {
    dispatch({ type: actions.SEND_PUSH_NOTIFICATION });
    const advanceSimulationResponse = medicaService.sendPushNotification(pushData);
    advanceSimulationResponse
      .then((response) => {
        if (response.data.outcome) {
          dispatch({
            type: actions.SEND_PUSH_NOTIFICATION_SUCCESSFUL,
            payload: response.data.outcome,
          });
        } else {
          dispatch({
            type: actions.SEND_PUSH_NOTIFICATION_FAILED,
            payload: response.data.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorPushing",
          };
          dispatch({
            type: actions.SEND_PUSH_NOTIFICATION_FAILED,
            payload: response.message,
          });
        }
      });
  };
};

export const getPerformanceData = (simulationName) => {
  return async function (dispatch) {
    dispatch({
      type: actions.GET_MEDICA_PERFORMANCE_DATA,
    });
    const performanceDataResponse = medicaService.getPerformanceData(simulationName);
    performanceDataResponse
      .then((response) => {
        if (response.data.outcome) {
          dispatch({
            type: actions.GET_MEDICA_PERFORMANCE_DATA_SUCCESSFUL,
            payload: response.data.payload,
          });
        } else {
          const customResponse = {
            message: response.data.message,
          };
          dispatch({
            type: actions.GET_MEDICA_PERFORMANCE_DATA_FAILED,
            payload: customResponse.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorGettingPerformance",
          };
          dispatch({
            type: actions.GET_MEDICA_PERFORMANCE_DATA_FAILED,
            payload: response.message,
          });
        }
      });
  };
};

export const resetPushNotificationState = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_PUSH_NOTIFICATION_STATE });
  };
};

export const runAgainSimulation = (simulation) => {
  return async function (dispatch) {
    dispatch({ type: actions.RUN_AGAIN });
    const runAgainResponse = medicaService.runAgain(simulation);
    runAgainResponse
      .then((response) => {
        if (response.data.outcome) {
          dispatch({
            type: actions.RUN_AGAIN_SUCCESSFUL,
            payload: response.data.outcome,
          });
        } else {
          dispatch({
            type: actions.RUN_AGAIN_FAILED,
            payload: response.data.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorRunning",
          };
          dispatch({
            type: actions.RUN_AGAIN_FAILED,
            payload: response.message,
          });
        }
      });
  };
};

export const getDocuments = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_DOCUMENTATION });
    const documentsResponse = medicaService.getDocuments(simulationId);
    documentsResponse
      .then((documents) => {
        if (documents) {
          const documentsData = medicaService.prepareCustomResponse(
            documents,
            actions.GET_MEDICA_DOCUMENTATION,
            "documentationCurrentlyBeingCreated"
          );
          documentsData.payload.simulationId = simulationId;
          dispatch(documentsData);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchingDocuments",
          };
          dispatch({
            type: actions.GET_MEDICA_DOCUMENTATION_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getDecisionsData = (simulationName) => {
  return async function (dispatch) {
    dispatch({
      type: actions.GET_MEDICA_DECISIONS_DATA,
    });
    const performanceDataResponse = medicaService.getDecisionsData(simulationName);
    performanceDataResponse
      .then((response) => {
        if (response.data.outcome) {
          dispatch({
            type: actions.GET_MEDICA_DECISIONS_DATA_SUCCESSFUL,
            payload: response.data.payload,
          });
        } else {
          const customResponse = {
            message: response.data.message,
          };
          dispatch({
            type: actions.GET_MEDICA_DECISIONS_DATA_FAILED,
            payload: customResponse.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorGettingDecisions",
          };
          dispatch({
            type: actions.GET_MEDICA_DECISIONS_DATA_FAILED,
            payload: response.message,
          });
        }
      });
  };
};

export const medicaGetDecisionHistory = (simulationName, language) => {
  return async function (dispatch) {
    dispatch({ type: actions.MEDICA_GET_DECISION_HISTORY_START });
    try {
      const response = await sendGetWithParamsRequest(API_PATHS.GET_MEDICA_CHANGE_REQUEST_HISTORY, {
        simulationName,
        language,
      });
      if (!response.data.outcome) {
        dispatch({ type: actions.MEDICA_GET_DECISION_HISTORY_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actions.MEDICA_GET_DECISION_HISTORY_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actions.MEDICA_GET_DECISION_HISTORY_FAIL, payload: err });
    }
  };
};

export const medicaGetSuggestions = (simulationName, preferredLanguage) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.GET_MEDICA_SUGGESTIONS });
    try {
      const response = await sendGetWithParamsRequest(API_PATHS.GET_MEDICA_SUGGESTIONS, {
        simulationName,
        preferredLanguage,
      });
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.GET_MEDICA_SUGGESTIONS_FAILED, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.GET_MEDICA_SUGGESTIONS_SUCCESSFUL, payload: response.data.payload });
    } catch (error) {
      dispatch({ type: actionTypes.GET_MEDICA_SUGGESTIONS_FAILED, payload: error });
    }
  };
};

export const resetCreateTeamStore = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_CREATE_TEAM_STORE });
  };
};

export const resetRunAgainStore = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_RUN_AGAIN_STORE });
  };
};
