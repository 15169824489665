import React, { useState } from "react";
import { Accordion, Icon, List, Segment } from "semantic-ui-react";

export default function SuggestionAccordion(props) {
  const { suggestions } = props;

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const suggestionList = (x, teamName) => (
    <List>
      {x.map((suggestion) => {
        return (
          <List.Item as="a" onClick={() => props.selectSuggestion(teamName, suggestion.title, suggestion.message)}>
            <Segment color="grey">
              <List.Header>{suggestion.title}</List.Header>
              <List.Description>{suggestion.message}</List.Description>
            </Segment>
          </List.Item>
        );
      })}
    </List>
  );
  const accordionContent = Object.keys(suggestions).map((teamName, index) => {
    return (
      <>
        <Accordion.Title active={activeIndex === index} onClick={handleClick} index={index}>
          <Icon name="dropdown" />
          {teamName}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          {suggestionList(suggestions[teamName], teamName)}
        </Accordion.Content>
      </>
    );
  });

  return (
    <Accordion styled fluid>
      {accordionContent}
    </Accordion>
  );
}
