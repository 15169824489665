import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Container, Form, Grid, Image } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import CrisisShareSimulationModal from "../modals/CrisisShareModal";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import * as CONSTANT from "../../constants/UniversalConstants";
import crisisLogo from "../../assets/crisis-logo.png";
import CreateCrisisSimulationForm from "./createCrisisSimulationForm";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  crisisCreateSimulation,
  crisisCreateAutomaticSimulation,
  resetCreateSimulationDefaults,
  resetCrisisBuySimulationStoreDefaults,
  restoreCrisisPurchaseInformationFromStorage,
  crisisConfirmPayment,
} from "../../store/CrisisBuySimulation/actions";
import "./purchasedCrisisStyles.css";
import { createCrisisLink, crisisValidateDates } from "../utilities/CrisisSimulationUtilities";
import { withRouter } from "react-router-dom";
import { CRISIS_SIMULATION_DETAILS } from "../../constants/Routes";
import { crisisGetProfessorScenarios } from "../../store/CrisisSimulationDetails/actions";
import gearIcon from "../../assets/gear-icon.svg";
import AdvancedOptions from "../crisisAdvancedOptions/AdvancedOptions";
import { setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as CRISIS_CONSTANTS from "../../constants/CrisisConstants";
import { switchTo12HourFormat, unifyDateTime } from "../utilities/DateTimeUtilities";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

class PurchasedCrisis extends Component {
  constructor(props) {
    super(props);

    const startDate = moment().add(1, "hour");
    const endDate = startDate.clone().add(1, "day");
    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }

    this.state = {
      formValidated: false,
      openAdvancedOptions: false,
      advancedOptions: {
        scenarioId: "",
      },
      dateValidationError: "",
      form: {
        simulationName: {
          value: "",
          valid: true,
          rules: {
            minLength: 1,
          },
        },
        gameMode: {
          value: CRISIS_CONSTANTS.OneStationUnknownPeers,
          valid: true,
          rules: {},
        },
        professorAdvance: {
          value: true,
          valid: true,
          rules: {},
        },
        startTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(startDate).format("HH"))),
            hourFormat: moment(startDate).format("a"),
            date: startDate,
          },
          valid: true,
          rules: {},
        },
        endTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(endDate).format("HH"))),
            hourFormat: moment(endDate).format("a"),
            date: endDate,
          },
          valid: true,
          rules: {},
        },
      },
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    this.props.restoreCrisisPurchaseInformationFromStorage();
    this.props.resetCreateSimulationDefaults();
    this.validatePayment();
    this.props.getProfessorScenarios();
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  submitCreateSimulationRequest = (event) => {
    event.preventDefault();
    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const form = this.state.form;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    if (this.state.form.professorAdvance.value) {
      const request = {
        simulationName: this.state.form.simulationName.value,
        gameMode: this.state.form.gameMode.value,
        paymentSummaryId: this.props.paymentSummaryId,
        scenarioId: this.state.advancedOptions.scenarioId,
      };

      this.props.crisisCreateSimulation(request);
      return;
    } else {
      const unifiedStartTime = unifyDateTime(form.startTime.value);
      const unifiedEndTime = unifyDateTime(form.endTime.value);
      const dateValidation = crisisValidateDates(unifiedStartTime, unifiedEndTime);

      if (dateValidation.invalidEndTime || dateValidation.invalidStartTime) {
        this.setState({
          form: {
            ...form,
            startTime: {
              ...form.startTime,
              valid: !dateValidation.invalidStartTime,
            },
            endTime: {
              ...form.endTime,
              valid: !dateValidation.invalidEndTime,
            },
          },
          formValidated: true,
          dateValidationError: dateValidation.dateValidationError,
        });
        return;
      }

      const request = {
        simulationName: this.state.form.simulationName.value,
        gameMode: this.state.form.gameMode.value,
        paymentSummaryId: this.props.paymentSummaryId,
        startTime: unifiedStartTime,
        endTime: unifiedEndTime,
        scenarioId: this.state.advancedOptions.scenarioId,
      };

      this.props.crisisCreateAutomaticSimulation(request);
    }
  };

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.minHoursBeforeDate) {
      const timeNow = moment();
      const unifiedDate = unifyDateTime(value);
      isValid = isValid && unifiedDate.diff(timeNow) >= rules.minHoursBeforeDate;
    }
    return isValid;
  };

  onModalDismiss = () => {
    const simulationId = this.props.createSimulation.simulationId;
    this.props.resetCrisisBuySimulationStoreDefaults();
    setStorage(LOCALSTORAGE.requestedSimulation, simulationId);
    this.props.history.push(`${CRISIS_SIMULATION_DETAILS}?simulationId=${simulationId}`);
  };

  setDate = (date, dateToModify) => {
    let updatedForm = { ...this.state.form };

    const segmentedStartTime = updatedForm.startTime.value;
    const segmentedEndTime = updatedForm.endTime.value;

    if (dateToModify === "startDate" && date >= moment(segmentedEndTime.date).format("YYYY-MM-DD")) {
      segmentedStartTime.date = date;
      segmentedEndTime.date = moment(date).add(1, "day");
    } else {
      if (dateToModify === "startDate") {
        segmentedStartTime.date = date;
      } else {
        segmentedEndTime.date = date;
      }
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  setHour = (statePropertyToModify) => {
    return (event, { value }) => {
      let updatedForm = { ...this.state.form };
      const segmentedStartTime = updatedForm[statePropertyToModify].value;

      if (isNaN(value)) {
        segmentedStartTime.hourFormat = value;
      } else {
        segmentedStartTime.hour = value;
      }

      updatedForm = this.updateFormValidation(updatedForm);
      this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
    };
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  onAdvancedOptionsChanged = (id, value) => {
    const { advancedOptions } = this.state;
    this.setState({
      advancedOptions: {
        ...advancedOptions,
        [id]: value,
      },
    });
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  onAdvancedOptionsChanged = (id, value) => {
    const { advancedOptions } = this.state;
    this.setState({
      advancedOptions: {
        ...advancedOptions,
        [id]: value,
      },
    });
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;
    const { simulationCreated, createdSimulationMessage, simulationId, errorMessage, loading, shortLink } =
      this.props.createSimulation;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    let navigationButton = null;
    navigationButton = (
      <div className="crisisBottomArea">
        <Button basic type="submit" form="createSimulationForm">
          {translate("continue")}
        </Button>
      </div>
    );

    if (loading) {
      navigationButton = <LoadingComponent message={translate("creatingSimulation")} />;
    }

    let messagesComponent = null;
    const errorToTranslate = errorMessage !== "" ? errorMessage : this.state.dateValidationError;
    if (errorToTranslate !== "") {
      messagesComponent = <MessagesComponent message={translate(errorToTranslate)} />;
    }

    const linkToDisplay =
      typeof shortLink === "string" ? shortLink : createCrisisLink(simulationId, this.props.paymentSummaryId);

    const shareSimulationModal = (
      <CrisisShareSimulationModal
        translate={translate}
        open={simulationCreated}
        shareLink={linkToDisplay}
        titleModal={translate(createdSimulationMessage)}
        titleSecondaryButton={translate("ModifyModal:accept")}
        handleSecondaryButton={() => this.onModalDismiss()}
        messageType={true}
      />
    );

    const { professorScenariosStatus } = this.props;
    const { advancedOptions, openAdvancedOptions } = this.state;
    const advancedOptionsComponent = (
      <>
        <AdvancedOptions
          scenarios={professorScenariosStatus.scenarios}
          advancedOptions={advancedOptions}
          advancedOptionsChanged={this.onAdvancedOptionsChanged}
        />
        <Grid>
          <Grid.Column width={16}>{navigationButton}</Grid.Column>
        </Grid>
      </>
    );

    const willDisplayAdvancedOptionsButton =
      professorScenariosStatus.scenarios !== null &&
      Array.isArray(professorScenariosStatus.scenarios) &&
      professorScenariosStatus.scenarios.length > 0;

    return (
      <Container>
        {shareSimulationModal}
        <HeaderComponent translate={translate} />
        <Grid className="customGrid">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.CRISIS_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.CRISIS_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column className="formColumn" mobile={16} computer={8} tablet={10}>
            <div>
              <Form id="createSimulationForm" onSubmit={this.submitCreateSimulationRequest}>
                <CreateCrisisSimulationForm
                  formData={this.state.form}
                  formChanged={this.onFormChanged.bind(this)}
                  segmentedStartTime={this.state.form.startTime.value}
                  segmentedEndTime={this.state.form.endTime.value}
                  setDate={this.setDate}
                  setStartHour={this.setHour("startTime")}
                  setEndHour={this.setHour("endTime")}
                  language={this.props.language}
                />
              </Form>
              {willDisplayAdvancedOptionsButton ? (
                <Button onClick={this.handleAdvancedOptionsComponent} id="whiteAndBlueButton">
                  <Image className="ui top aligned tiny image" src={gearIcon} id="imageButton" />
                  {translate("SupplyChainPurchased:AdvancedOptions")}
                </Button>
              ) : null}
            </div>
          </Grid.Column>
          <Grid.Column stretched className="logoColumn" only="tablet computer" computer={8} tablet={6}>
            <div className="crisisLogoContainer">
              <Image className="crisisLogo" src={crisisLogo} />
            </div>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            {messagesComponent}
            <Grid.Column width={16}>{navigationButton}</Grid.Column>
          </Grid.Row>
        </Grid>
        {openAdvancedOptions ? advancedOptionsComponent : null}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmPaymentStatus: state.crisisBuySimulation.confirmPayment,
  professorScenariosStatus: state.crisisSimulationDetails.professorScenarios,
  paymentSummaryId: state.crisisBuySimulation.payment.paymentSummaryId,
  language: state.user.userProfile.language,
  createSimulation: state.crisisBuySimulation.createSimulation,
});

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(crisisConfirmPayment(intentId)),
  getProfessorScenarios: () => dispatch(crisisGetProfessorScenarios()),
  crisisCreateSimulation: (request) => dispatch(crisisCreateSimulation(request)),
  crisisCreateAutomaticSimulation: (request) => dispatch(crisisCreateAutomaticSimulation(request)),
  restoreCrisisPurchaseInformationFromStorage: () => dispatch(restoreCrisisPurchaseInformationFromStorage()),
  resetCreateSimulationDefaults: () => dispatch(resetCreateSimulationDefaults()),
  resetCrisisBuySimulationStoreDefaults: () => dispatch(resetCrisisBuySimulationStoreDefaults()),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("CrisisBuySimulation", "CrisisPurchased", "Common"),
  withRouter
);

export default HOC(PurchasedCrisis);
