import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getPaymentIntent } from "../../store/StripePayment/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import CheckoutForm from "./CheckoutForm";
import LoadingComponent from "../helpers/LoadingComponent";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import "./PaymentManagerStripe.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const appearance = {
  theme: "none",
  variables: {
    colorBackground: "#ffffff",
    colorTextPlaceholder: "#e0e0e0",
    colorText: "#272727",

    colorDanger: "#df1b41",
    fontFamily: "Chivo, system-ui, sans-serif",
    spacingUnit: "4px",
  },
  labels: "above",
  rules: {
    ".Input": {
      borderRadius: "0px",
      border: "2px solid #d7d7d7",
    },
    ".Input:focus::placeholder": {
      color: "rgba(115,115,115,.87)",
    },
    ".Input:focus": {
      outline: "none",
    },
  },
};

class PaymentManagerStripe extends Component {
  async componentDidMount() {
    if (this.props.amount > 0) await this.getPaymentIntent();
  }

  async getPaymentIntent() {
    const { simulationId, discountCode, numberOfLicenses, amount, description, creatingSimulation, paymentIntentAPI } =
      this.props;
    const request = {
      simulationId: simulationId,
      discountCode: discountCode,
      numberOfLicenses: numberOfLicenses,
      amount: amount * 100,
      description: description,
      creatingSimulation: creatingSimulation,
    };
    await this.props.getPaymentIntent(request, paymentIntentAPI);
  }

  async componentDidUpdate(prevProps) {
    const { amount } = this.props;
    if (amount !== prevProps.amount && amount > 0) {
      await this.getPaymentIntent();
    }
  }

  render() {
    const { paymentIntent, successRoute, amount } = this.props;
    const returnUrl = `${process.env.REACT_APP_AUTH0_RETURN_TO}${successRoute}`;
    const clientSecret = paymentIntent.clientSecret.length > 0 ? paymentIntent.clientSecret : undefined;
    const options = {
      clientSecret,
      appearance,
      locale: this.props.i18n.language,
    };

    const displayElement = clientSecret && amount > 0;
    return (
      <Grid className="paymentContainer">
        <Grid.Row stretched columns={"16"} centered>
          <Grid.Column width={"15"} centered>
            {displayElement && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm return_url={returnUrl} />
              </Elements>
            )}
            {paymentIntent.loading && <LoadingComponent message="Loading Payment"></LoadingComponent>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentIntent: state.stripePayment.paymentIntent,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentIntent: (request, api) => dispatch(getPaymentIntent(request, api)),
});

const HOC = compose(connect(mapStateToProps, mapDispatchToProps), withTranslation());

export default HOC(PaymentManagerStripe);
