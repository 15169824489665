import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import RegisteredStudentsComponent from "./RegisteredStudentsComponent";
import { getRegisteredStudents } from "../../store/Medica/actions";
import { getUrlSearchParam } from "../../services/NavigationService";

class RegisteredStudentsScreen extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  componentDidMount() {
    this.getRegisteredStudents();
  }

  getRegisteredStudents = () => {
    this.props.getRegisteredStudents(this.state.requestedSimulationName);
  };

  render() {
    const translate = this.props.t;
    const registeredStudents = this.props.medica.registeredStudents;
    const error = this.props.medica.error;
    const loading = this.props.medica.loading;
    let loader = null,
      messageComponent = null,
      registeredStudentsComponent = null;

    if (loading) {
      loader = <LoadingComponent message={translate("loadingRegisteredStudents")} />;
    } else {
      if (error !== "") {
        let isInfo = false;
        if (error === "notStudentList") isInfo = true;
        messageComponent = <MessagesComponent info={isInfo} message={translate(error)} />;
      } else {
        if (registeredStudents.outcome) {
          registeredStudentsComponent = (
            <RegisteredStudentsComponent
              translate={translate}
              simulationRegisteredStudents={registeredStudents.payload}
            />
          );
        }
      }
    }
    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent title={translate("registeredStudentsOf")} subtitle={this.state.requestedSimulationName} />
        {loader}
        {messageComponent}
        {registeredStudentsComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRegisteredStudents: (simulationName) => dispatch(getRegisteredStudents(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    medica: {
      registeredStudents: state.medica.simulationRegisteredStudents,
      loading: state.medica.loadingRegisteredStudents,
      error: state.medica.getRegisteredStudentsError,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("RegisteredStudentsScreen", "Common")(withRouter(RegisteredStudentsScreen))
);
