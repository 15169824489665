import React, { Component } from "react";
import { List, Image } from "semantic-ui-react";
import medicaLogo from "../../assets/medica-logo-transparent.png";
import SimulationTitle from "./SimulationTitle";
import HelpHoverComponent from "../helpers/HelpHoverComponent";

const templateNames = {
  ATemplate: "MedicaAdvancedOptions:easyDifficultyTemplateName",
  BTemplate: "MedicaAdvancedOptions:mediumDifficultyTemplate",
  CTemplate: "MedicaAdvancedOptions:hardDifficultyTemplate",
  DTemplate: "MedicaAdvancedOptions:customOnlyDifficultyTemplate",
};

class MedicaDetailComponent extends Component {
  render() {
    const translate = this.props.translate;
    const simulationDetails = this.props.simulationDetails.data;
    const loadingDetails = this.props.loadingDetails;
    let detailBoxStyles = "detailsDataLoading";
    let listDetails = [];
    if (!loadingDetails && simulationDetails) {
      let customStartTime = null;
      let customEndDate = null;
      let templateScenarioName = null;
      detailBoxStyles = "detailsDataCharged";

      if (simulationDetails.templateName) {
        const templateNameWithTranslation = templateNames[simulationDetails.templateName];
        templateScenarioName =
          typeof templateNameWithTranslation === "string"
            ? translate(templateNameWithTranslation)
            : simulationDetails.templateName;
      }

      if (simulationDetails.professorManagedProgress) {
        customStartTime = translate("professorManaged");
        customEndDate = translate("professorManaged");
      } else {
        customStartTime = simulationDetails.startTimeFormatted;
        customEndDate = simulationDetails.endDateFormatted;
      }
      listDetails = [
        {
          key: 1,
          item: translate("Common:currentDay"),
          content: simulationDetails.currentSimulationDay,
          hasHelp: false,
          helpMessage: "",
        },
        {
          key: 2,
          item: translate("Common:status"),
          content: translate(`Common:${simulationDetails.status}`),
          hasHelp: true,
          helpMessage: "medicaStatusHelp",
        },
        {
          key: 3,
          item: translate("startTime"),
          content: customStartTime,
          hasHelp: false,
          helpMessage: "",
        },
        {
          key: 4,
          item: translate("Common:endDate"),
          content: customEndDate,
          hasHelp: false,
          helpMessage: "",
        },
        {
          key: 5,
          item: translate("initializationPeriod"),
          content: `${simulationDetails.initializationPeriod} ${translate("virtualDays")}`,
          hasHelp: true,
          helpMessage: "medicaInitializationPeriodHelp",
        },
        {
          key: 6,
          item: translate("studentInteractionPeriod"),
          content: `${simulationDetails.studentInteractionPeriod} ${translate("virtualDays")}`,
          hasHelp: true,
          helpMessage: "medicaStudentInteractionPeriodHelp",
        },
        {
          key: 7,
          item: translate("endingPeriod"),
          content: `${simulationDetails.endOfSimulationPeriod} ${translate("virtualDays")}`,
          hasHelp: true,
          helpMessage: "medicaEndOfSimulationPeriodHelp",
        },
      ];

      if (templateScenarioName) {
        listDetails.push({
          key: 8,
          item: translate("templateName"),
          content: templateScenarioName,
          hasHelp: false,
          helpMessage: "",
        });
      }
      if (!simulationDetails.professorManagedProgress) {
        listDetails.push({
          key: 9,
          item: translate("virtualDaysPerHour"),
          content: simulationDetails.virtualDaysPerHour,
          hasHelp: true,
          helpMessage: "medicaVirtualDaysHelp",
        });
      }
    } else {
      listDetails = [
        { key: 1, item: translate("Common:currentDay"), content: "" },
        { key: 2, item: translate("Common:status"), content: "" },
        { key: 3, item: translate("startTime"), content: "" },
        { key: 4, item: translate("Common:endDate"), content: "" },
        { key: 5, item: translate("initializationPeriod"), content: "" },
        { key: 6, item: translate("studentInteractionPeriod"), content: "" },
        { key: 7, item: translate("endingPeriod"), content: "" },
        { key: 8, item: translate("virtualDaysPerHour"), content: "" },
      ];
    }
    return (
      <div>
        <SimulationTitle title={simulationDetails && !loadingDetails ? simulationDetails.name : ""} />
        <div className="detailsContainer">
          <div className="simulationIcon">
            <Image className="medicaLogo" src={medicaLogo} size="small" />
          </div>
          <div className={`detailsData ${detailBoxStyles}`}>
            <p>{translate("simulationDetails").toUpperCase()}</p>
            <List>
              {listDetails.map((detail) => {
                let helpHoverComponent = null;
                if (detail.hasHelp) {
                  helpHoverComponent = <HelpHoverComponent helpMessage={translate(detail.helpMessage)} />;
                }
                return (
                  <List.Item key={detail.key}>
                    {loadingDetails === true ? (
                      <div className="animated-background" />
                    ) : (
                      <div>
                        <strong>{detail.item}: </strong>
                        {detail.content}
                        {helpHoverComponent}
                      </div>
                    )}
                  </List.Item>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default MedicaDetailComponent;
