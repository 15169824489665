import moment from "moment";

export const switchTo24HourFormat = (hour, format) => {
  let formattedHour = 0;
  if (format === "am" && hour === 12) {
    formattedHour = hour - 12;
  } else if (format === "pm" && hour < 12) {
    formattedHour = hour + 12;
  } else {
    formattedHour = hour;
  }
  return formattedHour;
};

export const switchTo12HourFormat = (hour) => {
  let formattedHour = null;
  if (hour > 12) {
    formattedHour = hour - 12;
  } else {
    if (hour === 0) {
      formattedHour = 12;
    } else {
      formattedHour = hour;
    }
  }
  return formattedHour;
};

export const unifyDateTime = ({ date, hour, hourFormat }) => {
  const formattedTime = switchTo24HourFormat(hour, hourFormat);
  return moment(`${moment(date).format("YYYY-MM-DD")} ${formattedTime}`, "YYYY-MM-DD H");
};

export const convertHoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;
