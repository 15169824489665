import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Grid, Image } from "semantic-ui-react";
import NavigationButton from "../helpers/NavigationButton";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import HeaderComponent from "../header/HeaderComponent";
import PurchasedMedicaComponent from "./PurchasedMedicaComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import ShareSimulationModal from "../modals/ShareModal";
import MedicaAdvancedOptions from "../medicaAdvancedOptions";
import medicaLogo from "../../assets/medica-logo-transparent.png";
import * as FETCHAPI from "../../constants/FetchApi";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import {
  checkStorageInformation,
  updateSimulationInformation,
  resetMedicaQuestionnaireStore,
  medicaConfirmPayment,
} from "../../store//MedicaQuestionnaire/actions";
import { getSimulations } from "../../store/Simulations/actions";
import { getProfessorScenarios } from "../../store/Medica/actions";
import { sendPostRequest } from "../../services/FetchService";
import { preventBack, allowBack } from "../../services/NavigationService";
import {
  prepareSimulationData,
  handleStorage,
  prepareSimulationDataWithManagedProgress,
} from "../../services/MedicaQuestionnaireService";
import { getStorage, removeStorage, setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "./purchaseMedicaStyles.css";
import AddVariationInformationModal from "./AddVariationInformationModal";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

class PurchasedMedicaScreen extends Component {
  constructor(props) {
    super(props);
    let showAddVariationInformationModal = true;
    const dontShowVariationModalAgainFlag = getStorage(LOCALSTORAGE.dontShowAddVariationInformationModalAgain);
    if (dontShowVariationModalAgainFlag) {
      showAddVariationInformationModal = false;
    }

    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }
    this.state = {
      createdSimulation: false,
      errorMessage: "",
      simulationId: "",
      activationLink: "",
      createdSimulationMessage: "",
      loading: false,
      showForm: false,
      openAdvancedOptions: false,
      advancedOptions: {
        templateName: CONSTANT.SIMULATION_DEFAULT_TEMPLATE,
        addVariation: false,
        addSuggestions: false,
      },
      paymentIntentId: paymentIntentId,
      showAddVariationInformationModal: showAddVariationInformationModal,
    };
  }

  async componentDidMount() {
    const checkStorage = this.props.checkStorageInformation();
    await this.validatePayment();
    checkStorage.then((isChecked) => {
      if (isChecked) {
        this.setState({
          showForm: true,
        });
      }
    });

    preventBack();

    this.lookingQuestionnaireCache();
    this.getProfessorScenarios();
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  componentWillUnmount() {
    allowBack();
  }

  getProfessorScenarios = () => {
    this.props.getProfessorScenarios();
  };

  lookingQuestionnaireCache = () => {
    const storageData = getStorage(LOCALSTORAGE.simulationCreated);
    if (storageData) {
      const keys = Object.keys(storageData);
      const values = Object.values(storageData);
      for (let i = 0; i < keys.length; i++) {
        this.setState({
          [keys[i]]: values[i],
          createdSimulationMessage: "simulationAddedSuccessfully",
          createdSimulation: true,
        });
      }
    }
  };

  createSimulation = (simulationData) => {
    let creatingSimulationResponse = null;
    if (this.props.medicaQuestionnaire.professorAdvanceSimulation) {
      creatingSimulationResponse = sendPostRequest(FETCHAPI.MEDICA_CREATE_SIMULATION_MANAGED_PROGRESS, simulationData);
    } else {
      creatingSimulationResponse = sendPostRequest(FETCHAPI.MEDICA_CREATE_SIMULATION, simulationData);
    }
    creatingSimulationResponse
      .then((response) => {
        if (response.data.outcome) {
          const data = response.data.payload;
          this.setState({
            loading: false,
            activationLink: data.activationLink,
            simulationId: data.simulationId,
            createdSimulationMessage: "simulationAddedSuccessfully",
            createdSimulation: true,
          });
          handleStorage(data.simulationId, data.activationLink, null);
        } else {
          this.setState({
            errorMessage: response.data.message,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({
            errorMessage: "errorCreatingSimulation",
            loading: false,
          });
        }
      });
  };

  handleContinue = async () => {
    const { language, updateSimulationInformation } = this.props;
    const { paymentIntentId } = this.state;
    this.setState({ errorMessage: "" });
    const purchasedData = this.refs.purchasedMedica.handleContinue();
    let simulationData = null;
    if (purchasedData) {
      const saving = await updateSimulationInformation(purchasedData);
      handleStorage(null, null, purchasedData);
      if (saving) {
        this.setState({ loading: true });
        if (this.props.medicaQuestionnaire.professorAdvanceSimulation) {
          simulationData = prepareSimulationDataWithManagedProgress(
            this.props.medicaQuestionnaire,
            paymentIntentId,
            language
          );
        } else {
          simulationData = prepareSimulationData(this.props.medicaQuestionnaire, paymentIntentId, language);
        }
        this.createSimulation(simulationData);
      }
    }
  };

  successRedirection = () => {
    const { language, t, getSimulations } = this.props;
    const translate = t;
    getSimulations(language, translate);
    removeStorage(LOCALSTORAGE.simulationCreated);
    this.props.resetMedicaQuestionnaireStore();
    setStorage(LOCALSTORAGE.requestedSimulation, this.state.simulationId);
    this.props.history.push({
      pathname: ROUTES.PURCHASED_MEDICA_DETAIL,
      search: this.state.simulationId,
    });
  };

  advancedOptionsClick = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  advancedOptionsChanged = (optionId, value) => {
    const newAdvancedOptions = {
      ...this.state.advancedOptions,
      [optionId]: value,
    };
    this.setState({
      advancedOptions: newAdvancedOptions,
    });
  };

  setErrorMessage = (errorMessage) => {
    this.setState({
      errorMessage,
    });
  };

  closeAddVariationModal = (neverShowAgain = false) => {
    if (neverShowAgain) {
      setStorage(LOCALSTORAGE.dontShowAddVariationInformationModalAgain, true);
    }
    this.setState({ showAddVariationInformationModal: false });
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;
    const {
      createdSimulation,
      activationLink,
      createdSimulationMessage,
      errorMessage,
      loading,
      openAdvancedOptions,
      advancedOptions,
    } = this.state;
    const { simulationName, startDate, endDate, professorAdvanceSimulation, totalSimulatedDays } =
      this.props.medicaQuestionnaire;
    const { customScenarios, loadingProfessorScenarios } = this.props.medica;

    let loader = null;
    let messagesComponent = null;
    let navigationButton = null;
    let purchasedMedicaComponent = null;
    let medicaAdvancedOptions = null;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    if (this.state.showForm) {
      purchasedMedicaComponent = (
        <PurchasedMedicaComponent
          ref="purchasedMedica"
          simulationName={simulationName}
          startDate={startDate}
          endDate={endDate}
          managedProgress={professorAdvanceSimulation}
          totalSimulatedDays={totalSimulatedDays}
          translate={translate}
          handleContinue={this.handleContinue}
          language={this.props.language}
          advancedOptionsClick={this.advancedOptionsClick}
          onError={this.setErrorMessage.bind(this)}
          advancedOptions={advancedOptions}
        />
      );
    }

    if (loading) {
      loader = (
        <Grid.Column width={16}>
          <LoadingComponent message={translate("creatingSimulation")} />
        </Grid.Column>
      );
    } else {
      navigationButton = (
        <Grid.Column width={16}>
          <NavigationButton action={this.handleContinue} label={translate("continue")} />
        </Grid.Column>
      );
    }

    if (errorMessage !== "") {
      messagesComponent = <MessagesComponent message={translate(errorMessage)} />;
    }

    const shareSimulationModal = (
      <ShareSimulationModal
        translate={translate}
        open={createdSimulation}
        shareLink={activationLink}
        titleModal={translate(createdSimulationMessage)}
        titleSecondaryButton={translate("ModifyModal:accept")}
        handleSecondaryButton={() => this.successRedirection()}
        messageType={true}
      />
    );

    const addVariationInformationModal = (
      <AddVariationInformationModal
        opened={this.state.showAddVariationInformationModal}
        showSuggestionsInformation={!this.props.medicaQuestionnaire.professorAdvanceSimulation}
        close={this.closeAddVariationModal}
      />
    );

    if (openAdvancedOptions && !loadingProfessorScenarios) {
      medicaAdvancedOptions = (
        <MedicaAdvancedOptions
          customScenarios={customScenarios}
          advancedOptions={advancedOptions}
          hideSuggestionsToggle={this.props.medicaQuestionnaire.professorAdvanceSimulation}
          advancedOptionsChanged={this.advancedOptionsChanged}
        />
      );
    }

    return (
      <Container>
        {shareSimulationModal}
        {addVariationInformationModal}
        <HeaderComponent translate={translate} />
        <Grid className="customGrid">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.MEDICA_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.MEDICA_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column className="formColumn" mobile={16} computer={8} tablet={10}>
            {purchasedMedicaComponent}
          </Grid.Column>
          <Grid.Column className="logoColumn" only="tablet computer" computer={8} tablet={6}>
            <Image className="medicaLogo" src={medicaLogo} />
          </Grid.Column>
        </Grid>
        <Grid>
          {openAdvancedOptions ? messagesComponent : null}
          {openAdvancedOptions ? loader : null}
          {openAdvancedOptions ? navigationButton : null}
          {medicaAdvancedOptions}
          <Grid.Row>
            {messagesComponent}
            {loader}
            {navigationButton}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkStorageInformation: () => dispatch(checkStorageInformation()),
  updateSimulationInformation: (data) => dispatch(updateSimulationInformation(data)),
  getSimulations: (language, translate) => dispatch(getSimulations(language, translate)),
  resetMedicaQuestionnaireStore: () => dispatch(resetMedicaQuestionnaireStore()),
  getProfessorScenarios: () => dispatch(getProfessorScenarios()),
  confirmPayment: (paymentIntentid) => dispatch(medicaConfirmPayment(paymentIntentid)),
});

const mapStateToProps = (state) => {
  return {
    medicaQuestionnaire: state.medicaQuestionnaire,
    role: state.user.userProfile.role,
    language: state.user.userProfile.language,
    confirmPaymentStatus: state.medicaQuestionnaire.confirmPayment,
    medica: {
      loadingProfessorScenarios: state.medica.loadingProfessorScenarios,
      getProfessorScenariosError: state.medica.getProfessorScenariosError,
      customScenarios: state.medica.customScenarios,
    },
  };
};

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("Purchased", "ModifyModal", "Common"),
  withRouter
);

export default HOC(PurchasedMedicaScreen);
