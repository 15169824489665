import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Container, Menu, Message, Popup, Tab, Table } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import "./decisionHistory.css";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: "true",
};

export class DecisionHistory extends Component {
  formatDate(dateText) {
    const { i18n } = this.props;

    const dateNumber = Date.parse(dateText);
    return new Date(dateNumber).toLocaleString(i18n.language, dateOptions);
  }

  renderDecisionTable = (decisions) => {
    const { t } = this.props;
    if (decisions.length === 0) {
      return <Message>{t("noChangesYet")}</Message>;
    }

    return (
      <Table fixed unstackable basic>
        <Table.Header>
          <Table.Row>
            <Table.Cell>{t("userName")}</Table.Cell>
            <Table.Cell>{t("description")}</Table.Cell>
            <Table.Cell>{t("timeSubmitted")}</Table.Cell>
            <Table.Cell>{t("simulationDay")}</Table.Cell>
          </Table.Row>
        </Table.Header>
        {decisions.map((x, index) => (
          <Table.Row>
            <Table.Cell>{x.userFullName}</Table.Cell>
            <Table.Cell>{x.description}</Table.Cell>
            <Table.Cell>{this.formatDate(x.timeSubmitted)}</Table.Cell>
            <Table.Cell>{x.simulationDay}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    );
  };

  renderMenuItem = (menuTitle) => {
    const numberOfLetters = 20;
    let menuItemContent = menuTitle;
    if (menuTitle.length > numberOfLetters) {
      menuItemContent = (
        <Popup
          content={menuTitle}
          trigger={<span>{`${menuTitle.substring(0, numberOfLetters - 1).trim()}...`}</span>}
        />
      );
    }

    return <Menu.Item> {menuItemContent}</Menu.Item>;
  };

  render() {
    const { t, simulationName, decisionHistoryStatus } = this.props;
    const { decisionHistory } = decisionHistoryStatus;

    const panes = Object.keys(decisionHistory).map((x) => ({
      menuItem: this.renderMenuItem(x),
      render: () => <Tab.Pane>{this.renderDecisionTable(decisionHistory[x])}</Tab.Pane>,
    }));
    return (
      <Container>
        <HeaderComponent translate={t} showBackButton />
        <MainTitleComponent title={t("DecisionHistoryTitle")} subtitle={simulationName}></MainTitleComponent>
        {decisionHistoryStatus.loading ? <LoadingComponent message={t("loadingDecisionHistory")} /> : null}
        {decisionHistoryStatus.outcome === false ? (
          <MessagesComponent message={t(decisionHistoryStatus.errorMessage)} />
        ) : null}
        {decisionHistoryStatus.outcome ? (
          <Tab
            menu={{
              className: "decision-history-tab__menu",
              vertical: true,
            }}
            panes={panes}
          />
        ) : null}
      </Container>
    );
  }
}

export default withTranslation("DecisionHistory")(DecisionHistory);
