import * as actions from "./action-types";
import * as scenarioInformationService from "../../services/ScenarioInformationService";
import { sentryReportEvent } from "../../services/SentryReportService";

export const getScenarioInformation = () => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_SCENARIO_INFORMATION });
    const scenarioInformationResponse = scenarioInformationService.getScenarioInformation();
    scenarioInformationResponse
      .then((scenarioInformation) => {
        if (scenarioInformation.data.outcome) {
          dispatch({
            type: actions.GET_SCENARIO_INFORMATION_SUCCESSFULL,
            payload: scenarioInformation.data.payload,
          });
        } else {
          dispatch({
            type: actions.GET_SCENARIO_INFORMATION_FAILED,
            payload: "noScenarioInformation",
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.GET_SCENARIO_INFORMATION_FAILED,
            payload: "errorFetchScenarioInformation",
          });
        }
      });
  };
};

export const getProfessorScenarioList = (language, translate) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_PROFESSOR_SCENARIO_LIST });
    const professorScenarioListResponse = scenarioInformationService.getProfessorScenarioList();
    professorScenarioListResponse
      .then((professorScenarioList) => {
        if (professorScenarioList.data.outcome) {
          const professorScenarios = scenarioInformationService.formatProfessorScenarioList(
            professorScenarioList.data.payload,
            language,
            translate
          );
          dispatch({
            type: actions.GET_PROFESSOR_SCENARIO_LIST_SUCCESSFULL,
            payload: professorScenarios,
          });
        } else {
          dispatch({
            type: actions.GET_PROFESSOR_SCENARIO_LIST_FAILED,
            payload: "noProfessorScenarioList",
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.GET_PROFESSOR_SCENARIO_LIST_FAILED,
            payload: "errorFetchProfessorScenarioList",
          });
        }
      });
  };
};

export const professorCreateNewScenario = (scenarioData) => {
  return async function (dispatch) {
    dispatch({ type: actions.PROFESSOR_CREATE_NEW_SCENARIO });
    const professorCreateNewScenarioResponse = scenarioInformationService.professorCreateNewScenario(scenarioData);
    professorCreateNewScenarioResponse
      .then((professorCreateNewScenario) => {
        if (professorCreateNewScenario.data.outcome) {
          const sentryTag = { name: "Team Name", data: professorCreateNewScenario.data.payload.team };
          sentryReportEvent("Professor Scenario Created", sentryTag, []);
          dispatch({
            type: actions.PROFESSOR_CREATE_NEW_SCENARIO_SUCCESSFULL,
            payload: "scenarioHasBeenCreatedSuccessfully",
          });
        } else {
          dispatch({
            type: actions.PROFESSOR_CREATE_NEW_SCENARIO_FAILED,
            payload: professorCreateNewScenario.data.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.PROFESSOR_CREATE_NEW_SCENARIO_FAILED,
            payload: "errorCreatingNewScenario",
          });
        }
      });
  };
};

export const professorDeleteScenario = (scenarioId) => {
  return async function (dispatch) {
    dispatch({ type: actions.PROFESSOR_DELETE_SCENARIO });
    const professorDeleteScenarioResponse = scenarioInformationService.professorDeleteScenario(scenarioId);
    professorDeleteScenarioResponse
      .then((professorDelete) => {
        if (professorDelete.data.outcome) {
          dispatch({
            type: actions.PROFESSOR_DELETE_SCENARIO_SUCCESSFULL,
            payload: "scenarioDeletedSuccesfully",
          });
        } else {
          dispatch({
            type: actions.PROFESSOR_DELETE_SCENARIO_FAILED,
            payload: "errorDeletingScenario",
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.PROFESSOR_DELETE_SCENARIO_FAILED,
            payload: "errorFetchProfessorDeleteScenario",
          });
        }
      });
  };
};

export const resetProfessorCreateNewScenarioStore = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_PROFESSOR_CREATE_NEW_SCENARIO_STORE });
  };
};

export const resetProfesorDeleteScenarioStore = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_PROFESSOR_DELETE_SCENARIO_STORE });
  };
};
