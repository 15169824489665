import React, { Component } from "react";
import { Header, Checkbox, Form, Divider } from "semantic-ui-react";
import * as CONSTANT from "../../constants/UniversalConstants";
import RecommendedIcon from "../../assets/recommended.svg";

class AdvancedOptions extends Component {
  constructor(props) {
    super(props);

    const { translate } = this.props;

    this.state = {
      predefinedScenarios: [
        {
          name: "DTemplate",
          title: translate("customOnlyDifficultyTemplate"),
          description: translate("customOnlyTemplateDescription"),
          recommended: false,
        },
        {
          name: "ATemplate",
          title: translate("easyDifficultyTemplateName"),
          description: translate("easyDifficultyTemplateDescription"),
        },
        {
          name: CONSTANT.SIMULATION_DEFAULT_TEMPLATE,
          title: translate("mediumDifficultyTemplate"),
          description: translate("mediumDifficultyTemplateDescription"),
          recommended: true,
        },
        {
          name: "CTemplate",
          title: translate("hardDifficultyTemplate"),
          description: translate("hardDifficultyTemplateDescription"),
        },
      ],
    };
  }

  componentDidMount() {
    this.concatCustomScenarios();
  }

  concatCustomScenarios = () => {
    const { customScenarios } = this.props;

    if (customScenarios) {
      const formattedScenarios = this.formatCustomScenarios(customScenarios);

      this.setState((state, props) => ({
        predefinedScenarios: state.predefinedScenarios.concat(formattedScenarios),
      }));
    }
  };

  formatCustomScenarios = (customScenarios) => {
    return customScenarios.map((scenario) => {
      scenario.title = scenario.name;
      return scenario;
    });
  };

  render() {
    const { translate, advancedOptions, advancedOptionsChanged, hideSuggestionsToggle } = this.props;
    const { predefinedScenarios } = this.state;

    const scenarioLabel = (title, description, recommended) => {
      let recommendedSign = null;
      if (recommended === true) {
        recommendedSign = (
          <span className="advancedOptionsRecommendedSpan">
            <img src={RecommendedIcon} alt="icon" className="advancedOptionsRecommendedIcon" />
            <span>{translate("recommended")}</span>
          </span>
        );
      }

      return (
        <label className="advancedOptionsLabelWrapper">
          <p className="advancedOptionsRadioNameLabel">
            {title} {recommendedSign}
          </p>
          <p className="advancedOptionsRadioDescriptionLabel">{description}</p>
        </label>
      );
    };

    let suggestionsToggle = null;
    if (!hideSuggestionsToggle) {
      suggestionsToggle = (
        <>
          <Divider section />
          <p>{translate("MedicaAdvancedOptions:addSuggestionsExplanation")}</p>
          <div className="medica-advanced-options__toggle">
            <label>{translate("MedicaAdvancedOptions:addSuggestions")}</label>
            <Checkbox
              toggle
              checked={advancedOptions.addSuggestions}
              onChange={(e, { checked }) => advancedOptionsChanged("addSuggestions", checked)}
            />
          </div>
        </>
      );
    }

    return (
      <div className="scenarioInformationContent">
        <Header>{translate("advancedOptionsTitle")}</Header>

        <p>{translate("advancedOptionsDescription1")}</p>
        <p>{translate("advancedOptionsDescription2")}</p>

        <Form>
          <div className="medica-advanced-options__toggle">
            <label>{translate("MedicaAdvancedOptions:addVariation")}</label>
            <Checkbox
              toggle
              checked={advancedOptions.addVariation}
              onChange={(e, { checked }) => advancedOptionsChanged("addVariation", checked)}
            />
          </div>

          {suggestionsToggle}

          <Divider section />
          <h4>{translate("MedicaAdvancedOptions:selectYourScenario")}</h4>
          {predefinedScenarios.map((scenario) => {
            return (
              <Form.Field key={scenario.name}>
                <Checkbox
                  radio
                  label={scenarioLabel(scenario.title, scenario.description, scenario.recommended)}
                  name="checkboxRadioGroup"
                  value={scenario.name}
                  checked={advancedOptions.templateName === scenario.name}
                  onChange={(e, { value }) => advancedOptionsChanged("templateName", value)}
                />
              </Form.Field>
            );
          })}
        </Form>
      </div>
    );
  }
}

export default AdvancedOptions;
