import React from "react";
import { Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const MobileActionButtons = (props) => {
  const translate = props.translate;
  let actionButtons = props.actionButtons;

  return (
    <Dropdown className="onlyMobile" trigger={<FontAwesomeIcon color="#494848" icon={faBars} size="2x" />}>
      <Dropdown.Menu direction="left">
        {actionButtons.map((button) => {
          const buttonName = translate(button.name).toUpperCase();
          if (button.name !== "Common:back") {
            return (
              <Dropdown.Item key={button.key} className="dropDownItem" onClick={button.action}>
                {buttonName}
              </Dropdown.Item>
            );
          } else {
            return null;
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MobileActionButtons;
