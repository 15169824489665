import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import * as CONSTANT from "../constants/UniversalConstants";
import * as Sentry from "@sentry/react";

export default function configureStore(history, initialState) {
  let store = {};
  let sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: sentryStateTransform,
    actionTransformer: sentryActionTransform,
  });
  if (process.env.NODE_ENV === CONSTANT.ENV_DEVELOPMENT) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      createRootReducer(history),
      initialState,
      composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)), sentryReduxEnhancer)
    );
  } else {
    store = createStore(
      createRootReducer(history),
      initialState,
      compose(applyMiddleware(thunk, routerMiddleware(history)), sentryReduxEnhancer)
    );
  }

  return store;
}

const sentryStateTransform = (state) => {
  const transformedState = {
    ...state,
    user: null,
    supplyChainSimulationDetails: {
      ...state.supplyChainSimulationDetails,
      simulationPerformanceDataRequestStatus: {
        ...state.supplyChainSimulationDetails,
        performanceData: {},
      },
    },
    simulations: {
      ...state.simulations,
      data: null,
    },
    crisisSimulationDetails: {
      ...state.crisisSimulationDetails,
      simulationPerformanceDataRequestStatus: {
        ...state.supplyChainSimulationDetails,
        performanceData: {},
      },
    },
    simulationPerformanceDataRequestStatus: null,
  };

  return transformedState;
};

const sentryActionTransform = (action) => {
  return action;
};
