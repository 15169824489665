import * as actions from "./action-types";
import * as tutorialsService from "../../services/TutorialsService";

export const getTutorials = () => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_TUTORIALS });
    const tutorialsResponse = tutorialsService.getTutorials();
    tutorialsResponse
      .then((tutorials) => {
        if (tutorials.data.outcome) {
          dispatch({
            type: actions.GET_TUTORIALS_SUCCESSFULL,
            payload: tutorialsService.formatTutorials(tutorials.data.payload),
          });
        } else {
          dispatch({
            type: actions.GET_TUTORIALS_FAILED,
            payload: "noVideosList",
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.GET_TUTORIALS_FAILED,
            payload: "errorFetchVideos",
          });
        }
      });
  };
};
