export const COMMIT_PURCHASE_FORM_DATA = "ABOGADOS_BUY_SIMULATION/COMMIT_PURCHASE_FORM_DATA";
export const VALIDATE_DISCOUNT_CODE = "ABOGADOS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_FAILED = "ABOGADOS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_FAILED";
export const VALIDATE_DISCOUNT_CODE_SUCCESS = "ABOGADOS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS = "ABOGADOS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_RESET = "ABOGADOS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_RESET";
export const RESTORE_STORAGE_PURCHASE_INFORMATION = "ABOGADOS_BUY_SIMULATION/RESTORE_STORAGE_PURCHASE_INFORMATION";
export const UPDATE_TRANSACTION_DATA = "ABOGADOS_BUY_SIMULATION/UPDATE_TRANSACTION_DATA";
export const CREATE_SIMULATION_REQUEST_START = "ABOGADOS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_START";
export const CREATE_SIMULATION_REQUEST_SUCCESS = "ABOGADOS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_SUCCESS";
export const CREATE_SIMULATION_REQUEST_FAIL = "ABOGADOS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_FAIL";
export const RESET_CREATE_SIMULATION_DEFAULTS = "ABOGADOS_BUY_SIMULATION/RESET_CREATE_SIMULATION_DEFAULTS";
export const RESET_ABOGADOS_BUY_SIMULATION_DEFAULTS = "ABOGADOS_BUY_SIMULATION/RESET_ABOGADOS_BUY_SIMULATION_DEFAULTS";