import React from "react";
import { Image } from "semantic-ui-react";
import LogoutButton from "../helpers/LogoutButton";
import { withRouter } from "react-router-dom";
import BackButton from "../helpers/BackButton";
import HeaderButtons from "./HeaderButtons";
import LanguageSelector from "../helpers/LanguageSelector";
import processimLogo from "../../assets/pl-logo.png";
import "./header.css";

const HeaderComponent = (props) => {
  const { translate, showLogoutButton, showBackButton, dropdown, showCompleteMenu, showLanguageSelector, history } =
    props;
  let logoutButton = null;
  let dropDownButton = null;
  let backButton = null;
  let headerButtons = null;
  let languageSelector = null;

  if (showLogoutButton) {
    logoutButton = <LogoutButton label={translate("Common:logout")} />;
  }

  if (props.showBackButton) {
    backButton = <BackButton translate={translate} history={history} showBackButton={showBackButton} />;
  }

  if (dropdown) {
    dropDownButton = dropdown;
  }

  if (showCompleteMenu) {
    headerButtons = <HeaderButtons translate={translate} history={history} />;
  }

  if (showLanguageSelector) {
    languageSelector = <LanguageSelector />;
  }

  return (
    <div className="customHeader">
      <div className="headerLeft">
        <a href="https://processimlabs.com">
          <Image className="logo" src={processimLogo} size="small" />
        </a>
      </div>
      <div className="headerRight">
        {dropDownButton}
        {backButton}
        {headerButtons}
        <div className="studentActions">
          {languageSelector}
          {logoutButton}
        </div>
      </div>
    </div>
  );
};

export default withRouter(HeaderComponent);
