export const GET_SIMUNATION_DETAILS = "Simunation/GET_SIMUNATION_DETAILS";
export const GET_SIMUNATION_DETAILS_SUCCESSFUL = "Simunation/GET_SIMUNATION_DETAILS_SUCCESSFUL";
export const GET_SIMUNATION_DETAILS_FAILED = "Simunation/GET_SIMUNATION_DETAILS_FAILED";
export const ADVANCE_SIMUNATION = "Simunation/ADVANCE_SIMUNATION";
export const ADVANCE_SIMUNATION_SUCCESSFUL = "Simunation/ADVANCE_SIMUNATION_SUCCESSFUL";
export const ADVANCE_SIMUNATION_FAILED = "Simunation/ADVANCE_SIMUNATION_FAILED";
export const RESET_ADVANCE_SIMUNATION_DATA = "Simunation/RESET_ADVANCE_SIMUNATION_DATA";
export const GET_SIMUNATION_STANDINGS = "Simunation/GET_SIMUNATION_STANDINGS";
export const GET_SIMUNATION_STANDINGS_SUCCESSFUL = "Simunation/GET_SIMUNATION_STANDINGS_SUCCESSFUL";
export const GET_SIMUNATION_STANDINGS_FAILED = "Simunation/GET_SIMUNATION_STANDINGS_FAILED";
export const GET_SIMUNATION_REGISTERED_STUDENTS = "Simunation/GET_SIMUNATION_REGISTERED_STUDENTS";
export const GET_SIMUNATION_REGISTERED_STUDENTS_SUCCESSFUL = "Simunation/GET_SIMUNATION_REGISTERED_STUDENTS_SUCCESSFUL";
export const GET_SIMUNATION_REGISTERED_STUDENTS_FAILED = "Simunation/GET_SIMUNATION_REGISTERED_STUDENTS_FAILED";
