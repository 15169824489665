import { sendGetRequest, sendPostRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";

export const getSimunationDetail = (simulationId) => {
  return sendGetRequest(FETCHAPI.GET_SIMULATION_DETAIL_SIMUNATION, simulationId);
};

export const advanceSimunationAction = (simulationId) => {
  return sendPostRequest(FETCHAPI.ADVANCE_SIMULATION_SIMUNATION, simulationId);
};

export const prepareCustomResponse = (response, action, emptyError) => {
  let customResponse = null;
  if (response.data.outcome) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: response.data,
    };
  } else {
    const data = {
      message: response.data.message,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }
  return customResponse;
};

export const prepareCustomListResponse = (response, action, emptyError, areStandings) => {
  let customResponse = null;
  if (response.data.outcome && response.data.payload.length === 0) {
    const data = {
      message: emptyError,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }

  if (response.data.outcome && response.data.payload.length > 0) {
    if (areStandings) {
      customResponse = {
        type: `${action}_SUCCESSFUL`,
        payload: formatStandingsItems(response.data),
      };
    } else {
      customResponse = {
        type: `${action}_SUCCESSFUL`,
        payload: response.data,
      };
    }
  }

  if (!response.data.outcome) {
    const data = {
      message: response.data.message,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }
  return customResponse;
};

export const getStandings = (simulationName) => {
  return sendGetRequest(`${FETCHAPI.GET_STANDINGS_SIMUNATION}${simulationName}`, "");
};

const formatStandingsItems = (standings) => {
  let newPayload = [];
  if (standings) {
    standings.payload.forEach((standing) => {
      const newStanding = {
        cash: numberWithCommas(standing.cash),
        gdp: formatToPercentage(standing.gdp),
        spi: formatToPercentage(standing.spi),
      };
      newPayload.push(Object.assign(standing, newStanding));
    });
  }

  function numberWithCommas(cash) {
    return `${Math.round(cash).toLocaleString()}.00`;
  }

  function formatToPercentage(number) {
    return `${(number * 100).toFixed(2)} %`;
  }

  const newData = Object.assign(standings, { payload: newPayload });

  return newData;
};

export const createSimulation = (postData) => {
  return sendPostRequest(FETCHAPI.SIMUNATION_CREATE_SIMULATION, postData);
};

export const getRegisteredStudents = (simulationName) => {
  return sendGetRequest(`${FETCHAPI.GET_REGISTERED_STUDENTS_SIMUNATION}${simulationName}`, "");
};
