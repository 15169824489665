import * as ButtonKeys from "./actionButtonConstants";

export const filterActionButtons = (simulation, actionButtons) => {
  const filteredActionButtons = [
    ...Object.keys(actionButtons)
      .filter((x) => {
        if (x === ButtonKeys.advanceSimulationKey) {
          return simulation.professorManagedProgress;
        }

        return true;
      })
      .map((x) => {
        return { key: x, ...actionButtons[x] };
      }),
  ];

  return filteredActionButtons;
};
