import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import StandingsComponent from "./StandingsComponent";
import { getStandings } from "../../store/Medica/actions";
import { getUrlSearchParam } from "../../services/NavigationService";
import ShareSimulationStandingsModal from "./ShareSimulationStandingsModal";
import * as ROUTES from "../../constants/Routes";

class StandingsScreen extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
    openShareModal: false,
  };

  componentDidMount() {
    this.getStandings();
  }

  getStandings = () => {
    this.props.getStandings(this.state.requestedSimulationName);
  };

  handleShareModal = () => {
    const openShareModal = !this.state.openShareModal;
    this.setState({ openShareModal: openShareModal });
  };

  render() {
    const translate = this.props.t;
    const standings = this.props.medica.standings;
    const error = this.props.medica.error;
    const loading = this.props.medica.loading;
    let loader = null,
      messageComponent = null,
      standingsComponent = null;

    if (loading) {
      loader = <LoadingComponent message={translate("loadingStandings")} />;
    } else {
      if (error !== "") {
        messageComponent = <MessagesComponent message={translate(error)} />;
      } else {
        if (standings.outcome) {
          standingsComponent = (
            <StandingsComponent
              openShareModalHandler={this.handleShareModal.bind(this)}
              translate={translate}
              simulationStandings={standings.payload}
            />
          );
        }
      }
    }

    const openShareModal = this.state.openShareModal;
    const encodedSimulationName = new Buffer.from(this.state.requestedSimulationName).toString("base64");
    const baseURL = window.location.origin.replace(/\/$/, "");
    const sharedLink = baseURL + ROUTES.MEDICA_STANDINGS_PUBLIC + "?" + encodedSimulationName;
    const shareModal = (
      <ShareSimulationStandingsModal
        open={openShareModal}
        sharedLink={sharedLink}
        onClose={this.handleShareModal.bind(this)}
      />
    );

    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent
          title={translate("standings")}
          subtitle={this.state.requestedSimulationName}
          helpMessage={translate("standingsHelpMessage")}
        />
        {loader}
        {messageComponent}
        {standingsComponent}
        {shareModal}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getStandings: (simulationName) => dispatch(getStandings(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    medica: {
      standings: state.medica.simulationStandings,
      loading: state.medica.loadingStandings,
      error: state.medica.getStandingsError,
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("StandingsScreen", "Common"),
  withRouter
)(StandingsScreen);
