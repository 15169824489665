import React from "react";
import { Route, Switch } from "react-router";
import * as ROUTES from "../constants/Routes";
import CallBackComponent from "./authComponents/CallBackComponent";
import CheckAuth from "./authComponents/CheckAuth";
import LoadingComponent from "./authComponents/LogoutComponent";
import CatalogueScreen from "./catalogue";
import AbogadosBuyQuestionnaire from "./abogadosBuyQuestionnaire";
import AbogadosDetail from "./abogadosDetail";
import AbogadosRegisteredStudents from "./abogadosRegisteredStudents";
import AbogadosStandings from "./abogadosStandings";
import CreateNewScenarioScreen from "./createNewScenario";
import CrisisBuyQuestionnaire from "./crisisBuyQuestionnaire";
import CrisisDashboards from "./crisisDashboards";
import CrisisDetail from "./crisisDetail";
import CrisisJoinSimulation from "./crisisJoinSimulation";
import CrisisJoinSimulationPreamble from "./crisisJoinSimulationPreamble";
import CrisisRegisteredStudents from "./crisisRegisteredStudents";
import CrisisStandings from "./crisisStandings";
import CrisisRunAgain from "./crisisRunAgain";
import CrisisBadLinkComponent from "./crisisBadLink";
import DashBoardsScreen from "./dashBoards";
import HomeScreen from "./home";
import MedicaBadLinkComponent from "./medicaBadLink";
import MedicaBuyQuestionnaireScreen from "./medicaBuyQuestionnaire";
import MedicaDetailScreen from "./medicaDetail";
import MedicaStandingsPublic from "./medicaStandingsPublic";
import ActivationAbogados from "./activationAbogados";
import PaymentMedica from "./paymentMedica";
import PaymentCrisis from "./paymentCrisis";
import PaymentSimunationScreen from "./paymentSimunation";
import PaymentSupplyChain from "./paymentSupplyChain";
import ProfileScreen from "./profile";
import PurchasedAbogados from "./purchasedAbogados";
import PurchasedCrisis from "./purchasedCrisis";
import PurchasedMedicaScreen from "./purchasedMedica";
import PurchasedSimunationScreen from "./purchasedSimunation";
import PushNotificationsScreen from "./pushNotifications";
import RegisteredStudentsScreen from "./registeredStudents";
import RunAgainScreen from "./runAgain";
import ScenarioManagerScreen from "./scenarioManager";
import SimulationCreated from "./simulationCreated";
import SimunationBuyQuestionnaireScreen from "./simunationBuyQuestionnaire";
import SimunationDetailScreen from "./simunationDetail";
import SimunationRegisteredStudentsScreen from "./simunationRegisteredStudents";
import SimunationStandingsScreen from "./simunationStandings";
import StandingsScreen from "./standings";
import StudentCreateTeamScreen from "./studentCreateTeam";
import StudentJoinTeamScreen from "./studentJoinTeam";
import StudentsLoginScreen from "./studentsLogin";
import SupplyChainBuyQuestionnaire from "./supplychainBuyQuestionnaire";
import PurchasedSupplyChain from "./purchasedSupplyChain";
import TutorialsScreen from "./tutorials";
import SupplyChainJoinSimulationPreamble from "./supplyChainJoinSimulationPreamble";
import SupplyChainJoinSimulation from "./supplyChainJoinSimulation";
import SupplyChainDashboards from "./supplyChainDashboards";
import SupplyChainDetail from "./supplyChainDetail";
import SupplyChainRegisteredStudents from "./supplyChainRegisteredStudents";
import SupplyChainStandings from "./supplyChainStandings";
import SupplyChainRunAgain from "./supplyChainRunAgain";
import * as Sentry from "@sentry/react";
import CreambowBuyQuestionnaire from "./creambowBuyQuestionnaire";
import ActivationCreambow from "./activationCreambow";
import PurchasedCreambow from "./purchasedCreambow";
import CreambowDetail from "./creambowDetail";
import CreambowRegisteredStudents from "./creambowRegisteredStudents";
import CreambowStandings from "./creambowStandings";
import MedicaDecisionHistory from "./medicaDecisionHistory";
import ProcessimLabsFaq from "./processimLabsFaq";

const SentryRoute = Sentry.withSentryRouting(Route);

const Navigation = () => {
  return (
    <Switch>
      <SentryRoute exact path={ROUTES.CHECK_AUTH} render={() => <CheckAuth />} />
      <SentryRoute exact path={ROUTES.CALLBACK} render={() => <CallBackComponent />} />
      <SentryRoute exact path={ROUTES.LOGOUT} render={() => <LoadingComponent />} />
      <SentryRoute exact path={ROUTES.HOME} render={() => <HomeScreen />} />
      <SentryRoute exact path={ROUTES.CATALOGUE} render={() => <CatalogueScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_DETAIL} render={() => <MedicaDetailScreen />} />
      <SentryRoute exact path={ROUTES.PURCHASED_MEDICA_DETAIL} render={() => <MedicaDetailScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_REGISTERED_STUDENTS} render={() => <RegisteredStudentsScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_STANDINGS} render={() => <StandingsScreen />} />
      <SentryRoute exact path={ROUTES.PUSH_NOTIFICATIONS} render={() => <PushNotificationsScreen />} />
      <SentryRoute exact path={ROUTES.PURCHASED_MEDICA} render={() => <PurchasedMedicaScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_BUY_QUESTIONNAIRE} render={() => <MedicaBuyQuestionnaireScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_BAD_LINK} render={() => <MedicaBadLinkComponent />} />
      <SentryRoute exact path={ROUTES.PAYMENT_MEDICA} render={() => <PaymentMedica />} />
      <SentryRoute exact path={ROUTES.PAYMENT_MEDICA_STUDENT} render={() => <PaymentMedica studentPayment />} />
      <SentryRoute exact path={ROUTES.STUDENTS_LOGIN} render={() => <StudentsLoginScreen />} />
      <SentryRoute exact path={ROUTES.STUDENT_CREATE_TEAM} render={() => <StudentCreateTeamScreen />} />
      <SentryRoute exact path={ROUTES.STUDENT_JOIN_TEAM} render={() => <StudentJoinTeamScreen />} />
      <SentryRoute exact path={ROUTES.DOWNLOAD_APP} render={() => <SimulationCreated />} />
      <SentryRoute exact path={ROUTES.SIMUNATION_DETAIL} render={() => <SimunationDetailScreen />} />
      <SentryRoute exact path={ROUTES.PURCHASED_SIMUNATION_DETAIL} render={() => <SimunationDetailScreen />} />
      <SentryRoute
        exact
        path={ROUTES.SIMUNATION_BUY_QUESTIONNAIRE}
        render={() => <SimunationBuyQuestionnaireScreen />}
      />
      <SentryRoute exact path={ROUTES.SIMUNATION_PAYMENT} render={() => <PaymentSimunationScreen />} />
      <SentryRoute exact path={ROUTES.PURCHASED_SIMUNATION} render={() => <PurchasedSimunationScreen />} />
      <SentryRoute exact path={ROUTES.SIMUNATION_STANDINGS} render={() => <SimunationStandingsScreen />} />
      <SentryRoute
        exact
        path={ROUTES.SIMUNATION_REGISTERED_STUDENTS}
        render={() => <SimunationRegisteredStudentsScreen />}
      />
      <SentryRoute exact path={ROUTES.MEDICA_DASHBOARDS} render={() => <DashBoardsScreen />} />
      <SentryRoute exact path={ROUTES.MEDICA_DETAILS_DECISION_HISTORY} render={() => <MedicaDecisionHistory />} />
      <SentryRoute exact path={ROUTES.RUN_AGAIN} render={() => <RunAgainScreen />} />
      <SentryRoute exact path={ROUTES.TUTORIALS} render={() => <TutorialsScreen />} />
      <SentryRoute exact path={ROUTES.PROFILE} render={() => <ProfileScreen />} />
      <SentryRoute path={ROUTES.FAQ} render={() => <ProcessimLabsFaq />} />
      <SentryRoute path={ROUTES.STUDENT_FAQ} render={() => <ProcessimLabsFaq isStudent />} />
      <SentryRoute exact path={ROUTES.SCENARIO_MANAGER} render={() => <ScenarioManagerScreen />} />
      <SentryRoute exact path={ROUTES.CREATE_NEW_SCENARIO} render={() => <CreateNewScenarioScreen />} />
      <SentryRoute exact path={ROUTES.CRISIS_BUY_QUESTIONNAIRE} render={() => <CrisisBuyQuestionnaire />} />
      <SentryRoute exact path={ROUTES.CRISIS_PAYMENT} render={() => <PaymentCrisis />} />
      <SentryRoute exact path={ROUTES.CRISIS_STUDENT_PAYMENT} render={() => <PaymentCrisis studentPayment />} />
      <SentryRoute exact path={ROUTES.CRISIS_CREATE_SIMULATION} render={() => <PurchasedCrisis />} />
      <SentryRoute
        exact
        path={ROUTES.CRISIS_JOIN_SIMULATION_PREAMBLE}
        render={() => <CrisisJoinSimulationPreamble />}
      />
      <SentryRoute exact path={ROUTES.CRISIS_JOIN_SIMULATION} render={() => <CrisisJoinSimulation />} />
      <SentryRoute exact path={ROUTES.CRISIS_SIMULATION_DETAILS} render={() => <CrisisDetail />} />
      <SentryRoute exact path={ROUTES.CRISIS_SIMULATION_DASHBOARDS} render={() => <CrisisDashboards />} />
      <SentryRoute exact path={ROUTES.CRISIS_STANDINGS} render={() => <CrisisStandings />} />
      <SentryRoute exact path={ROUTES.CRISIS_REGISTERED_STUDENTS} render={() => <CrisisRegisteredStudents />} />
      <SentryRoute exact path={ROUTES.CRISIS_CREATE_NEXT_RUN} render={() => <CrisisRunAgain />} />
      <SentryRoute exact path={ROUTES.CRISIS_BAD_LINK} render={() => <CrisisBadLinkComponent />} />

      <SentryRoute exact path={ROUTES.MEDICA_STANDINGS_PUBLIC} render={() => <MedicaStandingsPublic />} />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_BUY_QUESTIONNAIRE} render={() => <SupplyChainBuyQuestionnaire />} />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_CREATE_SIMULATION} render={() => <PurchasedSupplyChain />} />
      <SentryRoute
        exact
        path={ROUTES.SUPPLY_CHAIN_JOIN_SIMULATION_PREAMBLE}
        render={() => <SupplyChainJoinSimulationPreamble />}
      />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_JOIN_SIMULATION} render={() => <SupplyChainJoinSimulation />} />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_PAYMENT} render={() => <PaymentSupplyChain />} />
      <SentryRoute
        exact
        path={ROUTES.SUPPLY_CHAIN_STUDENT_PAYMENT}
        render={() => <PaymentSupplyChain studentPayment />}
      />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_SIMULATION_DETAILS} render={() => <SupplyChainDetail />} />
      <SentryRoute
        exact
        path={ROUTES.SUPPLY_CHAIN_REGISTERED_STUDENTS}
        render={() => <SupplyChainRegisteredStudents />}
      />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_STANDINGS} render={() => <SupplyChainStandings />} />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_SIMULATION_DASHBOARDS} render={() => <SupplyChainDashboards />} />
      <SentryRoute exact path={ROUTES.SUPPLY_CHAIN_CREATE_NEXT_RUN} render={() => <SupplyChainRunAgain />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_BUY_QUESTIONNAIRE} render={() => <AbogadosBuyQuestionnaire />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_CREATE_SIMULATION} render={() => <PurchasedAbogados />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_PAYMENT} render={() => <ActivationAbogados />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_SIMULATION_DETAILS} render={() => <AbogadosDetail />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_REGISTERED_STUDENTS} render={() => <AbogadosRegisteredStudents />} />
      <SentryRoute exact path={ROUTES.ABOGADOS_STANDINGS} render={() => <AbogadosStandings />} />

      <SentryRoute exact path={ROUTES.CREAMBOW_BUY_QUESTIONNAIRE} render={() => <CreambowBuyQuestionnaire />} />
      <SentryRoute exact path={ROUTES.CREAMBOW_PAYMENT} render={() => <ActivationCreambow />} />
      <SentryRoute exact path={ROUTES.CREAMBOW_CREATE_SIMULATION} render={() => <PurchasedCreambow />} />
      <SentryRoute exact path={ROUTES.CREAMBOW_SIMULATION_DETAILS} render={() => <CreambowDetail />} />
      <SentryRoute exact path={ROUTES.CREAMBOW_REGISTERED_STUDENTS} render={() => <CreambowRegisteredStudents />} />
      <SentryRoute exact path={ROUTES.CREAMBOW_STANDINGS} render={() => <CreambowStandings />} />
    </Switch>
  );
};
export default Navigation;
