import React, { Component } from "react";
import { List, Image } from "semantic-ui-react";
import creambowLogo from "../../assets/creambow-logo.png";
import SimulationTitle from "./SimulationTitle";
import HelpHoverComponent from "../helpers/HelpHoverComponent";

class CreambowDetailComponent extends Component {
  render() {
    const translate = this.props.translate;
    const simulationDetails = this.props.simulationDetails;
    const loadingDetails = this.props.loadingDetails;
    let detailBoxStyles = "detailsDataLoading";
    let listDetails = [];

    if (!loadingDetails && simulationDetails) {
      detailBoxStyles = "detailsDataCharged";
      listDetails = [
        {
          item: translate("Common:status"),
          content: translate(`Common:${simulationDetails.status}`),
        },
        {
          item: translate("Common:currentDay"),
          content: simulationDetails.currentSimulationDay,
        },
      ];
    } else {
      listDetails = [{ key: 1, item: translate("Common:status"), content: "" }];
    }

    return (
      <div>
        <SimulationTitle title={simulationDetails && !loadingDetails ? simulationDetails.name : ""} />
        <div className="detailsContainer">
          <div className="simulationIcon">
            <Image className="creambowDetailLogo" src={creambowLogo} size="small" />
          </div>
          <div className={`detailsData ${detailBoxStyles}`}>
            <p>{translate("simulationDetails").toUpperCase()}</p>
            <List>
              {listDetails.map((detail, index) => {
                if (!detail.content) return null;
                let helpHoverComponent = null;
                if (detail.hasHelp) {
                  helpHoverComponent = <HelpHoverComponent helpMessage={translate(detail.helpMessage)} />;
                }
                return (
                  <List.Item key={index}>
                    {loadingDetails === true ? (
                      <div className="animated-background" />
                    ) : (
                      <div>
                        <strong>{detail.item}: </strong>
                        {detail.content}
                        {helpHoverComponent}
                      </div>
                    )}
                  </List.Item>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default CreambowDetailComponent;
