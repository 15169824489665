import * as actionTypes from "./action-types";
import { sendGetRequest, sendPostRequest } from "../../services/FetchService.js";
import * as API_PATHS from "../../constants/FetchApi.js";

export const abogadosGetSimulationDetails = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.GET_SIMULATION_DETAILS_START });
    try {
      const response = await sendGetRequest(API_PATHS.FORIO_GET_SIMULATION_DETAILS, simulationId);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.GET_SIMULATION_DETAILS_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.GET_SIMULATION_DETAILS_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.GET_SIMULATION_DETAILS_FAIL, payload: err });
    }
  };
};

export const abogadosAdvanceSimulation = (advanceSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.ADVANCE_SIMULATION_START });
    try {
      const response = await sendPostRequest(API_PATHS.FORIO_ADVANCE_SIMULATION, advanceSimulationRequest);
      if (!response.data.outcome) {
        const payload = {
          message: response.data.message,
          failedTeams: response.data.payload,
        };

        dispatch({ type: actionTypes.ADVANCE_SIMULATION_FAIL, payload });
        return;
      }
      dispatch({ type: actionTypes.ADVANCE_SIMULATION_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.ADVANCE_SIMULATION_FAIL, payload: err });
    }
  };
};

export const abogadosGetRegisteredStudents = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.GET_REGISTERED_STUDENTS_START });
    try {
      const response = await sendGetRequest(API_PATHS.FORIO_GET_REGISTERED_STUDENTS, simulationId);
      if (!response?.data?.outcome) {
        dispatch({ type: actionTypes.GET_REGISTERED_STUDENTS_FAIL, payload: response.data?.message });
        return;
      }

      const payload = {
        registeredStudents: response.data.payload,
      };

      dispatch({ type: actionTypes.GET_REGISTERED_STUDENTS_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.GET_REGISTERED_STUDENTS_FAIL, payload: err });
    }
  };
};

export const abogadosResetRequestsState = () => {
  return { type: actionTypes.RESET_REQUESTS_STATE };
};

export const abogadosGetSimulationStandings = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.GET_SIMULATION_STANDINGS_START });
    try {
      const response = await sendGetRequest(API_PATHS.FORIO_GET_STANDINGS, simulationId);
      if (!response?.data?.outcome) {
        dispatch({ type: actionTypes.GET_SIMULATION_STANDINGS_FAIL, payload: response.data?.message });
        return;
      }

      const payload = {
        standings: response.data.payload.standingsInformation,
        teamMembers: response.data.payload.studentsInformation,
      };

      dispatch({ type: actionTypes.GET_SIMULATION_STANDINGS_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.GET_SIMULATION_STANDINGS_FAIL, payload: err });
    }
  };
};
