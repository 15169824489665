import * as actions from "./action-types";
import { getStorage, setStorage, mergeDataToStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as simunationService from "../../services/SimunationService";
import * as API_PATHS from "../../constants/FetchApi.js";
import * as simunationQuestionnaireService from "../../services/SimunationQuestionnaireService";
import { sendPostRequest } from "../../services/FetchService";

export const checkStorageSimunationInformation = () => {
  return async function (dispatch) {
    const cachedQuestionnaireInformation = getStorage(LOCALSTORAGE.simunationQuestionnaire);
    if (cachedQuestionnaireInformation) {
      dispatch({
        type: actions.CHECK_STORAGE_INFORMATION,
        payload: cachedQuestionnaireInformation,
      });
    }
    return true;
  };
};

export const cacheSimunationQuestionnaireData = (data) => {
  return async function (dispatch) {
    setStorage(LOCALSTORAGE.simunationQuestionnaire, data);
    dispatch({
      type: actions.UPDATE_INSTITUTION_STUDENTS_NUMBER,
      payload: data,
    });
  };
};

export const updateSimulationInformation = (data) => {
  return async function (dispatch) {
    dispatch({ type: actions.UPDATE_SIMULATION_INFORMATION, payload: data });
    return true;
  };
};

export const updateTransactionSimunationData = (data) => {
  return async function (dispatch) {
    dispatch({ type: actions.UPDATE_TRANSACTION_DATA, payload: data });
  };
};

export const resetSimunationQuestionnaireStore = () => {
  return async function (dispatch) {
    dispatch({
      type: actions.RESET_QUESTIONNAIRE_STORE,
    });
  };
};

export const createSimulation = (postData) => {
  return async function (dispatch) {
    dispatch({ type: actions.SIMUNATION_CREATE_SIMULATION });
    const createSimunationResponse = simunationService.createSimulation(postData);
    createSimunationResponse
      .then((simunationResponse) => {
        if (simunationResponse.data.outcome) {
          dispatch({
            type: actions.SIMUNATION_CREATE_SIMULATION_SUCCESSFUL,
            payload: simunationResponse.data,
          });
        } else {
          dispatch({
            type: actions.SIMUNATION_CREATE_SIMULATION_FAILED,
            payload: simunationResponse.data.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorCreatingSimulation",
          };
          dispatch({
            type: actions.SIMUNATION_CREATE_SIMULATION_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const checkStorageSimunationCreated = () => {
  return async function (dispatch) {
    const storageData = getStorage(LOCALSTORAGE.simulationSimunationCreated);
    if (storageData) {
      const storagePayload = {
        outcome: true,
        payload: storageData.payload,
      };
      dispatch({
        type: actions.CHECK_STORAGE_CREATED_SIMUNATION,
        payload: storagePayload,
      });
    }
  };
};

export const validateSimunationDiscountCode = (discountCode) => {
  return async function (dispatch) {
    dispatch({ type: actions.VALIDATE_DISCOUNT_CODE });
    const discountCodePromise = simunationQuestionnaireService.validateSimunationDiscountCode(discountCode.code);
    discountCodePromise
      .then((response) => {
        if (response.data.outcome) {
          const data = simunationQuestionnaireService.prepareCustomResponse(
            response,
            actions.VALIDATE_DISCOUNT_CODE,
            discountCode.code
          );
          mergeDataToStorage(LOCALSTORAGE.simunationQuestionnaire, {
            discountCode: { discountCode: data.payload },
          });
          dispatch(data);
        } else {
          dispatch({
            type: actions.VALIDATE_DISCOUNT_CODE_FAILED,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.VALIDATE_DISCOUNT_CODE_FAILED,
            message: "errorValidatingDiscountCode",
          });
        }
      });
  };
};

export const simunationConfirmPayment = (paymentIntentId) => {
  return async function (dispatch) {
    dispatch({ type: actions.CONFIRM_PAYMENT_START });
    try {
      const response = await sendPostRequest(API_PATHS.SIMUNATION_CONFIRM_PAYMENT, { paymentIntentId });
      if (!response.data.outcome) {
        dispatch({ type: actions.CONFIRM_PAYMENT_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actions.CONFIRM_PAYMENT_SUCCESS, payload: response.data.payload });
    } catch (error) {
      dispatch({ type: actions.CONFIRM_PAYMENT_FAIL, payload: error });
    }
  };
};

export const simunationResetDiscountCode = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_DISCOUNT_CODE });
  };
};
