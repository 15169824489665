import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { creambowAdvanceSimulation, creambowResetRequestsState } from "../../store/CreambowSimulationDetails/actions";
import { connect } from "react-redux";

class AdvanceSimulationModal extends Component {
  advanceSimulation = () => {
    const advanceParams = { simulationId: this.props.simulationDetails.id };
    this.props.creambowAdvanceSimulation(advanceParams);
  };

  handleClose = () => {
    this.props.creambowResetRequestsState();
    this.props.handleAdvanceModal();
  };

  successAction = () => {
    this.props.getSimulationDetails(this.props.simulationDetails.id);
    this.handleClose();
  };

  render() {
    const translate = this.props.t;
    const { loading, errorMessage, success, failedTeams } = this.props.advanceSimulationRequestStatus;

    let oncloseFunction = this.handleClose;
    let statusComponent = null;
    let buttonContent = translate("AdvanceSimulationModal:advance");
    let customSuccessMessage = translate("AdvanceSimulationModal:Simulation advanced successfully.");
    let titleText = translate(`AdvanceSimulationModal:advanceSimulation`);

    if (this.props.simulationDetails.status === "AwaitingInitialization") {
      buttonContent = translate("AdvanceSimulationModal:start");
      titleText = translate(`AdvanceSimulationModal:startSimulation`);
    } else if (
      this.props.simulationDetails.status === "Initialized" ||
      this.props.simulationDetails.status === "Running"
    ) {
      statusComponent = (
        <div align="justify">
          <p>{translate("AdvanceMessage")}</p>
        </div>
      );
    }

    let actionButtons = (
      <Modal.Actions>
        <Button disabled={loading === true} content={buttonContent} onClick={() => this.advanceSimulation()} />
        <Button content={translate("ModifyModal:cancel")} onClick={() => this.handleClose()} />
      </Modal.Actions>
    );

    if (loading === true) {
      statusComponent = <div className="loader" />;
    } else if (errorMessage !== "") {
      if (errorMessage === "AdvanceSimulationController_FailedAdvanceTeam") {
        statusComponent = (
          <div>
            <div className="contentMarginBottom">
              <MessagesComponent messageType={false} message={translate(errorMessage)} />
            </div>
            <p>
              {translate("outdatedTeams")}
              {failedTeams}
            </p>
          </div>
        );
      } else {
        statusComponent = (
          <div>
            <MessagesComponent messageType={false} message={translate(errorMessage)} />
          </div>
        );
      }
    } else if (success) {
      oncloseFunction = this.successAction;
      statusComponent = <MessagesComponent messageType={true} message={customSuccessMessage} />;

      actionButtons = (
        <Modal.Actions>
          <Button content={"Ok"} onClick={this.successAction} />
        </Modal.Actions>
      );
    }

    return (
      <Modal size="tiny" open={this.props.open} onClose={oncloseFunction}>
        <Modal.Header>{titleText}</Modal.Header>
        <Modal.Content>{statusComponent}</Modal.Content>
        {actionButtons}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  advanceSimulationRequestStatus: state.creambowSimulationDetails.advanceSimulationRequestStatus,
  simulationDetails: state.creambowSimulationDetails.simulationDetails,
});

const mapDispatchToProps = (dispatch) => ({
  creambowAdvanceSimulation: (data) => dispatch(creambowAdvanceSimulation(data)),
  creambowResetRequestsState: () => dispatch(creambowResetRequestsState()),
});

const HOC = compose(
  withTranslation("CreambowAdvanceSimulationModal", "AdvanceSimulationModal", "Common"),
  connect(mapStateToProps, mapDispatchToProps)
);

export default HOC(AdvanceSimulationModal);
