import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import DropDownComponent from "../helpers/DropDownComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import InputComponent from "../helpers/InputComponent";
import NavigationButton from "../helpers/NavigationButton";
import * as ROUTES from "../../constants/Routes";

class MedicaBuyQuestionnaireComponent extends Component {
  state = {
    numberOfStudents: this.props.numberOfStudents,
    isInstitution: this.props.isInstitution,
    professorAdvanceSimulation: this.props.professorAdvanceSimulation,
    isInstitutionError: false,
    numberOfStudentsError: false,
    professorAdvanceSimulationError: false,
  };

  handleChangeNumberStudents = (id, data) => {
    this.setState({
      [id]: data,
      numberOfStudentsError: false,
    });
  };

  onSelectionInstitution = (val) => {
    if (!this.state.isInstitution && val) {
      this.setState({
        numberOfStudents: 0,
      });
    }
    this.setState({
      isInstitution: val,
      isInstitutionError: false,
    });
    this.props.cacheMedicaQuestionnaireData({
      isInstitution: val,
    });
  };

  onSelectionAdvanceSimulation = (val) => {
    this.setState({
      professorAdvanceSimulation: val,
      professorAdvanceSimulationError: false,
    });
    this.props.cacheMedicaQuestionnaireData({
      professorAdvanceSimulation: val,
    });
  };

  handleContinue = () => {
    const { numberOfStudents, professorAdvanceSimulation, isInstitution } = this.state;
    let questionnaire = {
      numberOfStudents: null,
      isInstitution: null,
      professorAdvanceSimulation: null,
    };
    if (isInstitution !== null) {
      if (isInstitution === false) {
        questionnaire.numberOfStudents = 1000;
        questionnaire.isInstitution = isInstitution;
        questionnaire.professorAdvanceSimulation = professorAdvanceSimulation;
        this.saveStateAndRoute(questionnaire);
      } else {
        if (numberOfStudents >= 4) {
          questionnaire.numberOfStudents = Number(numberOfStudents);
          questionnaire.isInstitution = isInstitution;
          questionnaire.professorAdvanceSimulation = professorAdvanceSimulation;
          this.saveStateAndRoute(questionnaire);
        } else {
          this.setState({
            numberOfStudentsError: true,
          });
        }
      }
    } else {
      this.setState({
        isInstitutionError: true,
      });
    }
  };

  saveStateAndRoute = (data) => {
    this.props.cacheMedicaQuestionnaireData(data);
    if (data.isInstitution) {
      this.props.history.push(ROUTES.PAYMENT_MEDICA);
    } else {
      this.props.history.push(ROUTES.PURCHASED_MEDICA);
    }
  };

  render() {
    const translate = this.props.translate;
    const {
      isInstitutionError,
      numberOfStudentsError,
      isInstitution,
      numberOfStudents,
      professorAdvanceSimulation,
      professorAdvanceSimulationError,
    } = this.state;
    let inputComponent = null;
    let messagesComponent = null;
    const optionsWhoPaying = [
      {
        key: 1,
        text: translate("Common:institution"),
        value: true,
      },
      {
        key: 2,
        text: translate("Students"),
        value: false,
      },
    ];
    const optionsAdvanceSimulation = [
      {
        key: 1,
        text: translate("Common:yes"),
        value: true,
      },
      {
        key: 2,
        text: translate("Common:no"),
        value: false,
      },
    ];
    if (isInstitution) {
      inputComponent = (
        <InputComponent
          label={translate("numbersStudents")}
          id="numberOfStudents"
          type="number"
          min="0"
          onError={numberOfStudentsError}
          errorMessage={translate("notValidNumberMin")}
          value={numberOfStudents}
          handleChange={this.handleChangeNumberStudents}
        />
      );
    }
    if (isInstitutionError || numberOfStudentsError || professorAdvanceSimulationError) {
      messagesComponent = <MessagesComponent message={translate("verifyFieldsCorrectlyFilled")} />;
    }
    return (
      <Form>
        <DropDownComponent
          label={translate("whoPaysSelected")}
          helpMessage={translate("MedicaBuyQuestionnaire:whoPaysHelp")}
          options={optionsWhoPaying}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.onSelectionInstitution}
          selectError={isInstitutionError}
          value={isInstitution}
        />
        {inputComponent}
        <DropDownComponent
          label={translate("professorAdvanceSimulation")}
          helpMessage={translate("medicaProfessorAdvanceSimulationHelp")}
          options={optionsAdvanceSimulation}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.onSelectionAdvanceSimulation}
          selectError={professorAdvanceSimulationError}
          value={professorAdvanceSimulation}
        />
        {messagesComponent}
        <NavigationButton action={this.handleContinue} label={translate("next")} />
      </Form>
    );
  }
}

export default MedicaBuyQuestionnaireComponent;
