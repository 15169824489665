import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Container, Form, Grid, Image } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import * as CONSTANT from "../../constants/UniversalConstants";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import NextRunFormComponent from "./NextRunFormComponent";
import moment from "moment";
import * as CRISIS_CONSTANTS from "../../constants/CrisisConstants";
import { compose } from "redux";
import { connect } from "react-redux";
import { getUrlSearchParam } from "../../services/NavigationService";
import {
  crisisCreateNextRun,
  crisisGetSimulationDetails,
  resetCreateNextRunState,
} from "../../store/CrisisSimulationDetails/actions";
import { crisisGetProfessorScenarios } from "../../store/CrisisSimulationDetails/actions";
import MessagesComponent from "../helpers/MessagesComponent";
import BackButton from "../helpers/BackButton";
import { withRouter } from "react-router-dom";
import crisisLogo from "../../assets/crisis-logo.png";
import RedirectModal from "../modals/RedirectModal";
import gearIcon from "../../assets/gear-icon.svg";
import AdvancedOptions from "../crisisAdvancedOptions/AdvancedOptions";
import * as ROUTES from "../../constants/Routes";

const getSimulationAffix = (iterationNumber) => {
  const numberString = iterationNumber.toString();
  if (typeof numberString !== "string") {
    return;
  }
  if (numberString.endsWith("1") === true && numberString.endsWith("11") === false) {
    return "st";
  }
  if (numberString.endsWith("2") === true && numberString.endsWith("12") === false) {
    return "nd";
  }
  if (numberString.endsWith("3") === true && numberString.endsWith("13") === false) {
    return "rd";
  }
  return "th";
};

class index extends Component {
  constructor(props) {
    super(props);

    const startDate = moment().add(1, "hour");
    const endDate = startDate.clone().add(1, "day");

    this.state = {
      formValidated: true,
      dateValidationError: "",
      openAdvancedOptions: false,
      advancedOptions: {
        scenarioId: "",
      },
      form: {
        gameMode: {
          value: CRISIS_CONSTANTS.OneStationUnknownPeers,
          valid: true,
          rules: {},
        },
        teamsStayTheSame: {
          value: true,
          valid: true,
          rules: {},
        },
        professorAdvance: {
          value: true,
          valid: true,
          rules: {},
        },
        startTime: {
          value: {
            hour: this.switchTo12HourFormat(Number(moment(startDate).format("HH"))),
            hourFormat: moment(startDate).format("a"),
            date: startDate,
          },
          valid: true,
          rules: {},
        },
        endTime: {
          value: {
            hour: this.switchTo12HourFormat(Number(moment(endDate).format("HH"))),
            hourFormat: moment(endDate).format("a"),
            date: endDate,
          },
          valid: true,
          rules: {},
        },
      },
    };
  }

  async componentDidMount() {
    const storedSimulationDetailsId = this.props.simulationDetails.id;
    const requestedSimulationId = getUrlSearchParam();
    const { getSimulationDetails } = this.props;
    this.props.resetCreateNextRunState();
    if (storedSimulationDetailsId !== requestedSimulationId) {
      await getSimulationDetails(requestedSimulationId);
    }
    this.props.getProfessorScenarios();
  }

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  setDate = (date, dateToModify) => {
    let updatedForm = { ...this.state.form };

    const segmentedStartTime = updatedForm.startTime.value;
    const segmentedEndTime = updatedForm.endTime.value;

    if (dateToModify === "startDate" && date >= moment(segmentedEndTime.date).format("YYYY-MM-DD")) {
      segmentedStartTime.date = date;
      segmentedEndTime.date = moment(date).add(1, "day");
    } else {
      if (dateToModify === "startDate") {
        segmentedStartTime.date = date;
      } else {
        segmentedEndTime.date = date;
      }
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  setHour = (statePropertyToModify) => {
    return (event, { value }) => {
      let updatedForm = { ...this.state.form };
      const segmentedStartTime = updatedForm[statePropertyToModify].value;

      if (isNaN(value)) {
        segmentedStartTime.hourFormat = value;
      } else {
        segmentedStartTime.hour = value;
      }

      updatedForm = this.updateFormValidation(updatedForm);
      this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
    };
  };

  switchTo24HourFormat = (hour, format) => {
    let formatedHour = 0;
    if (format === "am" && hour === 12) {
      formatedHour = hour - 12;
    } else if (format === "pm" && hour < 12) {
      formatedHour = hour + 12;
    } else {
      formatedHour = hour;
    }
    return formatedHour;
  };

  switchTo12HourFormat = (hour) => {
    let formatedHour = null;
    if (hour > 12) {
      formatedHour = hour - 12;
    } else {
      if (hour === 0) {
        formatedHour = 12;
      } else {
        formatedHour = hour;
      }
    }
    return formatedHour;
  };

  getTime = (segmentedTime) => {
    const formattedHour = this.switchTo24HourFormat(segmentedTime.hour, segmentedTime.hourFormat);
    return this.formatDateTime(segmentedTime.date, formattedHour);
  };

  formatDateTime = (date, time) => {
    return moment(`${moment(date).format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD H");
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.minHoursBeforeDate) {
      const timeNow = moment();
      const convertedDate = this.getTime(value);
      isValid = isValid && convertedDate.diff(timeNow) >= rules.minHoursBeforeDate;
    }
    return isValid;
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  getNextRunSimulationName() {
    const currentSimulationName = this.props.simulationDetails.name;
    const currentIteration = this.props.simulationDetails.iterationNumber;
    const baseName = currentIteration > 1 ? currentSimulationName.slice(0, -6) : currentSimulationName;
    const nextIteration = currentIteration + 1;
    const nextIterationAffix = getSimulationAffix(nextIteration);

    return `${baseName} (${nextIteration}${nextIterationAffix})`;
  }

  submitCreateSimulationRequest = (event) => {
    event.preventDefault();
    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const form = this.state.form;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    let gameMode = form.gameMode.value;
    const professorAdvance = form.professorAdvance.value;
    let startTime;
    let endTime;
    const simulationId = this.props.simulationDetails.id;
    const teamsStayTheSame = form.teamsStayTheSame.value;

    if (professorAdvance === false) {
      const formEndTime = form.endTime;
      startTime = this.getTime(form.startTime.value);
      endTime = this.getTime(formEndTime.value);

      if (endTime.diff(moment()) < CRISIS_CONSTANTS.minTimeBeforeSimulationStartsInMiliseconds) {
        form.startTime.valid = false;
        this.setState({
          form: form,
          formValidated: true,
          dateValidationError: "CrisisPurchased:SimulationStartInTheFuture",
        });
        return;
      }

      if (endTime.diff(startTime) < CRISIS_CONSTANTS.minSimulationDurationInMiliseconds) {
        formEndTime.valid = false;
        this.setState({
          form: form,
          formValidated: true,
          dateValidationError: "CrisisPurchased:SimulationMinDurationError",
        });
        return;
      }

      if (endTime.diff(startTime) > CRISIS_CONSTANTS.maxSimulationDurationInMiliseconds) {
        formEndTime.valid = false;
        this.setState({
          form: form,
          formValidated: true,
          dateValidationError: "CrisisPurchased:SimulationMaxDurationError",
        });
        return;
      }
    }

    const request = {
      simulationId: simulationId,
      gameMode: gameMode,
      ProfessorManagedProgress: professorAdvance,
      startTime: startTime,
      endTime: endTime,
      teamsStayTheSame: teamsStayTheSame,
      scenarioId: this.state.advancedOptions.scenarioId,
    };

    this.props.createNextRun(request);
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  onAdvancedOptionsChanged = (id, value) => {
    const { advancedOptions } = this.state;
    this.setState({
      advancedOptions: {
        ...advancedOptions,
        [id]: value,
      },
    });
  };

  render() {
    const { t: translate } = this.props;
    const {
      loading: detailsLoading,
      detailsValid,
      errorMessage: detailErrorMessage,
    } = this.props.simulationDetailsRequestStatus;
    const { dateValidationError } = this.state;
    const {
      loading: createNextRunLoading,
      errorMessage: createNextRunErrorMessage,
      success: createNextRunSuccess,
    } = this.props.createNextRunStatus;

    let continueButton = (
      <Button basic type="submit" form="createSimulationForm">
        {translate("Common:continue")}
      </Button>
    );

    let formBody = null;

    if (detailsLoading === true) {
      formBody = <div className="loader" />;
    }
    if (createNextRunLoading === true) {
      continueButton = <div className="loader" />;
    }

    if (detailsValid === true) {
      const nextRunSimulationName = this.getNextRunSimulationName();
      formBody = (
        <div>
          <Form id="createSimulationForm" onSubmit={this.submitCreateSimulationRequest}>
            <NextRunFormComponent
              formData={this.state.form}
              formChanged={this.onFormChanged.bind(this)}
              simulationName={nextRunSimulationName}
              setDate={this.setDate}
              setStartHour={this.setHour("startTime")}
              setEndHour={this.setHour("endTime")}
            />
            <div className="bottomArea">{continueButton}</div>
          </Form>
          <Button onClick={this.handleAdvancedOptionsComponent} id="whiteAndBlueButton">
            <Image className="ui top aligned tiny image" src={gearIcon} id="imageButton" />
            {translate("CrisisPurchased:AdvancedOptions")}
          </Button>
        </div>
      );
    }

    let message = null;
    const errorMessages = [detailErrorMessage, createNextRunErrorMessage, dateValidationError];
    const messageToDisplay = errorMessages.find((message) => message.length > 0);
    if (typeof messageToDisplay === "string") {
      message = (
        <Grid.Row>
          <Grid.Column width={16}>
            <MessagesComponent message={translate(messageToDisplay)} />
          </Grid.Column>
        </Grid.Row>
      );
    }

    const { professorScenariosStatus } = this.props;
    const { advancedOptions, openAdvancedOptions } = this.state;
    const advancedOptionsComponent = (
      <>
        <AdvancedOptions
          loading={professorScenariosStatus.loading}
          errorMessage={professorScenariosStatus.errorMessage}
          scenarios={professorScenariosStatus.scenarios}
          advancedOptions={advancedOptions}
          advancedOptionsChanged={this.onAdvancedOptionsChanged}
        />
        <Grid.Column>
          <div className="bottomArea">{continueButton}</div>
        </Grid.Column>
      </>
    );

    return (
      <Container>
        <HeaderComponent translate={translate} />
        <RedirectModal
          open={createNextRunSuccess}
          translate={translate}
          history={this.props.history}
          title={translate("CrisisRunAgain:Success")}
          message={translate("CrisisRunAgain:createNextRunSuccess")}
          route={ROUTES.HOME}
        />
        <Grid className="">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.CRISIS_NAME.toLocaleUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.CRISIS_NAME.toLocaleUpperCase()} />
          </Grid.Column>
          <Grid.Row className="crisis-run-again--form-row">
            <Grid.Column className="formColumn runAgainColumn" mobile={16} computer={14} tablet={13}>
              {formBody}
            </Grid.Column>
            <Grid.Column className="crisis-run-again--logo-column" only="tablet computer" computer={2} tablet={3}>
              <div className="crisisSimulationIcon">
                <Image className="crisisDetailLogo" src={crisisLogo} size="small" />
              </div>
            </Grid.Column>
          </Grid.Row>
          {message}
        </Grid>
        {openAdvancedOptions ? advancedOptionsComponent : null}
        <Grid>
          <Grid.Column width={16}>
            <BackButton translate={translate} history={this.props.history} />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  professorScenariosStatus: state.crisisSimulationDetails.professorScenarios,
  simulationDetailsRequestStatus: state.crisisSimulationDetails.simulationDetailsRequestStatus,
  simulationDetails: state.crisisSimulationDetails.simulationDetails,
  createNextRunStatus: state.crisisSimulationDetails.createNextRunStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getProfessorScenarios: () => dispatch(crisisGetProfessorScenarios()),
  getSimulationDetails: (id) => dispatch(crisisGetSimulationDetails(id)),
  createNextRun: (request) => dispatch(crisisCreateNextRun(request)),
  resetCreateNextRunState: () => dispatch(resetCreateNextRunState()),
});

const HOCs = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(["CrisisRunAgain", "common"]),
  withRouter
);

export default HOCs(index);
