export const RESET_SUPPLYCHAIN_BUY_SIMULATION_DEFAULTS =
  "SUPPLYCHAIN_BUY_SIMULATION/RESET_SUPPLYCHAIN_BUY_SIMULATION_DEFAULTS";
export const COMMIT_PURCHASE_FORM_DATA = "SUPPLYCHAIN_BUY_SIMULATION/COMMIT_PURCHASE_FORM_DATA";
export const RESTORE_STORAGE_PURCHASE_INFORMATION = "SUPPLYCHAIN_BUY_SIMULATION/RESTORE_STORAGE_PURCHASE_INFORMATION";
export const UPDATE_TRANSACTION_DATA = "SUPPLYCHAIN_BUY_SIMULATION/UPDATE_TRANSACTION_DATA";
export const CREATE_SIMULATION_REQUEST_START = "SUPPLYCHAIN_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_START";
export const CREATE_SIMULATION_REQUEST_SUCCESS = "SUPPLYCHAIN_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_SUCCESS";
export const CREATE_SIMULATION_REQUEST_FAIL = "SUPPLYCHAIN_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_FAIL";
export const RESET_CREATE_SIMULATION_DEFAULTS = "SUPPLYCHAIN_BUY_SIMULATION/RESET_CREATE_SIMULATION_DEFAULTS";
export const VALIDATE_DISCOUNT_CODE = "SUPPLYCHAIN_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_FAILED = "SUPPLYCHAIN_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_FAILED";
export const VALIDATE_DISCOUNT_CODE_SUCCESS = "SUPPLYCHAIN_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS =
  "SUPPLYCHAIN_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_RESET = "SUPPLYCHAIN_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_RESET";
export const CHECK_STUDENT_PAYMENT = "SUPPLYCHAIN_BUY_SIMULATION/CHECK_STUDENT_PAYMENT";
export const CHECK_STUDENT_PAYMENT_SUCCESSFUL = "SUPPLYCHAIN_BUY_SIMULATION/CHECK_STUDENT_PAYMENT_SUCCESSFUL";
export const CHECK_STUDENT_PAYMENT_FAILED = "SUPPLYCHAIN_BUY_SIMULATION/CHECK_STUDENT_PAYMENT_FAILED";
export const CONFIRM_PAYMENT_START = "SUPPLYCHAIN_BUY_SIMULATION/CONFIRM_PAYMENT_START";
export const CONFIRM_PAYMENT_SUCCESS = "SUPPLYCHAIN_BUY_SIMULATION/CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAIL = "SUPPLYCHAIN_BUY_SIMULATION/CONFIRM_PAYMENT_FAIL";
