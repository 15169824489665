export const SUPPLY_CHAIN_GET_SIMULATION_DETAILS_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_DETAILS_START";
export const SUPPLY_CHAIN_GET_SIMULATION_DETAILS_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_DETAILS_FAILED";
export const SUPPLY_CHAIN_GET_SIMULATION_DETAILS_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_DETAILS_SUCCESS";
export const SUPPLY_CHAIN_RESET_REQUESTS_STATE = "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_RESET_REQUESTS_STATE";
export const SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_START";
export const SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_SUCCESS";
export const SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_FAIL";
export const SUPPLY_CHAIN_ADVANCE_SIMULATION_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_ADVANCE_SIMULATION_START";
export const SUPPLY_CHAIN_ADVANCE_SIMULATION_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_ADVANCE_SIMULATION_FAILED";
export const SUPPLY_CHAIN_ADVANCE_SIMULATION_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_ADVANCE_SIMULATION_SUCCESS";
export const SUPPLY_CHAIN_START_SIMULATION_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_START_SIMULATION_START";
export const SUPPLY_CHAIN_START_SIMULATION_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_START_SIMULATION_FAILED";
export const SUPPLY_CHAIN_START_SIMULATION_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_START_SIMULATION_SUCCESS";
export const SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS";
export const SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_FAIL";
export const SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_START";
export const SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_SUCCESS";
export const SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_FAIL";
export const SUPPLY_CHAIN_GET_PERFORMANCE_DATA_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PERFORMANCE_DATA_START";
export const SUPPLY_CHAIN_GET_PERFORMANCE_DATA_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PERFORMANCE_DATA_FAILED";
export const SUPPLY_CHAIN_GET_PERFORMANCE_DATA_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PERFORMANCE_DATA_SUCCESS";
export const SUPPLY_CHAIN_GET_DECISIONS_DATA_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DECISIONS_DATA_START";
export const SUPPLY_CHAIN_GET_DECISIONS_DATA_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DECISIONS_DATA_FAILED";
export const SUPPLY_CHAIN_GET_DECISIONS_DATA_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DECISIONS_DATA_SUCCESS";
export const SUPPLY_CHAIN_CREATE_NEXT_RUN_START = "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_CREATE_NEXT_RUN_START";
export const SUPPLY_CHAIN_CREATE_NEXT_RUN_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_CREATE_NEXT_RUN_SUCCESS";
export const SUPPLY_CHAIN_CREATE_NEXT_RUN_FAIL = "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_CREATE_NEXT_RUN_FAIL";
export const SUPPLY_CHAIN_CREATE_NEXT_RUN_CLEAN = "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_CREATE_NEXT_RUN_CLEAN";

export const SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PROFESSOR_DETAILS_START";
export const SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PROFESSOR_DETAILS_SUCCESS";
export const SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_PROFESSOR_DETAILS_FAIL";

export const SUPPLY_CHAIN_MODIFY_SIMULATION_START =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_MODIFY_SIMULATION_START";
export const SUPPLY_CHAIN_MODIFY_SIMULATION_SUCCESS =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_MODIFY_SIMULATION_SUCCESS";
export const SUPPLY_CHAIN_MODIFY_SIMULATION_FAIL =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_MODIFY_SIMULATION_FAIL";
export const SUPPLY_CHAIN_MODIFY_SIMULATION_RESET =
  "SUPPLY_CHAIN_SIMULATION_DETAILS/SUPPLY_CHAIN_MODIFY_SIMULATION_RESET";

export const SUPPLY_CHAIN_GET_DOCUMENTATION_START = "CRISIS_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DOCUMENTATION_START";
export const SUPPLY_CHAIN_GET_DOCUMENTATION_SUCCESS =
  "CRISIS_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DOCUMENTATION_SUCCESS";
export const SUPPLY_CHAIN_GET_DOCUMENTATION_FAIL = "CRISIS_SIMULATION_DETAILS/SUPPLY_CHAIN_GET_DOCUMENTATION_FAIL";
