import backgroundImageOne from "../../assets/background-one.png";
import backgroundImageTwo from "../../assets/background-two.png";
import mobileBackgroundImageOne from "../../assets/background-mobile-one.png";
import mobileBackgroundImageTwo from "../../assets/background-mobile-two.png";
import * as ROUTES from "../../constants/Routes";

let backgroundImage = "";

export const changeBackgroundImage = (pathName) => {
  const isPortrait = window.innerHeight > window.innerWidth;
  let imageOne = null;
  let imageTwo = null;
  if (isPortrait) {
    imageOne = mobileBackgroundImageOne;
    imageTwo = mobileBackgroundImageTwo;
  } else {
    imageOne = backgroundImageOne;
    imageTwo = backgroundImageTwo;
  }

  if (pathName === ROUTES.HOME) {
    backgroundImage = imageOne;
  } else {
    backgroundImage = imageTwo;
  }
  return backgroundImage;
};
