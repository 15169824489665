import React, { Component } from "react";
import { connect } from "react-redux";
import { crisisGetPerformanceData } from "../../store/CrisisSimulationDetails/actions";
import HeaderComponent from "../header/HeaderComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import GraphModal from "../modals/GraphModal";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import { createTeamsList, getGraphSections } from "./utilities";
import DashBoardSection from "./DashBoardSection";
import TeamListMobile from "./TeamListMobile";
import TeamsListAside from "./TeamsListAside";
import { getStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import LoadingComponent from "../helpers/LoadingComponent";
import TeamsListModal from "./TeamsListModal";
import { Translation } from "react-i18next";

class DashBoardsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openTeamsListModal: false,
      openGraphModal: false,
      selectedTeams: [],
      teams: [],
      sections: [],
      plot: {},
      loadingSections: false,
      performanceData: null,
    };
  }

  componentDidMount = () => {
    const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation);
    this.props.crisisGetPerformanceData(requestedSimulationId);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.simulationPerformanceDataRequestStatus.performanceData !==
        prevProps.simulationPerformanceDataRequestStatus.performanceData &&
      this.props.simulationPerformanceDataRequestStatus.performanceData
    ) {
      this.setState({
        performanceData: this.props.simulationPerformanceDataRequestStatus.performanceData,
      });
    }

    if (this.state.performanceData && this.state.performanceData !== prevState.performanceData) {
      this.getTeamsList();
    }
  }

  configureDashboardData = (teamsList) => {
    const { performanceData } = this.state;
    const { selectedTeams } = this.state;
    let teams = selectedTeams;
    let graphsData = {};

    if (teamsList) {
      teams = teamsList;
    }

    graphsData = performanceData;

    if (Object.keys(graphsData).length > 0) {
      const sectionsData = getGraphSections(graphsData, teams);
      this.setState({
        sections: sectionsData,
      });
    } else {
      this.setState({
        sections: [],
      });
    }
  };

  getTeamsList = () => {
    const { performanceData } = this.state;
    const teamsList = createTeamsList(performanceData);

    this.setState({
      teams: teamsList,
      selectedTeams: teamsList,
    });

    if (teamsList.length > 0) {
      this.configureDashboardData(teamsList);
    }
  };

  modifySelectedTeamsList = (teamsList) => {
    this.setState({
      selectedTeams: teamsList,
    });
  };

  handleTeamsListModal = () => {
    this.setState({
      openTeamsListModal: !this.state.openTeamsListModal,
    });
  };

  handleGraphModal = (plot) => {
    this.setState({
      openGraphModal: !this.state.openGraphModal,
      plot: plot,
    });
  };

  render() {
    const { teams, selectedTeams, openTeamsListModal, sections, openGraphModal, plot } = this.state;
    const { translate, simulationPerformanceDataRequestStatus } = this.props;
    let teamsListAside = null;
    let dashBoardSection = null;
    let teamsListModal = null;
    let graphModal = null;
    let body = null;

    let selectedTeamsFiltered = selectedTeams;
    let anyTeamSelected = selectedTeamsFiltered.some((team) => {
      return team.isChecked;
    });
    const teamListMobile = (
      <TeamListMobile handleTeamsListModal={this.handleTeamsListModal} buttonTitle={translate("manageTeams")} />
    );

    dashBoardSection = (
      <div id="dashboardsBody">
        <DashBoardSection
          handleGraphModal={this.handleGraphModal}
          selectedTeams={selectedTeams}
          sections={sections}
          modifySelectedTeamsList={this.modifySelectedTeamsList}
        />
      </div>
    );

    if (!anyTeamSelected) {
      dashBoardSection = (
        <div className="messageSeparator">
          <MessagesComponent info={true} message={translate("noTeamSelectedToDisplayInformation")} />
        </div>
      );
    }

    if (teams.length > 0) {
      teamsListAside = (
        <TeamsListAside
          translate={translate}
          teamsList={teams}
          modifySelectedTeamsList={this.modifySelectedTeamsList}
        />
      );

      teamsListModal = (
        <TeamsListModal
          translate={translate}
          openTeamsListModal={openTeamsListModal}
          teamsList={teams}
          handleTeamsListModal={this.handleTeamsListModal}
          modifySelectedTeamsList={this.modifySelectedTeamsList}
        />
      );
    }

    if (openGraphModal) {
      graphModal = (
        <Translation ns="CrisisCommon">
          {(t) => (
            <GraphModal
              openGraphModal={openGraphModal}
              handleGraphModal={this.handleGraphModal}
              plot={plot}
              selectedTeams={selectedTeams}
              translate={t}
            />
          )}
        </Translation>
      );
    }

    body = (
      <>
        {teamListMobile}
        {dashBoardSection}
      </>
    );

    if (simulationPerformanceDataRequestStatus.loading) {
      body = <LoadingComponent message={translate("loadingTeamData")} />;
    }

    if (!simulationPerformanceDataRequestStatus.success && !simulationPerformanceDataRequestStatus.loading) {
      let errorMessage = translate(simulationPerformanceDataRequestStatus.errorMessage);
      if (errorMessage === simulationPerformanceDataRequestStatus.errorMessage)
        errorMessage = translate(`CrisisDashboards:${simulationPerformanceDataRequestStatus.errorMessage}`);
      body = <MessagesComponent message={errorMessage} />;
    }

    return (
      <div className="dashBoardContainer">
        {teamsListModal}
        {graphModal}
        <div className="leftSideContainer">{teamsListAside}</div>
        <div className="rightSideContainer">
          <HeaderComponent translate={translate} showBackButton={true} />
          <div className="titleSection">
            <MainTitleComponent title={translate("Common:dashboards")} />
          </div>
          {body}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  crisisGetPerformanceData: (simulationId) => dispatch(crisisGetPerformanceData(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    simulationPerformanceDataRequestStatus: state.crisisSimulationDetails.simulationPerformanceDataRequestStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardsComponent);
