import React, { Component } from "react";
import { Grid } from "semantic-ui-react";

class AmountsComponent extends Component {
  render() {
    const { translate, amountByStudent, paymentData } = this.props;
    const numberOfLicenses = paymentData.numberOfLicenses;
    let studentsTranslate = null;

    if (numberOfLicenses > 1) {
      studentsTranslate = translate("Common:students");
    } else {
      studentsTranslate = translate("Common:Student").toLowerCase();
    }

    return (
      <Grid centered columns={1} className="breakdown">
        <Grid.Column>
          <p className="centeredLabel">
            {numberOfLicenses} {studentsTranslate} X ${amountByStudent}
          </p>
          <p className="centeredLabel">Total = ${paymentData.amount}</p>
        </Grid.Column>
      </Grid>
    );
  }
}

export default AmountsComponent;
