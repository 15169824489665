export const isLoggedIn = "isLoggedIn";
export const teamCode = "teamCode";
export const activationCode = "activationCode";
export const securityCode = "securityCode";
export const authInformation = "authInformation";
export const medicaQuestionnaire = "medicaQuestionnaire";
export const simulationCreated = "simulationCreated";
export const simunationQuestionnaire = "simunationQuestionnaire";
export const simulationSimunationCreated = "simulationSimunationCreated";
export const requestedSimulation = "requestedSimulation";
export const simulationId = "simulationId";
export const teamName = "teamName";
export const secondRunWarningWasShown = "secondRunWarningWasShown";
export const localLanguage = "localLanguage";
export const wasVideoSeen = "wasVideoSeen";
export const dontShowWelcomeModalAgain = "dontShowWelcomeModalAgain";
export const dontShowAddVariationInformationModalAgain = "dontShowAddVariationInformationModalAgain";
export const purchasedProduct = "purchasedProduct";
export const crisisQuestionnaire = "crisisQuestionnaire";
export const crisisJoinSimulationId = "crisisJoinSimulationId";
export const crisisPaymentSummaryId = "crisisPaymentSummaryId";
export const abogadosQuestionnaire = "abogadosQuestionnaire";
export const creambowQuestionnaire = "creambowQuestionnaire";
export const supplychainQuestionnaire = "supplychainQuestionnaire";
export const supplyChainJoinSimulationId = "supplyChainJoinSimulationId";
export const supplyChainPaymentSummaryId = "supplyChainPaymentSummaryId";
