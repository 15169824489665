import * as actions from "./action-types";
import { sendUnauthorizedGetRequest } from "../../services/FetchService";
import * as FETCHAPI from "../../constants/FetchApi";

export const getStandings = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_MEDICA_STANDINGS });
    const standingsResponse = sendUnauthorizedGetRequest(FETCHAPI.GET_STANDINGS_MEDICA_PUBLIC, simulationName);
    standingsResponse
      .then((standings) => {
        let errorMessage = "";
        if (standings?.data?.outcome) {
          const data = standings.data;
          dispatch({
            type: actions.GET_MEDICA_STANDINGS_SUCCESSFUL,
            standingsTable: data.payload,
          });
          return;
        } else if (standings?.data?.outcome === false) {
          errorMessage = "WebUnauthorizeGetStandings API Error";
        }

        if (errorMessage.length === 0) {
          errorMessage = "Unknown Error";
        }

        dispatch({
          type: actions.GET_MEDICA_STANDINGS_FAILED,
          errorMessage: errorMessage,
        });
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: actions.GET_MEDICA_STANDINGS_FAILED,
            errorMessage: "Error fetching standings",
          });
        }
      });
  };
};
