import * as CONSTANT from "../../constants/UniversalConstants";
import * as ButtonKeys from "./actionButtonConstants";

export const filterActionButtons = (simulation, actionButtons) => {
  var filteredActionButtons = [];

  filteredActionButtons = [
    ...Object.keys(actionButtons)
      .filter((x) => {
        if (x === ButtonKeys.runAgainKey) return simulation.currentRun < simulation.maxNumberOfRuns;

        if (x === ButtonKeys.modifyKey) {
          return !simulation.professorManagedProgress && simulation.status === CONSTANT.INITIALIZED;
        }
        if (x === ButtonKeys.startSimulationKey) {
          return (
            simulation.professorManagedProgress && simulation.currentSimulationDay === simulation.initializationPeriod
          );
        }
        if (x === ButtonKeys.advanceSimulationKey) {
          return (
            simulation.professorManagedProgress &&
            simulation.currentSimulationDay > simulation.initializationPeriod &&
            simulation.currentSimulationDay < simulation.initializationPeriod + simulation.studentInteractionPeriod
          );
        }
        if (x === ButtonKeys.finishSimulationKey) {
          return (
            simulation.professorManagedProgress &&
            simulation.currentSimulationDay >= simulation.initializationPeriod + simulation.studentInteractionPeriod &&
            simulation.status !== CONSTANT.FINISHED
          );
        }
        return true;
      })
      .map((x) => {
        return { key: x, ...actionButtons[x] };
      }),
  ];

  return filteredActionButtons;
};
