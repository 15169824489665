import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import DropDownComponent from "../helpers/DropDownComponent";
import InputComponent from "../helpers/InputComponent";

class PushNotificationsComponent extends Component {
  onSelection = (val) => {
    this.props.handleChange("team", val);
  };

  render() {
    const { translate, formState, teamOptions } = this.props;
    const dropdownText = teamOptions.find((x) => x.value === formState.team.value)?.text;
    const titleErrorMessage = !formState.title.valid ? "required" : "";
    const messageErrorMessage = !formState.message.valid ? "required" : "";
    return (
      <Form>
        <DropDownComponent
          translate={translate}
          label={translate("Common:team")}
          specialLabel={true}
          options={teamOptions}
          onSelection={this.onSelection}
          placeholder={translate("selectTeam")}
          selectError={!formState.team.valid}
          value={formState.team.value}
          text={dropdownText}
          labelAlignment="left"
        />
        <InputComponent
          label={translate("title")}
          id="title"
          specialLabel={true}
          type="text"
          onError={!formState.title.valid}
          value={formState.title.value}
          handleChange={this.props.handleChange}
          counter={true}
          length={formState.title.value.length}
          maxLength={formState.title.rules.maxLength}
          errorMessage={translate(titleErrorMessage)}
          labelAlignment="left"
        />
        <InputComponent
          multiLine={true}
          label={translate("message")}
          id="message"
          specialLabel={true}
          rows={5}
          onError={!formState.message.valid}
          value={formState.message.value}
          handleChange={this.props.handleChange}
          counter={true}
          length={formState.message.value.length}
          maxLength={formState.message.rules.maxLength}
          errorMessage={translate(messageErrorMessage)}
          labelAlignment="left"
        />
      </Form>
    );
  }
}

export default PushNotificationsComponent;
