import React from "react";

const InformativeParagraph = (props) => {
  const { paragraphOne, paragraphTwo } = props;
  return (
    <div className="informativeParagraphContainer">
      <p className="informativeParagraph">{paragraphOne}</p>
      <p className="informativeParagraph">{paragraphTwo}</p>
    </div>
  );
};

export default InformativeParagraph;
