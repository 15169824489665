import React from "react";
import { Header, Image } from "semantic-ui-react";
import "./helpers.css";
import processimLogo from "../../assets/pl-logo.png";

const LoadingComponent = (props) => {
  let image = null;

  if (props.image) {
    image = <Image centered size="small" src={processimLogo} />;
  }

  return (
    <div className={props.fullSize ? "loadingContainer" : null}>
      <div className="loadingInformation">
        {image}
        <div className="loader" />
        <Header as="h2" icon textAlign="center">
          <Header.Subheader>{props.message}</Header.Subheader>
        </Header>
      </div>
    </div>
  );
};

export default LoadingComponent;
