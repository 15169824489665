import _ from "lodash";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Table } from "semantic-ui-react";

class StandingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: null,
      standingsList: props.simulationStandings,
      direction: null,
    };
  }

  handleSort = (clickedColumn) => () => {
    const { column, standingsList, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        standingsList: _.sortBy(standingsList, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      standingsList: standingsList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  numberWithCommas = (cash) => {
    return `${Math.round(cash).toLocaleString()}.00`;
  };

  render() {
    const translate = this.props.translate;
    const { column, direction, standingsList } = this.state;
    const arrowsIcon = <FontAwesomeIcon className="tableIcon" icon={faSort} size="lg" color="#FFF" />;
    return (
      <div id="standingsTable" className="tableContainer" ref={(ref) => (this.topTable = ref)}>
        <p>{`${translate("totalTeams")}: ${standingsList.length - 1}`}</p>
        <Table unstackable sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === "standing" ? direction : null} onClick={this.handleSort("standing")}>
                <div className="headerCell">
                  {translate("standings").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "team" ? direction : null} onClick={this.handleSort("team")}>
                <div className="headerCell">
                  {translate("Common:team").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "cash" ? direction : null} onClick={this.handleSort("cash")}>
                <div className="headerCell">
                  {translate("cash").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {standingsList.map((standing) => {
              return (
                <Table.Row key={standing.standing}>
                  <Table.Cell singleLine>{standing.standing}</Table.Cell>
                  <Table.Cell singleLine>{standing.team}</Table.Cell>
                  <Table.Cell singleLine>{`$ ${this.numberWithCommas(standing.cash)}`}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default StandingsComponent;
