import React, { Component } from "react";
import { connect } from "react-redux";
import { getStorage } from "../../services/StorageService";
import { crisisJoinSimulation } from "../../store/CrisisJoinSimulation/actions";
import { crisisConfirmPayment } from "../../store/CrisisBuySimulation/actions";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import LoadingComponent from "../helpers/LoadingComponent";
import SuccessModal from "../modals/SuccessModal";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ErrorModal from "../modals/ErrorModal";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANTS from "../../constants/UniversalConstants";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

export class CrisisJoinSimulation extends Component {
  constructor(props) {
    super(props);
    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }
    this.state = {
      showSuccessMessage: true,
      redirectToDownload: false,
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    if (typeof this.state.paymentIntentId === "string") {
      await this.validatePayment();
      return;
    }

    this.attemptToJoinSimulation();
  }

  componentDidUpdate(prevProps) {
    const prevConfirmPaymentStatus = prevProps.confirmPaymentStatus;
    const currentConfirmPaymentStatus = this.props.confirmPaymentStatus;
    if (
      typeof this.state.paymentIntentId === "string" &&
      prevConfirmPaymentStatus.status !== currentConfirmPaymentStatus.status &&
      currentConfirmPaymentStatus.status === confirmPaymentStatuses.success
    ) {
      this.attemptToJoinSimulation();
    }
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  attemptToJoinSimulation = () => {
    const simulationId = getStorage(LOCALSTORAGE.crisisJoinSimulationId);
    const paymentSummaryId = getStorage(LOCALSTORAGE.crisisPaymentSummaryId);
    const token = this.state.paymentIntentId || paymentSummaryId;
    this.props.crisisJoinSimulation({ simulationId, token });
  };

  closeSuccessModal = () => {
    this.setState({
      showSuccessMessage: false,
      redirectToDownload: true,
    });
  };

  handleModalOk = () => {
    this.setState({
      redirectToDownload: true,
    });
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    let body = null;
    if (this.props.loading) {
      body = <LoadingComponent fullSize={true} image={true} message={translate("AuthComponents:loadingPage")} />;
    }

    const showSuccessModal = this.props.code !== "" && this.state.showSuccessMessage;
    const successModal = (
      <SuccessModal
        openSuccessModal={showSuccessModal}
        handleSuccessModal={this.closeSuccessModal.bind(this)}
        translate={translate}
        message={translate("joinSimulationSuccessful")}
      />
    );

    let errorMessageModal = null;
    if (this.props.errorMessage === "JoinSimulationController_AlreadyJoined") {
      errorMessageModal = (
        <ErrorModal
          openErrorModal
          title={translate("failedJoinSimulation")}
          message={translate(this.props.errorMessage)}
          warning
          translate={translate}
          handleErrorModal={this.handleModalOk.bind(this)}
        />
      );
    } else if ((this.props.errorMessage ?? "") !== "") {
      errorMessageModal = (
        <ErrorModal
          openErrorModal
          title={translate("failedJoinSimulation")}
          message={translate(this.props.errorMessage)}
          hasRetry
          retryText={translate("Retry")}
          translate={translate}
          handleRetry={this.attemptToJoinSimulation.bind(this)}
        />
      );
    }

    if (this.state.redirectToDownload) {
      body = (
        <Redirect
          to={{
            pathname: ROUTES.DOWNLOAD_APP,
            search: `?app=${CONSTANTS.CRISIS_NAME}`,
          }}
        />
      );
    }
    return (
      <>
        {body}
        {errorMessageModal}
        {successModal}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmPaymentStatus: state.crisisBuySimulation.confirmPayment,
  errorMessage: state.crisisJoinSimulation.errorMessage,
  loading: state.crisisJoinSimulation.loading,
  code: state.crisisJoinSimulation.code,
});

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(crisisConfirmPayment(intentId)),
  crisisJoinSimulation: (request) => dispatch(crisisJoinSimulation(request)),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("CrisisJoinSimulation", "AuthComponents")
);

export default HOC(CrisisJoinSimulation);
