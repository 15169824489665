import React, { Component } from "react";
import { Divider } from "semantic-ui-react";
import InputSliderComponent from "../helpers/InputSliderComponent";
import InputComponent from "../helpers/InputComponent";
import InputToggleComponent from "../helpers/InputToggleComponent";
import { CREATE_NEW_SCENARIO_SECTIONS } from "../utilities/ScenarioInformationUtilities";

class NewScenarioFormItems extends Component {
  render() {
    const {
      translate,
      state,
      onChangeInputToggle,
      onChangeInputText,
      onChangeInputRange,
      numberAddition,
      numberSubtraction,
      setRamdomValue,
    } = this.props;

    const formInputSections = CREATE_NEW_SCENARIO_SECTIONS.map((section, index) => {
      return (
        <React.Fragment key={index}>
          <Divider horizontal>{translate(section.sectionName)}</Divider>
          {section.items.map((item, index) => {
            const inputId = `${section.sectionName}_${item.name}`;
            const inputValue = state[inputId];
            let toggleLabelText = null;
            let inputComponent = null;
            let minValueInfo = item.minValue;
            let maxValueInfo = item.maxValue;
            let valueLabel = translate(item.units, { count: inputValue });

            let toggleId = null;
            if (item.toggle) {
              toggleLabelText = translate("hasVariance");
              toggleId = `${section.sectionName}_${item.toggle}`;
            }

            switch (item.type) {
              case "string":
                let showInputError = null;
                let buttonSectionClasses = "buttonSection";
                if (item.name === "description") {
                  showInputError = state.descriptionError;
                } else if (item.name === "seed") {
                  showInputError = state.seedError;
                  if (state.seedError) {
                    buttonSectionClasses += " heigthGrow";
                  }
                } else {
                  showInputError = state.nameError;
                }

                if (item.name === "seed") {
                  inputComponent = (
                    <div ref={item.name} key={index} className="newScenarioItem">
                      <div className="seedItem">
                        <div className="inputSection">
                          <InputComponent
                            handleChange={onChangeInputText}
                            label={translate(item.name)}
                            onError={showInputError}
                            errorMessage={translate(state.inputTextError)}
                            id={inputId}
                            value={inputValue}
                            labelAlignment="left"
                            type="number"
                            min={item.minValue}
                            max={item.maxValue}
                            helpMessage={translate(item.helpMessage)}
                          />
                        </div>
                        <div className={buttonSectionClasses}>
                          <button onClick={() => setRamdomValue(inputId)}>{translate("randomize")}</button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  inputComponent = (
                    <div ref={item.name} key={index} className="newScenarioItem">
                      <InputComponent
                        handleChange={onChangeInputText}
                        label={translate(item.name)}
                        onError={showInputError}
                        errorMessage={translate(state.inputTextError)}
                        id={inputId}
                        value={inputValue}
                        labelAlignment="left"
                      />
                    </div>
                  );
                }
                break;
              case "number":
                inputComponent = (
                  <div key={index} className="newScenarioItem">
                    <InputSliderComponent
                      id={inputId}
                      label={translate(item.name)}
                      minValue={item.minValue}
                      maxValue={item.maxValue}
                      minValueLabel={minValueInfo}
                      maxValueLabel={maxValueInfo}
                      valueLabel={valueLabel}
                      value={inputValue}
                      onChangeInputRange={onChangeInputRange}
                      numberAddition={numberAddition}
                      numberSubtraction={numberSubtraction}
                      toggleLabel={toggleLabelText}
                      toggleId={toggleId}
                      onChangeInputToggle={onChangeInputToggle}
                      step={item.step}
                      helpMessage={translate(item.helpMessage)}
                    />
                  </div>
                );
                break;
              case "toggle":
                inputComponent = (
                  <div key={index} className="newScenarioItem">
                    <InputToggleComponent
                      id={inputId}
                      label={translate("hasVariance")}
                      onChangeInputToggle={onChangeInputToggle}
                    />
                  </div>
                );
                break;
              default:
                break;
            }

            return inputComponent;
          })}
        </React.Fragment>
      );
    });

    return formInputSections;
  }
}

export default NewScenarioFormItems;
