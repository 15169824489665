import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { Trans } from "react-i18next";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";

const DeleteProfessorScenarioModal = (props) => {
  const {
    translate,
    openDeleteProfessorScenarioModal,
    deleteProfessorScenarioClick,
    scenarioSelected,
    professorDeleteScenarioData,
    professorDeleteScenario,
    resetProfesorDeleteScenarioStore,
    getScenarioList,
  } = props;

  const closeModalAndResetStore = () => {
    resetProfesorDeleteScenarioStore();
    deleteProfessorScenarioClick();
  };

  const closeModalResetStoreAndGetProfessorScenarios = () => {
    closeModalAndResetStore();
    getScenarioList();
  };

  const {
    loadingProfessorDeleteScenario,
    professorDeleteScenarioSuccess,
    professorDeleteScenarioMessage,
  } = professorDeleteScenarioData;

  let actionButtonsContent = null;
  let modalContent = null;
  let actionButtons = null;

  if (professorDeleteScenarioSuccess) {
    modalContent = <MessagesComponent message={translate(professorDeleteScenarioMessage)} messageType={true} />;

    actionButtons = <Button content={translate("close")} onClick={closeModalResetStoreAndGetProfessorScenarios} />;
  } else {
    if (professorDeleteScenarioMessage === "") {
      const scenarioName = scenarioSelected.name;
      modalContent = (
        <Trans i18nKey={`ScenarioManagerScreen:areYouSureYouWantDeleteScenario`}>
          Are you sure do you want delete your scenario <strong>{{ scenarioName }}</strong>?
        </Trans>
      );

      actionButtons = (
        <>
          <Button content={translate("delete")} onClick={() => professorDeleteScenario(scenarioSelected.id)} />
          <Button content={translate("close")} onClick={closeModalAndResetStore} />
        </>
      );
    } else {
      modalContent = <MessagesComponent message={translate(professorDeleteScenarioMessage)} messageType={false} />;

      actionButtons = (
        <>
          <Button content={translate("Common:tryAgain")} onClick={() => professorDeleteScenario(scenarioSelected.id)} />
          <Button content={translate("close")} onClick={closeModalAndResetStore} />
        </>
      );
    }
  }

  if (loadingProfessorDeleteScenario) {
    actionButtonsContent = <LoadingComponent />;
  } else {
    actionButtonsContent = actionButtons;
  }

  return (
    <Modal size="tiny" open={openDeleteProfessorScenarioModal} onClose={closeModalAndResetStore}>
      <Modal.Header>{translate("deleteScenario")}</Modal.Header>
      <Modal.Content>{modalContent}</Modal.Content>
      <Modal.Actions>{actionButtonsContent}</Modal.Actions>
    </Modal>
  );
};

export default DeleteProfessorScenarioModal;
