import React from "react";
import { Card, Grid, Image } from "semantic-ui-react";
import NavigationButton from "../helpers/NavigationButton";

const NUMBER_OF_COLUMNS = 2;

const Products = (props) => {
  const translate = props.translate;

  const RenderProductListing = (product) => {
    const comingSoonBadge = <div className="coming-soon-badge__product">Coming Soon</div>;

    return (
      <Card fluid className="product-card__catalogue">
        <div className="productImage" style={{ backgroundColor: product.color }}>
          <Image className="productLogo" src={product.logo} />
          {product.comingSoon ? comingSoonBadge : null}
        </div>
        <Card.Content>
          <Card.Header>{product.name}</Card.Header>
          <p className="product-card__catalogue--flavor-text">{translate(product.flavorText)}</p>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={11} computer={10} largeScreen={11}>
                {product.topics ? (
                  <p className="product-card__catalogue--topics-text">
                    {translate("topic", { topicsList: translate(product.topics) })}
                  </p>
                ) : null}
              </Grid.Column>
              <Grid.Column mobile={12} tablet={5} computer={6} largeScreen={5} verticalAlign="bottom" floated="right">
                <NavigationButton
                  className="product-card__catalogue--buy-button"
                  route={product.route}
                  history={props.history}
                  label={translate("select")}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
  };

  const RenderGridContent = (products) => {
    const numberOfRows = Math.ceil(products.length / NUMBER_OF_COLUMNS);
    const rows = [];
    for (let row = 0; row < numberOfRows; row++) {
      const rowStartItemIndex = row * NUMBER_OF_COLUMNS;
      let lastItemInRowOffset = NUMBER_OF_COLUMNS;

      if (row + 1 === numberOfRows && products.length % NUMBER_OF_COLUMNS !== 0) {
        lastItemInRowOffset = products.length % NUMBER_OF_COLUMNS;
      }

      const rowItems = products.slice(rowStartItemIndex, rowStartItemIndex + lastItemInRowOffset);
      rows.push(
        <Grid.Row key={`cataloge_row${row}`} className="product-row__catalogue">
          {rowItems.map((product) => (
            <Grid.Column key={product.key} tablet={16} computer={8}>
              {RenderProductListing(product)}
            </Grid.Column>
          ))}
        </Grid.Row>
      );
    }

    return rows;
  };

  return (
    <div className="displayFlex justifyContentCenter alignItemsCenter">
      <Grid stackable columns={NUMBER_OF_COLUMNS}>
        {RenderGridContent(props.productsList)}
      </Grid>
    </div>
  );
};

export default Products;
