export const getUrlSearchParam = () => {
  return decodeURIComponent(window.location.search.slice(1));
};

export const preventBack = () => {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener("popstate", backPressEvent);
};

export const allowBack = () => {
  window.removeEventListener("popstate", backPressEvent);
};

function backPressEvent() {
  window.history.pushState(null, document.title, window.location.href);
}
