import React from "react";

const TeamsHandlers = (props) => {
  const { translate, teamsList, handleAllChecked, handleTopSelection, selection } = props;
  const teamsQuantity = teamsList.length;
  let topTreeButton = null;
  let topFiveButton = null;
  let topTenButton = null;

  const selectAllContainer = (
    <div className="buttonContainer">
      <button className="selectAllButton" id="selectAll" onClick={(event) => handleAllChecked(event)}>
        {translate("selectAll")}
      </button>
      <button className="selectAllButton" id="unSelectAll" onClick={(event) => handleAllChecked(event)}>
        {translate("unselectAll")}
      </button>
    </div>
  );

  if (teamsQuantity > 5) {
    let clickHandler = null;
    if (selection !== 3) {
      clickHandler = () => handleTopSelection(3);
    }
    topTreeButton = (
      <button className="specialSelection" onClick={clickHandler}>
        {translate("topThree")}
      </button>
    );
  }

  if (teamsQuantity > 9) {
    let clickHandler = null;
    if (selection !== 5) {
      clickHandler = () => handleTopSelection(5);
    }
    topFiveButton = (
      <button className="specialSelection" onClick={clickHandler}>
        {translate("topFive")}
      </button>
    );
  }

  if (teamsQuantity > 19) {
    let clickHandler = null;
    if (selection !== 10) {
      clickHandler = () => handleTopSelection(10);
    }
    topTenButton = (
      <button className="specialSelection" onClick={clickHandler}>
        {translate("topTen")}
      </button>
    );
  }

  const specialSelectionsContainer = (
    <div className="buttonContainer">
      {topTreeButton}
      {topFiveButton}
      {topTenButton}
    </div>
  );

  return (
    <div className="teamListHeader">
      {selectAllContainer}
      {specialSelectionsContainer}
    </div>
  );
};

export default TeamsHandlers;
