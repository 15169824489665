import * as actions from "./action-types";

export const initialState = {
  medicaPublicStandings: {
    outcome: false,
    loading: true,
    errorMessage: "",
    standingsTable: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_MEDICA_STANDINGS:
      return {
        ...state,
        medicaPublicStandings: {
          loading: true,
          outcome: false,
          errorMessage: "",
          standingsTable: null,
        },
      };
    case actions.GET_MEDICA_STANDINGS_SUCCESSFUL:
      return {
        ...state,
        medicaPublicStandings: {
          loading: false,
          outcome: true,
          errorMessage: "",
          standingsTable: action.standingsTable,
        },
      };
    case actions.GET_MEDICA_STANDINGS_FAILED:
      return {
        ...state,
        medicaPublicStandings: {
          loading: false,
          outcome: false,
          errorMessage: action.errorMessage,
          standingsTable: null,
        },
      };
    default:
      return state;
  }
}
