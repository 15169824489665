import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import history from "../constants/History";
import CrisisBuySimulation from "./CrisisBuySimulation";
import CrisisJoinSimulation from "./CrisisJoinSimulation";
import CrisisSimulationDetails from "./CrisisSimulationDetails";
import MedicaReducer from "./Medica";
import MedicaQuestionnaireReducer from "./MedicaQuestionnaire";
import ProfileReducer from "./Profile";
import ScenarioReducer from "./Scenario";
import SimulationsReducer from "./Simulations";
import simunationReducer from "./Simunation";
import SimunationQuestionnaireReducer from "./SimunationQuestionnaire";
import SupplyChainBuySimulation from "./SupplyChainBuySimulation";
import SupplyChainJoinSimulation from "./SupplyChainJoinSimulation";
import SupplyChainSimulationDetails from "./SupplyChainSimulationDetails";
import TutorialsReducer from "./Tutorials";
import UserReducer from "./User";
import PublicPages from "./PublicPages";
import AbogadosBuySimulation from "./AbogadosBuySimulation";
import AbogadosSimulationDetails from "./AbogadosSimulationDetails";
import CreambowBuySimulation from "./CreambowBuySimulation";
import CreambowSimulationDetails from "./CreambowSimulationDetails";
import StripePayment from "./StripePayment";

export default () =>
  combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    simulations: SimulationsReducer,
    medica: MedicaReducer,
    medicaQuestionnaire: MedicaQuestionnaireReducer,
    simunation: simunationReducer,
    simunationQuestionnaire: SimunationQuestionnaireReducer,
    tutorials: TutorialsReducer,
    profile: ProfileReducer,
    scenario: ScenarioReducer,
    crisisBuySimulation: CrisisBuySimulation,
    crisisJoinSimulation: CrisisJoinSimulation,
    crisisSimulationDetails: CrisisSimulationDetails,
    supplychainBuySimulation: SupplyChainBuySimulation,
    supplyChainJoinSimulation: SupplyChainJoinSimulation,
    supplyChainSimulationDetails: SupplyChainSimulationDetails,
    publicPages: PublicPages,
    abogadosBuySimulation: AbogadosBuySimulation,
    abogadosSimulationDetails: AbogadosSimulationDetails,
    creambowBuySimulation: CreambowBuySimulation,
    creambowSimulationDetails: CreambowSimulationDetails,
    stripePayment: StripePayment,
  });
