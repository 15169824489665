import i18n from "i18next";
import { setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import resources from "../../translations/resources";

export const processLanguageAndSave = (language, setUserLanguage) => {
  i18n.changeLanguage(language);
  setStorage(LOCALSTORAGE.localLanguage, language);
  if (setUserLanguage) {
    setUserLanguage(language);
  }
};

export const translationExists = (key) => {
  const allNamespaces = Object.keys(resources.en);
  const translationExists = allNamespaces.some((namespace) => {
    return i18n.exists(`${namespace}:${key}`);
  });
  return translationExists;
};

export const generateTranslationKey = ({ ns, key }) => {
  if (typeof ns === "string" && ns.length > 0) return `${ns}:${key}`;
  return key;
};

export const getLanguagePrefix = (language) => {
  let languagePrefix = "en";
  if (language) {
    languagePrefix = language.slice(0, 2);
  }
  return languagePrefix;
};
