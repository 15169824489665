import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import * as CONSTANT from "../../constants/UniversalConstants";
import moment from "moment";
import MessagesComponent from "../helpers/MessagesComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import CalendarComponent from "../helpers/CalendarComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import InputComponent from "../helpers/InputComponent";
import AdvancedOptionsComponent from "../purchasedMedica/AdvancedOptionsComponent";

const nextRunAffix = {
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
};

class RunAgainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateToModify: "",
      startHour: this.switchTo12HourFormat(Number(moment().add(1, "hours").format("HH"))),
      startHourFormat: moment().format("a"),
      startDate: moment().startOf("day"),
      endHour: this.switchTo12HourFormat(Number(moment().add(1, "hours").format("HH"))),
      endHourFormat: moment().format("a"),
      endDate: moment().startOf("day").add(1, "day"),
      errorMessage: "",
      error: false,
      openCalendar: false,
      openAdvancedOptions: false,
      managedProgressError: false,
      daysToRunError: "",
      daysToRun: null,
    };
  }

  createNewSimulationName = () => {
    const { parentSimulation } = this.props;
    const nextRun = parentSimulation.currentRun + 1;
    const baseName = nextRun > 2 ? parentSimulation.name.slice(0, -6) : parentSimulation.name;

    if (nextRun in Object.keys(nextRunAffix)) {
      return `${baseName} (${nextRunAffix[nextRun]})`;
    }

    return `${baseName} (${nextRun}th)`;
  };

  handleShowCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  onCalendarDateClicked = (date, dateToModify) => {
    if (dateToModify === "startDate" && date >= moment(this.state.endDate).format("YYYY-MM-DD")) {
      this.setState({
        [dateToModify]: date,
        endDate: moment(date).add(1, "day"),
      });
    } else {
      this.setState({
        [dateToModify]: date,
        errorMessage: "",
      });
    }
  };

  settingStartHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        startHourFormat: value,
        errorMessage: "",
      });
    } else {
      this.setState({
        startHour: value,
        errorMessage: "",
      });
    }
  };

  settingEndHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        endHourFormat: value,
        errorMessage: "",
      });
    } else {
      this.setState({
        endHour: value,
        errorMessage: "",
      });
    }
  };

  switchTo24HourFormat = (hour, format) => {
    let formattedHour = 0;
    if (format === "am" && hour === 12) {
      formattedHour = hour - 12;
    } else if (format === "pm" && hour < 12) {
      formattedHour = hour + 12;
    } else {
      formattedHour = hour;
    }
    return formattedHour;
  };

  switchTo12HourFormat = (hour) => {
    let formattedHour = null;
    if (hour > 12) {
      formattedHour = hour - 12;
    } else {
      if (hour === 0) {
        formattedHour = 12;
      } else {
        formattedHour = hour;
      }
    }
    return formattedHour;
  };

  endDateMustBeGreaterError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("ModifyModal:End Date must be after Start Date"),
    });
    return;
  };

  fieldsError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("verifyFieldsCorrectlyFilled"),
    });
    return;
  };

  setErrorMessage = (errorMessage) => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate(errorMessage),
    });
    return;
  };

  checkWithDatesSelected = () => {
    const { startDate, endDate, startHour, endHour, startHourFormat, endHourFormat, daysToRun } = this.state;
    const { isProfessorManagedProgress } = this.props;

    const formattedStartHour = this.switchTo24HourFormat(startHour, startHourFormat);
    const formattedEndHour = this.switchTo24HourFormat(endHour, endHourFormat);
    const newDates = {
      endDate: this.formatDateTime(endDate, formattedEndHour),
      startDate: this.formatDateTime(startDate, formattedStartHour),
    };

    const differenceBetweenDates = newDates.endDate.diff(newDates.startDate);

    if (isProfessorManagedProgress) {
      if (daysToRun === null) {
        this.setState({
          daysToRunError: true,
        });
        this.setErrorMessage("verifyFieldsCorrectlyFilled");
      } else {
        return this.saveInformation(newDates, daysToRun);
      }
    } else {
      if (newDates.startDate.diff(moment().add(1, "hour")) < 0) {
        this.setErrorMessage("ModifyModal:simulationMustStartLeastOneHourInFuture");
      } else if (differenceBetweenDates < 0) {
        this.setErrorMessage("ModifyModal:End Date must be after Start Date");
      } else if (differenceBetweenDates >= 0 && differenceBetweenDates < CONSTANT.SIX_HOURS_MILLISECONDS) {
        this.setErrorMessage("ModifyModal:Simulation must run for at least 6 hours");
      } else if (differenceBetweenDates > CONSTANT.SEVEN_DAYS_MILLISECONDS) {
        this.setErrorMessage("ModifyModal:Simulation can only run for 7 days or less");
      } else if (differenceBetweenDates >= CONSTANT.SIX_HOURS_MILLISECONDS) {
        return this.saveInformation(newDates, daysToRun);
      }
    }
  };

  saveInformation = (newDates, daysToRun) => {
    const { advancedOptions } = this.props;
    const values = {
      startDate: moment(newDates.startDate).toISOString(),
      endDate: moment(newDates.endDate).toISOString(),
      simulationName: this.createNewSimulationName(),
      simulationId: this.props.parentSimulation.id,
      managedProgress: this.props.isProfessorManagedProgress,
      totalStudentInteractionDays: daysToRun !== null ? daysToRun : 0,
      timeOffset: -new Date().getTimezoneOffset() / 60,
      ...advancedOptions,
    };
    return values;
  };

  formatDateTime = (date, time) => {
    return moment(`${moment(date).format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD H");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formValues = this.checkWithDatesSelected();
    this.props.handleContinue(formValues);
  };

  onSelectionProfessorManagedProgress = (val) => {
    this.setState({
      managedProgressError: false,
      errorMessage: "",
      daysToRunError: false,
      error: false,
    });

    this.props.onSetSimulationParameters("managedProgress", val);
  };

  onSelectionDay = (val) => {
    this.setState({
      daysToRun: val,
      daysToRunError: false,
      errorMessage: "",
    });
  };

  render() {
    const { language, translate } = this.props;
    const optionsDaysToRun = [
      {
        key: 1,
        text: 100,
        value: 100,
      },
      {
        key: 2,
        text: 200,
        value: 200,
      },
      {
        key: 3,
        text: 300,
        value: 300,
      },
    ];

    const optionsProfessorManagedProgress = [
      {
        key: 1,
        text: translate("Common:yes"),
        value: true,
      },
      {
        key: 2,
        text: translate("Common:no"),
        value: false,
      },
    ];

    let calendarComponent = null;
    let errorMessageComponent = null;
    let selectedDate = null;
    let daysToRunDropDown = null;
    let startDateSelector = null;
    let endDateSelector = null;

    if (this.state.dateToModify === "startDate") {
      selectedDate = this.state.startDate;
    } else {
      selectedDate = this.state.endDate;
    }

    const { managedProgressError } = this.state;
    const { isProfessorManagedProgress } = this.props;
    if (isProfessorManagedProgress) {
      daysToRunDropDown = (
        <DropDownComponent
          id="studentInteractionDaysDropdown"
          helpMessage={translate("daysToPlayHelp")}
          label={translate("daysToPlay")}
          options={optionsDaysToRun}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.onSelectionDay}
          selectError={this.state.daysToRunError}
          value={this.state.daysToRun}
          labelAlignment="left"
        />
      );
      calendarComponent = null;
      startDateSelector = null;
      endDateSelector = null;
    } else {
      startDateSelector = (
        <div id="startDateSelector">
          <DateTimeSelector
            label={translate("ModifyModal:startDate")}
            onFocus={() => this.setState({ dateToModify: "startDate" })}
            error={this.state.error}
            handleCalendar={this.handleShowCalendar}
            dateToSet={CONSTANT.START_DATE}
            settingHour={this.settingStartHour}
            date={this.state.startDate}
            hour={this.state.startHour}
            hourFormat={this.state.startHourFormat}
            labelAlignment="left"
            language={language}
          />
        </div>
      );
      endDateSelector = (
        <div id="endDateSelector">
          <DateTimeSelector
            label={translate("ModifyModal:endDate")}
            onFocus={() => this.setState({ dateToModify: "endDate" })}
            error={this.state.error}
            handleCalendar={this.handleShowCalendar}
            dateToSet={CONSTANT.END_DATE}
            settingHour={this.settingEndHour}
            date={this.state.endDate}
            hour={this.state.endHour}
            hourFormat={this.state.endHourFormat}
            labelAlignment="left"
            language={language}
          />
        </div>
      );

      if (this.state.dateToModify === "startDate") {
        selectedDate = this.state.startDate;
      } else {
        selectedDate = this.state.endDate;
      }

      if (this.state.openCalendar) {
        calendarComponent = (
          <div className="calendarPosition">
            <CalendarComponent
              translate={translate}
              handleCalendar={this.handleShowCalendar}
              dateToModify={this.state.dateToModify}
              onDateClick={this.onCalendarDateClicked}
              selectedDate={selectedDate}
            />
          </div>
        );
      }
    }

    if (this.state.errorMessage !== "") {
      errorMessageComponent = <MessagesComponent message={this.state.errorMessage} />;
    }

    const simulationName = this.createNewSimulationName();
    return (
      <div>
        <Form id="run-again-form" onSubmit={this.handleSubmit.bind(this)}>
          {calendarComponent}
          <InputComponent
            id="simulationName"
            label={translate("simulationName")}
            value={simulationName}
            disabled={true}
            labelAlignment="left"
            boldContent={true}
          />
          <DropDownComponent
            id="manualSimulationSelector"
            helpMessage={translate("medicaProfessorAdvanceSimulationHelp")}
            label={translate("professorAdvanceSimulation")}
            options={optionsProfessorManagedProgress}
            placeholder={translate("pleaseChooseOne")}
            onSelection={this.onSelectionProfessorManagedProgress}
            selectError={managedProgressError}
            value={isProfessorManagedProgress}
            labelAlignment="left"
          />
          {startDateSelector}
          {endDateSelector}
          {daysToRunDropDown}
          {errorMessageComponent}
        </Form>
        <AdvancedOptionsComponent
          title={translate("advancedOptionsButtonText")}
          advancedOptionsClick={this.props.advancedOptionsClick}
        />
      </div>
    );
  }
}

export default RunAgainComponent;
