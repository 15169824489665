import * as actionTypes from "./action-types";
import { sendGetRequest, sendPostRequest } from "../../services/FetchService.js";
import * as API_PATHS from "../../constants/FetchApi.js";

export const supplyChainGetSimulationDetails = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_DETAILS_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_SIMULATION_DETAILS, simulationId);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_DETAILS_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_DETAILS_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_DETAILS_FAIL, payload: err });
    }
  };
};

export const supplyChainResetRequestsState = () => {
  return { type: actionTypes.SUPPLY_CHAIN_RESET_REQUESTS_STATE };
};

export const supplyChainGetRegisteredStudents = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_REGISTERED_STUDENTS, simulationId);
      if (!response?.data?.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_FAIL, payload: response.data?.message });
        return;
      }

      const payload = {
        registeredStudents: response.data.payload,
      };

      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_REGISTERED_STUDENTS_FAIL, payload: err });
    }
  };
};

export const supplyChainAdvanceSimulation = (advanceSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_ADVANCE_SIMULATION_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_ADVANCE_SIMULATION, advanceSimulationRequest);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_ADVANCE_SIMULATION_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_ADVANCE_SIMULATION_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_ADVANCE_SIMULATION_FAIL, payload: err });
    }
  };
};

export const supplyChainStartSimulation = (startSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_START_SIMULATION_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_START_SIMULATION, startSimulationRequest);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_START_SIMULATION_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_START_SIMULATION_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_START_SIMULATION_FAIL, payload: err });
    }
  };
};

export const supplyChainGetNumberOfRegisteredStudents = (simulationId) => {
  return async function (dispatch) {
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS, simulationId);
      if (!response?.data?.outcome) {
        dispatch({
          type: actionTypes.SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_FAIL,
          payload: response.data?.message,
        });
        return;
      }

      dispatch({
        type: actionTypes.SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS,
        payload: response.data.payload,
      });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS_FAIL, payload: err });
    }
  };
};

export const supplyChainGetSimulationStandings = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_STANDINGS, simulationId);
      if (!response?.data?.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_FAIL, payload: response.data?.message });
        return;
      }

      const payload = {
        standings: response.data.payload.teamPositionStandings,
        teamMembers: response.data.payload.studentsInformation,
      };

      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_SIMULATION_STANDINGS_FAIL, payload: err });
    }
  };
};

export const supplyChainGetPerformanceData = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PERFORMANCE_DATA_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_PERFORMANCE_DATA, simulationId);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PERFORMANCE_DATA_FAIL, payload: response.data.message });
        return;
      }
      const payload = {
        currentStep: response.data.payload.currentStep,
        performanceData: response.data.payload.flowsInformation,
        simulationName: response.data.payload.simulationName,
      };

      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PERFORMANCE_DATA_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PERFORMANCE_DATA_FAIL, payload: err });
    }
  };
};

export const supplyChainGetDecisionsData = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DECISIONS_DATA_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_DECISIONS_DATA, simulationId);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DECISIONS_DATA_FAIL, payload: response.data.message });
        return;
      }
      const payload = {
        currentStep: response.data.payload.currentStep,
        decisionsData: response.data.payload.sections,
        simulationName: response.data.payload.name,
      };

      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DECISIONS_DATA_SUCCESS, payload: payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DECISIONS_DATA_FAIL, payload: err });
    }
  };
};

export const supplyChainCreateNextRun = (request) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_CREATE_NEXT_RUN_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_CREATE_NEXT_RUN, request);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_CREATE_NEXT_RUN_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_CREATE_NEXT_RUN_SUCCESS, payload: response.data?.message });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_CREATE_NEXT_RUN_FAIL, payload: err });
    }
  };
};

export const resetCreateNextRunState = () => {
  return { type: actionTypes.SUPPLY_CHAIN_CREATE_NEXT_RUN_CLEAN };
};

export const supplyChainGetProfessorScenarios = () => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS, "");
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_FAIL, payload: response.data.message });
        return;
      }

      const scenarioPayload = response.data.payload.map((x) => ({
        title: x.name,
        description: x.description,
        id: x.id,
      }));

      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_SUCCESS, payload: scenarioPayload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS_FAIL, payload: err });
    }
  };
};

export const supplyChainModifySimulation = (request) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_MODIFY_SIMULATION_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_MODIFY_SIMULATION, request);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_MODIFY_SIMULATION_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_MODIFY_SIMULATION_SUCCESS, payload: response.data?.message });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_MODIFY_SIMULATION_FAIL, payload: err });
    }
  };
};

export const supplyChainModifyReset = () => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_MODIFY_SIMULATION_RESET });
  };
};

export const supplyChainGetDocumentation = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DOCUMENTATION_START });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_DOCUMENTATION, simulationId);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DOCUMENTATION_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DOCUMENTATION_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_GET_DOCUMENTATION_FAIL, payload: err });
    }
  };
};
