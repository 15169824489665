import React from "react";
import { Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const headerButtons = [
  {
    name: "home",
    pathname: "/Home",
  },
  {
    name: "videos",
    pathname: "/Videos",
  },
  {
    name: "profile",
    pathname: "/Profile",
  },
  {
    name: "scenarioManager",
    pathname: "/ScenarioManager",
  },
  {
    name: "logout",
    pathname: "/Logout",
  },
];

const HeaderButtons = (props) => {
  const { translate } = props;
  let headerButtonsPack = null;
  let headerButtonsPackDropdown = null;

  headerButtonsPackDropdown = (
    <Dropdown
      className="dropdownHeader onlyMobile"
      trigger={<FontAwesomeIcon color="#494848" icon={faBars} size="2x" />}>
      <Dropdown.Menu direction="left">
        {headerButtons.map((button, index) => {
          let headerLabel = null;
          const buttonName = translate(`Common:${button.name}`).toUpperCase();
          return (
            <Dropdown.Item key={index} className="dropDownItem">
              <NavLink
                to={button.pathname}
                exact
                activeStyle={{
                  color: "#0379BA",
                }}>
                {buttonName}
                {headerLabel}
              </NavLink>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );

  headerButtonsPack = (
    <div className="headerButtonsContainer hideButtonsContainer">
      {headerButtons.map((button, index) => {
        return (
          <div key={index} className="navLinkContainer">
            <NavLink
              to={button.pathname}
              exact
              activeStyle={{
                color: "#0379BA",
              }}>
              {translate(`Common:${button.name}`)}
            </NavLink>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      {headerButtonsPack}
      {headerButtonsPackDropdown}
    </>
  );
};

export default HeaderButtons;
