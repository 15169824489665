import React from "react";
import { Header } from "semantic-ui-react";
import ConfirmationModal from "../modals/ConfirmationModal";
import StudentInstructionsModal from "../modals/StudentInstructionsModal";

const InstructionsComponent = (props) => {
  const {
    translate,
    openTeamLeaderConfirmation,
    checkIsLoggedIn,
    handleStudentInstructionsModal,
    openStudentInstructionsModal,
    isTeamLeader,
  } = props;
  let confirmationModal = null;
  let studentInstructionsModal = null;

  const confirmationBody = (
    <>
      <Header as="h3">{translate("StudentCreateTeam:youAreAboutToCreateATeam")}</Header>
      <p className="teamLeadConfirmation">{translate("StudentCreateTeam:wereYouAssignedAsATeamLeader")}</p>
    </>
  );

  if (openTeamLeaderConfirmation) {
    confirmationModal = (
      <ConfirmationModal
        openConfirmModal={openTeamLeaderConfirmation}
        headerContent={translate("StudentCreateTeam:welcomeToProcessimLabs")}
        confirmationBody={confirmationBody}
        confirmButton={translate("Common:yes")}
        cancelButton={translate("Common:no")}
        onConfirm={() => handleStudentInstructionsModal(true)}
        onCancel={() => handleStudentInstructionsModal(false)}
      />
    );
  }

  if (openStudentInstructionsModal) {
    studentInstructionsModal = (
      <StudentInstructionsModal
        translate={translate}
        openStudentInstructionsModal={openStudentInstructionsModal}
        isTeamLeader={isTeamLeader}
        handleStudentInstructionsModal={handleStudentInstructionsModal}
        checkIsLoggedIn={checkIsLoggedIn}
      />
    );
  }

  return (
    <>
      {confirmationModal}
      {studentInstructionsModal}
    </>
  );
};

export default InstructionsComponent;
