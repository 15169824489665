import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";

const ActionButtons = (props) => {
  const translate = props.translate;
  const requestedSimulation = props.simulationDetails.name;
  let component = null;
  let actionButtons = [];
  var actionsDictionary = {};

  const registeredStudentsAction = () => {
    props.history.push({
      pathname: ROUTES.CREAMBOW_REGISTERED_STUDENTS,
      search: requestedSimulation,
    });
  };

  const standingsAction = () => {
    props.history.push({
      pathname: ROUTES.CREAMBOW_STANDINGS,
      search: requestedSimulation,
    });
  };

  actionsDictionary["handleAdvanceModal"] = props.handleAdvanceModal;
  actionsDictionary["registeredStudentsAction"] = registeredStudentsAction;
  actionsDictionary["standingsAction"] = standingsAction;
  actionsDictionary["handleShareModal"] = props.handleShareModal;
  actionsDictionary["backAction"] = () => props.history.push(ROUTES.HOME);

  if (props.detailsLoaded) {
    actionButtons = buildActionButtons(props.simulationDetails);
  }

  if (props.isMobile) {
    component = (
      <Dropdown className="onlyMobile" trigger={<FontAwesomeIcon color="#494848" icon={faBars} size="2x" />}>
        <Dropdown.Menu direction="left">
          {actionButtons.map((button) => {
            const buttonName = translate(button.name).toUpperCase();
            return (
              <Dropdown.Item key={button.key} className="dropDownItem" onClick={actionsDictionary[button.action]}>
                {buttonName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    component = (
      <div id="simulationDetailsActionButtons" className="buttonsContainer">
        {actionButtons.map((button) => {
          const buttonName = translate(button.name).toUpperCase();
          return (
            <Button key={button.key} basic className="actionButton" onClick={actionsDictionary[button.action]}>
              {buttonName}
            </Button>
          );
        })}
      </div>
    );
  }
  return component;
};

const buildActionButtons = (details) => {
  var actionButtons = [
    ...creambowButtons.map((value) => {
      return { ...value };
    }),
  ];

  if (details.status === CONSTANT.FINISHED) {
    const buttonIndex = actionButtons.findIndex((element) => element.key === "advanceSimulation");
    actionButtons[buttonIndex].hide = true;
  } else if (details.status === CONSTANT.AWAITING_INITIALIZATION) {
    const advanceButtonIndex = actionButtons.findIndex((element) => element.key === "advanceSimulation");
    actionButtons[advanceButtonIndex].hide = true;
    const startButtonIndex = actionButtons.findIndex((element) => element.key === "startSimulation");
    actionButtons[startButtonIndex].hide = false;
  }

  return actionButtons.filter((button) => !button.hide);
};

const creambowButtons = [
  {
    key: "advanceSimulation",
    name: "AdvanceSimulationModal:advanceSimulation",
    action: "handleAdvanceModal",
    hide: false,
  },
  {
    key: "startSimulation",
    name: "AdvanceSimulationModal:startSimulation",
    action: "handleAdvanceModal",
    hide: true,
  },
  {
    key: "standings",
    name: "standings",
    action: "standingsAction",
    hide: false,
  },
  {
    key: "registeredStudents",
    name: "registeredStudents",
    action: "registeredStudentsAction",
    hide: false,
  },
  {
    key: "shareSimulation",
    name: "shareSimulation",
    action: "handleShareModal",
    hide: false,
  },
  {
    key: "back",
    name: "Common:back",
    action: "backAction",
    hide: false,
  },
];

export default ActionButtons;
