import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Divider, Form, Modal } from "semantic-ui-react";
import DropDownComponent from "../helpers/DropDownComponent";
import { crisisGameModeOptions, crisisValidateDates } from "../utilities/CrisisSimulationUtilities";
import { switchTo12HourFormat, unifyDateTime } from "../utilities/DateTimeUtilities";
import DateTimeSelector from "../helpers/DateTimeSelector";
import CalendarComponent from "../helpers/CalendarComponent";
import { crisisModifySimulation } from "../../store/CrisisSimulationDetails/actions";
import MessagesComponent from "../helpers/MessagesComponent";
import { generateTranslationKey } from "../utilities/LanguageUtilities";

export class ModifySimulationModal extends Component {
  constructor(props) {
    super(props);

    const { startTime, endTime, gameMode } = props.simulationDetails;

    this.state = {
      formValidated: false,
      openCalendar: false,
      modifySuccesful: false,
      advancedOptions: {
        scenarioId: "",
      },
      dateValidationError: "",
      dateToModify: "startTime",
      form: {
        gameMode: {
          value: gameMode,
          valid: true,
          rules: {},
        },
        startTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(startTime).format("HH"))),
            hourFormat: moment(startTime).format("a"),
            date: startTime,
          },
          valid: true,
          rules: {},
        },
        endTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(endTime).format("HH"))),
            hourFormat: moment(endTime).format("a"),
            date: endTime,
          },
          valid: true,
          rules: {},
        },
      },
    };
  }

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.minHoursBeforeDate) {
      const timeNow = moment();
      const unifiedDateTime = unifyDateTime(value);
      isValid = isValid && unifiedDateTime.diff(timeNow) >= rules.minHoursBeforeDate;
    }
    return isValid;
  };

  setHour = (statePropertyToModify, value) => {
    let updatedForm = { ...this.state.form };
    const segmentedStartTime = updatedForm[statePropertyToModify].value;

    if (isNaN(value)) {
      segmentedStartTime.hourFormat = value;
    } else {
      segmentedStartTime.hour = value;
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  setDate = (date, dateToModify) => {
    const updatedDateValue = { ...this.state.form[dateToModify].value, date: date };
    const updatedDateField = { ...this.state.form[dateToModify], value: updatedDateValue };
    let updatedForm = { ...this.state.form, [dateToModify]: updatedDateField };

    if (dateToModify === "startTime" && date >= moment(updatedForm.endTime.value.date).format("YYYY-MM-DD")) {
      const adjustedEndDateValue = { ...this.state.form.endTime.value, date: moment(date).add(1, "day") };
      const adjustedEndDateField = { ...this.state.form.endTime, value: adjustedEndDateValue };
      updatedForm = { ...updatedForm, endTime: adjustedEndDateField };
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  handleCalendar = (action) => {
    this.setState((state) => ({
      dateToModify: action,
      openCalendar: !state.openCalendar,
    }));
  };

  submitModifySimulationRequest = (event) => {
    event.preventDefault();

    const { professorManagedProgress, id } = this.props.simulationDetails;

    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const { form } = this.state;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    let request = {
      simulationId: id,
      gameMode: form.gameMode.value,
    };

    if (professorManagedProgress === false) {
      const convertedStartTime = unifyDateTime(form.startTime.value);
      const convertedEndTime = unifyDateTime(form.endTime.value);
      const dateValidation = crisisValidateDates(convertedStartTime, convertedEndTime);

      if (dateValidation.invalidEndTime || dateValidation.invalidStartTime) {
        this.setState({
          form: {
            ...form,
            startTime: {
              ...form.startTime,
              valid: !dateValidation.invalidStartTime,
            },
            endTime: {
              ...form.endTime,
              valid: !dateValidation.invalidEndTime,
            },
          },
          formValidated: true,
          dateValidationError: dateValidation.dateValidationError,
        });
        return;
      }

      request = {
        simulationId: id,
        gameMode: form.gameMode.value,
        startTime: convertedStartTime,
        endTime: convertedEndTime,
      };
    }

    this.props.modifySimulation(request);
    this.setState({ modifySuccesful: true });
  };

  renderDates = (t) => {
    const { startTime, endTime } = this.state.form;
    return (
      <div>
        <DateTimeSelector
          label={t("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "startTime" })}
          settingHour={(event, { value }) => this.setHour("startTime", value)}
          dateToSet={"startTime"}
          date={startTime.value.date}
          hour={startTime.value.hour}
          hourFormat={startTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={t("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "endTime" })}
          settingHour={(event, { value }) => this.setHour("endTime", value)}
          dateToSet={"endTime"}
          date={endTime.value.date}
          hour={endTime.value.hour}
          hourFormat={endTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />
      </div>
    );
  };

  renderGameModeDropdown = (t) => {
    const gameModeOptions = crisisGameModeOptions(t);
    return (
      <DropDownComponent
        id="gameMode"
        label={t("CrisisPurchased:GameModeTitle")}
        options={gameModeOptions}
        onSelection={(data) => this.onFormChanged("gameMode", data)}
        value={this.state.form.gameMode.value}
        labelAlignment="left"
      />
    );
  };

  renderCalendar = (t) => {
    const { dateToModify } = this.state;
    const calendarSelectedDay = this.state.form[dateToModify].value.date;

    return (
      <div className="calendarPosition">
        <CalendarComponent
          translate={t}
          handleCalendar={this.handleCalendar}
          dateToModify={this.state.dateToModify}
          onDateClick={(value) => this.setDate(value, this.state.dateToModify)}
          selectedDate={calendarSelectedDay}
          language={this.props.language}
        />
      </div>
    );
  };

  renderMessage = (message, { success } = {}) => {
    return (
      <>
        <Divider hidden />
        <MessagesComponent messageType={success} message={message}></MessagesComponent>
      </>
    );
  };

  onClose = () => {
    const { modifySuccesful } = this.state;
    if (modifySuccesful) this.props.getSimulationDetails(this.props.simulationDetails.id);
    this.props.onClose();
  };

  render() {
    const { openCalendar, dateValidationError, modifySuccesful } = this.state;
    const { simulationDetails } = this.props;
    const { t, modifySimulationStatus } = this.props;
    const formOnSubmit = modifySimulationStatus.loading === false ? this.submitModifySimulationRequest : undefined;

    const apiErrorNamespace = modifySimulationStatus.errorMessage.includes(":") ? "" : "CrisisModifyModal";
    const errors = [
      { ns: "", key: dateValidationError },
      { ns: apiErrorNamespace, key: modifySimulationStatus.errorMessage },
    ];
    const errorToDisplay = errors.find((x) => x.key.length > 0);

    return (
      <Modal open size="small">
        <Modal.Header>{t("CrisisModifyModal:ModifySimulation")}</Modal.Header>
        <Modal.Content>
          {openCalendar === true ? this.renderCalendar(t) : null}

          <Form id="modifySimulationForm" onSubmit={formOnSubmit}>
            {this.renderGameModeDropdown(t)}
            {simulationDetails.professorManagedProgress === false ? this.renderDates(t) : null}
          </Form>
          {typeof errorToDisplay !== "undefined" ? this.renderMessage(t(generateTranslationKey(errorToDisplay))) : null}
          {modifySuccesful === true ? this.renderMessage(t("CrisisModifyModal:Success"), { success: true }) : null}
        </Modal.Content>
        <Modal.Actions>
          {modifySuccesful === false ? (
            <Button
              loading={modifySimulationStatus.loading}
              type="submit"
              form="modifySimulationForm"
              content={t("CrisisModifyModal:accept")}
            />
          ) : null}
          <Button content={t("CrisisModifyModal:close")} onClick={this.onClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  modifySimulationStatus: state.crisisSimulationDetails.modifySimulationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  modifySimulation: (request) => dispatch(crisisModifySimulation(request)),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(["CrisisCommon", "CrisisModifyModal", "CrisisPurchased"])
);

export default HOC(ModifySimulationModal);
