import React from "react";
import { Popup } from "semantic-ui-react";
import { GRAPHS_GROUP } from "../utilities/DashBoardUtilities";

const DataSourceNavigator = (props) => {
  const {
    translate,
    selectedGraphs,
    handleGraphSelector,
    loadingDecisionsData,
    decisionsData,
    decisionsDataError,
  } = props;

  let performanceClass = "";
  let decisionsClass = "";
  const decisionsDataExist =
    loadingDecisionsData === false && (Object.keys(decisionsData).length > 0 || decisionsDataError !== "");

  if (selectedGraphs === GRAPHS_GROUP.PERFORMANCE) {
    performanceClass = "selectedGraph";
    decisionsClass = "";
  } else {
    performanceClass = "";
    decisionsClass = "selectedGraph";
  }

  const selectGraphContainer = (
    <div className="buttonGraphGroup">
      <button
        className={`selectGraphButton buttonLeft ${performanceClass}`}
        id="performance"
        onClick={handleGraphSelector}>
        {translate("performance")}
      </button>
      <Popup
        trigger={
          <button
            className={`selectGraphButton buttonRight ${decisionsClass}`}
            id="decisions"
            disabled={!decisionsDataExist}
            onClick={handleGraphSelector}>
            {translate("decisions")}
          </button>
        }
        content={translate("loadingDecisionsData")}
        on="click"
        open={!decisionsDataExist}
        position="bottom center"
      />
    </div>
  );

  return <>{selectGraphContainer}</>;
};

export default DataSourceNavigator;
