import React from "react";
import { Button } from "semantic-ui-react";

const ActionButtons = (props) => {
  const translate = props.translate;
  let actionButtons = props.actionButtons;

  return (
    <div id="simulationDetailsActionButtons" className="buttonsContainer">
      {actionButtons.map((button) => {
        const buttonName = translate(button.name).toUpperCase();
        return (
          <Button key={button.key} basic className="actionButton" onClick={button.action}>
            {buttonName}
          </Button>
        );
      })}
    </div>
  );
};

export default ActionButtons;
