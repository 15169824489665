import React from "react";
import { Button, Modal, List, Header } from "semantic-ui-react";
import "./modals.css";
const leaderInstructions = [
  "afterDismissingThisPopUpYouWillNeedCreateOrLogin",
  "thenEnterTheNameOfYourChoiseForYourTeam",
  "youWillBePresentedWithAUniqueLink",
  "downloadOurMobileAppAndLogIn",
  "enjoyManagingYourVirtualCompany",
];
const noLeaderInstructions = [
  "waitForYourTeamLeadToShareTheTeamsUniqueLinkWithYou",
  "clickOnTheLinkToSetUpYourAccountOnOurWebsite",
  "youCanNowDownloadTheMobileAppOnYourSmartphone",
];

const StudentInstructionsModal = (props) => {
  const {
    translate,
    openStudentInstructionsModal,
    isTeamLeader,
    handleStudentInstructionsModal,
    checkIsLoggedIn,
  } = props;
  let modalTitle = null;
  let introduction = null;
  let instructionsList = null;
  let continueButton = null;

  if (isTeamLeader) {
    modalTitle = translate("StudentInstructionsModal:teamLeaderInstructions");
    introduction = translate("StudentInstructionsModal:leaderIntroduction");
    instructionsList = leaderInstructions;
    continueButton = <Button content={translate("Common:continue")} onClick={checkIsLoggedIn} />;
  } else {
    modalTitle = translate("StudentInstructionsModal:studentInstructions");
    introduction = translate("StudentInstructionsModal:noLeaderIntroduction");
    instructionsList = noLeaderInstructions;
  }

  const actionButtons = (
    <Modal.Actions>
      {continueButton}
      <Button content={translate("Common:back")} onClick={handleStudentInstructionsModal} />
    </Modal.Actions>
  );

  return (
    <Modal className="instructtionsModal" size="tiny" open={openStudentInstructionsModal}>
      <Modal.Header>{modalTitle}</Modal.Header>
      <Modal.Content>
        <List.Item>
          <Header as="h3">{introduction}</Header>
        </List.Item>
        <List as="ol">
          {instructionsList.map((instruction, index) => {
            return (
              <List.Item key={index} as="li">
                {translate(`StudentInstructionsModal:${instruction}`)}
              </List.Item>
            );
          })}
        </List>
      </Modal.Content>
      {actionButtons}
    </Modal>
  );
};

export default StudentInstructionsModal;
