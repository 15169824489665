import React from "react";
import { Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./helpers.css";

const HelpHoverComponent = (props) => {
  return (
    <div className="popUpContainer">
      <Popup
        size={props.size}
        trigger={<FontAwesomeIcon icon={faQuestionCircle} fixedWidth color="#0379ba" size={props.iconSize} />}>
        <Popup.Content>
          <p style={{ textAlign: "justify" }}>{props.helpMessage}</p>
        </Popup.Content>
      </Popup>
    </div>
  );
};

export default HelpHoverComponent;
