import { setCookie, getCookie, deleteCookie } from "./CookieService";
import { isJsonString } from "../components/utilities/DataValidationUtilities";
const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
let isStorageAvailable = null;

const storageAvailable = () => {
  if (isStorageAvailable === null) {
    try {
      let x = "__storage_test__";
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      isStorageAvailable = true;
    } catch (e) {
      isStorageAvailable = false;
    }
  }

  return isStorageAvailable;
};

export const setStorage = (key, storageData) => {
  if (!storageAvailable() || isFirefox) {
    return setCookie(key, storageData);
  } else {
    return localStorage.setItem(key, JSON.stringify(storageData));
  }
};

export const getStorage = (key) => {
  let data = "";
  if (!storageAvailable() || isFirefox) {
    data = getCookie(key);
    if (data) {
      return data;
    }
  } else {
    data = localStorage.getItem(key);
    if (data) {
      try {
        const jsonVerified = isJsonString(data);
        if (jsonVerified) return JSON.parse(data);
        return false;
      } catch (e) {
        return false;
      }
    }
  }
  return false;
};

export function getStorageOrDefault(key) {
  const storageValue = getStorage(key);
  if (!storageValue) {
    return undefined;
  }
  return storageValue;
}

export const removeStorage = (key) => {
  const isAnExistingKey = getStorage(key);
  if (isAnExistingKey) {
    if (!storageAvailable() || isFirefox) {
      return deleteCookie(key);
    } else {
      return localStorage.removeItem(key);
    }
  }
};

export const mergeDataToStorage = (key, newObj) => {
  let newData;
  const lastObject = getStorage(key);
  if (lastObject) {
    newData = Object.assign(lastObject, newObj);
  } else {
    newData = newObj;
  }
  return setStorage(key, newData);
};
