import * as actionTypes from "./action-types";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { getStorage, setStorage, removeStorage, mergeDataToStorage } from "../../services/StorageService";
import { sendPostRequest } from "../../services/FetchService.js";
import * as API_PATHS from "../../constants/FetchApi.js";
import { sentryReportEvent } from "../../services/SentryReportService";

export const commitAbogadosBuyFormData = (data) => {
  return async function (dispatch) {
    setStorage(LOCALSTORAGE.abogadosQuestionnaire, data);
    dispatch({
      type: actionTypes.COMMIT_PURCHASE_FORM_DATA,
      payload: data,
    });
  };
};

export const abogadosValidateDiscountCode = (discountCode) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE });
    try {
      const discountCodeResponse = await sendPostRequest(API_PATHS.FORIO_VALIDATE_DISCOUNT_CODE, discountCode);
      if (!discountCodeResponse.data?.payload?.validDiscountCode) {
        dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_FAILED, payload: discountCodeResponse.data.message });
        return;
      }
      const payload = {
        discountCode: discountCodeResponse.data.payload.discountCode,
        discountedPrice: discountCodeResponse.data.payload.discountedPrice,
      };
      mergeDataToStorage(LOCALSTORAGE.abogadosQuestionnaire, payload);

      if (discountCodeResponse.data?.payload?.paymentSummaryId) {
        payload.paymentSummaryId = discountCodeResponse.data.payload.paymentSummaryId;
        mergeDataToStorage(LOCALSTORAGE.abogadosQuestionnaire, {
          paymentSummaryId: discountCodeResponse.data.payload.paymentSummaryId,
        });
        dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS, payload: payload });
        return;
      }

      dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_SUCCESS, payload: payload });
    } catch (err) {
      const sentryTag = {
        name: "abogadosDiscountCodeFailed",
        data: discountCode,
      };
      sentryReportEvent(err, sentryTag);
      dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_FAILED, payload: err });
    }
  };
};

export const abogadosResetDiscountCode = () => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_RESET });
  };
};

export const restoreAbogadosPurchaseInformationFromStorage = () => {
  return async function (dispatch) {
    const cachedQuestionnaireInformation = getStorage(LOCALSTORAGE.abogadosQuestionnaire);
    if (cachedQuestionnaireInformation) {
      dispatch({
        type: actionTypes.RESTORE_STORAGE_PURCHASE_INFORMATION,
        payload: cachedQuestionnaireInformation,
      });
    }
    return true;
  };
};

export const updateTransactionAbogadosData = (transactionData) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_TRANSACTION_DATA, payload: transactionData });
  };
};

export const abogadosCreateSimulation = (createSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_START });
    try {
      const response = await sendPostRequest(API_PATHS.FORIO_CREATE_SIMULATION, createSimulationRequest);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: err });
    }
  };
};

export const resetCreateSimulationDefaults = () => {
  return { type: actionTypes.RESET_CREATE_SIMULATION_DEFAULTS };
};

export const resetAbogadosBuySimulationStoreDefaults = () => {
  removeStorage(LOCALSTORAGE.abogadosQuestionnaire);
  return {
    type: actionTypes.RESET_ABOGADOS_BUY_SIMULATION_DEFAULTS,
  };
};
