import React from "react";
import "./modals.css";
import { Button, Modal } from "semantic-ui-react";

const ConfirmationModal = (props) => {
  return (
    <Modal size="tiny" open={props.openConfirmModal}>
      <Modal.Header>{props.headerContent}</Modal.Header>
      <Modal.Content>{props.confirmationBody}</Modal.Content>
      <Modal.Actions>
        <Button content={props.confirmButton} onClick={props.onConfirm} />
        <Button content={props.cancelButton} onClick={props.onCancel} />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
