import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import BackButton from "../helpers/BackButton";
import CatalogueComponent from "./CatalogueComponent";
import { removeStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "./catalogueStyles.css";

class CatalogueScreen extends Component {
  componentDidMount() {
    this.transactionStorages();
  }

  transactionStorages = () => {
    const storagesToRemove = [
      LOCALSTORAGE.medicaQuestionnaire,
      LOCALSTORAGE.simunationQuestionnaire,
      LOCALSTORAGE.simulationCreated,
      LOCALSTORAGE.simulationSimunationCreated,
    ];

    storagesToRemove.forEach((storage) => {
      removeStorage(storage);
    });
  };

  render() {
    const translate = this.props.t;
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent title={translate("catalogue")} subtitle={translate("getCreative")} reducedSubTitle={true} />
        <CatalogueComponent translate={translate} history={this.props.history} />
        <BackButton translate={translate} history={this.props.history} />
      </Container>
    );
  }
}

export default withTranslation("CatalogueScreen", "Common")(withRouter(CatalogueScreen));
