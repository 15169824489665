import * as actions from "./action-types";
import * as profileService from "../../services/ProfileService";

export const requestFeature = (featureData) => {
  return async function (dispatch) {
    dispatch({ type: actions.REQUEST_FEATURE });
    const requestFeatureResponse = profileService.requestFeature(featureData);
    requestFeatureResponse
      .then((response) => {
        if (response.data.outcome) {
          dispatch({
            type: actions.REQUEST_FEATURE_SUCCESSFULL,
            payload: "featureRequestedSuccessfully",
          });
        } else {
          const response = {
            message: "errorRequestingFeature",
          };
          dispatch({
            type: actions.REQUEST_FEATURE_FAILED,
            payload: response.message,
          });
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorRequestingFeature",
          };
          dispatch({
            type: actions.REQUEST_FEATURE_FAILED,
            payload: response.message,
          });
        }
      });
  };
};

export const resetRequestFeatureStore = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_REQUEST_FEATURE_STORE });
  };
};
