import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { CHECK_AUTH, CRISIS_JOIN_SIMULATION, CRISIS_STUDENT_PAYMENT } from "../../constants/Routes";
import { getCurrentSession } from "../../services/AuthService";
import { setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import LoadingComponent from "../helpers/LoadingComponent";

class CrisisJoinSimulationPreamble extends Component {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let simulationId;
    let paymentSummaryId;
    for (let param of query.entries()) {
      if (param[0] === "simulationId") {
        simulationId = param[1];
      }
      if (param[0] === "paymentSummaryId") {
        paymentSummaryId = param[1];
      }
    }

    if (!simulationId) {
      this.props.history.replace(CHECK_AUTH);
      return;
    }

    setStorage(LOCALSTORAGE.crisisJoinSimulationId, simulationId);
    setStorage(LOCALSTORAGE.crisisPaymentSummaryId, paymentSummaryId);
    setStorage(LOCALSTORAGE.activationCode, simulationId);

    const session = await getCurrentSession();
    if (session) {
      if (!paymentSummaryId) {
        this.props.history.push(CRISIS_STUDENT_PAYMENT);
      }
      else {
        this.props.history.push(CRISIS_JOIN_SIMULATION);
      }
    }
  }

  render() {
    const translate = this.props.t;
    return (
      <>
        <LoadingComponent fullSize={true} image={true} message={translate("loadingPage")} />
      </>
    );
  }
}

const HOC = compose(withRouter, withTranslation("AuthComponents", "Common"));

export default HOC(CrisisJoinSimulationPreamble);
