import CONFIG from "../constants/ConfigAuth0";
import { setCookie, getCookie, deleteCookie } from "./CookieService";
import { getStorage, removeStorage } from "./StorageService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import history from "../constants/History";
import * as ROUTES from "../constants/Routes";
import * as CONSTANT from "../constants/UniversalConstants";
import * as COOKIES from "../constants/Cookies";
import auth0 from "auth0-js";
import { sentryReportEvent } from "./SentryReportService";

const authSDK = new auth0.WebAuth(CONFIG);

export const login = (options) => {
  authSDK.authorize(options);
};

export const getCurrentSession = async () => {
  let cookie = getCookie(COOKIES.AUTH_INFORMATION);

  if (!cookie) {
    const options = getAuthOptions();
    login(options);
    return;
  }

  var jwtDecode = require("jwt-decode");
  const decodedToken = jwtDecode(cookie.tokenInfo.idToken);
  const timeNow = Math.floor(new Date().getTime() / 1000);
  const tokenExpirationDate = decodedToken.exp;
  if (timeNow < tokenExpirationDate) {
    return cookie;
  }

  let tryAgain = false;
  let session = null;
  try {
    session = await renewSession();
  } catch (err) {
    if (err.error && err.error === "timeout") {
      tryAgain = true;
    }
  }

  if (tryAgain) {
    try {
      session = await renewSession();
    } catch (err) {
      sentryReportEvent("Failed twice to renew session due to timeout ", "Auth0", [err]);
    }
  }

  if (session === null) {
    const options = getAuthOptions();
    login(options);
    return;
  }
  return session;
};

const getAuthOptions = () => {
  const teamCode = getStorage(LOCALSTORAGE.teamCode);
  const activationCode = getStorage(LOCALSTORAGE.activationCode);
  let options = null;
  if (teamCode || activationCode) {
    options = { role: "Student" };
  }

  return options;
};

export const loginSuccessful = () => {
  return new Promise((resolve, reject) => {
    authSDK.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        let authInformation = extractUserDataAndProfileFromToken(authResult);
        return resolve(authInformation);
      } else if (err) {
        logout();
        return reject(err);
      }
    });
  });
};

export const logout = () => {
  deleteCookie(COOKIES.AUTH_INFORMATION);
  removeStorage(LOCALSTORAGE.wasVideoSeen);
  removeStorage(LOCALSTORAGE.purchasedProduct);
  authSDK.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_AUTH0_RETURN_TO,
  });
};

export const renewSession = () => {
  return new Promise((resolve, reject) => {
    authSDK.checkSession({ timeout: 4000 }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        let authInformation = extractUserDataAndProfileFromToken(authResult);
        return resolve(authInformation);
      } else if (err) {
        return reject(err);
      }
    });
  });
};

const extractUserDataAndProfileFromToken = (authResult) => {
  window.location.hash = "";
  const userProfile = {
    email: authResult.idTokenPayload["https://app.processimlabs.com/email"],
    fullName: `${authResult.idTokenPayload["https://app.processimlabs.com/first_name"]} ${authResult.idTokenPayload["https://app.processimlabs.com/last_name"]}`,
    firstName: authResult.idTokenPayload["https://app.processimlabs.com/first_name"],
    lastName: authResult.idTokenPayload["https://app.processimlabs.com/last_name"],
    institution: authResult.idTokenPayload["https://app.processimlabs.com/institution"],
    role: authResult.idTokenPayload["https://app.processimlabs.com/role"],
    language: authResult.idTokenPayload["https://app.processimlabs.com/language"],
  };
  const userTokenDataAndProfile = {
    tokenInfo: authResult,
    userProfile: userProfile,
  };
  setCookie(COOKIES.AUTH_INFORMATION, userTokenDataAndProfile);
  return userTokenDataAndProfile;
};

export const redirectionHandler = (user) => {
  const crisisJoinSimulationCode = getStorage(LOCALSTORAGE.crisisJoinSimulationId);
  const crisisPaymentSummaryId = getStorage(LOCALSTORAGE.crisisPaymentSummaryId);
  if (!crisisPaymentSummaryId && crisisJoinSimulationCode) {
    history.replace(ROUTES.CRISIS_STUDENT_PAYMENT);
    return;
  }
  if (crisisJoinSimulationCode) {
    history.replace(ROUTES.CRISIS_JOIN_SIMULATION);
    return;
  }

  const supplyChainJoinSimulationCode = getStorage(LOCALSTORAGE.supplyChainJoinSimulationId);
  const supplyChainPaymentSummaryId = getStorage(LOCALSTORAGE.supplyChainPaymentSummaryId);

  if (!supplyChainPaymentSummaryId && supplyChainJoinSimulationCode) {
    history.replace(ROUTES.SUPPLY_CHAIN_STUDENT_PAYMENT);
    return;
  }

  if (supplyChainJoinSimulationCode) {
    history.replace(ROUTES.SUPPLY_CHAIN_JOIN_SIMULATION);
    return;
  }

  if (user.role === CONSTANT.ROLES.PROFESSOR) {
    history.replace(ROUTES.HOME);
  } else {
    studentRedirection();
  }
};

const studentRedirection = () => {
  const teamCode = getStorage(LOCALSTORAGE.teamCode);
  const activationCode = getStorage(LOCALSTORAGE.activationCode);
  const securityCode = getStorage(LOCALSTORAGE.securityCode);
  if ((teamCode || activationCode) && securityCode) {
    if (securityCode.charAt(8) >= securityCode.charAt(16)) {
      history.replace(ROUTES.PAYMENT_MEDICA_STUDENT);
    } else {
      if (activationCode) {
        history.replace(ROUTES.STUDENT_CREATE_TEAM);
      } else {
        history.replace(ROUTES.STUDENT_JOIN_TEAM);
      }
    }
  } else {
    history.replace(ROUTES.STUDENTS_LOGIN);
  }
};
