export const startSimulationKey = "startSimulation";
export const advanceSimulationKey = "advanceSimulation";
export const finishSimulationKey = "finishSimulation";
export const modifyKey = "modify";
export const standingsKey = "standings";
export const pushNotificationKey = "pushNotification";
export const registeredStudentsKey = "registeredStudents";
export const shareSimulationKey = "shareSimulation";
export const dashboardsKey = "dashboards";
export const documentsKey = "documents";
export const runAgainKey = "runAgain";
export const decisionsHistoryKey = "decisionsHistory";
export const backKey = "back";
