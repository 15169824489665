export const COMMIT_PURCHASE_FORM_DATA = "CREAMBOW_BUY_SIMULATION/COMMIT_PURCHASE_FORM_DATA";
export const VALIDATE_DISCOUNT_CODE = "CREAMBOW_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_FAILED = "CREAMBOW_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_FAILED";
export const VALIDATE_DISCOUNT_CODE_SUCCESS = "CREAMBOW_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS = "CREAMBOW_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_RESET = "CREAMBOW_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_RESET";
export const RESTORE_STORAGE_PURCHASE_INFORMATION = "CREAMBOW_BUY_SIMULATION/RESTORE_STORAGE_PURCHASE_INFORMATION";
export const UPDATE_TRANSACTION_DATA = "CREAMBOW_BUY_SIMULATION/UPDATE_TRANSACTION_DATA";
export const CREATE_SIMULATION_REQUEST_START = "CREAMBOW_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_START";
export const CREATE_SIMULATION_REQUEST_SUCCESS = "CREAMBOW_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_SUCCESS";
export const CREATE_SIMULATION_REQUEST_FAIL = "CREAMBOW_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_FAIL";
export const RESET_CREATE_SIMULATION_DEFAULTS = "CREAMBOW_BUY_SIMULATION/RESET_CREATE_SIMULATION_DEFAULTS";
export const RESET_CREAMBOW_BUY_SIMULATION_DEFAULTS = "CREAMBOW_BUY_SIMULATION/RESET_CREAMBOW_BUY_SIMULATION_DEFAULTS";
