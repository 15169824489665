import * as actions from "./action-types";
import * as simulationService from "../../services/SimulationsService";

export const getSimulations = (language, translate) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_SIMULATIONS });

    const getMedicaSimulationTask = simulationService.getMedicaList();
    const getSimunationSimulationsTask = simulationService.getSimunationList();
    const getCrisisSimulationsTask = simulationService.getCrisisList();
    const getSupplyChainList = simulationService.getSupplyChainList();
    const getForioList = simulationService.getForioList();

    const [medicaSettledTask, simunationSettledTask, crisisSettledTask, supplyChainSettledTask, forioSettledTask] =
      await Promise.allSettled([
        getMedicaSimulationTask,
        getSimunationSimulationsTask,
        getCrisisSimulationsTask,
        getSupplyChainList,
        getForioList,
      ]);

    if (
      (medicaSettledTask.status === "rejected" || medicaSettledTask.value?.status !== 200) &&
      (simunationSettledTask.status === "rejected" || simunationSettledTask.value?.status !== 200) &&
      (crisisSettledTask.status === "rejected" || crisisSettledTask.value?.status !== 200) &&
      (supplyChainSettledTask.status === "rejected" || supplyChainSettledTask.value?.status !== 200) &&
      (forioSettledTask.status === "rejected" || forioSettledTask.value?.status !== 200)
    ) {
      dispatch({
        type: actions.GET_SIMULATIONS_FAILED,
        payload: {
          message: "errorFetchingSimulations",
        },
      });
      return;
    }

    const medicaResponse = medicaSettledTask.status === "fulfilled" ? medicaSettledTask.value.data : { outcome: false };
    const simunationResponse =
      simunationSettledTask.status === "fulfilled" ? simunationSettledTask.value.data : { outcome: false };
    const crisisResponse = crisisSettledTask.status === "fulfilled" ? crisisSettledTask.value.data : { outcome: false };
    const supplyChainResponse =
      supplyChainSettledTask.status === "fulfilled" ? supplyChainSettledTask.value.data : { outcome: false };
    const forioResponse = forioSettledTask.status === "fulfilled" ? forioSettledTask.value.data : { outcome: false };

    let response = simulationService.getUserSimulations(
      medicaResponse,
      simunationResponse,
      crisisResponse,
      supplyChainResponse,
      forioResponse,
      language,
      translate
    );

    if (response.payload.data.length === 0) {
      dispatch({
        type: actions.GET_SIMULATIONS_FAILED,
        payload: {
          message: "noSimulationList",
          showVideoToBuy: true,
        },
      });
      return;
    }
    dispatch({
      type: actions.GET_SIMULATIONS_SUCCESSFUL,
      payload: response.payload,
    });
  };
};
