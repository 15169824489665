import { setStorage, removeStorage, mergeDataToStorage } from "./StorageService";
import { sendGetRequest } from "../services/FetchService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import * as FETCHAPI from "../constants/FetchApi";

export const prepareSimulationData = (simunationQuestionnaire) => {
  const { simulationName, numberOfStudents, paymentId, advanceSimunation, discountCode } = simunationQuestionnaire;
  const postData = {
    Name: simulationName,
    NumberOfStudents: numberOfStudents,
    ProfessorManagedProgress: advanceSimunation,
    transactionId: paymentId,
    discountCode: discountCode?.discountCode || "",
  };
  return postData;
};

export const handleStorage = (activationCode, purchasedData) => {
  if (!purchasedData) {
    const data = {
      payload: activationCode,
    };
    removeStorage(LOCALSTORAGE.simunationQuestionnaire);
    setStorage(LOCALSTORAGE.simulationSimunationCreated, data);
  } else {
    mergeDataToStorage(LOCALSTORAGE.simunationQuestionnaire, purchasedData);
  }
};

export const validateSimunationDiscountCode = (discountCode) => {
  return sendGetRequest(FETCHAPI.GET_SIMUNATION_VALIDATE_DISCOUNT_CODE, discountCode);
};

export const prepareCustomResponse = (response, action, discountCode) => {
  let customResponse = null;
  if (response.data.outcome === true && response.data.payload?.validDiscountCode === true) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: {
        discountedPrice: response.data.payload.discountedPrice,
        discountCode: discountCode,
      },
    };
  } else {
    customResponse = {
      type: `${action}_FAILED`,
      payload: {
        discountCodeMessage: response.data.message,
      },
    };
  }
  return customResponse;
};
