import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Container } from "semantic-ui-react";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import HeaderComponent from "../header/HeaderComponent";
import PushNotificationsComponent from "./PushNotificationsComponent";
import NavigationButton from "../helpers/NavigationButton";
import MessagesComponent from "../helpers/MessagesComponent";
import SuccessModal from "../modals/SuccessModal";
import medicaLogo from "../../assets/medica-logo-transparent.png";
import { getTeams, medicaGetSuggestions } from "../../store/Medica/actions";
import { sendPushNotification, resetPushNotificationState } from "../../store/Medica/actions";
import { getUrlSearchParam } from "../../services/NavigationService";
import SuggestionAccordion from "./SuggestionAccordion";

const sendToAllTeams = "Send Push Notification To All Teams Selection";

const initialFormState = {
  pushNotificationForm: {
    team: {
      value: null,
      valid: true,
      rules: { minLength: 1 },
    },
    title: {
      value: "",
      valid: true,
      rules: {
        minLength: 1,
        maxLength: 80,
      },
    },
    message: {
      value: "",
      valid: true,
      rules: {
        minLength: 1,
        maxLength: 400,
      },
    },
  },
};

class PushNotificationsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectError: false,
      ...initialFormState,
      errorMessage: "",
    };
  }

  handleFormChange = (id, value) => {
    const oldForm = this.state.pushNotificationForm;
    const { rules } = this.state.pushNotificationForm[id];
    const valid = this.validateFormValue(value, rules);
    this.setState({
      pushMessage: null,
      selectError: false,
      pushNotificationForm: {
        ...oldForm,
        [id]: {
          value,
          valid,
          rules,
        },
      },
    });
  };

  selectSuggestion = (team, title, message) => {
    const teams = this.getTeamOptions();
    const selectedTeam = teams.find((x) => x.text === team);
    this.setState((state) => ({
      pushNotificationForm: {
        title: {
          ...state.pushNotificationForm.title,
          value: title,
        },
        message: {
          ...state.pushNotificationForm.message,
          value: message,
        },
        team: {
          ...state.pushNotificationForm.team,
          value: selectedTeam.value,
        },
      },
    }));
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.maxLength) {
      isValid = isValid && value.length <= rules.maxLength;
    }

    return isValid;
  };

  validateAllFormFields = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  async componentDidMount() {
    const simulationName = getUrlSearchParam();
    const { language } = this.props.i18n;
    await this.props.getTeams(simulationName);
    await this.props.getSuggestions(simulationName, language);
  }

  submitPushNotification = () => {
    const translate = this.props.t;
    const form = { ...this.state.pushNotificationForm };
    const validatedForm = this.validateAllFormFields(form);
    const formValid = Object.keys(validatedForm).every((x) => validatedForm[x].valid);
    if (!formValid) {
      this.setState({
        pushNotificationForm: validatedForm,
        pushMessage: translate("verifyFieldsCorrectlyFilled"),
        selectError: true,
        sendingPush: false,
        messageType: false,
      });
    }

    const simulationName = getUrlSearchParam();
    this.setState({ sendingPush: true });
    const pushData = {
      SimulationName: simulationName,
      sendAllTeams: form.team.value === sendToAllTeams,
      SimulationId: form.team.value === sendToAllTeams ? "" : form.team.value,
      Title: form.title.value,
      Message: form.message.value,
    };
    this.props.sendPushNotification(pushData);
  };

  handleSuccessModal = () => {
    this.props.resetPushNotificationState();
    this.setState({
      ...initialFormState,
    });
  };

  getTeamOptions = () => {
    const { t: translate } = this.props;
    const { teams } = this.props.medica;
    const allTeamsOption = { key: "1", value: sendToAllTeams, text: translate("allTeams") };
    if (!teams.outcome) return [];
    const teamList = teams.payload;
    const teamOptions = teamList.map((team) => ({
      key: team.id,
      text: team.team,
      value: team.id,
    }));

    return [allTeamsOption, ...teamOptions];
  };

  render() {
    const { t: translate, suggestionsStatus } = this.props;
    const { loading, error, teams } = this.props.medica;
    const { loadingPushNotification, pushNotificationSuccessfully, pushNotificationError } =
      this.props.pushNotification;

    const teamOptions = this.getTeamOptions();
    let loader = null;
    let navigationButton = null;
    let messagesComponent = null;
    if (loadingPushNotification || loading) {
      loader = (
        <Grid.Column width={16}>
          <div className="loader" />
        </Grid.Column>
      );
    } else {
      if (teams.payload && error === "") {
        navigationButton = (
          <NavigationButton
            action={this.submitPushNotification}
            disabled={error !== ""}
            label={translate("sendNotification")}
          />
        );
      }
    }

    if (error || pushNotificationError) {
      let messageToShow = "";
      let messageSuccessful = false;
      if (error !== "") {
        messageToShow = error;
      } else if (pushNotificationError !== "") {
        messageToShow = pushNotificationError;
      }
      if (messageToShow !== "") {
        messagesComponent = <MessagesComponent messageType={messageSuccessful} message={translate(messageToShow)} />;
      }
    }

    const successModal = (
      <SuccessModal
        openSuccessModal={pushNotificationSuccessfully}
        handleSuccessModal={this.handleSuccessModal}
        translate={translate}
        message={translate("pushNotificationSuccess")}
      />
    );

    const suggestionsAccordion = suggestionsStatus.outcome ? (
      <div>
        <SuggestionAccordion
          selectSuggestion={this.selectSuggestion}
          suggestions={suggestionsStatus.suggestions}
          src={medicaLogo}
        />
      </div>
    ) : null;

    return (
      <Container>
        {successModal}
        <HeaderComponent translate={translate} showBackButton={true} />
        <Grid className="customGrid">
          <Grid.Row stretched>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <LeftTitleComponent
                title={translate("Common:pushNotification")}
                helpMessage={translate("pushNotificationHelpMessage")}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" computer={8} tablet={8} mobile={16}>
              <LeftTitleComponent
                title={"Student Suggestions"}
                helpMessage={translate("suggestionsSubTitle2")}
                hasHelp
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="top">
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <PushNotificationsComponent
                ref="pushNotification"
                translate={translate}
                teamOptions={error === "" ? teamOptions : []}
                handleChange={this.handleFormChange}
                selectError={this.state.selectError}
                refreshForm={pushNotificationSuccessfully}
                formState={this.state.pushNotificationForm}
                resetPushNotificationState={this.pushNotificationWasDelivered}
              />
              {navigationButton}
            </Grid.Column>
            <Grid.Column
              verticalAlign="top"
              computer={8}
              tablet={8}
              mobile={16}
              className="suggestionsColumn__PushNotification">
              <>
                <p>{translate("suggestionsSubTitle1")}</p>
                {suggestionsAccordion}
              </>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            {messagesComponent}
            {loader}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTeams: (simulationName) => dispatch(getTeams(simulationName)),
  sendPushNotification: (pushData) => dispatch(sendPushNotification(pushData)),
  resetPushNotificationState: () => dispatch(resetPushNotificationState()),
  getSuggestions: (simulationName, language) => dispatch(medicaGetSuggestions(simulationName, language)),
});

const mapStateToProps = (state) => {
  return {
    medica: {
      teams: state.medica.simulationTeams,
      error: state.medica.getTeamsError,
      loading: state.medica.loadingTeams,
    },
    pushNotification: {
      pushNotificationSuccessfully: state.medica.pushNotificationSuccessfully,
      pushNotificationError: state.medica.pushNotificationError,
      loadingPushNotification: state.medica.loadingPushNotification,
    },
    suggestionsStatus: state.medica.suggestionsStatus,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("PushNotificationsScreen", "Common")(withRouter(PushNotificationsScreen))
);
