import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Container, Form, Grid, Image } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import * as CONSTANT from "../../constants/UniversalConstants";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import NextRunFormComponent from "./NextRunFormComponent";
import moment from "moment";
import * as SUPPLY_CHAIN_CONSTANTS from "../../constants/SupplyChainConstants";
import { compose } from "redux";
import { connect } from "react-redux";
import { getUrlSearchParam } from "../../services/NavigationService";
import {
  supplyChainCreateNextRun,
  supplyChainGetSimulationDetails,
  resetCreateNextRunState,
} from "../../store/SupplyChainSimulationDetails/actions";
import { supplyChainGetProfessorScenarios } from "../../store/SupplyChainSimulationDetails/actions";
import MessagesComponent from "../helpers/MessagesComponent";
import BackButton from "../helpers/BackButton";
import { withRouter } from "react-router-dom";
import supplyChainLogo from "../../assets/supplychain-logo.png";
import gearIcon from "../../assets/gear-icon.svg";
import RedirectModal from "../modals/RedirectModal";
import * as ROUTES from "../../constants/Routes";
import AdvancedOptions from "../supplyChainAdvancedOptions/AdvancedOptions";

const getSimulationAffix = (iterationNumber) => {
  const numberString = iterationNumber.toString();
  if (typeof numberString !== "string") {
    return;
  }
  if (numberString.endsWith("1") === true && numberString.endsWith("11") === false) {
    return "st";
  }
  if (numberString.endsWith("2") === true && numberString.endsWith("12") === false) {
    return "nd";
  }
  if (numberString.endsWith("3") === true && numberString.endsWith("13") === false) {
    return "rd";
  }
  return "th";
};

const convertHoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;

class supplyChainRunAgain extends Component {
  constructor(props) {
    super(props);

    const startDate = moment().add(1, "hour");
    const endDate = startDate.clone().add(1, "day");

    this.state = {
      formValidated: true,
      dateValidationError: "",
      openAdvancedOptions: false,
      advancedOptions: {
        scenarioId: "",
      },
      form: {
        gameMode: {
          value: SUPPLY_CHAIN_CONSTANTS.OneStationUnknownPeers,
          valid: true,
          rules: {},
        },
        professorAdvance: {
          value: true,
          valid: true,
          rules: {},
        },
        startTime: {
          value: {
            hour: this.switchTo12HourFormat(Number(moment(startDate).format("HH"))),
            hourFormat: moment(startDate).format("a"),
            date: startDate,
          },
          valid: true,
          rules: {},
        },
        endTime: {
          value: {
            hour: this.switchTo12HourFormat(Number(moment(endDate).format("HH"))),
            hourFormat: moment(endDate).format("a"),
            date: endDate,
          },
          valid: true,
          rules: {},
        },
        timeBetweenAdvances: {
          value: 1,
          valid: true,
          rules: {},
        },
        teamsStayTheSame: {
          value: true,
          valid: true,
          rules: {},
        },
      },
    };
  }

  async componentDidMount() {
    const storedSimulationDetailsId = this.props.simulationDetails.id;
    const requestedSimulationId = getUrlSearchParam();
    const { getSimulationDetails } = this.props;
    this.props.resetCreateNextRunState();
    if (storedSimulationDetailsId !== requestedSimulationId) {
      await getSimulationDetails(requestedSimulationId);
    }
    this.props.getProfessorScenarios();
  }

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  setDate = (date, dateToModify) => {
    let updatedForm = { ...this.state.form };

    const segmentedStartTime = updatedForm.startTime.value;
    const segmentedEndTime = updatedForm.endTime.value;

    if (dateToModify === "startDate" && date >= moment(segmentedEndTime.date).format("YYYY-MM-DD")) {
      segmentedStartTime.date = date;
      segmentedEndTime.date = moment(date).add(1, "day");
    } else {
      if (dateToModify === "startDate") {
        segmentedStartTime.date = date;
      } else {
        segmentedEndTime.date = date;
      }
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  setHour = (statePropertyToModify, value) => {
    let updatedForm = { ...this.state.form };
    const segmentedStartTime = updatedForm[statePropertyToModify].value;

    if (isNaN(value)) {
      segmentedStartTime.hourFormat = value;
    } else {
      segmentedStartTime.hour = value;
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: {} });
  };

  switchTo24HourFormat = (hour, format) => {
    let formatedHour = 0;
    if (format === "am" && hour === 12) {
      formatedHour = hour - 12;
    } else if (format === "pm" && hour < 12) {
      formatedHour = hour + 12;
    } else {
      formatedHour = hour;
    }
    return formatedHour;
  };

  switchTo12HourFormat = (hour) => {
    let formatedHour = null;
    if (hour > 12) {
      formatedHour = hour - 12;
    } else {
      if (hour === 0) {
        formatedHour = 12;
      } else {
        formatedHour = hour;
      }
    }
    return formatedHour;
  };

  getTime = (segmentedTime) => {
    const formattedHour = this.switchTo24HourFormat(segmentedTime.hour, segmentedTime.hourFormat);
    return this.formatDateTime(segmentedTime.date, formattedHour);
  };

  formatDateTime = (date, time) => {
    return moment(`${moment(date).format("YYYY-MM-DD")} ${time}`, "YYYY-MM-DD H");
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.minHoursBeforeDate) {
      const timeNow = moment();
      const convertedDate = this.getTime(value);
      isValid = isValid && convertedDate.diff(timeNow) >= rules.minHoursBeforeDate;
    }
    return isValid;
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  getNextRunSimulationName() {
    const currentSimulationName = this.props.simulationDetails.name;
    const currentIteration = this.props.simulationDetails.iterationNumber;
    const baseName = currentIteration > 1 ? currentSimulationName.slice(0, -6) : currentSimulationName;
    const nextIteration = currentIteration + 1;
    const nextIterationAffix = getSimulationAffix(nextIteration);

    return `${baseName} (${nextIteration}${nextIterationAffix})`;
  }

  submitCreateSimulationRequest = (event) => {
    event.preventDefault();
    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const form = this.state.form;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    const gameMode = form.gameMode.value;
    const professorAdvance = form.professorAdvance.value;
    const simulationId = this.props.simulationDetails.id;
    const teamsStayTheSame = form.teamsStayTheSame.value;

    if (professorAdvance) {
      const request = {
        simulationId: simulationId,
        gameMode: gameMode,
        ProfessorManagedProgress: professorAdvance,
        teamsStayTheSame: teamsStayTheSame,
        scenarioId: this.state.advancedOptions.scenarioId,
      };

      this.props.createNextRun(request);
      return;
    }

    const endTime = form.endTime;
    const convertedStartTime = this.getTime(form.startTime.value);
    const convertedEndTime = this.getTime(endTime.value);

    if (convertedStartTime.diff(moment()) < SUPPLY_CHAIN_CONSTANTS.minTimeBeforeSimulationStartsInMiliseconds) {
      form.startTime.valid = false;
      this.setState({
        form: form,
        formValidated: true,
        dateValidationError: { message: "SupplyChainPurchased:SimulationStartInTheFuture" },
      });
      return;
    }

    const pace = this.state.form.timeBetweenAdvances.value;
    const { minimumNumberOfHoursToRunByPace, maximumNumberOfHoursToRunByPace } = SUPPLY_CHAIN_CONSTANTS;
    const minimumHoursToRun =
      typeof minimumNumberOfHoursToRunByPace[pace] === "number" ? minimumNumberOfHoursToRunByPace[pace] : 50;
    const maximumHoursToRun =
      typeof maximumNumberOfHoursToRunByPace[pace] === "number" ? maximumNumberOfHoursToRunByPace[pace] : 122;

    if (convertedEndTime.diff(convertedStartTime) < convertHoursToMilliseconds(minimumHoursToRun)) {
      endTime.valid = false;
      this.setState({
        form: form,
        formValidated: true,
        dateValidationError: { message: "SupplyChainPurchased:SimulationMinDurationError", hours: minimumHoursToRun },
      });
      return;
    }

    if (convertedEndTime.diff(convertedStartTime) > convertHoursToMilliseconds(maximumHoursToRun)) {
      endTime.valid = false;
      this.setState({
        form: form,
        formValidated: true,
        dateValidationError: { message: "SupplyChainPurchased:SimulationMaxDurationError", hours: maximumHoursToRun },
      });
      return;
    }

    const request = {
      simulationId: simulationId,
      gameMode: gameMode,
      ProfessorManagedProgress: professorAdvance,
      startTime: convertedStartTime,
      endTime: convertedEndTime,
      pace: pace,
      teamsStayTheSame: teamsStayTheSame,
      scenarioId: this.state.advancedOptions.scenarioId,
    };

    this.props.createNextRun(request);
  };

  handleAdvancedOptionsComponent = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  onAdvancedOptionsChanged = (id, value) => {
    const { advancedOptions } = this.state;
    this.setState({
      advancedOptions: {
        ...advancedOptions,
        [id]: value,
      },
    });
  };

  render() {
    const { t: translate } = this.props;
    const {
      loading: detailsLoading,
      detailsValid,
      errorMessage: detailErrorMessage,
    } = this.props.simulationDetailsRequestStatus;
    const {
      loading: createNextRunLoading,
      errorMessage: createNextRunErrorMessage,
      success: createNextRunSuccess,
    } = this.props.createNextRunStatus;

    let continueButton = (
      <Button basic type="submit" form="createSimulationForm">
        {translate("Common:continue")}
      </Button>
    );

    let formBody = null;

    if (detailsLoading === true) {
      formBody = <div className="loader" />;
    }
    if (createNextRunLoading === true) {
      continueButton = <div className="loader" />;
    }

    if (detailsValid === true) {
      const nextRunSimulationName = this.getNextRunSimulationName();
      formBody = (
        <div>
          <Form id="createSimulationForm" onSubmit={this.submitCreateSimulationRequest}>
            <NextRunFormComponent
              formData={this.state.form}
              formChanged={this.onFormChanged.bind(this)}
              simulationName={nextRunSimulationName}
              setDate={this.setDate}
              setHour={this.setHour}
            />
          </Form>
          <Button onClick={this.handleAdvancedOptionsComponent} id="whiteAndBlueButton">
            <Image className="ui top aligned tiny image" src={gearIcon} id="imageButton" />
            {translate("SupplyChainPurchased:AdvancedOptions")}
          </Button>
        </div>
      );
    }

    let messagesComponent = null;
    let translatedErrorMessage = "";
    const { dateValidationError } = this.state;
    if (typeof dateValidationError.message === "string") {
      translatedErrorMessage = translate(dateValidationError.message, { hours: dateValidationError.hours });
    }
    if (createNextRunErrorMessage !== "") {
      translatedErrorMessage = translate(createNextRunErrorMessage);
    }
    if (detailErrorMessage !== "") {
      translatedErrorMessage = translate(detailErrorMessage);
    }
    if (translatedErrorMessage !== "") {
      messagesComponent = (
        <Grid.Row>
          <Grid.Column width={16}>
            <MessagesComponent message={translatedErrorMessage} />
          </Grid.Column>
        </Grid.Row>
      );
    }

    const { professorScenariosStatus } = this.props;
    const { advancedOptions, openAdvancedOptions } = this.state;
    const advancedOptionsComponent = (
      <>
        <AdvancedOptions
          scenarios={professorScenariosStatus.scenarios}
          loading={professorScenariosStatus.loading}
          errorMessage={professorScenariosStatus.errorMessage}
          advancedOptions={advancedOptions}
          advancedOptionsChanged={this.onAdvancedOptionsChanged}
        />
        <Grid.Column centered>
          <div className="bottomArea">{continueButton}</div>
        </Grid.Column>
      </>
    );

    return (
      <Container>
        <HeaderComponent translate={translate} />
        <RedirectModal
          open={createNextRunSuccess}
          translate={translate}
          history={this.props.history}
          title={translate("SupplyChainRunAgain:Success")}
          message={translate("SupplyChainRunAgain:createNextRunSuccess")}
          route={ROUTES.HOME}
        />
        <Grid className="">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.SUPPLY_CHAIN_NAME.toLocaleUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.SUPPLY_CHAIN_NAME.toLocaleUpperCase()} />
          </Grid.Column>
          <Grid.Row className="supply-chain-run-again--form-row">
            <Grid.Column className="formColumn runAgainColumn" mobile={16} computer={14} tablet={13}>
              {formBody}
            </Grid.Column>
            <Grid.Column className="supply-chain-run-again--logo-column" only="tablet computer" computer={2} tablet={3}>
              <div className="supplyChainSimulationIcon">
                <Image className="supplyChainDetailLogo" src={supplyChainLogo} size="small" />
              </div>
            </Grid.Column>
          </Grid.Row>
          {messagesComponent}
          <Grid.Row>
            <Grid.Column centered>
              <div className="bottomArea">{continueButton}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {openAdvancedOptions ? advancedOptionsComponent : null}

        <Grid>
          <Grid.Column centered width={16}>
            <BackButton translate={translate} history={this.props.history} />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  professorScenariosStatus: state.supplyChainSimulationDetails.professorScenarios,
  simulationDetailsRequestStatus: state.supplyChainSimulationDetails.simulationDetailsRequestStatus,
  simulationDetails: state.supplyChainSimulationDetails.simulationDetails,
  createNextRunStatus: state.supplyChainSimulationDetails.createNextRunStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getProfessorScenarios: () => dispatch(supplyChainGetProfessorScenarios()),
  getSimulationDetails: (id) => dispatch(supplyChainGetSimulationDetails(id)),
  createNextRun: (request) => dispatch(supplyChainCreateNextRun(request)),
  resetCreateNextRunState: () => dispatch(resetCreateNextRunState()),
});

const HOCs = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(["SupplyChainRunAgain", "common"]),
  withRouter
);

export default HOCs(supplyChainRunAgain);
