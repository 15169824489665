import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import LoadingComponent from "../helpers/LoadingComponent";
import InputComponent from "../helpers/InputComponent";
import NavigationButton from "../helpers/NavigationButton";
import ErrorModal from "../modals/ErrorModal";
import ShareSimulationModal from "../modals/ShareModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import { getStorage, setStorage } from "../../services/StorageService";
import { getLanguagePrefix } from "../utilities/LanguageUtilities";
import { checkNameIsValid } from "../utilities/DataValidationUtilities";

class StudentCreateTeamComponent extends Component {
  constructor(props) {
    super(props);
    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }

    this.state = {
      teamName: "",
      teamNameRequiredError: false,
      openConfirmModal: false,
      paymentIntentId: paymentIntentId,
    };
  }

  setTeamName = (id, data) => {
    const isValidName = checkNameIsValid(data);
    if (isValidName) {
      this.setState({
        [id]: data,
        teamNameRequiredError: false,
      });
    } else {
      this.setState({
        teamNameRequiredError: true,
        teamNameRequiredErrorText: "invalidTeamNameCharacterError",
      });
    }
  };

  handleSubmit = () => {
    if (this.state.teamName === "") {
      this.setState({
        teamNameRequiredError: true,
        teamNameRequiredErrorText: "Payment:required",
      });
    } else {
      this.handleConfirmModal();
    }
  };

  handleConfirmModal = () => {
    this.setState({
      openConfirmModal: !this.state.openConfirmModal,
    });
  };

  onConfirm = () => {
    const { language, createTeam } = this.props;
    const languagePrefix = getLanguagePrefix(language);
    const activationCode = getStorage(LOCALSTORAGE.activationCode);
    const { discountCode } = getStorage(LOCALSTORAGE.medicaQuestionnaire).discountCode || "";
    let postData = {
      teamName: this.state.teamName,
      language: "en",
      activationCode: activationCode,
      timeDifference: "-5",
      sendTeamCreationEmail: true,
      UserLanguage: languagePrefix,
      discountCode: discountCode,
    };
    createTeam(postData);
    this.handleConfirmModal();
  };

  handleErrorModal = () => {
    const { removeOldStoredData, history } = this.props;
    removeOldStoredData();
    history.push(ROUTES.STUDENTS_LOGIN);
  };

  closeErrorModal = () => {
    const { resetCreateTeamStore } = this.props;
    resetCreateTeamStore();
    this.setState({
      teamName: "",
    });
  };

  render() {
    const translate = this.props.translate;
    const { teamNameRequiredErrorText } = this.state;
    const onlyOneTeamError = "You can only create one team.";
    const teamAlreadyExistError = "Team already exists.";
    let statusComponent = null;
    let confirmationModal = null;
    let errorModal = null;

    const confirmationBody = (
      <p>
        {translate("sureYouWantCreateTeam")} <strong className="teamName">{this.state.teamName}</strong>?
      </p>
    );

    if (this.state.openConfirmModal) {
      confirmationModal = (
        <ConfirmationModal
          openConfirmModal={this.state.openConfirmModal}
          onConfirm={this.onConfirm}
          onCancel={this.handleConfirmModal}
          confirmationBody={confirmationBody}
          confirmButton={translate("Common:yes")}
          cancelButton={translate("Common:cancel")}
          headerContent={translate("Common:confirmation")}
        />
      );
    }

    if (this.props.shareLink !== "") {
      this.props.removeOldStoredData();
      setStorage(LOCALSTORAGE.purchasedProduct, CONSTANT.PRODUCTS.MEDICA);
      return (
        <ShareSimulationModal
          open={this.props.shareLink !== ""}
          shareLink={this.props.shareLink}
          titleModal={translate("createTeamSuccess")}
          titleSecondaryButton={translate("play")}
          handleSecondaryButton={() => this.props.history.push(ROUTES.DOWNLOAD_APP)}
          messageType={true}
          translate={translate}
          updateTransactionData={this.props.updateTransactionData}
          isStudent={true}
        />
      );
    }

    if (this.props.isLoading) {
      statusComponent = <LoadingComponent message={translate("creatingTeam")} />;
    } else {
      if (this.props.error !== "") {
        if (this.props.error === onlyOneTeamError) {
          errorModal = (
            <ErrorModal
              title=" "
              translate={translate}
              openErrorModal={true}
              message={translate(`StudentCreateTeam:${this.props.error}`)}
              handleErrorModal={this.handleErrorModal}
            />
          );
        } else if (this.props.error === teamAlreadyExistError) {
          errorModal = (
            <ErrorModal
              title=" "
              translate={translate}
              openErrorModal={true}
              message={translate(`StudentCreateTeam:${this.props.error}`)}
              handleErrorModal={this.closeErrorModal}
            />
          );
        } else {
          errorModal = (
            <ErrorModal
              title=" "
              translate={translate}
              openErrorModal={true}
              message={translate(this.props.error)}
              handleRetry={this.handleConfirmModal}
              retryText={translate("StudentCreateTeam:retry")}
              hasRetry={true}
            />
          );
        }
      }
    }

    return (
      <div className="teamContainer">
        {confirmationModal}
        {errorModal}
        <Form>
          <InputComponent
            label={translate("teamName")}
            id="teamName"
            specialLabel={true}
            type="text"
            onError={this.state.teamNameRequiredError}
            errorMessage={translate(teamNameRequiredErrorText)}
            value={this.state.teamName}
            handleChange={this.setTeamName}
          />
          <NavigationButton
            disabled={this.props.isLoading}
            action={this.handleSubmit}
            label={translate("createTeam")}
          />
          {statusComponent}
        </Form>
      </div>
    );
  }
}

export default StudentCreateTeamComponent;
