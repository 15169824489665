import * as actionTypes from "./action-types";
import { sendPostRequest } from "../../services/FetchService.js";

export const getPaymentIntent = (request, apiUrl) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.GET_PAYMENT_INTENT });
    try {
      const response = await sendPostRequest(apiUrl, request);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.GET_PAYMENT_INTENT_FAILED, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.GET_PAYMENT_INTENT_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.GET_PAYMENT_INTENT_FAILED, payload: err });
    }
  };
};
