import React from "react";
import "./SimulationDetailStyles.css";

const SimulationTitle = (props) => {
  return (
    <div className="headerSimulationName">
      <h1>{props.title}</h1>
      <span className="specialLine" />
    </div>
  );
};

export default SimulationTitle;
