import * as actions from "./action-types";

export const initialState = {
  simulationId: "",
  simulationShareLink: "",
  simulationDetails: {
    data: null,
    medicaLink: "",
    outcome: null,
  },
  simulationStandings: {
    payload: null,
    outcome: null,
  },
  simulationRegisteredStudents: {
    payload: null,
    outcome: null,
  },
  simulationTeams: {
    payload: null,
    outcome: null,
  },
  simulationDocumentation: {
    loadingDocumentation: null,
    getDocumentationError: "",
    getDocumentationSuccess: null,
    documentation: [],
    simulationId: "",
  },
  decisionHistoryStatus: {
    outcome: null,
    loading: false,
    errorMessage: "",
    decisionHistory: [],
  },
  suggestionsStatus: {
    loading: false,
    outcome: null,
    errorMessage: "",
    suggestions: {},
  },
  simulationPerformanceData: {},
  simulationDecisionsData: {},
  joinTeamSuccessfully: "",
  advanceSimulationSuccessfully: "",
  pushNotificationSuccessfully: null,
  runAgainSuccessfully: null,
  getTeamsError: "",
  getRegisteredStudentsError: "",
  getStandingsError: "",
  getSimulationDetailsError: "",
  pushNotificationError: "",
  createTeamError: "",
  joinTeamError: "",
  runAgainError: "",
  advanceSimulationError: "",
  loadingTeams: null,
  loadingRegisteredStudents: null,
  loadingDetails: null,
  loadingStandings: null,
  loadingPushNotification: null,
  loadingPerformanceData: false,
  loadingDecisionsData: false,
  advancingSimulation: null,
  creatingTeam: null,
  joiningTeam: null,
  loadingProfessorScenarios: null,
  loadingRunAgain: null,
  getProfessorScenariosError: "",
  customScenarios: null,
  checkingIfStudentHasPaid: null,
  studentHasPaid: null,
  checkStudentHasPaidError: "",
  simulationPerformanceDataError: "",
  simulationDecisionsDataError: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_MEDICA_DETAILS:
      return {
        ...state,
        loadingDetails: true,
        getSimulationDetailsError: "",
        simulationDetails: {
          data: null,
          medicaLink: "",
          outcome: null,
        },
      };
    case actions.GET_MEDICA_DETAILS_SUCCESSFUL:
      return {
        ...state,
        simulationDetails: action.payload,
        loadingDetails: false,
      };
    case actions.GET_MEDICA_DETAILS_FAILED:
      return {
        ...state,
        getSimulationDetailsError: action.payload.message,
        loadingDetails: false,
      };
    case actions.GET_MEDICA_STANDINGS:
      return {
        ...state,
        loadingStandings: true,
        simulationStandings: {
          payload: null,
          outcome: null,
        },
        getStandingsError: "",
      };
    case actions.GET_MEDICA_STANDINGS_SUCCESSFUL:
      return {
        ...state,
        simulationStandings: action.payload,
        loadingStandings: false,
      };
    case actions.GET_MEDICA_STANDINGS_FAILED:
      return {
        ...state,
        getStandingsError: action.payload.message,
        loadingStandings: false,
      };
    case actions.GET_MEDICA_REGISTERED_STUDENTS:
      return {
        ...state,
        loadingRegisteredStudents: true,
        simulationRegisteredStudents: {
          payload: null,
          outcome: null,
        },
        getRegisteredStudentsError: "",
      };
    case actions.GET_MEDICA_REGISTERED_STUDENTS_SUCCESSFUL:
      return {
        ...state,
        simulationRegisteredStudents: action.payload,
        loadingRegisteredStudents: false,
      };
    case actions.GET_MEDICA_REGISTERED_STUDENTS_FAILED:
      return {
        ...state,
        getRegisteredStudentsError: action.payload.message,
        loadingRegisteredStudents: false,
      };
    case actions.GET_MEDICA_TEAMS:
      return {
        ...state,
        loadingTeams: true,
        getTeamsError: "",
      };
    case actions.GET_MEDICA_TEAMS_SUCCESSFUL:
      return {
        ...state,
        simulationTeams: action.payload,
        loadingTeams: false,
      };
    case actions.GET_MEDICA_TEAMS_FAILED:
      return {
        ...state,
        getTeamsError: action.payload.message,
        loadingTeams: false,
      };
    case actions.CREATE_MEDICA_TEAM:
      return {
        ...state,
        creatingTeam: true,
        createTeamError: "",
      };
    case actions.CREATE_MEDICA_TEAM_SUCCESSFUL:
      return {
        ...state,
        simulationShareLink: action.payload.payload,
        creatingTeam: false,
      };
    case actions.CREATE_MEDICA_TEAM_FAILED:
      return {
        ...state,
        createTeamError: action.payload.message,
        creatingTeam: false,
      };
    case actions.JOIN_MEDICA_TEAM:
      return {
        ...state,
        joiningTeam: true,
        joinTeamError: "",
      };
    case actions.JOIN_MEDICA_TEAM_SUCCESSFUL:
      return {
        ...state,
        joiningTeam: false,
        joinTeamSuccessfully: action.payload,
      };
    case actions.JOIN_MEDICA_TEAM_FAILED:
      return {
        ...state,
        joiningTeam: false,
        joinTeamError: action.payload.message,
      };
    case actions.ADVANCE_MEDICA_SIMULATION:
      return {
        ...state,
        advancingSimulation: true,
        advanceSimulationError: "",
      };
    case actions.ADVANCE_MEDICA_SIMULATION_SUCCESSFUL:
      return {
        ...state,
        advancingSimulation: false,
        advanceSimulationSuccessfully: action.payload.message,
      };
    case actions.ADVANCE_MEDICA_SIMULATION_FAILED:
      return {
        ...state,
        advancingSimulation: false,
        advanceSimulationError: action.payload.message,
      };
    case actions.GET_PROFESSOR_SCENARIOS:
      return {
        ...state,
        loadingProfessorScenarios: true,
        getProfessorScenariosError: "",
      };
    case actions.GET_PROFESSOR_SCENARIOS_SUCCESSFUL:
      return {
        ...state,
        customScenarios: action.payload.payload,
        loadingProfessorScenarios: false,
      };
    case actions.GET_PROFESSOR_SCENARIOS_FAILED:
      return {
        ...state,
        getProfessorScenariosError: action.payload.message,
        loadingProfessorScenarios: false,
      };
    case actions.CHECK_STUDENT_PAYMENT:
      return {
        ...state,
        checkingIfStudentHasPaid: true,
        studentHasPaid: null,
        checkStudentHasPaidError: "",
      };
    case actions.CHECK_STUDENT_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        checkingIfStudentHasPaid: false,
        studentHasPaid: action.payload,
      };
    case actions.CHECK_STUDENT_PAYMENT_FAILED:
      return {
        ...state,
        checkingIfStudentHasPaid: false,
        checkStudentHasPaidError: action.payload,
      };
    case actions.RESET_ADVANCE_SIMULATION_PROPS:
      return {
        ...state,
        advanceSimulationSuccessfully: "",
        advancingSimulation: null,
        advanceSimulationError: "",
      };
    case actions.SEND_PUSH_NOTIFICATION:
      return {
        ...state,
        loadingPushNotification: true,
        pushNotificationSuccessfully: null,
        pushNotificationError: "",
      };
    case actions.SEND_PUSH_NOTIFICATION_SUCCESSFUL:
      return {
        ...state,
        loadingPushNotification: false,
        pushNotificationSuccessfully: action.payload,
      };
    case actions.SEND_PUSH_NOTIFICATION_FAILED:
      return {
        ...state,
        loadingPushNotification: false,
        pushNotificationError: action.payload,
      };
    case actions.GET_MEDICA_PERFORMANCE_DATA:
      return {
        ...state,
        loadingPerformanceData: true,
        simulationPerformanceData: {},
        simulationPerformanceDataError: "",
      };
    case actions.GET_MEDICA_PERFORMANCE_DATA_SUCCESSFUL:
      return {
        ...state,
        loadingPerformanceData: false,
        simulationPerformanceDataError: "",
        simulationPerformanceData: action.payload,
      };
    case actions.GET_MEDICA_PERFORMANCE_DATA_FAILED:
      return {
        ...state,
        loadingPerformanceData: false,
        simulationPerformanceData: {},
        simulationPerformanceDataError: action.payload,
      };
    case actions.RESET_PUSH_NOTIFICATION_STATE:
      return {
        ...state,
        pushNotificationSuccessfully: false,
      };
    case actions.RUN_AGAIN:
      return {
        ...state,
        loadingRunAgain: true,
        runAgainError: "",
      };
    case actions.RUN_AGAIN_SUCCESSFUL:
      return {
        ...state,
        loadingRunAgain: false,
        runAgainSuccessfully: action.payload,
      };
    case actions.RUN_AGAIN_FAILED:
      return {
        ...state,
        loadingRunAgain: false,
        runAgainError: action.payload,
      };
    case actions.GET_MEDICA_DOCUMENTATION:
      return {
        ...state,
        simulationDocumentation: {
          ...initialState.simulationDocumentation,
          loadingDocumentation: true,
        },
      };
    case actions.GET_MEDICA_DOCUMENTATION_SUCCESSFUL:
      return {
        ...state,
        simulationDocumentation: {
          ...state.simulationDocumentation,
          getDocumentationSuccess: true,
          documentation: action.payload.payload,
          simulationId: action.payload.simulationId,
          loadingDocumentation: false,
        },
      };
    case actions.GET_MEDICA_DOCUMENTATION_FAILED:
      return {
        ...state,
        simulationDocumentation: {
          ...state.simulationDocumentation,
          getDocumentationError: action.payload.message,
          loadingDocumentation: false,
        },
      };
    case actions.GET_MEDICA_DECISIONS_DATA:
      return {
        ...state,
        loadingDecisionsData: true,
        simulationDecisionsData: {},
        simulationDecisionsDataError: "",
      };
    case actions.GET_MEDICA_DECISIONS_DATA_SUCCESSFUL:
      return {
        ...state,
        loadingDecisionsData: false,
        simulationDecisionsDataError: "",
        simulationDecisionsData: action.payload,
      };
    case actions.GET_MEDICA_DECISIONS_DATA_FAILED:
      return {
        ...state,
        loadingDecisionsData: false,
        simulationDecisionsData: {},
        simulationDecisionsDataError: action.payload,
      };
    case actions.RESET_CREATE_TEAM_STORE:
      return {
        ...state,
        creatingTeam: false,
        createTeamError: "",
      };
    case actions.RESET_RUN_AGAIN_STORE:
      return {
        ...state,
        loadingRunAgain: null,
        runAgainError: "",
        runAgainSuccessfully: null,
      };
    case actions.MEDICA_GET_DECISION_HISTORY_START:
      return {
        ...state,
        decisionHistoryStatus: {
          loading: true,
          outcome: null,
          errorMessage: "",
          decisionHistory: [],
        },
      };
    case actions.MEDICA_GET_DECISION_HISTORY_SUCCESS:
      return {
        ...state,
        decisionHistoryStatus: {
          loading: false,
          outcome: true,
          errorMessage: "",
          decisionHistory: action.payload,
        },
      };
    case actions.MEDICA_GET_DECISION_HISTORY_FAIL:
      return {
        ...state,
        decisionHistoryStatus: {
          loading: false,
          outcome: false,
          errorMessage: action.payload,
          decisionHistory: [],
        },
      };
    case actions.GET_MEDICA_SUGGESTIONS:
      return {
        ...state,
        suggestionsStatus: {
          loading: true,
          outcome: null,
          errorMessage: "",
          suggestions: {},
        },
      };
    case actions.GET_MEDICA_SUGGESTIONS_SUCCESSFUL:
      return {
        ...state,
        suggestionsStatus: {
          loading: false,
          outcome: true,
          errorMessage: "",
          suggestions: action.payload,
        },
      };
    case actions.GET_MEDICA_SUGGESTIONS_FAILED:
      return {
        ...state,
        suggestionsStatus: {
          loading: false,
          outcome: false,
          errorMessage: action.payload,
          suggestions: {},
        },
      };
    default:
      return state;
  }
}
