import * as actionTypes from "./action-types";

export const initialState = {
  simulationDetailsRequestStatus: {
    loading: false,
    errorMessage: "",
    detailsValid: false,
  },
  simulationDetails: {
    id: "",
    name: "",
    status: "",
    professorManagedProgress: false,
    paymentSummaryId: "",
    professorPaid: false,
    shortLink: "",
    iterationNumber: 0,
  },
  advanceSimulationRequestStatus: {
    loading: false,
    errorMessage: "",
    success: false,
  },
  startSimulationRequestStatus: {
    loading: false,
    errorMessage: "",
    success: false,
  },
  simulationPerformanceDataRequestStatus: {
    loading: false,
    errorMessage: "",
    success: null,
    simulationName: null,
    currentStep: null,
    performanceData: null,
  },
  standings: {
    loading: false,
    errorMessage: "",
    success: null,
    teamMembers: null,
    simulationStandings: null,
  },
  registeredStudents: {
    loading: false,
    errorMessage: "",
    success: null,
    registeredStudents: null,
  },
  numberOfRegisteredStudentsStatus: {
    errorMessage: "",
    numberOfStudents: null,
  },
  createNextRunStatus: {
    loading: false,
    errorMessage: "",
    success: null,
  },
  professorScenarios: {
    loading: false,
    errorMessage: "",
    success: null,
    scenarios: null,
  },
  modifySimulationStatus: {
    loading: false,
    errorMessage: "",
    success: null,
  },
  documentationStatus: {
    loading: false,
    errorMessage: "",
    documentation: [],
    success: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CRISIS_GET_SIMULATION_DETAILS_START:
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: true, errorMessage: "", detailsValid: false },
      };
    case actionTypes.CRISIS_GET_SIMULATION_DETAILS_FAIL:
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: false, errorMessage: action.payload, detailsValid: false },
      };
    case actionTypes.CRISIS_GET_SIMULATION_DETAILS_SUCCESS:
      const {
        id,
        name,
        status,
        professorManagedProgress,
        currentSimulationDay,
        startTime,
        endTime,
        paymentSummaryId,
        professorPaid,
        shortLink,
        iterationNumber,
        hasANextIteration,
        gameMode,
      } = action.payload;
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: false, errorMessage: "", detailsValid: true },
        simulationDetails: {
          id,
          name,
          status,
          professorManagedProgress,
          currentSimulationDay,
          startTime,
          endTime,
          paymentSummaryId,
          professorPaid,
          shortLink,
          iterationNumber,
          hasANextIteration,
          gameMode,
        },
      };
    case actionTypes.CRISIS_ADVANCE_SIMULATION_START:
      return {
        ...state,
        advanceSimulationRequestStatus: { loading: true, errorMessage: "", success: false },
      };
    case actionTypes.CRISIS_ADVANCE_SIMULATION_FAIL:
      return {
        ...state,
        advanceSimulationRequestStatus: { loading: false, errorMessage: action.payload, success: false },
      };
    case actionTypes.CRISIS_ADVANCE_SIMULATION_SUCCESS:
      return {
        ...state,
        advanceSimulationRequestStatus: { loading: false, errorMessage: "", success: true },
      };
    case actionTypes.CRISIS_START_SIMULATION_START:
      return {
        ...state,
        startSimulationRequestStatus: { loading: true, errorMessage: "", success: false },
      };
    case actionTypes.CRISIS_START_SIMULATION_FAIL:
      return {
        ...state,
        startSimulationRequestStatus: { loading: false, errorMessage: action.payload, success: false },
      };
    case actionTypes.CRISIS_START_SIMULATION_SUCCESS:
      return {
        ...state,
        startSimulationRequestStatus: { loading: false, errorMessage: "", success: true },
      };
    case actionTypes.CRISIS_RESET_REQUESTS_STATE:
      const { startSimulationRequestStatus, advanceSimulationRequestStatus } = initialState;
      return {
        ...state,
        startSimulationRequestStatus,
        advanceSimulationRequestStatus,
      };
    case actionTypes.CRISIS_GET_PERFORMANCE_DATA_START:
      return {
        ...state,
        simulationPerformanceDataRequestStatus: {
          ...state.simulationPerformanceDataRequestStatus,
          loading: true,
          errorMessage: "",
          success: null,
        },
      };
    case actionTypes.CRISIS_GET_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        simulationPerformanceDataRequestStatus: {
          ...state.simulationPerformanceDataRequestStatus,
          loading: false,
          success: true,
          simulationName: action.payload.simulationName,
          currentStep: action.payload.currentStep,
          performanceData: action.payload.performanceData,
        },
      };
    case actionTypes.CRISIS_GET_PERFORMANCE_DATA_FAIL:
      return {
        ...state,
        simulationPerformanceDataRequestStatus: {
          ...state.simulationPerformanceDataRequestStatus,
          loading: false,
          errorMessage: action.payload,
          success: false,
        },
      };
    case actionTypes.CRISIS_GET_SIMULATION_STANDINGS_START:
      return {
        ...state,
        standings: {
          loading: true,
          errorMessage: "",
          success: null,
          teamMembers: null,
          simulationStandings: null,
        },
      };
    case actionTypes.CRISIS_GET_SIMULATION_STANDINGS_SUCCESS:
      return {
        ...state,
        standings: {
          loading: false,
          errorMessage: "",
          success: true,
          teamMembers: action.payload.teamMembers,
          simulationStandings: action.payload.standings,
        },
      };
    case actionTypes.CRISIS_GET_SIMULATION_STANDINGS_FAIL:
      return {
        ...state,
        standings: {
          loading: false,
          errorMessage: action.payload,
          success: false,
          teamMembers: null,
          simulationStandings: null,
        },
      };
    case actionTypes.CRISIS_GET_REGISTERED_STUDENTS_START:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: true,
          errorMessage: "",
          success: null,
        },
      };
    case actionTypes.CRISIS_GET_REGISTERED_STUDENTS_SUCCESS:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: false,
          success: true,
          registeredStudents: action.payload.registeredStudents,
        },
      };
    case actionTypes.CRISIS_GET_REGISTERED_STUDENTS_FAIL:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: false,
          errorMessage: action.payload,
          success: false,
        },
      };
    case actionTypes.CRISIS_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS:
      return {
        ...state,
        numberOfRegisteredStudentsStatus: {
          errorMessage: "",
          numberOfStudents: action.payload,
        },
      };
    case actionTypes.CRISIS_GET_NUMBER_REGISTERED_STUDENTS_FAIL:
      return {
        ...state,
        numberOfRegisteredStudentsStatus: {
          errorMessage: action.payload,
          numberOfStudents: null,
        },
      };
    case actionTypes.CRISIS_CREATE_NEXT_RUN_START:
      return {
        ...state,
        createNextRunStatus: {
          loading: true,
          errorMessage: "",
          success: null,
        },
      };
    case actionTypes.CRISIS_CREATE_NEXT_RUN_SUCCESS:
      return {
        ...state,
        createNextRunStatus: {
          loading: false,
          errorMessage: "",
          success: true,
        },
      };
    case actionTypes.CRISIS_CREATE_NEXT_RUN_FAIL:
      return {
        ...state,
        createNextRunStatus: {
          loading: false,
          errorMessage: action.payload,
          success: false,
        },
      };
    case actionTypes.CRISIS_CREATE_NEXT_RUN_CLEAN:
      return {
        ...state,
        createNextRunStatus: { ...initialState.createNextRunStatus },
      };
    case actionTypes.CRISIS_GET_PROFESSOR_SCENARIOS_START:
      return {
        ...state,
        professorScenarios: {
          loading: true,
          errorMessage: "",
          success: null,
          scenarios: null,
        },
      };
    case actionTypes.CRISIS_GET_PROFESSOR_SCENARIOS_FAIL:
      return {
        ...state,
        professorScenarios: {
          loading: false,
          errorMessage: action.payload,
          success: false,
          scenarios: null,
        },
      };
    case actionTypes.CRISIS_GET_PROFESSOR_SCENARIOS_SUCCESS:
      return {
        ...state,
        professorScenarios: {
          loading: false,
          errorMessage: "",
          success: true,
          scenarios: action.payload,
        },
      };
    case actionTypes.CRISIS_MODIFY_SIMULATION_START:
      return {
        ...state,
        modifySimulationStatus: {
          loading: true,
          errorMessage: "",
          success: null,
        },
      };
    case actionTypes.CRISIS_MODIFY_SIMULATION_FAIL:
      return {
        ...state,
        modifySimulationStatus: {
          loading: false,
          errorMessage: action.payload,
          success: false,
        },
      };
    case actionTypes.CRISIS_MODIFY_SIMULATION_SUCCESS:
      return {
        ...state,
        modifySimulationStatus: {
          loading: false,
          errorMessage: "",
          success: true,
        },
      };
    case actionTypes.CRISIS_MODIFY_SIMULATION_RESET:
      return {
        ...state,
        modifySimulationStatus: {
          ...initialState.modifySimulationStatus,
        },
      };
    case actionTypes.CRISIS_GET_DOCUMENTATION_START:
      return {
        ...state,
        documentationStatus: { loading: true, errorMessage: "", documentation: [], success: null },
      };
    case actionTypes.CRISIS_GET_DOCUMENTATION_FAIL:
      return {
        ...state,
        documentationStatus: { loading: false, errorMessage: action.payload, documentation: [], success: false },
      };
    case actionTypes.CRISIS_GET_DOCUMENTATION_SUCCESS:
      return {
        ...state,
        documentationStatus: {
          loading: false,
          errorMessage: "",
          documentation: action.payload,
          success: true,
        },
      };
    default:
      return state;
  }
}
