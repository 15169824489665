export const OneStationUnknownPeers = "OneStationUnknownPeers";
export const OneStationKnownPeers = "OneStationKnownPeers";
export const AllStationsKnownPeers = "AllStationsKnownPeers";
export const LastMile = "LastMile";

export const minTimeBeforeSimulationStartsInMiliseconds = 1800000; //30 min
export const minSimulationDurationInMiliseconds = 21600000; //6 hours
export const maxSimulationDurationInMiliseconds = 187200000; //52 hours
export const minimumNumberOfHoursToRunByPace = { 1: 10, 2: 48 };
export const maximumNumberOfHoursToRunByPace = { 1: 60, 2: 120 };
