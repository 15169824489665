import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import DashBoardContainer from "./DashBoardContainer";

export default class DashboardSectionRow extends Component {
  state = {
    open: true,
  };

  toggleSection = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { name, handleGraphModal, section, selectedTeams } = this.props;
    const sectionRowContent = (columns) => (
      <Grid key={section.index} columns={columns} stretched>
        <Grid.Column className="sectionGraphHeaderCrisis" width={16}>
          <Header className="sectionGraphHeaderCrisis_title" as="h3" onClick={this.toggleSection}>
            {name}
            <FontAwesomeIcon className={`icon ${!this.state.open ? `icon__down` : ""}`} icon={faChevronUp} />
          </Header>
        </Grid.Column>
        {this.state.open && (
          <Grid.Row>
            <DashBoardContainer selectedTeams={selectedTeams} handleGraphModal={handleGraphModal} data={section.data} />
          </Grid.Row>
        )}
      </Grid>
    );

    return (
      <>
        <Grid id="sectionTop">
          <Grid.Row only="computer">{sectionRowContent(3)}</Grid.Row>
          <Grid.Row only="tablet">{sectionRowContent(2)}</Grid.Row>
          <Grid.Row only="mobile">{sectionRowContent(1)}</Grid.Row>
        </Grid>
      </>
    );
  }
}
