import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  crisisAdvanceSimulation,
  crisisResetRequestsState,
  crisisStartSimulation,
  crisisGetNumberOfRegisteredStudents,
} from "../../store/CrisisSimulationDetails/actions";
import { connect } from "react-redux";
import InputComponent from "../helpers/InputComponent";

class AdvanceSimulationModal extends Component {
  state = {
    inputNumberValue: 1,
  };

  componentDidMount() {
    this.props.crisisGetNumberOfRegisteredStudents(this.props.simulationDetails.id);
  }

  advanceSimulation = (advanceMethod) => {
    let advanceParams = { simulationId: this.props.simulationDetails.id };
    if (this.props.simulationDetails.status === "AwaitingInitialization") {
      advanceParams["numberOfTeams"] = this.state.inputNumberValue;
    } else if (
      this.props.simulationDetails.status === "Initialized" ||
      this.props.simulationDetails.status === "Running"
    ) {
      advanceParams["numberOfWeeksToAdvance"] = this.state.inputNumberValue;
    }
    advanceMethod(advanceParams);
  };

  handleClose = () => {
    this.props.handleAdvanceModal();
  };

  successAction = () => {
    this.props.getSimulationDetails(this.props.simulationDetails.id);
    this.props.crisisResetRequestsState();
    this.handleClose();
  };

  handleInputNumberValueChanged = (id, data) => {
    this.setState({ inputNumberValue: parseInt(data) });
  };

  handleWeeksToAdvanceChanged = (id, data) => {
    let valueToSave = parseInt(data);
    if (valueToSave < 1) {
      valueToSave = 1;
    }

    if (valueToSave > 4) {
      valueToSave = 4;
    }
    this.setState({ inputNumberValue: valueToSave });
  };

  render() {
    const translate = this.props.t;
    let oncloseFunction = this.handleClose;

    let requestStatus = this.props.advanceSimulationRequestStatus;
    let buttonContent = translate("AdvanceSimulationModal:advance");
    let advanceButtonMethod = this.props.crisisAdvanceSimulation;
    let customSuccessMessage = translate("Simulation advanced successfully.");
    let titleText = translate(`AdvanceSimulationModal:advanceSimulation`);
    let disabledAdvanceButton = Number.isNaN(this.state.inputNumberValue);

    let numberInput = null;
    if (this.props.simulationDetails.status === "AwaitingInitialization") {
      buttonContent = translate("AdvanceSimulationModal:start");
      advanceButtonMethod = this.props.crisisStartSimulation;
      requestStatus = this.props.startSimulationRequestStatus;
      titleText = translate(`AdvanceSimulationModal:startSimulation`);

      let numberOfStudents = this.props.numberOfRegisteredStudentsStatus.numberOfStudents;
      let numberOfStudentsError = this.props.numberOfRegisteredStudentsStatus.errorMessage;
      let messageToStartSimulation = null;

      if (numberOfStudentsError === "" && numberOfStudents === 0) {
        disabledAdvanceButton = true;
        messageToStartSimulation = (
          <p align="justify">{translate("CrisisAdvanceSimulationModal:StartSimulationController_SimulationEmpty")}</p>
        );
      } else {
        messageToStartSimulation = (
          <div className="contentMarginBottom" align="justify">
            <p>{translate("CrisisAdvanceSimulationModal:StudentsAreJoined")}</p>
            <p>
              {translate("CrisisAdvanceSimulationModal:RegisteredStudents")}{" "}
              {numberOfStudentsError === "" ? numberOfStudents : "N"}
            </p>
            <p>
              {translate("CrisisAdvanceSimulationModal:RecommendedNumberOfTeams")}{" "}
              {numberOfStudentsError === ""
                ? Math.ceil(numberOfStudents / 4)
                : translate("CrisisAdvanceSimulationModal:RoundedUp")}
            </p>
            <p>{translate("CrisisAdvanceSimulationModal:MissingSpotsMessage")}</p>
          </div>
        );
      }

      numberInput = (
        <div className="contentMarginBottom">
          {messageToStartSimulation}
          <InputComponent
            type="number"
            label={translate("CrisisAdvanceSimulationModal:NumberOfTeams")}
            value={this.state.inputNumberValue}
            handleChange={this.handleInputNumberValueChanged}
            specialLabel={true}
          />
        </div>
      );
    } else if (
      this.props.simulationDetails.status === "Initialized" ||
      this.props.simulationDetails.status === "Running"
    ) {
      numberInput = (
        <div className="contentMarginBottom">
          <InputComponent
            type="number"
            label={translate("CrisisAdvanceSimulationModal:NumberOfWeeks")}
            value={this.state.inputNumberValue}
            handleChange={this.handleWeeksToAdvanceChanged}
            specialLabel={true}
          />
        </div>
      );
    }

    let actionButtons = null;
    actionButtons = (
      <Modal.Actions>
        <Button
          disabled={requestStatus.loading === true || disabledAdvanceButton}
          content={buttonContent}
          onClick={() => this.advanceSimulation(advanceButtonMethod)}
        />
        <Button content={translate("ModifyModal:cancel")} onClick={() => this.handleClose()} />
      </Modal.Actions>
    );
    let statusComponent = null;
    if (requestStatus.loading === true) {
      statusComponent = <div className="loader" />;
    } else if (requestStatus.errorMessage !== "") {
      const errorMessage = this.props.i18n.exists(requestStatus.errorMessage)
        ? translate(requestStatus.errorMessage)
        : translate(`CrisisAdvanceSimulationModal:${requestStatus.errorMessage}`);
      statusComponent = (
        <div className="contentMarginTop">
          <MessagesComponent messageType={false} message={errorMessage} />
        </div>
      );
    } else if (requestStatus.success) {
      oncloseFunction = this.successAction;
      statusComponent = <MessagesComponent messageType={true} message={customSuccessMessage} />;

      actionButtons = (
        <Modal.Actions>
          <Button content={"Ok"} onClick={this.successAction} />
        </Modal.Actions>
      );
    }

    return (
      <Modal size="tiny" open={this.props.open} onClose={oncloseFunction}>
        <Modal.Header>{titleText}</Modal.Header>
        <Modal.Content>
          {numberInput}
          {statusComponent}
        </Modal.Content>
        {actionButtons}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  advanceSimulationRequestStatus: state.crisisSimulationDetails.advanceSimulationRequestStatus,
  startSimulationRequestStatus: state.crisisSimulationDetails.startSimulationRequestStatus,
  simulationDetails: state.crisisSimulationDetails.simulationDetails,
  numberOfRegisteredStudentsStatus: state.crisisSimulationDetails.numberOfRegisteredStudentsStatus,
});

const mapDispatchToProps = (dispatch) => ({
  crisisStartSimulation: (startSimulationRequest) => dispatch(crisisStartSimulation(startSimulationRequest)),
  crisisAdvanceSimulation: (simulationId) => dispatch(crisisAdvanceSimulation(simulationId)),
  crisisGetNumberOfRegisteredStudents: (simulationId) => dispatch(crisisGetNumberOfRegisteredStudents(simulationId)),
  crisisResetRequestsState: () => dispatch(crisisResetRequestsState()),
});

const HOC = compose(
  withTranslation("AdvanceSimulationModal", "CrisisAdvanceSimulationModal", "Common"),
  connect(mapStateToProps, mapDispatchToProps)
);

export default HOC(AdvanceSimulationModal);
