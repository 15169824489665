import React, { Component } from "react";
import ScenarioInitialValuesSection from "./ScenarioInitialValuesSection";
import ScenarioGraphSection from "./ScenarioGraphSection";
import ScenarioPlayerValuesSection from "./ScenarioPlayerValuesSection";

class ScenarioComparison extends Component {
  render() {
    const { translate, scenarioData } = this.props;

    return (
      <div className="scenarioComparisonContent">
        <ScenarioInitialValuesSection translate={translate} scenarioData={scenarioData} />
        <ScenarioGraphSection translate={translate} scenarioData={scenarioData} />
        <ScenarioPlayerValuesSection translate={translate} scenarioData={scenarioData} />
      </div>
    );
  }
}

export default ScenarioComparison;
