import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import "./medicaBadLink.css";

function MedicaBadLinkComponent(props) {
  const translate = props.t;
  return (
    <Container>
      <HeaderComponent translate={translate} location={props.location} showLanguageSelector />
      <MainTitleComponent title={"Oops"} />
      <div className={"medica-bad-link__body-container"}>
        <p className={"medica-bad-link__error-text"}>
          <b>{translate("badLink")}</b>
        </p>
        <p>{translate("tryFollowing")}</p>
        <ul className="medica-bad-link__todo-list">
          <li>{translate("tryingToCreateTeam")}</li>
          <li>{translate("tryingToJoinTeam")}</li>
          <li>
            <Trans t={translate} i18nKey="lastResort">
              If this doesn't work please reach us at
              <a href="mailto:support@processimlabs.com">support@processimlabs.com</a> or using the chat on the bottom
              right corner.
            </Trans>
          </li>
        </ul>
      </div>
    </Container>
  );
}

const HOC = compose(withRouter, withTranslation("MedicaBadLink", "Common"));

export default HOC(MedicaBadLinkComponent);
