import React, { Component } from "react";
import { Trans } from "react-i18next";
import { scenarioInitialValuesSection } from "../utilities/ScenarioInformationUtilities";
import CustomTableHeader from "./CustomTableHeader";

class ScenarioInitialValuesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValuesSectionData: [],
    };
  }

  componentDidMount() {
    this.prepareTableSectionData();
  }

  prepareTableSectionData = () => {
    const { scenarioData } = this.props;
    const initialValuesSectionData = scenarioInitialValuesSection(scenarioData);
    this.setState({
      initialValuesSectionData: initialValuesSectionData,
    });
  };

  getValueTranslation = (configuration, values) => {
    const { translate } = this.props;

    const value = values.value;
    let pluralKey = "";
    let dollarValue = Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (value > 1) pluralKey = "_plural";

    switch (configuration.units) {
      case "Dollars":
        return dollarValue;
      case "DollarsPerDay":
        return <Trans i18nKey={`MedicaAdvancedOptions:DollarsPerDay`}>{{ dollarValue }}/day</Trans>;
      case "UnitsPerDay":
        return <Trans i18nKey={`MedicaAdvancedOptions:UnitsPerDay${pluralKey}`}>{{ value }} unit/day</Trans>;
      default:
        return `${value} ${translate(`${configuration.units}${pluralKey}`)}`;
    }
  };

  getConfigurationItem = (index, configuration, values) => {
    const { translate } = this.props;

    const valueTranslation = this.getValueTranslation(configuration, values);

    const showGraphBellowText = configuration.name === "CustomDemand" || configuration.name === "StandardMarketPrice";

    if (configuration.units !== "UnitLess") {
      if (showGraphBellowText) {
        return (
          <div className="rowValue" key={index}>
            {translate("graphBelow")}
          </div>
        );
      }
      return (
        <div className="rowValue" key={index}>
          {valueTranslation}
        </div>
      );
    } else {
      let rowItems = null;
      const hasATriangularDistribution = values.DistributionType === "Triangular";

      if (hasATriangularDistribution) {
        rowItems = (
          <>
            <div>
              {translate("Distribution")}
              {translate("TriangularDistribution")}
            </div>
            <div>
              {translate("lowerBound")}
              {values.LowerBound}
            </div>
            <div>
              {translate("upperBound")}
              {values.UpperBound}
            </div>
            <div>
              {translate("mode")}
              {values.Mode}
            </div>
          </>
        );
      } else {
        rowItems = (
          <>
            <div>
              {translate("Distribution")}
              {translate("LogNormalDistribution")}
            </div>
            <div>
              {translate("mean")}
              {values.Mean}
            </div>
            <div>
              {translate("stdDev")}
              {values.StdDev}
            </div>
          </>
        );
      }

      return (
        <div className="rowValue blockValue" key={index}>
          {rowItems}
        </div>
      );
    }
  };

  getPriceVsDemandConfigurationItem = (values, index) => {
    const ratios = Object.keys(values);
    const priceVsDemandItems = ratios.map((ratio, index) => {
      return {
        [ratio]: values[ratio],
      };
    });

    return (
      <div className="rowValue blockValue" key={index}>
        {priceVsDemandItems.map((item, index) => {
          const configurationItem = this.getPriceVsDemandValueTranslation(item, index);

          return configurationItem;
        })}
      </div>
    );
  };

  getPriceVsDemandValueTranslation = (item, index) => {
    const ratioKey = Object.keys(item);
    const ratio = ratioKey[0];
    const units = item[ratio];
    return (
      <p className="unitsIfPriceRatio" key={index}>
        <Trans i18nKey={`MedicaAdvancedOptions:unitsIfPriceRatio`}>
          {{ units }} units if Price ratio is {{ ratio }}
        </Trans>
      </p>
    );
  };

  render() {
    const { translate, scenarioData } = this.props;
    const { initialValuesSectionData } = this.state;
    const tableHeaderColumns = scenarioData.map((x) => x.name);

    return (
      <div className="customTable">
        <CustomTableHeader translate={translate} tableColumnNames={tableHeaderColumns} />
        {initialValuesSectionData.map((section, index) => {
          return (
            <div key={index} className="body">
              <div className="sectionName">
                <p>{translate(section.name)}</p>
              </div>
              <div className="sectionConfigurations">
                {section.configurations.map((configuration, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="rowItem">
                        <p>{translate(configuration.name)}</p>
                      </div>
                      {configuration.values.map((values, index) => {
                        let configurationItem = null;
                        if (configuration.name === "PriceVsDemand") {
                          configurationItem = this.getPriceVsDemandConfigurationItem(values, index);
                        } else {
                          configurationItem = this.getConfigurationItem(index, configuration, values);
                        }

                        return configurationItem;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ScenarioInitialValuesSection;
