export const RESET_CRISIS_BUY_SIMULATION_DEFAULTS = "CRISIS_BUY_SIMULATION/RESET_CRISIS_BUY_SIMULATION_DEFAULTS";
export const COMMIT_PURCHASE_FORM_DATA = "CRISIS_BUY_SIMULATION/COMMIT_PURCHASE_FORM_DATA";
export const RESTORE_STORAGE_PURCHASE_INFORMATION = "CRISIS_BUY_SIMULATION/RESTORE_STORAGE_PURCHASE_INFORMATION";
export const UPDATE_TRANSACTION_DATA = "CRISIS_BUY_SIMULATION/UPDATE_TRANSACTION_DATA";
export const CREATE_SIMULATION_REQUEST_START = "CRISIS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_START";
export const CREATE_SIMULATION_REQUEST_SUCCESS = "CRISIS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_SUCCESS";
export const CREATE_SIMULATION_REQUEST_FAIL = "CRISIS_BUY_SIMULATION/CREATE_SIMULATION_REQUEST_FAIL";
export const RESET_CREATE_SIMULATION_DEFAULTS = "CRISIS_BUY_SIMULATION/RESET_CREATE_SIMULATION_DEFAULTS";
export const VALIDATE_DISCOUNT_CODE = "CRISIS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_FAILED = "CRISIS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_FAILED";
export const VALIDATE_DISCOUNT_CODE_SUCCESS = "CRISIS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS = "CRISIS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS";
export const VALIDATE_DISCOUNT_CODE_RESET = "CRISIS_BUY_SIMULATION/VALIDATE_DISCOUNT_CODE_RESET";
export const CHECK_STUDENT_PAYMENT = "CRISIS_BUY_SIMULATION/CHECK_STUDENT_PAYMENT";
export const CHECK_STUDENT_PAYMENT_SUCCESSFUL = "CRISIS_BUY_SIMULATION/CHECK_STUDENT_PAYMENT_SUCCESSFUL";
export const CHECK_STUDENT_PAYMENT_FAILED = "CRISIS_BUY_SIMULATION/CHECK_STUDENT_PAYMENT_FAILED";
export const CONFIRM_PAYMENT_START = "CRISIS_BUY_SIMULATION/CONFIRM_PAYMENT_START";
export const CONFIRM_PAYMENT_SUCCESS = "CRISIS_BUY_SIMULATION/CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAIL = "CRISIS_BUY_SIMULATION/CONFIRM_PAYMENT_FAIL";
