import React from "react";
import { Button } from "semantic-ui-react";
import "./buttonStyles.css";

const DefaultButton = (props) => {
  const { title, action } = props;
  return (
    <div className="defaultButton">
      <Button basic name={title} onClick={(e) => action(e)}>
        {title}
      </Button>
    </div>
  );
};

export default DefaultButton;
