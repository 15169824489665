import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import InputComponent from "../helpers/InputComponent";
import { checkNameIsValid } from "../utilities/DataValidationUtilities";

class PurchasedSimunationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      simulationName: this.props.simulationName,
      simulationNameError: false,
      errorMessage: "",
      error: false,
    };
  }

  settingSimulationName = (id, data) => {
    const isValidName = checkNameIsValid(data);
    if (isValidName) {
      this.setState({
        [id]: data,
        simulationNameError: false,
      });
    } else {
      this.setState({
        simulationNameError: true,
        simulationNameErrorText: "invalidSimulationNameCharacterError",
      });
    }
  };

  saveInformation = (simulationName) => {
    const values = {
      simulationName: simulationName,
    };
    return values;
  };

  handleContinue = () => {
    if (this.state.simulationName !== "") {
      return {
        simulationName: this.state.simulationName,
      };
    } else {
      this.setState({
        simulationNameError: true,
        simulationNameErrorText: "notSimulationName",
      });
    }
  };

  render() {
    const translate = this.props.translate;
    let messageComponent = null;

    if (this.state.errorMessage !== "") {
      messageComponent = <MessagesComponent message={this.state.errorMessage} />;
    }

    return (
      <Form>
        <InputComponent
          label={translate("simulationName")}
          id="simulationName"
          specialLabel={true}
          placeholder="Sus Dev 101"
          type="text"
          onError={this.state.simulationNameError}
          errorMessage={translate(this.state.simulationNameErrorText)}
          value={this.state.simulationName}
          handleChange={this.settingSimulationName}
          labelAlignment="left"
        />
        {messageComponent}
      </Form>
    );
  }
}

export default PurchasedSimunationComponent;
