import React from "react";
import { Button } from "semantic-ui-react";
import { logout } from "../../services/AuthService";
import "./buttonStyles.css";

const LogoutButton = (props) => {
  return (
    <div className="logoutButton">
      <Button basic onClick={logout}>
        {props.label}
      </Button>
    </div>
  );
};

export default LogoutButton;
