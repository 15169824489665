import { sendGetRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";

export const getTutorials = () => {
  return sendGetRequest(FETCHAPI.GET_VIDEO_TUTORIALS, "");
};

export const formatTutorials = (tutorialsData) => {
  const tutorialsFormatted = tutorialsData.map((section) => {
    return {
      language: section.language,
      tutorials: section.tutorials.reverse(),
    };
  });

  return tutorialsFormatted;
};
