import { sendGetRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";
import * as CONSTANT from "../constants/UniversalConstants";
import moment from "moment";
import "moment/locale/es";
import { getLanguagePrefix } from "../components/utilities/LanguageUtilities";

export const getMedicaList = () => {
  return sendGetRequest(FETCHAPI.GET_SIMULATIONS_MEDICA, "");
};

export const getSimunationList = () => {
  return sendGetRequest(FETCHAPI.GET_SIMULATIONS_SIMUNATION, "");
};

export const getCrisisList = () => {
  return sendGetRequest(FETCHAPI.GET_SIMULATIONS_CRISIS, "");
};

export const getSupplyChainList = () => {
  return sendGetRequest(FETCHAPI.GET_SIMULATIONS_SUPPLY_CHAIN, "");
};

export const getForioList = () => {
  return sendGetRequest(FETCHAPI.GET_SIMULATIONS_FORIO, "");
};

export const getUserSimulations = (
  medicaResponse,
  simunationResponse,
  crisisResponse,
  supplyChainResponse,
  forioResponse,
  language,
  translate
) => {
  let response = {
    action: null,
    payload: {
      data: null,
      message: "",
      showVideoToBuy: null,
    },
  };

  let medicaSimulations = null;
  let simunationSimulations = null;
  let crisisSimulations = null;
  let supplyChainSimulations = null;
  let abogadosSimulations = null;
  let creambowSimulations = null;

  if (medicaResponse?.outcome) {
    medicaSimulations = listToArray(CONSTANT.MEDICA_NAME, medicaResponse.payload);
  }

  if (simunationResponse?.outcome) {
    simunationSimulations = listToArray(CONSTANT.SIMUNATION_NAME, simunationResponse.payload);
  }

  if (crisisResponse?.outcome) {
    crisisSimulations = listToArray(CONSTANT.CRISIS_NAME, crisisResponse.payload);
  }

  if (supplyChainResponse?.outcome) {
    supplyChainSimulations = listToArray(CONSTANT.SUPPLY_CHAIN_NAME, supplyChainResponse.payload);
  }

  if (forioResponse?.outcome) {
    abogadosSimulations = listToArray(
      CONSTANT.ABOGADOS_NAME,
      forioResponse.payload.filter((x) => x.simulationType === "LaneAndSlinger")
    );
  }

  if (forioResponse?.outcome) {
    creambowSimulations = listToArray(
      CONSTANT.CREAM_BOW_NAME,
      forioResponse.payload.filter((x) => x.simulationType === "Creambow")
    );
  }

  const responseData = joinSimulationData(
    medicaSimulations,
    simunationSimulations,
    crisisSimulations,
    supplyChainSimulations,
    abogadosSimulations,
    creambowSimulations,
    language,
    translate
  );
  response.payload.data = responseData;
  return response;
};

export const joinSimulationData = (
  medicaSimulations,
  simunationSimulations,
  crisisSimulations,
  supplyChainsSimulations,
  abogadosSimulations,
  creambowSimulations,
  language,
  translate
) => {
  const simulationsList = [];
  if (medicaSimulations) simulationsList.push(...formatSimulationDatesAndSetId(medicaSimulations, language, translate));
  if (simunationSimulations)
    simulationsList.push(...simunationFormatSimulationDatesAndSetId(simunationSimulations, language, translate));
  if (crisisSimulations) simulationsList.push(...formatSimulationDatesAndSetId(crisisSimulations, language, translate));
  if (supplyChainsSimulations)
    simulationsList.push(...formatSimulationDatesAndSetId(supplyChainsSimulations, language, translate));
  if (abogadosSimulations)
    simulationsList.push(...formatSimulationDatesAndSetId(abogadosSimulations, language, translate));
  if (creambowSimulations)
    simulationsList.push(...formatSimulationDatesAndSetId(creambowSimulations, language, translate));
  return simulationsList.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
};

const formatSimulationDatesAndSetId = (simulations, language, translate) => {
  let simulationWithFormattedDate = [];
  simulations.forEach((simulation) => {
    simulation.startTime = dateFormatter(simulation.startTime, language, translate);
    simulation.endDate = dateFormatter(simulation.endDate, language, translate);
    simulationWithFormattedDate.push(simulation);
  });

  return simulationWithFormattedDate;
};

const simunationFormatSimulationDatesAndSetId = (simulations, language, translate) => {
  return simulations.map((simulation) => ({
    ...simulation,
    startTime: dateFormatter(simulation.createdAt, language, translate),
  }));
};

const dateFormatter = (date, language, translate) => {
  const languagePrefix = getLanguagePrefix(language);
  try {
    return moment(date)
      .locale(languagePrefix)
      .format(`MMMM D, YYYY [${translate("at")}] hh:mm A`);
  } catch (error) {
    console.log(error);
  }
};

export const listToArray = (type, list) => {
  let newListArr = [];
  list.forEach((simulation) => {
    newListArr.push({
      ...simulation,
      type: type,
    });
  });
  return newListArr;
};
