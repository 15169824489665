import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as CONSTANT from "../../constants/UniversalConstants";
import DropDownComponent from "../helpers/DropDownComponent";
import InputComponent from "../helpers/InputComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import CalendarComponent from "../helpers/CalendarComponent";
import "./purchasedCrisisStyles.css";
import { crisisGameModeOptions } from "../utilities/CrisisSimulationUtilities";

class CreateCrisisSimulationForm extends Component {
  state = {
    dateToModify: "",
    openCalendar: false,
  };

  handleFormChange = (id, data) => {
    this.props.formChanged(id, data);
  };

  advanceSimulationOptions = [
    {
      key: 1,
      text: this.props.t("Common:yes"),
      value: true,
    },
    {
      key: 2,
      text: this.props.t("Common:no"),
      value: false,
    },
  ];

  handleCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  render() {
    const translate = this.props.t;
    let calendarComponent = null;
    let selectedDate = null;

    if (this.state.dateToModify === "startDate") {
      selectedDate = this.props.segmentedStartTime.date;
    } else {
      selectedDate = this.props.segmentedStartTime.date;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <div className="calendarPosition">
          <CalendarComponent
            translate={translate}
            handleCalendar={this.handleCalendar}
            dateToModify={this.state.dateToModify}
            onDateClick={(value) => this.props.setDate(value, this.state.dateToModify)}
            selectedDate={selectedDate}
            language={this.props.language}
          />
        </div>
      );
    }

    const dateSelectors = this.props.formData.professorAdvance.value ? null : (
      <div>
        <DateTimeSelector
          label={translate("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "startDate" })}
          dateToSet={CONSTANT.START_DATE}
          settingHour={this.props.setStartHour}
          date={this.props.segmentedStartTime.date}
          hour={this.props.segmentedStartTime.hour}
          hourFormat={this.props.segmentedStartTime.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={translate("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "endDate" })}
          dateToSet={CONSTANT.END_DATE}
          settingHour={this.props.setEndHour}
          date={this.props.segmentedEndTime.date}
          hour={this.props.segmentedEndTime.hour}
          hourFormat={this.props.segmentedEndTime.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />
      </div>
    );

    const gameModeOptions = crisisGameModeOptions(translate);

    return (
      <>
        {calendarComponent}
        <InputComponent
          label={translate("simulationName")}
          id="simulationName"
          specialLabel={true}
          placeholder="i.e. OM 101"
          type="text"
          onError={!this.props.formData.simulationName.valid}
          errorMessage={translate(this.props.simulationNameErrorText)}
          value={this.props.formData.simulationName.value}
          handleChange={this.handleFormChange.bind(this)}
          labelAlignment="left"
        />

        <DropDownComponent
          id="professorAdvance"
          label={translate("professorAdvanceSimulation")}
          helpMessage={translate("medicaProfessorAdvanceSimulationHelp")}
          options={this.advanceSimulationOptions}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.handleFormChange.bind(this, "professorAdvance")}
          value={this.props.formData.professorAdvance.value}
          labelAlignment="left"
        />
        {dateSelectors}
        <DropDownComponent
          id="gameMode"
          label={translate("CrisisPurchased:GameModeTitle")}
          options={gameModeOptions}
          onSelection={this.handleFormChange.bind(this, "gameMode")}
          value={this.props.formData.gameMode.value}
          labelAlignment="left"
        />

        <p>{translate("CrisisCommon:GameModeHelp")}</p>
        <ul className="create-crisis-simulation__game-mode-help-list">
          <li>{translate("CrisisCommon:OneStationUnknownPeersHelp")}</li>
          <li>{translate("CrisisCommon:OneStationKnownPeersHelp")}</li>
          <li>{translate("CrisisCommon:AllStationsKnownPeersHelp")}</li>
        </ul>
      </>
    );
  }
}

export default withTranslation(["Purchased", "Common", "CrisisCommon"])(CreateCrisisSimulationForm);
