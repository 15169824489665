import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import * as Sentry from "@sentry/react";
import Axios from "axios";
import Navigation from "./components/Navigation";
import * as ROUTES from "./constants/Routes";
import CookiesDisabledScreen from "./components/cookiesDisable";
import { changeBackgroundImage } from "./components/utilities/BackgroundUtilities";
import { isLoggedIn, login, setUserLanguage } from "./store/User/actions";
import { getStorage, removeStorage } from "./services/StorageService";
import { processLanguageAndSave } from "./components/utilities/LanguageUtilities";
import * as LOCALSTORAGE from "./constants/LocalStorage";
import * as CONSTANT from "./constants/UniversalConstants";
import { axiosLogExceptionsFromBackend, axiosLogErrorsFromBackend } from "./services/FetchService";
const cookiesEnabled = navigator.cookieEnabled;

class App extends Component {
  componentDidMount() {
    if (cookiesEnabled) {
      this.checkIsLoggedIn();
    }
    Axios.interceptors.response.use(axiosLogErrorsFromBackend, axiosLogExceptionsFromBackend);
  }

  componentWillUnmount() {
    Axios.interceptors.response.eject(axiosLogErrorsFromBackend, axiosLogExceptionsFromBackend);
  }

  componentDidUpdate(prevProps) {
    const { user, history, location } = this.props;

    if (cookiesEnabled && (!user.language || prevProps.user.language !== user.language)) {
      this.setLanguage();
    }

    if (prevProps.user.email !== user.email) {
      this.saveUserChatInformation(user);
      this.configureSentryScope(user);
    }

    if (location.pathname === ROUTES.DOWNLOAD_APP) {
      window.history.pushState(null, null, location.href);
      window.onpopstate = function () {
        history.replace(ROUTES.STUDENT_FAQ);
        removeStorage(LOCALSTORAGE.purchasedProduct);
      };
    }
  }

  configureSentryScope = (user) => {
    Sentry.configureScope((scope) => {
      scope.setUser({ email: user.email });
      scope.setExtra("website version", CONSTANT.APP_VERSION_NUMBER);
      scope.setExtra("user role", user.role);
    });
  };

  setChatLanguage = (language) => {
    var _smartsupp = window.smartsupp;
    if (_smartsupp) {
      _smartsupp("language", language);
    }
  };

  saveUserChatInformation = (user) => {
    if (user) {
      var _smartsupp = window.smartsupp;
      if (_smartsupp) {
        _smartsupp("name", user.firstName);
        _smartsupp("email", user.email);
        _smartsupp("variables", {
          role: {
            label: "Role",
            value: user.role,
          },
        });
      }
    }
  };

  checkIsLoggedIn = async () => {
    const isLoggedInPath = this.checkIfLoggedInPath();
    if (isLoggedInPath) await this.props.isLoggedIn();
  };

  checkIfLoggedInPath = () => {
    const pathName = window.location.pathname.toLowerCase();
    const noLoginRequiredPages = [
      ROUTES.CALLBACK.toLowerCase(),
      ROUTES.CHECK_AUTH.toLowerCase(),
      ROUTES.FAQ.toLowerCase(),
      ROUTES.MEDICA_STANDINGS_PUBLIC.toLowerCase(),
      ROUTES.CRISIS_JOIN_SIMULATION_PREAMBLE.toLowerCase(),
      ROUTES.MEDICA_BAD_LINK.toLowerCase(),
      ROUTES.CRISIS_BAD_LINK.toLowerCase(),
      ROUTES.STUDENT_FAQ.toLowerCase(),
    ];
    return !noLoginRequiredPages.includes(pathName);
  };

  setLanguage = () => {
    const { setUserLanguage, user } = this.props;
    const { language } = user;
    const localLanguage = getStorage(LOCALSTORAGE.localLanguage);
    let bestLanguageFit = null;

    if (language) {
      if (CONSTANT.LANGUAGES_LIST.includes(language.slice(0, 2))) {
        bestLanguageFit = language.slice(0, 2);
      } else {
        bestLanguageFit = CONSTANT.LANGUAGES_LIST[0];
      }
    } else {
      if (localLanguage) {
        bestLanguageFit = localLanguage;
      } else {
        const browserLang = navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2);
        if (CONSTANT.LANGUAGES_LIST.includes(browserLang)) {
          bestLanguageFit = browserLang;
        } else {
          bestLanguageFit = CONSTANT.LANGUAGES_LIST[0];
        }
      }
    }

    this.setChatLanguage(bestLanguageFit);
    processLanguageAndSave(bestLanguageFit, setUserLanguage);
  };

  render() {
    const pathName = window.location.pathname;
    const backgroundImage = changeBackgroundImage(pathName);
    let routeNavigator = null;

    if (cookiesEnabled) {
      routeNavigator = <Navigation />;
    } else {
      routeNavigator = <CookiesDisabledScreen />;
    }

    return (
      <div>
        <img className="appBackground" src={backgroundImage} alt="Background" />
        {routeNavigator}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      fullName: state.user.userProfile.fullName,
      firstName: state.user.userProfile.firstName,
      lastName: state.user.userProfile.lastName,
      email: state.user.userProfile.email,
      institution: state.user.userProfile.institution,
      language: state.user.userProfile.language,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(isLoggedIn()),
  login: () => dispatch(login()),
  setUserLanguage: (lang) => dispatch(setUserLanguage(lang)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(App));
