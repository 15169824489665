import React from "react";
import { Image, Button } from "semantic-ui-react";
import gearIcon from "../../assets/gear-icon.svg";
import "./purchaseMedicaStyles.css";

const AdvancedOptionsComponent = (props) => {
  return (
    <Button onClick={props.advancedOptionsClick} id="whiteAndBlueButton">
      <Image className="ui top aligned tiny image" src={gearIcon} id="imageButton" />
      {props.title}
    </Button>
  );
};

export default AdvancedOptionsComponent;
