import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Grid, Image } from "semantic-ui-react";
import i18n from "i18next";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import HeaderComponent from "../header/HeaderComponent";
import RunAgainComponent from "./RunAgainComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import InformativeParagraph from "./InformativeParagraph";
import BackButton from "../helpers/BackButton";
import RedirectModal from "../modals/RedirectModal";
import ErrorModal from "../modals/ErrorModal";
import MedicaAdvancedOptions from "../medicaAdvancedOptions";
import medicaLogo from "../../assets/medica-logo-transparent.png";
import { getProfessorScenarios, runAgainSimulation, resetRunAgainStore } from "../../store/Medica/actions";
import { getStorage, setStorage } from "../../services/StorageService";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { getLanguagePrefix } from "../utilities/LanguageUtilities";
import "../purchasedMedica/purchaseMedicaStyles.css";

class RunAgainScreen extends Component {
  state = {
    openErrorModal: false,
    openAdvancedOptions: false,
    openIntroductionModal: false,
    simulationParameters: {
      managedProgress: this.props.location.state?.simulation?.professorManagedProgress ?? false,
    },
    advancedOptions: {
      templateName: CONSTANT.SIMULATION_DEFAULT_TEMPLATE,
      addVariation: false,
      addSuggestions: false,
    },
  };

  componentDidMount() {
    this.getProfessorScenarios();
    this.firstTimeShowIntroductionModal();
  }

  firstTimeShowIntroductionModal = () => {
    const initialMessageWasShown = getStorage(LOCALSTORAGE.secondRunWarningWasShown);
    if (!initialMessageWasShown) {
      this.handleOpenCloseIntroductionModal();
      setStorage(LOCALSTORAGE.secondRunWarningWasShown, true);
    }
  };

  handleOpenCloseIntroductionModal = () => {
    this.setState({ openErrorModal: !this.state.openIntroductionModal });
  };

  getProfessorScenarios = () => {
    this.props.getProfessorScenarios();
  };

  handleContinue = (postData) => {
    const { language, runAgainSimulation } = this.props;
    const languagePrefix = getLanguagePrefix(language);
    if (postData) {
      Object.assign(postData, {
        UserLanguage: languagePrefix,
      });
      runAgainSimulation(postData);
    }
  };

  advancedOptionsClick = () => {
    this.setState({
      openAdvancedOptions: !this.state.openAdvancedOptions,
    });
  };

  advancedOptionsChanged = (optionId, value) => {
    const newAdvancedOptions = {
      ...this.state.advancedOptions,
      [optionId]: value,
    };
    this.setState({
      advancedOptions: newAdvancedOptions,
    });
  };

  componentWillUnmount() {
    this.props.resetRunAgainStore();
  }

  onSetSimulationParameters(parameterName, value) {
    const newSimulationParameters = {
      ...this.state.simulationParameters,
      [parameterName]: value,
    };

    this.setState({
      simulationParameters: newSimulationParameters,
    });
  }

  render() {
    const translate = this.props.t;
    const language = this.props.language;
    const { loadingRunAgain, runAgainError, runAgainSuccessfully } = this.props.runAgain;
    const { openAdvancedOptions, advancedOptions } = this.state;
    const { managedProgress } = this.state.simulationParameters;
    const { customScenarios, loadingProfessorScenarios } = this.props.medica;
    const { openIntroductionModal } = this.state;

    let loader = null;
    let messagesComponent = null;
    let navigationButton = null;
    let runAgainComponent = null;
    let backButton = null;
    let medicaAdvancedOptions = null;

    const currentSimulation = this.props.location.state.simulation;
    const parentSimulation = {
      name: currentSimulation.name,
      id: currentSimulation.id,
      currentRun: currentSimulation.currentRun,
    };

    runAgainComponent = (
      <RunAgainComponent
        ref="runAgainComponent"
        onSetSimulationParameters={this.onSetSimulationParameters.bind(this)}
        parentSimulation={parentSimulation}
        isProfessorManagedProgress={managedProgress}
        translate={translate}
        handleContinue={this.handleContinue}
        advancedOptionsClick={this.advancedOptionsClick}
        advancedOptions={advancedOptions}
        language={language}
      />
    );

    if (loadingRunAgain) {
      loader = (
        <Grid.Column width={16}>
          <LoadingComponent message={translate("creatingSimulation")} />
        </Grid.Column>
      );
    } else {
      navigationButton = (
        <Grid.Column width={16}>
          <div className="bottomArea">
            <button className="ui basic button" form="run-again-form" type="submit">
              {translate("continue")}
            </button>
          </div>
        </Grid.Column>
      );
    }

    let errorMessage = "";

    if (runAgainError !== "") {
      const translateExists = i18n.exists(`RunAgainScreen:${runAgainError}`);

      if (translateExists) {
        errorMessage = `RunAgainScreen:${runAgainError}`;
      } else {
        errorMessage = runAgainError;
      }

      messagesComponent = <MessagesComponent message={translate(errorMessage)} />;
    }

    const redirectSimulationModal = (
      <RedirectModal
        open={runAgainSuccessfully}
        translate={translate}
        history={this.props.history}
        title={translate("simulationAddedSuccessfully")}
        message={translate("RunAgainScreen:addedSuccessfully")}
        route={ROUTES.HOME}
      />
    );

    if (this.props.role === CONSTANT.ROLES.PROFESSOR) {
      backButton = <BackButton translate={translate} history={this.props.history} />;
    }

    const introductionModal = (
      <ErrorModal
        openErrorModal={openIntroductionModal}
        handleErrorModal={this.handleOpenCloseIntroductionModal}
        title={translate("Common:warning")}
        message={translate("secondRunWarningMessage")}
        warning={true}
        translate={translate}
      />
    );

    if (openAdvancedOptions && !loadingProfessorScenarios) {
      medicaAdvancedOptions = (
        <MedicaAdvancedOptions
          customScenarios={customScenarios}
          advancedOptions={advancedOptions}
          advancedOptionsChanged={this.advancedOptionsChanged}
          hideSuggestionsToggle={managedProgress}
        />
      );
    }

    return (
      <Container>
        {introductionModal}
        {redirectSimulationModal}
        <HeaderComponent translate={translate} />
        <Grid className="customGrid">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.MEDICA_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.MEDICA_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column className="formColumn runAgainColumn" mobile={16} computer={8} tablet={10}>
            <InformativeParagraph
              paragraphOne={translate("secondRunParagraphPartOne")}
              paragraphTwo={translate("secondRunParagraphPartTwo")}
            />
            {runAgainComponent}
          </Grid.Column>
          <Grid.Column className="logoColumn" only="tablet computer" computer={8} tablet={6}>
            <Image className="medicaLogo" src={medicaLogo} />
          </Grid.Column>
        </Grid>
        <Grid>
          {medicaAdvancedOptions}
          <Grid.Row>
            {messagesComponent}
            {loader}
            {navigationButton}
            {backButton}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProfessorScenarios: () => dispatch(getProfessorScenarios()),
  runAgainSimulation: (data) => dispatch(runAgainSimulation(data)),
  resetRunAgainStore: () => dispatch(resetRunAgainStore()),
});

const mapStateToProps = (state) => {
  return {
    role: state.user.userProfile.role,
    language: state.user.userProfile.language,
    medica: {
      loadingProfessorScenarios: state.medica.loadingProfessorScenarios,
      getProfessorScenariosError: state.medica.getProfessorScenariosError,
      customScenarios: state.medica.customScenarios,
    },
    runAgain: {
      loadingRunAgain: state.medica.loadingRunAgain,
      runAgainError: state.medica.runAgainError,
      runAgainSuccessfully: state.medica.runAgainSuccessfully,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("Purchased", "ModifyModal", "Common")(withRouter(RunAgainScreen))
);
