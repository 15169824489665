import React from "react";
import { Form, Checkbox } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import HelpHoverComponent from "./HelpHoverComponent";
import "./input.css";

const InputSliderComponent = (props) => {
  const {
    id,
    label,
    minValue,
    maxValue,
    minValueLabel,
    maxValueLabel,
    value,
    valueLabel,
    helpMessage,
    onChangeInputRange,
    numberAddition,
    numberSubtraction,
    toggleLabel,
    toggleId,
    onChangeInputToggle,
    step,
  } = props;

  const onChangeRange = (event) => {
    const name = event.target.id;
    const value = Number(event.target.value);
    const rangeChanged = {
      [name]: value,
    };
    onChangeInputRange(rangeChanged);
  };

  const onChangeToggle = (event) => {
    const name = event.target.id;
    const value = event.target.checked;
    const rangeChanged = {
      [name]: value,
    };
    onChangeInputToggle(rangeChanged);
  };

  let descriptionLabel = null;
  let inputSection = null;
  let helpHoverComponent = null;
  let toggle = null;
  if (helpMessage && helpMessage.length > 0) {
    helpHoverComponent = <HelpHoverComponent helpMessage={helpMessage} />;
  }

  if (toggleLabel && toggleLabel.length > 0) {
    toggle = (
      <div className="toggleSelection">
        <label>{toggleLabel}</label>
        <Checkbox id={toggleId} toggle onChange={onChangeToggle} />
      </div>
    );
  }

  if (label) {
    let labelContainerClassNames = "labelContainer";
    if (toggleLabel) {
      labelContainerClassNames += " heightGrow";
    }

    descriptionLabel = (
      <div className="sliderLabelContainer">
        <div className={labelContainerClassNames}>
          <label className="specialLabel">
            {label}
            {helpHoverComponent}
          </label>

          {toggle}
        </div>
        <div className="sliderControls">
          <div className="buttonControl">
            <p>{minValueLabel}</p>
            <button onClick={() => numberSubtraction(id)}>
              <FontAwesomeIcon icon={faMinus} fixedWidth color="#0379ba" />
            </button>
          </div>
          <p>{valueLabel}</p>
          <div className="buttonControl">
            <p>{maxValueLabel}</p>
            <button onClick={() => numberAddition(id)}>
              <FontAwesomeIcon icon={faPlus} fixedWidth color="#0379ba" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  inputSection = (
    <div className="inputSliderContainer">
      <input
        id={id}
        className="inputSlider"
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        step={step}
        onChange={onChangeRange}
      />
    </div>
  );

  return (
    <Form.Field>
      {descriptionLabel}
      {inputSection}
    </Form.Field>
  );
};
export default InputSliderComponent;
