import React from "react";
import "../modals/modals.css";
import { withTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

export const AddVariationInformationModal = (props) => {
  const translate = props.t;

  let suggestionsText = <p>{translate("MedicaAdvancedOptions:addVariationInformationModalBody3")}</p>;

  if (props.showSuggestionsInformation) {
    suggestionsText = (
      <>
        <h4>{translate("MedicaAdvancedOptions:addSuggestionsInformationModalSubtitle")}</h4>
        <p>{translate("MedicaAdvancedOptions:addSuggestionsInformationModalBody")}</p>
        <p>{translate("MedicaAdvancedOptions:addVariationInformationModalBody2")}</p>
      </>
    );
  }

  return (
    <Modal size="small" open={props.opened} onClose={props.handleSecondaryButton}>
      <Modal.Header>{translate("MedicaAdvancedOptions:addVariationInformationModalTitle")}</Modal.Header>
      <Modal.Content>
        <h4>{translate("MedicaAdvancedOptions:newFeaturesInRegularAndEasyScenarioSubtitle")}</h4>
        <p>{translate("MedicaAdvancedOptions:newFeaturesInRegularAndEasyScenarioBody")}</p>
        <h4>{translate("MedicaAdvancedOptions:addVariationInformationModalSubtitle")}</h4>
        <p>{translate("MedicaAdvancedOptions:addVariationInformationModalBody1")}</p>
        {suggestionsText}
      </Modal.Content>
      <Modal.Actions>
        <Button content={translate("MedicaAdvancedOptions:neverShowThisAgain")} onClick={() => props.close(true)} />
        <Button content={translate("Common:close")} onClick={() => props.close()} />
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation("MedicaAdvancedOptions", "Common")(AddVariationInformationModal);
