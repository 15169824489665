import React, { Component } from "react";
import { Grid, Form } from "semantic-ui-react";
import DefaultButton from "../helpers/DefaultButton";
import NewScenarioFormItems from "./NewScenarioFormItems";
import {
  createInitialFormItemsState,
  getItemOperationData,
  getOperationValue,
} from "../utilities/ScenarioInformationUtilities";

class CreateNewScenarioComponent extends Component {
  constructor(props) {
    super(props);

    const initialFormItemsState = createInitialFormItemsState();

    this.state = {
      ...initialFormItemsState,
      nameError: false,
      descriptionError: false,
      seedError: false,
      inputTextError: "",
    };
  }

  onChangeInputRange = (rangeChanged) => {
    this.setState(rangeChanged);
  };

  onChangeInputToggle = (toggleChanged) => {
    this.setState(toggleChanged);
  };

  numberAddition = (itemId) => {
    const item = getItemOperationData(itemId);
    const operationValue = getOperationValue(itemId);

    this.setState((state, props) => {
      if (state[itemId] < item.maxValue) {
        state[itemId] = state[itemId] + operationValue;
      }
      return state;
    });
  };

  numberSubtraction = (itemId) => {
    const item = getItemOperationData(itemId);
    const operationValue = getOperationValue(itemId);

    this.setState((state, props) => {
      if (state[itemId] > item.minValue) {
        state[itemId] = state[itemId] - operationValue;
      }
      return state;
    });
  };

  setRamdomValue = (itemId) => {
    const splitedId = itemId.split("_");
    const itemName = splitedId[1];
    const item = getItemOperationData(itemId);
    const min = item.minValue;
    const max = item.maxValue;
    const random = Math.floor(Math.random() * (max - min)) + min;

    this.setState({
      [itemId]: random,
      [`${itemName}Error`]: false,
    });
  };

  onChangeInputText = (itemId, data) => {
    const splitedId = itemId.split("_");
    const itemName = splitedId[1];

    this.setState({
      [itemId]: data,
      [`${itemName}Error`]: false,
    });
  };

  validateCreateNewScenarioForm = () => {
    const { handleCreateScenarioModal } = this.props;
    const state = this.state;
    const formItemsStateKeys = Object.keys(state);
    const formDataPost = {};

    formItemsStateKeys.forEach((itemKey) => {
      const isFormItem = itemKey.includes("_");

      if (isFormItem) {
        const splitedItem = itemKey.split("_");
        const itemName = splitedItem[1];

        formDataPost[itemName] = state[itemKey];
      }
    });

    const inputsTextPreFilled = this.validateInputsTextPreFilled(formDataPost);

    if (inputsTextPreFilled) {
      handleCreateScenarioModal(formDataPost);
    }
  };

  validateInputsTextPreFilled = (formDataPost) => {
    const formItemsRefs = this.refs.formItems.refs;
    let inputsTextPreFilled = false;

    const nameFilled = this.validateInputName(formDataPost, formItemsRefs);
    const descriptionFilled = this.validateInputDescription(formDataPost, formItemsRefs);
    const seedBetweenRanges = this.validateInputSeed(formDataPost, formItemsRefs);

    if (nameFilled && descriptionFilled && seedBetweenRanges) {
      const escenarioNameExist = this.validateScenarioNameExist(formDataPost.name);

      if (escenarioNameExist) {
        window.scrollTo(0, formItemsRefs.name.offsetTop);
        inputsTextPreFilled = false;
        this.setState({
          nameError: true,
          inputTextError: "scenarioNameAlreadyExist",
        });
      } else {
        inputsTextPreFilled = true;
      }
    }
    return inputsTextPreFilled;
  };

  validateInputName = (formDataPost, formItemsRefs) => {
    let nameFilled = true;

    if (formDataPost.name.length === 0) {
      window.scrollTo(0, formItemsRefs.name.offsetTop);
      this.setState({
        nameError: true,
        inputTextError: "required",
      });

      nameFilled = false;
    }

    return nameFilled;
  };

  validateInputDescription = (formDataPost, formItemsRefs) => {
    let descriptionFilled = true;

    if (formDataPost.description.length === 0) {
      window.scrollTo(0, formItemsRefs.description.offsetTop);
      this.setState({
        descriptionError: true,
        inputTextError: "required",
      });

      descriptionFilled = false;
    }

    return descriptionFilled;
  };

  validateInputSeed = (formDataPost, formItemsRefs) => {
    let seedBetweenRanges = true;

    if (formDataPost.seed < 0 || formDataPost.seed > 20000) {
      window.scrollTo(0, formItemsRefs.seed.offsetTop);
      this.setState({
        seedError: true,
        inputTextError: "seedMustBeBetweenZeroAndTwentyThousand",
      });

      seedBetweenRanges = false;
    }

    return seedBetweenRanges;
  };

  validateScenarioNameExist = (scenarioName) => {
    const { professorScenarioList } = this.props;

    if (professorScenarioList) {
      const scenario = professorScenarioList.find((scenario) => scenario.name === scenarioName);
      return scenario;
    } else {
      return false;
    }
  };

  render() {
    const { translate } = this.props;
    const state = this.state;

    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column tablet={8} computer={8} mobile={16}>
            <Form className="createNewScenarioForm">
              <NewScenarioFormItems
                ref="formItems"
                translate={translate}
                state={state}
                onChangeInputToggle={this.onChangeInputToggle}
                onChangeInputText={this.onChangeInputText}
                onChangeInputRange={this.onChangeInputRange}
                numberAddition={this.numberAddition}
                numberSubtraction={this.numberSubtraction}
                setRamdomValue={this.setRamdomValue}
              />
              <div className="submitButtonContainer">
                <DefaultButton title={translate("createNewScenario")} action={this.validateCreateNewScenarioForm} />
              </div>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default CreateNewScenarioComponent;
