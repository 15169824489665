export const CHECK_STORAGE_INFORMATION = "SimunationQuestionnaire/CHECK_STORAGE_INFORMATION";
export const UPDATE_INSTITUTION_STUDENTS_NUMBER = "SimunationQuestionnaire/UPDATE_INSTITUTION_STUDENTS_NUMBER";
export const UPDATE_SIMULATION_INFORMATION = "SimunationQuestionnaire/UPDATE_SIMULATION_INFORMATION";
export const UPDATE_TRANSACTION_DATA = "SimunationQuestionnaire/UPDATE_TRANSACTION_DATA";
export const RESET_QUESTIONNAIRE_STORE = "SimunationQuestionnaire/RESET_QUESTIONNAIRE_STORE";
export const SIMUNATION_CREATE_SIMULATION = "SimunationQuestionnaire/SIMUNATION_CREATE_SIMULATION";
export const SIMUNATION_CREATE_SIMULATION_SUCCESSFUL =
  "SimunationQuestionnaire/SIMUNATION_CREATE_SIMULATION_SUCCESSFUL";
export const SIMUNATION_CREATE_SIMULATION_FAILED = "SimunationQuestionnaire/SIMUNATION_CREATE_SIMULATION_FAILED";
export const CHECK_STORAGE_CREATED_SIMUNATION = "SimunationQuestionnaire/CHECK_STORAGE_CREATED_SIMUNATION";
export const VALIDATE_DISCOUNT_CODE = "SimunationQuestionnaire/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_SUCCESSFUL = "SimunationQuestionnaire/VALIDATE_DISCOUNT_CODE_SUCCESSFUL";
export const VALIDATE_DISCOUNT_CODE_FAILED = "SimunationQuestionnaire/VALIDATE_DISCOUNT_CODE_FAILED";
export const CONFIRM_PAYMENT_START = "SimunationQuestionnaire/CONFIRM_PAYMENT_START";
export const CONFIRM_PAYMENT_SUCCESS = "SimunationQuestionnaire/CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAIL = "SimunationQuestionnaire/CONFIRM_PAYMENT_FAIL";
export const RESET_DISCOUNT_CODE = "SimunationQuestionnaire/RESET_DISCOUNT_CODE";
