import { COLOR_PALETTE } from "../../constants/ColorPalette";
import simplify from "simplify-js";

export const GRAPHS_GROUP = {
  PERFORMANCE: "performance",
  DECISIONS: "decisions",
};

export const getGraphSections = (selectedGraphs, graphsData, selectedTeams) => {
  const structuredData = {
    graphsGroup: selectedGraphs,
    simulationName: graphsData.name,
    currentSimulationDay: graphsData.currentSimulationDay,
    sections: prepareGraphSections(selectedGraphs, graphsData),
  };

  const sectionsData = dashboadsDataGenerator(selectedTeams, structuredData);
  return sectionsData;
};

const prepareGraphSections = (selectedGraphs, sections) => {
  const graphSections = sections.map((section) => {
    let name = "";
    let graphData = {};
    switch (selectedGraphs) {
      case GRAPHS_GROUP.DECISIONS:
        name = section.sectionName;
        graphData = section.changeRequestHistories;
        break;
      default:
        name = section.name;
        graphData = section.sections;
        break;
    }

    return {
      name: name,
      graphData: prepareGraphData(selectedGraphs, graphData),
    };
  });

  return graphSections;
};

const prepareGraphData = (selectedGraphs, graphData) => {
  const formattedGraphData = graphData.map((graph) => {
    let graphName = "";
    let teamsData = [];
    switch (selectedGraphs) {
      case GRAPHS_GROUP.DECISIONS:
        graphName = graph.displayText;
        teamsData = graph.changeRequestHistoricDataPerTeam;
        break;
      default:
        graphName = graph.name;
        teamsData = graph.teamsData;
        break;
    }

    return {
      graphName: graphName,
      teamsData: prepareTeamsHistoricData(selectedGraphs, teamsData),
      units: graph.units,
    };
  });

  return formattedGraphData;
};

const prepareTeamsHistoricData = (selectedGraphs, teamsData) => {
  const teamsHistoricData = teamsData.map((teamData) => {
    let teamName = "";
    switch (selectedGraphs) {
      case GRAPHS_GROUP.PERFORMANCE:
        teamName = teamData.teamName;
        break;
      case GRAPHS_GROUP.DECISIONS:
        teamName = teamData.teamName; //es teamName pero se malogra todo
        break;
      default:
        teamName = teamData.name;
        break;
    }

    return {
      teamName: teamName,
      historicData: teamData.historicData,
    };
  });

  return teamsHistoricData;
};

export const createTeamsList = (performanceData, standings) => {
  let teams = [];
  let number = 1;
  performanceData[0].sections[0].teamsData.forEach((team, index) => {
    let teamName = team.teamName;
    teams.push({
      name: teamName,
      icon: getColorPointIcon(index).icon,
      iconName: getColorPointIcon(index).name,
      index: index,
      isChecked: true,
      standing: number++,
      color: getColorPointIcon(index).color,
    });
  });

  const sorteredTeamsList = teams.sort((a, b) => {
    const teamA = a.name.toLowerCase();
    const teamB = b.name.toLowerCase();

    if (teamA > teamB) return 1;
    if (teamA < teamB) return -1;
    return 0;
  });

  return sorteredTeamsList;
};

export const dashboadsDataGenerator = (selectedTeams, graphsData) => {
  const sections = createSectionsList(graphsData, selectedTeams);

  return sections;
};

const createSectionsList = (graphsData, selectedTeams) => {
  const sections = graphsData.sections.map((section, index) => {
    return {
      index: index,
      name: section.name,
      data: createStatusesList(graphsData.graphsGroup, section, selectedTeams),
    };
  });
  return sections;
};

const createStatusesList = (graphsGroup, section, selectedTeams) => {
  const statuses = section.graphData.map((status) => {
    const graphName = status.graphName;
    return {
      index: `${section.name}_${graphName}`,
      ...createLineValues(graphsGroup, status, selectedTeams),
      units: status.units,
      name: graphName,
    };
  });
  return statuses;
};

const createLineValues = (graphsGroup, instances, selectedTeams) => {
  let lineValues = [];
  let lineLabels = [];
  let newTeamsData = {};
  let teamsData = instances.teamsData;
  teamsData.forEach((data) => {
    for (let i = 0; i < selectedTeams.length; i++) {
      if (selectedTeams[i].name === data.teamName) {
        const historicData = formatHistoricData(graphsGroup, data.historicData);
        let simplifiedHistoricData = historicData;
        if (graphsGroup === GRAPHS_GROUP.PERFORMANCE) {
          const currentStep = simplifiedHistoricData[simplifiedHistoricData.length - 1].x;
          simplifiedHistoricData = simplifyHistoricData(historicData, currentStep, selectedTeams);
        }
        if (graphsGroup === GRAPHS_GROUP.DECISIONS) {
          simplifiedHistoricData = simplifyDecisionsData(historicData);
        }
        newTeamsData = {
          index: i,
          name: data.teamName,
          historicData: historicData,
          historicDataSimplified: simplifiedHistoricData,
          color: selectedTeams[i].color,
          icon: selectedTeams[i].iconName,
          figure: selectedTeams[i].icon,
        };
        lineValues.push(newTeamsData);
        lineLabels.push(data.teamName);
      }
    }
  });

  return {
    lineValues: lineValues,
    lineLabels: lineLabels,
  };
};

const simplifyDecisionsData = (decisionData) => {
  const simplifiedDecisionData = [];

  for (let currentData = 0; currentData < decisionData.length; currentData++) {
    const dataPoint = decisionData[currentData];

    if (dataPoint.x === 0) {
      simplifiedDecisionData.push(dataPoint);
      continue;
    }

    if (
      dataPoint.y !== simplifiedDecisionData[simplifiedDecisionData.length - 1].y ||
      dataPoint === decisionData[decisionData.length - 1]
    ) {
      simplifiedDecisionData.push(decisionData[currentData - 1]);
      simplifiedDecisionData.push(dataPoint);
    }
  }

  return simplifiedDecisionData;
};

export const formatHistoricData = (graphsGroup, historicData) => {
  const formattedHistoricData = historicData.map((data) => {
    if (graphsGroup === GRAPHS_GROUP.PERFORMANCE) {
      return {
        x: data.step,
        y: data.value,
      };
    } else if (graphsGroup === GRAPHS_GROUP.DECISIONS) {
      return {
        x: data.day,
        y: data.value,
      };
    }
    //Shouldn't Happen
    return {}
  });

  return formattedHistoricData;
};

const simplifyHistoricData = (historicData, currentSimulationDay, selectedTeams) => {
  let simplifiedData = [];
  const willSimplifyData = shouldSimplifyData(currentSimulationDay, selectedTeams);
  if (willSimplifyData) {
    const tolerance = toleranceCalculator(currentSimulationDay, selectedTeams);
    simplifiedData = simplify(historicData, tolerance, false);
  } else {
    simplifiedData = historicData;
  }
  return simplifiedData;
};

const shouldSimplifyData = (currentSimulationDay, selectedTeams) => {
  const pointsQuantity = currentSimulationDay * selectedTeams.length;
  const minQuantityPointsRender = 7200;
  if (pointsQuantity > minQuantityPointsRender) {
    return true;
  }
  return false;
};

const toleranceCalculator = (currentSimulationDay, selectedTeams) => {
  const pointsQuantity = currentSimulationDay * selectedTeams.length;
  let tolerance = null;
  if (pointsQuantity > 7200 && pointsQuantity < 14201) {
    tolerance = 1.5;
  } else if (pointsQuantity > 14200 && pointsQuantity < 21201) {
    tolerance = 2.5;
  } else if (pointsQuantity > 21200 && pointsQuantity < 28201) {
    tolerance = 3.5;
  } else if (pointsQuantity > 28200 && pointsQuantity < 35201) {
    tolerance = 4.5;
  } else {
    tolerance = 5;
  }

  return tolerance;
};

const getColorPointIcon = (index) => {
  let icon = "●";
  let name = "circle";
  let color = COLOR_PALETTE[index];
  if (index > 19 && index < 40) {
    icon = "■";
    name = "rect";
    color = COLOR_PALETTE[index - 20];
  } else if (index > 39 && index < 60) {
    icon = "▲";
    name = "triangle";
    color = COLOR_PALETTE[index - 40];
  } else if (index > 59 && index < 80) {
    icon = "★";
    name = "star";
    color = COLOR_PALETTE[index - 60];
  }
  return {
    icon: icon,
    name: name,
    color: color,
  };
};

export const abbreviateNumber = (value) => {
  const isNegative = value < 0;
  let formattedNumber = value;
  if (isNegative) formattedNumber = value * -1;
  const suffixList = ["", "k", "M", "B", "T"];
  const prefixIndex = (Math.log10(formattedNumber) / 3) | 0;

  if (prefixIndex === 0) return value;
  const suffix = suffixList[prefixIndex];
  const scale = Math.pow(10, prefixIndex * 3);
  const scaled = formattedNumber / scale;

  let fixedScaled = scaled.toFixed(2) + suffix;
  if (Number.isInteger(scaled)) fixedScaled = scaled.toFixed(0) + suffix;

  if (isNegative) return "-" + fixedScaled;
  return fixedScaled;
};

export const getModalPlotDatasets = (plot, selectedTeams, translate) => {
  let datasets = [];
  let lineObj = {};
  let pointsAndLabels = null;
  let filteredPlot = [];
  plot.lineValues.forEach((line) => {
    const showLine = selectedTeams[line.index].isChecked;
    pointsAndLabels = getDataPoints(line.historicData);
    if (showLine || statusAlwaysPresent(plot.name)) {
      lineObj = {
        fillColor: line.color,
        data: pointsAndLabels.points,
        fill: false,
        borderColor: line.color,
        borderWidth: getLineWidth(selectedTeams),
        pointRadius: getLineWidth(selectedTeams),
        pointHoverRadius: getLineWidth(selectedTeams),
        pointBackgroundColor: line.color,
        pointStyle: line.icon,
      };
      datasets.push(lineObj);
      filteredPlot.push(line);
    }
  });
  return {
    datasets: datasets,
    labels: pointsAndLabels.labels,
    options: getModalPlotOptions(plot, filteredPlot, translate),
  };
};

const getModalPlotOptions = (plot, filteredPlot, translate) => {
  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    title: {
      display: true,
      text: translate(plot.name),
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontColor: "black",
        defaultFontFamily: "Chivo",
        boxWidth: 5,
        usePointStyle: true,
      },
      fullWidth: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return abbreviateNumber(value);
            },
            beginAtZero: !(plot.name === "Cash On Hand" || plot.name === "Cash"),
          },
          scaleLabel: {
            display: true,
            labelString: translate(plot.units),
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: translate("day"),
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        let tooltipElement = document.getElementById("chartjs-tooltip");

        if (!tooltipElement) {
          tooltipElement = document.createElement("div");
          tooltipElement.id = "chartjs-tooltip";
          tooltipElement.innerHTML = "<table></table>";
          document.body.appendChild(tooltipElement);
        }

        if (tooltipModel.opacity === 0) {
          const showTooltip = () => {
            tooltipElement.style.display = "block";
          };

          const hideTooltip = () => {
            tooltipElement.style.display = "none";
          };

          const hideTooltipTimer = () => {
            setTimeout(() => {
              tooltipElement.style.display = "none";
            }, 500);
          };

          tooltipElement.onmouseover = showTooltip;
          tooltipElement.onmousedown = hideTooltip;
          tooltipElement.onmouseleave = hideTooltipTimer;

          return;
        }

        tooltipElement.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipElement.classList.add(tooltipModel.yAlign);
        } else {
          tooltipElement.classList.add("no-transform");
        }

        if (tooltipModel.body) {
          let titleLines = tooltipModel.title || [];
          let innerHtml = "<thead>";

          titleLines.forEach((title) => {
            const titleStyle = "font-weight: 900;";
            innerHtml += `<tr><th style="${titleStyle}">${translate("day")} ${title}</th></tr>`;
          });

          innerHtml += "</thead><tbody>";

          tooltipModel.dataPoints.forEach((point) => {
            const datasetIndex = point.datasetIndex;
            const teamIcon = filteredPlot[datasetIndex].figure;
            const teamName = filteredPlot[datasetIndex].name;
            const teamColor = filteredPlot[datasetIndex].color;
            const value = point.yLabel.toFixed(2);
            const itemStyle = "font-weight: 500; display: block; min-width: 40px";
            const iconStyle = `color:${teamColor}; font-size:18px;`;
            innerHtml += `<tr><td style="${itemStyle}"><span style="${iconStyle}">${teamIcon}</span> ${teamName} ${value}</td></tr>`;
          });
          innerHtml += "</tbody>";

          let tableRoot = tooltipElement.querySelector("table");
          tableRoot.innerHTML = innerHtml;
        }

        let bottomAjustment = 1;
        let position = this._chart.canvas.getBoundingClientRect();

        if (tooltipModel.dataPoints.length > 1) {
          bottomAjustment = 0.8;
        }

        tooltipElement.style.display = "block";
        tooltipElement.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipElement.style.top = position.top + window.pageYOffset + tooltipModel.caretY * bottomAjustment + "px";
        tooltipElement.style.fontFamily = "Chivo";
        tooltipElement.style.fontSize = tooltipModel.bodyFontSize + "px";
        tooltipElement.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipElement.style.padding = "5px";
        tooltipElement.style.maxHeight = "185px";
        tooltipElement.style.width = "auto";
        tooltipElement.style.overflow = "auto";
        tooltipElement.style.border = "2px solid #272727";
        tooltipElement.style.position = "absolute";
        tooltipElement.style.zIndex = 10000;
        tooltipElement.style.backgroundColor = "#FFF";
      },
    },
  };

  return options;
};

const getDataPoints = (historicData) => {
  let points = [];
  let labels = [];
  historicData.forEach((point) => {
    labels.push(point.x);
    points.push(point.y);
  });
  return {
    points: points,
    labels: labels,
  };
};

export const statusAlwaysPresent = (plotName) => {
  if (plotName === "Standard Market Price" || plotName === "Custom Demand") {
    return true;
  }
  return false;
};

const getLineWidth = (selectedTeams) => {
  const teamsQuantity = selectedTeams && selectedTeams.filter((team) => team.isChecked).length;

  let lineWidth = 1.4;
  if (teamsQuantity > 20 && teamsQuantity < 41) {
    lineWidth = 1.2;
  }
  if (teamsQuantity > 40 && teamsQuantity < 61) {
    lineWidth = 1;
  }
  if (teamsQuantity > 60) {
    lineWidth = 0.6;
  }

  return lineWidth;
};

export const checkControlStanding = (standings) => {
  const findControl = standings.filter((standing) => standing.team === "CONTROL");
  const controlStanding = findControl[0].standing;
  return controlStanding;
};
