import React, { Component } from "react";
import { Container, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import ProfileDetailComponent from "./ProfileDetailComponent";
import LanguageSelector from "../helpers/LanguageSelector";
import RequestFeatureModal from "../modals/RequestFeatureModal";
import { requestFeature, resetRequestFeatureStore } from "../../store/Profile/actions";
import profileImage from "../../assets/profileIcon.png";
import "./profileStyles.css";

class ProfileScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openRequestFeatureModal: false,
    };
  }

  handleRequestFeatureModal = () => {
    this.setState((state, props) => ({
      openRequestFeatureModal: !state.openRequestFeatureModal,
    }));
  };

  render() {
    const { t, user, requestFeature, requestFeatureData, resetRequestFeatureStore } = this.props;
    const { openRequestFeatureModal } = this.state;
    const translate = t;
    let requestFeatureModal = null;

    if (openRequestFeatureModal) {
      requestFeatureModal = (
        <RequestFeatureModal
          translate={translate}
          openRequestFeatureModal={openRequestFeatureModal}
          handleRequestFeatureModal={this.handleRequestFeatureModal}
          requestFeature={requestFeature}
          requestFeatureData={requestFeatureData}
          resetRequestFeatureStore={resetRequestFeatureStore}
        />
      );
    }

    return (
      <>
        {requestFeatureModal}
        <Container>
          <HeaderComponent translate={translate} showCompleteMenu={true} />
          <MainTitleComponent title={translate("Common:profile")} />
          <div className="profileFrame">
            <ProfileDetailComponent
              translate={translate}
              user={user}
              requestFeature={requestFeature}
              requestFeatureData={requestFeatureData}
              resetRequestFeatureStore={resetRequestFeatureStore}
              openRequestFeatureModal={openRequestFeatureModal}
              handleRequestFeatureModal={this.handleRequestFeatureModal}
            />
            <div className="profileIconContainer">
              <Image className="profileImage" src={profileImage} />
            </div>
          </div>
        </Container>
        <LanguageSelector />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestFeature: (featureData) => dispatch(requestFeature(featureData)),
  resetRequestFeatureStore: () => dispatch(resetRequestFeatureStore()),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      fullName: state.user.userProfile.fullName,
      email: state.user.userProfile.email,
      institution: state.user.userProfile.institution,
      language: state.user.userProfile.language,
    },
    requestFeatureData: {
      loadingRequestFeature: state.profile.loadingRequestFeature,
      requestFeatureSuccess: state.profile.requestFeatureSuccess,
      requestFeatureMessage: state.profile.requestFeatureMessage,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("ProfileScreen", "Common")(withRouter(ProfileScreen))
);
