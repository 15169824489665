import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";

function WelcomePopup(props) {
  const { open, handleModal, t } = props;

  return (
    <Modal open={open} onClose={handleModal}>
      <Modal.Header>{t("HomeScreen:WelcomeModalTitle")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>{t("HomeScreen:WelcomeModalBody1")}</p>
          <br />
          <p>{t("HomeScreen:WelcomeModalBody2")}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleModal(true)}>{t("HomeScreen:WelcomeModalNeverShowAgain")}</Button>
        <Button onClick={() => handleModal(false)}>{t("Common:close")}</Button>
        <Button as={Link} to={"/Videos"} exact>
          {t("HomeScreen:WelcomeModalGoToVideos")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withTranslation("HomeScreen", "Common")(WelcomePopup);
