import * as actions from "./action-types";
import { getStorage, setStorage, mergeDataToStorage } from "../../services/StorageService";
import { sentryReportException } from "../../services/SentryReportService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as medicaQuestionnaireService from "../../services/MedicaQuestionnaireService";
import * as FETCHAPI from "../../constants/FetchApi.js";
import * as actionTypes from "./action-types";
import { sendPostRequest } from "../../services/FetchService";

export const checkStorageInformation = () => {
  return async function (dispatch) {
    const cachedQuestionnaireInformation = getStorage(LOCALSTORAGE.medicaQuestionnaire);
    if (cachedQuestionnaireInformation) {
      dispatch({
        type: actions.CHECK_STORAGE_INFORMATION,
        payload: cachedQuestionnaireInformation,
      });
    }
    return true;
  };
};

export const cacheMedicaQuestionnaireData = (data) => {
  return async function (dispatch) {
    setStorage(LOCALSTORAGE.medicaQuestionnaire, data);
    dispatch({ type: actions.UPDATE_INSTITUTION_STUDENTS_NUMBER, payload: data });
  };
};

export const updateSimulationInformation = (data) => {
  return async function (dispatch) {
    dispatch({ type: actions.UPDATE_SIMULATION_INFORMATION, payload: data });
    return true;
  };
};

export const updateTransactionData = (data) => {
  return async function (dispatch) {
    dispatch({ type: actions.UPDATE_TRANSACTION_DATA, payload: data });
  };
};

export const validateDiscountCode = (discountCode) => {
  return async function (dispatch) {
    dispatch({ type: actions.VALIDATE_DISCOUNT_CODE });
    const discountCodeResponse = medicaQuestionnaireService.validateDiscountCode(discountCode);
    discountCodeResponse
      .then((response) => {
        if (response.data) {
          const data = medicaQuestionnaireService.prepareCustomResponse(
            discountCode.code,
            response,
            actions.VALIDATE_DISCOUNT_CODE
          );
          mergeDataToStorage(LOCALSTORAGE.medicaQuestionnaire, { discountCode: data.payload });
          dispatch(data);
        } else {
          dispatch({
            type: actions.VALIDATE_DISCOUNT_CODE_FAILED,
            message: "errorValidatingDiscountCode",
          });
        }
      })
      .catch((error) => {
        if (error) {
          const sentryTag = {
            name: "discountCodeFailed",
            data: discountCode.code,
          };
          sentryReportException(error, sentryTag);
          dispatch({
            type: actions.VALIDATE_DISCOUNT_CODE_FAILED,
            message: "errorValidatingDiscountCode",
          });
        }
      });
  };
};

export const medicaConfirmPayment = (paymentIntentId) => {
  return async function (dispatch) {
    dispatch({ type: actions.CONFIRM_PAYMENT_START });
    try {
      const response = await sendPostRequest(FETCHAPI.MEDICA_CONFIRM_PAYMENT, { paymentIntentId });
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CONFIRM_PAYMENT_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CONFIRM_PAYMENT_SUCCESS, payload: response.data.payload });
    } catch (error) {
      dispatch({ type: actionTypes.CONFIRM_PAYMENT_FAIL, payload: error });
    }
  };
};

export const medicaResetDiscountCode = () => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.RESET_DISCOUNT_CODE });
  };
};

export const resetMedicaQuestionnaireStore = () => {
  return async function (dispatch) {
    dispatch({
      type: actions.RESET_QUESTIONNAIRE_STORE,
    });
  };
};
