import React, { Component } from "react";
import { Button, Message, Modal } from "semantic-ui-react";
import LoadingComponent from "../helpers/LoadingComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import "./modals.css";

class DocumentsModal extends Component {
  state = {
    selectedLanguage: "English",
  };

  setDocumentsLanguage = (language) => {
    this.setState({ selectedLanguage: language });
  };

  render() {
    const { translate, openDocumentsModal, handleDocumentsModal, simulationDocumentation } = this.props;
    const { selectedLanguage } = this.state;

    let documentsLanguages = simulationDocumentation.documentation.map((x) => ({
      text: translate(`Common:${x.language}`),
      value: x.language,
    }));

    let documentsList = simulationDocumentation.documentation
      .filter((data) => {
        return data.language === selectedLanguage;
      })
      .map((x) => x.documents)
      .flat()
      .map((document, index) => ({ ...document, key: index }));

    let fileLinkRender = null;
    if (documentsList.length > 0) {
      fileLinkRender = documentsList.map((file) => {
        return (
          <div key={file.key} className="itemDocument">
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <img alt="file.url" src={require(`../../assets/extentionsImages/${file.type}.svg`)} />
              <p className="itemDocumentLabel">{file.displayName}</p>
            </a>
          </div>
        );
      });
    }

    let modalBody = (
      <>
        <div className="documentLanguageSelector">
          <div style={{ width: "40%" }}>
            <DropDownComponent
              id={"LanguagesDropdown"}
              options={documentsLanguages}
              onSelection={this.setDocumentsLanguage}
              value={selectedLanguage}
            />
          </div>
        </div>
        <div className="itemsDocumentContent">{fileLinkRender}</div>
      </>
    );

    if (simulationDocumentation.loading) {
      modalBody = <LoadingComponent />;
    }

    const showCreatingDocumentationWarning =
      simulationDocumentation.errorMessage === "documentationCurrentlyBeingCreated" ||
      (simulationDocumentation.success === true && simulationDocumentation.documentation.length === 0);

    if (simulationDocumentation.errorMessage !== "" || showCreatingDocumentationWarning) {
      const errorMessage = simulationDocumentation.errorMessage || "documentationCurrentlyBeingCreated";
      const isWarning = showCreatingDocumentationWarning;
      const isError = !showCreatingDocumentationWarning;
      modalBody = (
        <Message error={isError} warning={isWarning}>
          <Message.Header>{translate(errorMessage)}</Message.Header>
        </Message>
      );
    }

    return (
      <Modal id="documentModal" size="tiny" open={openDocumentsModal} onClose={() => handleDocumentsModal()}>
        <Modal.Header>{translate("Common:documents")}</Modal.Header>
        <Modal.Content scrolling>{modalBody}</Modal.Content>
        <Modal.Actions>
          <Button content={translate("Common:accept")} onClick={() => handleDocumentsModal()} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DocumentsModal;
