import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import ScenarioManagerComponent from "./ScenarioManagerComponent";
import DefaultButton from "../helpers/DefaultButton";
import DeleteProfessorScenarioModal from "../modals/DeleteProfessorScenarioModal";
import {
  getProfessorScenarioList,
  professorDeleteScenario,
  resetProfesorDeleteScenarioStore,
} from "../../store/Scenario/actions";
import * as ROUTES from "../../constants/Routes";
import "./scenarioManager.css";

class ScenarioManagerScreen extends Component {
  state = {
    openDeleteProfessorScenarioModal: false,
    scenarioSelected: {},
  };

  componentDidMount() {
    const { language } = this.props;
    if (language) this.getScenarioList();
  }

  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (prevProps.language !== language) {
      this.getScenarioList();
    }
  }

  getScenarioList = () => {
    const { getProfessorScenarioList, language, t } = this.props;
    const translate = t;
    getProfessorScenarioList(language, translate);
  };

  createNewScenarioButtonClick = () => {
    const { history } = this.props;
    history.push(ROUTES.CREATE_NEW_SCENARIO);
  };

  deleteProfessorScenarioClick = (scenarioSelected) => {
    this.setState((state, props) => ({
      openDeleteProfessorScenarioModal: !state.openDeleteProfessorScenarioModal,
      scenarioSelected: scenarioSelected,
    }));
  };

  render() {
    const translate = this.props.t;
    const { scenarioList, professorDeleteScenario, professorDeleteScenarioData, resetProfesorDeleteScenarioStore } =
      this.props;
    const { openDeleteProfessorScenarioModal, scenarioSelected } = this.state;
    const { loadingProfessorScenarioList, professorScenarioListErrorMessage, professorScenarioList } = scenarioList;

    let scenarioManagerComponent = null;
    let deleteProfessorScenarioModal = null;

    if (openDeleteProfessorScenarioModal) {
      deleteProfessorScenarioModal = (
        <DeleteProfessorScenarioModal
          translate={translate}
          openDeleteProfessorScenarioModal={openDeleteProfessorScenarioModal}
          deleteProfessorScenarioClick={this.deleteProfessorScenarioClick}
          professorDeleteScenarioData={professorDeleteScenarioData}
          professorDeleteScenario={professorDeleteScenario}
          scenarioSelected={scenarioSelected}
          resetProfesorDeleteScenarioStore={resetProfesorDeleteScenarioStore}
          getScenarioList={this.getScenarioList}
        />
      );
    }

    if (professorScenarioList?.length > 0) {
      scenarioManagerComponent = (
        <ScenarioManagerComponent
          translate={translate}
          professorScenarioList={professorScenarioList}
          deleteProfessorScenarioClick={this.deleteProfessorScenarioClick}
        />
      );
    }

    if (professorScenarioList?.length === 0) {
      scenarioManagerComponent = (
        <div className="scenarioManagerInfoMessage">
          <MessagesComponent info message={translate("noProfessorScenarioList")} />
        </div>
      );
    }

    if (professorScenarioListErrorMessage !== "") {
      scenarioManagerComponent = <MessagesComponent message={translate(professorScenarioListErrorMessage)} />;
    }

    if (loadingProfessorScenarioList) {
      scenarioManagerComponent = <LoadingComponent message={translate("loadingProfessorScenarioList")} />;
    }

    return (
      <Container>
        {deleteProfessorScenarioModal}
        <HeaderComponent translate={translate} showCompleteMenu={true} />
        <div className="scenarioManagerTitleContainer">
          <MainTitleComponent title={translate("scenarioManager")} />

          <DefaultButton
            className="scenarioManagerCreateScenarioButton"
            title={translate("createScenario")}
            action={this.createNewScenarioButtonClick}
          />
        </div>
        <div className="scenarioManagerTableTopper">
          <p className="scenarioManagerIntroText">{translate("scenarioManagerIntro")}</p>
        </div>
        {scenarioManagerComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProfessorScenarioList: (language, translate) => dispatch(getProfessorScenarioList(language, translate)),
  professorDeleteScenario: (scenarioId) => dispatch(professorDeleteScenario(scenarioId)),
  resetProfesorDeleteScenarioStore: () => dispatch(resetProfesorDeleteScenarioStore()),
});

const mapStateToProps = (state) => {
  return {
    scenarioList: {
      loadingProfessorScenarioList: state.scenario.loadingProfessorScenarioList,
      professorScenarioListErrorMessage: state.scenario.professorScenarioListErrorMessage,
      professorScenarioList: state.scenario.professorScenarioList,
    },
    professorDeleteScenarioData: {
      loadingProfessorDeleteScenario: state.scenario.loadingProfessorDeleteScenario,
      professorDeleteScenarioSuccess: state.scenario.professorDeleteScenarioSuccess,
      professorDeleteScenarioMessage: state.scenario.professorDeleteScenarioMessage,
    },
    language: state.user.userProfile.language,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("ScenarioManagerScreen", "Common")(withRouter(ScenarioManagerScreen))
);
