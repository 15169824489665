import React, { Component } from "react";
import { connect } from "react-redux";
import { getStorage } from "../../services/StorageService";
import { supplyChainJoinSimulation } from "../../store/SupplyChainJoinSimulation/actions";
import { supplyChainConfirmPayment } from "../../store/SupplyChainBuySimulation/actions";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import LoadingComponent from "../helpers/LoadingComponent";
import SuccessModal from "../modals/SuccessModal";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ErrorModal from "../modals/ErrorModal";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANTS from "../../constants/UniversalConstants";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

export class SupplyChainJoinSimulation extends Component {
  constructor(props) {
    super(props);
    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }
    this.state = {
      showSuccessMessage: true,
      redirectToDownload: false,
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    if (typeof this.state.paymentIntentId === "string") {
      await this.validatePayment();
      return;
    }

    this.attemptToJoinSimulation();
  }

  componentDidUpdate(prevProps) {
    const prevConfirmPaymentStatus = prevProps.confirmPaymentStatus;
    const currentConfirmPaymentStatus = this.props.confirmPaymentStatus;
    if (
      typeof this.state.paymentIntentId === "string" &&
      prevConfirmPaymentStatus.status !== currentConfirmPaymentStatus.status &&
      currentConfirmPaymentStatus.status === confirmPaymentStatuses.success
    ) {
      this.attemptToJoinSimulation();
    }
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  attemptToJoinSimulation = () => {
    const simulationId = getStorage(LOCALSTORAGE.supplyChainJoinSimulationId);
    const paymentSummaryId = getStorage(LOCALSTORAGE.supplyChainPaymentSummaryId);
    const token = this.state.paymentIntentId || paymentSummaryId;
    this.props.supplyChainJoinSimulation({ simulationId, token });
  };

  closeSuccessModal = () => {
    this.setState({
      showSuccessMessage: false,
      redirectToDownload: true,
    });
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    let body = null;
    if (this.props.loading) {
      body = <LoadingComponent fullSize={true} image={true} message={translate("AuthComponents:loadingPage")} />;
    }

    const showSuccessModal = this.props.code !== "" && this.state.showSuccessMessage;
    const successModal = (
      <SuccessModal
        openSuccessModal={showSuccessModal}
        handleSuccessModal={this.closeSuccessModal.bind(this)}
        translate={translate}
        message={translate("joinSimulationSuccessful")}
      />
    );

    const showErrorModal = (this.props.errorMessage ?? "") !== "";
    const errorMessageModal = (
      <ErrorModal
        openErrorModal={showErrorModal}
        title={translate("failedJoinSimulation")}
        message={translate(this.props.errorMessage)}
        hasRetry
        retryText={translate("Retry")}
        translate={translate}
        handleRetry={this.attemptToJoinSimulation.bind(this)}
      />
    );

    if (this.state.redirectToDownload) {
      body = (
        <Redirect
          to={{
            pathname: ROUTES.DOWNLOAD_APP,
            search: `?app=${CONSTANTS.SUPPLY_CHAIN_NAME}`,
          }}
        />
      );
    }
    return (
      <>
        {body}
        {errorMessageModal}
        {successModal}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmPaymentStatus: state.supplychainBuySimulation.confirmPayment,
  errorMessage: state.supplyChainJoinSimulation.errorMessage,
  loading: state.supplyChainJoinSimulation.loading,
  code: state.supplyChainJoinSimulation.code,
});

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(supplyChainConfirmPayment(intentId)),
  supplyChainJoinSimulation: (request) => dispatch(supplyChainJoinSimulation(request)),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("SupplyChainJoinSimulation", "AuthComponents")
);

export default HOC(SupplyChainJoinSimulation);
