import { CRISIS_JOIN_SIMULATION_PREAMBLE } from "../../constants/Routes";
import * as CRISIS_CONSTANTS from "../../constants/CrisisConstants";
import moment from "moment";

export const createCrisisLink = (simulationId, paymentSummaryId) => {
  if (paymentSummaryId !== "") {
    return `${window.location.origin}${CRISIS_JOIN_SIMULATION_PREAMBLE}?simulationId=${simulationId}&paymentSummaryId=${paymentSummaryId}`;
  } else {
    return `${window.location.origin}${CRISIS_JOIN_SIMULATION_PREAMBLE}?simulationId=${simulationId}`;
  }
};

export const crisisGameModeOptions = (translate) => [
  {
    key: 1,
    text: translate(`CrisisCommon:${CRISIS_CONSTANTS.OneStationUnknownPeers}`),
    value: CRISIS_CONSTANTS.OneStationUnknownPeers,
  },
  {
    key: 2,
    text: translate(`CrisisCommon:${CRISIS_CONSTANTS.OneStationKnownPeers}`),
    value: CRISIS_CONSTANTS.OneStationKnownPeers,
  },
  {
    key: 3,
    text: translate(`CrisisCommon:${CRISIS_CONSTANTS.AllStationsKnownPeers}`),
    value: CRISIS_CONSTANTS.AllStationsKnownPeers,
  },
];

export const crisisValidateDates = (startDate, endDate) => {
  if (startDate.diff(moment()) < CRISIS_CONSTANTS.minTimeBeforeSimulationStartsInMiliseconds) {
    return {
      invalidStartTime: true,
      invalidEndTime: false,
      dateValidationError: "CrisisPurchased:SimulationStartInTheFuture",
    };
  }
  if (endDate.diff(startDate) < CRISIS_CONSTANTS.minSimulationDurationInMiliseconds) {
    return {
      invalidStartTime: false,
      invalidEndTime: true,
      dateValidationError: "CrisisPurchased:SimulationMinDurationError",
    };
  }

  if (endDate.diff(startDate) > CRISIS_CONSTANTS.maxSimulationDurationInMiliseconds) {
    return {
      invalidStartTime: false,
      invalidEndTime: true,
      dateValidationError: "CrisisPurchased:SimulationMaxDurationError",
    };
  }
  return {
    invalidStartTime: false,
    invalidEndTime: false,
    dateValidationError: "",
  };
};
