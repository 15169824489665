export const COLOR_PALETTE = [
  "#CC5A05",
  "#1E5D8E",
  "#CC4437",
  "#563104",
  "#5bb848",
  "#F4C454",
  "#06D1F2",
  "#9E5F05",
  "#84579C",
  "#FCDF8D",
  "#022644",
  "#EA843F",
  "#1C2B2B",
  "#DD81A0",
  "#465151",
  "#2D4A6C",
  "#707070",
  "#141E1E",
  "#72CAF7",
  "#2F83AD",
];
