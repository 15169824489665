import { getCurrentSession, login } from "../services/AuthService";
import axios from "axios";
import {
  MEDICA_BASE_API_URL,
  SIMUNATION_BASE_API_URL,
  CRISIS_BASE_API_URL,
  SUPPLY_CHAIN_BASE_API_URL,
  FORIO_APPS_BASE_API_URL,
} from "../constants/FetchApi";
import { sentryReportEvent } from "./SentryReportService";
import { translationExists } from "../components/utilities/LanguageUtilities";

export const getConfig = async () => {
  const session = await getCurrentSession();
  if (session) {
    const token = session.tokenInfo.idToken;
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": window.location.origin,
        "ZUMO-API-VERSION": "2.0.0",
        Authorization: `Bearer ${token}`,
      },
    };
    return axiosConfig;
  } else {
    login();
  }
};

export const sendGetRequest = async (apiRoute, reference) => {
  const config = await getConfig();
  if (config) {
    reference = encodeURI(reference);
    return axios({
      method: "get",
      url: `${apiRoute}${reference}`,
      headers: config.headers,
      responseType: "stream",
    });
  }
};

export const sendGetWithParamsRequest = async (apiRoute, params) => {
  const config = await getConfig();
  if (config) {
    return axios.get(apiRoute, { params, responseType: "stream", headers: config.headers });
  }
};

export const sendUnauthorizedGetRequest = async (apiRoute, reference) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": window.location.origin,
    "ZUMO-API-VERSION": "2.0.0",
  };
  reference = encodeURI(reference);
  return axios({
    method: "get",
    url: `${apiRoute}${reference}`,
    headers: headers,
    responseType: "stream",
  });
};

export const sendPostRequest = async (apiRoute, postData) => {
  const config = await getConfig();
  if (config) {
    return axios({
      method: "post",
      url: apiRoute,
      headers: config.headers,
      data: JSON.stringify(postData),
    });
  }
};

export const sendDeleteRequest = async (apiRoute, reference) => {
  const config = await getConfig();
  if (config) {
    const encodedReference = encodeURI(reference);
    return axios({
      method: "delete",
      url: `${apiRoute}${encodedReference}`,
      headers: config.headers,
    });
  }
};

export const axiosLogErrorsFromBackend = (response) => {
  if (response.status !== 200 || (response.data && response.data.outcome)) {
    return response;
  }

  if (response.data.message && translationExists(response.data.message)) {
    return response;
  }

  if (response.data.message.includes("Entities may have been modified or deleted since entities were loaded")) {
    return {
      ...response,
      data: {
        outcome: false,
        message: "Common:Busy Server",
      },
    };
  }

  if (shouldLogErrorInSentry()) {
    let apiURL = new URL(response.config.url);
    let simulationName = getSimulationNameFromAPI(apiURL.origin);

    const reportMessage = `[${simulationName}] API Error: ${apiURL.pathname}`;
    const sentryTag = { name: "Backend", data: response.config.url };

    const sentryExtras = [];
    if (response.config.data) {
      const requestData = JSON.parse(response.config.data);
      sentryExtras.push(
        ...Object.keys(requestData).map((key) => {
          return { title: "request_" + key.toString(), value: requestData[key] };
        })
      );
    }
    if (response.data) {
      const responseData = response.data;
      sentryExtras.push(
        ...Object.keys(responseData).map((key) => {
          return { title: "response_" + key.toString(), value: responseData[key] };
        })
      );
    }

    sentryExtras.push({ title: "method", value: response.config.method });
    sentryReportEvent(reportMessage, sentryTag, sentryExtras);
  }
  return {
    ...response,
    data: {
      outcome: false,
      message: "Common:unknownBackendErrorMessage",
    },
  };
};

export const axiosLogExceptionsFromBackend = (error) => {
  let reportMessage = "";
  let apiURL = new URL(error.config.url);
  let simulationName = getSimulationNameFromAPI(apiURL.origin);

  reportMessage = `[${simulationName}] API Exception: ${apiURL.pathname}`;
  const sentryTag = { name: "Backend", data: error.config.url };

  const sentryExtras = [];
  if (error.config.data) {
    const requestData = JSON.parse(error.config.data);
    sentryExtras.push(
      ...Object.keys(requestData).map((key) => {
        return { title: "request_" + key.toString(), value: requestData[key] };
      })
    );
  }

  sentryExtras.push({ title: "method", value: error.config.method });
  sentryExtras.push({ title: "message", value: error.message });
  sentryReportEvent(reportMessage, sentryTag, sentryExtras);

  return {
    ...error,
    message: "Common:unknownBackendExceptionMessage",
  };
};

const shouldLogErrorInSentry = (errorMessage) => {
  let logErrorInSentry = true;

  if (
    errorMessage === "Network Error" ||
    errorMessage === "timeout of 0ms exceeded" ||
    errorMessage === "Request aborted"
  ) {
    logErrorInSentry = false;
  }
  return logErrorInSentry;
};

const getSimulationNameFromAPI = (apiRoute) => {
  if (apiRoute.includes(MEDICA_BASE_API_URL)) {
    return "Medica";
  }
  if (apiRoute.includes(SIMUNATION_BASE_API_URL)) {
    return "Simunation";
  }
  if (apiRoute.includes(CRISIS_BASE_API_URL)) {
    return "Crisis";
  }
  if (apiRoute.includes(SUPPLY_CHAIN_BASE_API_URL)) {
    return "Supply Chain";
  }
  if (apiRoute.includes(FORIO_APPS_BASE_API_URL)) {
    return "Forio";
  }
  return "Unknown";
};
