import _ from "lodash";
import React from "react";
import moment from "moment";
import { Form, Select, Image } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import calendarIcon from "../../assets/calendar-icon.png";
import * as CONSTANT from "../../constants/UniversalConstants";
import { getLanguagePrefix } from "../utilities/LanguageUtilities";

const DateTimeSelector = (props) => {
  const { language } = props;
  const languagePrefix = getLanguagePrefix(language);
  const hourFormats = _.map(CONSTANT.HOUR_MERIDIEM, (format) => ({
    key: format.key,
    text: format.value,
    value: format.value,
  }));
  const hourNumbers = _.map(CONSTANT.HOUR_NUMBERS, (hour) => ({
    key: hour.key,
    text: hour.value,
    value: hour.value,
  }));

  let labelClassNames = "labelContainer";
  if (props.labelAlignment === "left") {
    labelClassNames += " leftLabelAlignment";
  }

  const timeOptions =
    typeof props.timeOptions == "object" && Array.isArray(props.timeOptions) ? props.timeOptions : hourNumbers;

  return (
    <Form.Field error={props.error}>
      <div className={labelClassNames}>
        <label className="specialLabel">{props.label}</label>
      </div>
      <div className="dateTimeSelectorContainer">
        <div className="inputItem dateInfo" onClick={() => props.handleCalendar(props.dateToSet)}>
          <span>{moment(props.date).locale(languagePrefix).format("ddd DD MMM YYYY")}</span>
          <Image src={calendarIcon} alt="calendarIcon" />
        </div>
        <div className="inputItem timeSelector">
          <Select
            className="selector"
            options={timeOptions}
            onChange={(event, { value }) => props.settingHour(event, { value })}
            value={props.hour}
          />
          <Select
            className="selector"
            options={hourFormats}
            onChange={(event, { value }) => props.settingHour(event, { value })}
            value={props.hourFormat}
          />
          <FontAwesomeIcon icon={faClock} />
        </div>
      </div>
    </Form.Field>
  );
};

export default DateTimeSelector;
