import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import InputComponent from "../helpers/InputComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import NavigationButton from "../helpers/NavigationButton";
import * as ROUTES from "../../constants/Routes";

class SimunationBuyQuestionnaireComponent extends Component {
  state = {
    numberOfStudents: this.props.numberOfStudents,
    advanceSimunation: this.props.advanceSimunation,
    isInstitution: true,
    numberOfStudentsError: false,
    advanceSimunationError: false,
  };

  handleChangeNumberStudents = (id, data) => {
    this.setState({
      [id]: data,
      numberOfStudentsError: false,
    });
  };

  onSelectionAdvanceSimulation = (val) => {
    this.setState({
      advanceSimunation: val,
      advanceSimunationError: false,
    });
    this.props.cacheSimunationQuestionnaireData({
      advanceSimunation: val,
    });
  };

  handleContinue = () => {
    const { numberOfStudents, advanceSimunation, isInstitution } = this.state;
    let questionnaire = {
      numberOfStudents: null,
      advanceSimunation: null,
      isInstitution: this.state.isInstitution,
    };
    if (isInstitution !== null) {
      if (numberOfStudents >= 1) {
        questionnaire.numberOfStudents = Number(numberOfStudents);
        questionnaire.advanceSimunation = advanceSimunation;
        this.saveStateAndRoute(questionnaire);
      } else {
        this.setState({
          numberOfStudentsError: true,
        });
      }
    } else {
      this.setState({
        isInstitutionError: true,
      });
    }
  };

  saveStateAndRoute = (data) => {
    this.props.cacheSimunationQuestionnaireData(data);
    this.props.history.push(ROUTES.SIMUNATION_PAYMENT);
  };

  render() {
    const translate = this.props.translate;
    const {
      isInstitutionError,
      numberOfStudentsError,
      isInstitution,
      numberOfStudents,
      advanceSimunation,
      advanceSimunationError,
    } = this.state;
    let inputComponent = null;
    let messagesComponent = null;
    const optionsAdvanceSimulation = [
      {
        key: 1,
        text: translate("simunationOptionProfessor"),
        value: true,
      },
      {
        key: 2,
        text: translate("simunationOptionStudents"),
        value: false,
      },
    ];
    if (isInstitution) {
      inputComponent = (
        <InputComponent
          label={translate("numbersStudents")}
          id="numberOfStudents"
          type="number"
          min="0"
          onError={numberOfStudentsError}
          errorMessage={translate("notEnoughStudentErrorSimunation")}
          value={numberOfStudents}
          handleChange={this.handleChangeNumberStudents}
        />
      );
    }
    if (isInstitutionError || numberOfStudentsError || advanceSimunationError) {
      messagesComponent = <MessagesComponent message={translate("verifyFieldsCorrectlyFilled")} />;
    }
    return (
      <Form>
        {inputComponent}
        <DropDownComponent
          helpMessage={translate("simunationProfessorAdvanceSimulationHelp")}
          label={translate("simunationWhoAdvancesSimulation")}
          options={optionsAdvanceSimulation}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.onSelectionAdvanceSimulation}
          selectError={advanceSimunationError}
          value={advanceSimunation}
        />
        {messagesComponent}
        <NavigationButton action={this.handleContinue} label={translate("next")} />
      </Form>
    );
  }
}

export default SimunationBuyQuestionnaireComponent;
