import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import HeaderComponent from "../header/HeaderComponent";
import StudentCreateTeamComponent from "./StudentCreateTeamComponent";
import { removeStorage } from "../../services/StorageService";
import { createTeam, resetCreateTeamStore } from "../../store/Medica/actions";
import { medicaConfirmPayment, updateTransactionData } from "../../store/MedicaQuestionnaire/actions";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "./studentCreateTeamStyles.css";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

class StudentCreateTeamScreen extends Component {
  removeOldStoredData = () => {
    removeStorage(LOCALSTORAGE.teamCode);
    removeStorage(LOCALSTORAGE.securityCode);
    removeStorage(LOCALSTORAGE.teamName);
    removeStorage(LOCALSTORAGE.simulationId);
    removeStorage(LOCALSTORAGE.activationCode);
    removeStorage(LOCALSTORAGE.medicaQuestionnaire);
  };

  constructor(props) {
    super(props);
    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }

    this.state = {
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    await this.validatePayment();
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;
    const { language, medica } = this.props;
    const { creatingTeam, createTeamError, simulationShareLink } = medica;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }
    const studentCreateTeamComponent = (
      <StudentCreateTeamComponent
        isLoading={creatingTeam}
        translate={translate}
        error={createTeamError}
        shareLink={simulationShareLink}
        history={this.props.history}
        createTeam={this.props.createTeam}
        resetCreateTeamStore={this.props.resetCreateTeamStore}
        updateTransactionData={this.props.updateTransactionData}
        language={language}
        removeOldStoredData={this.removeOldStoredData}
      />
    );

    return (
      <Container>
        <HeaderComponent translate={translate} showLanguageSelector={true} />
        <MainTitleComponent
          title={translate("createTeam")}
          subtitle={translate("createTeamDescription")}
          reducedSubTitle={true}
        />
        {studentCreateTeamComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(medicaConfirmPayment(intentId)),
  createTeam: (data) => dispatch(createTeam(data)),
  updateTransactionData: (data) => dispatch(updateTransactionData(data)),
  resetCreateTeamStore: () => dispatch(resetCreateTeamStore()),
});

const mapStateToProps = (state) => {
  return {
    confirmPaymentStatus: state.medicaQuestionnaire.confirmPayment,
    medica: {
      simulationShareLink: state.medica.simulationShareLink,
      createTeamError: state.medica.createTeamError,
      creatingTeam: state.medica.creatingTeam,
    },
    language: state.user.userProfile.language,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("StudentCreateTeam", "ShareModal", "Common")(withRouter(StudentCreateTeamScreen))
);
