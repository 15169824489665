import React from "react";
import { Container } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";

const CookiesDisabledScreen = (props) => {
  const { t } = props;
  const translate = t;

  return (
    <Container>
      <HeaderComponent translate={translate} showLanguageSelector={true} />
      <MainTitleComponent />
      <MessagesComponent message={translate("cookiesNotEnabled")} />
    </Container>
  );
};

export default withTranslation("CookiesDisabledScreen", "Common")(CookiesDisabledScreen);
