import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ActivationCodeModal from "./ActivationCodeModal";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import RedirectModal from "../modals/RedirectModal";

class PaymentValidationController extends Component {
  state = {
    openDiscountCodeModal: false,
  };

  handleDiscountModal = () => {
    this.setState({
      openDiscountCodeModal: !this.state.openDiscountCodeModal,
    });
  };

  render() {
    const {
      t: translate,
      numberOfStudents,
      updateStorage,
      discountCodeStatus,
      resetDiscountCode,
      removeDiscountCode,
      validateDiscountCode,
      successPaymentRoute,
    } = this.props;
    const { openDiscountCodeModal } = this.state;

    let discountCodeButton = null;
    if (discountCodeStatus.discountActivated === true) {
      discountCodeButton = (
        <p className={`discountLabel`} onClick={removeDiscountCode}>
          {translate("removeDiscountCode")}
        </p>
      );
    } else {
      discountCodeButton = (
        <p className={`discountLabel`} onClick={this.handleDiscountModal}>
          {translate("discountCode")}
        </p>
      );
    }

    const redirectModal = (
      <RedirectModal
        open={discountCodeStatus.paidWithDiscountCode}
        translate={translate}
        history={this.props.history}
        title={translate("paymentSuccess")}
        message={translate("yourPaymentHasBeenSuccessfullyRegistered")}
        route={successPaymentRoute}
      />
    );

    return (
      <Grid centered columns={1}>
        {redirectModal}
        <ActivationCodeModal
          open={openDiscountCodeModal}
          translate={translate}
          handleDiscountModal={this.handleDiscountModal.bind(this)}
          validateDiscountCode={validateDiscountCode}
          resetDiscountCode={resetDiscountCode}
          discountCodeStatus={discountCodeStatus}
          updateStorage={updateStorage}
        />
        <Grid.Row>
          <p>{translate("activationCodeHelpText")}</p>
        </Grid.Row>
        <Grid.Row>
          <p className="centeredLabel">
            {numberOfStudents} {translate("Common:students")}
          </p>
        </Grid.Row>
        <Grid.Row>{discountCodeButton}</Grid.Row>
      </Grid>
    );
  }
}

const HOC = compose(withTranslation("PaymentForio", "Common"), withRouter);
export default HOC(PaymentValidationController);
