import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import BackButton from "../helpers/BackButton";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import { fixChatWidgetPlacement } from "../../services/ChatWidgetPositionService";
import PaymentValidationComponent from "../activationForio/PaymentValidationComponent";
import { getStorage, mergeDataToStorage, setStorage } from "../../services/StorageService";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";
import {
  creambowResetDiscountCode,
  creambowValidateDiscountCode,
  restoreCreambowPurchaseInformationFromStorage,
} from "../../store/CreambowBuySimulation/actions";

class ActivationCreamBow extends Component {
  updateStorage = (value) => {
    mergeDataToStorage(LOCAL_STORAGE.creambowQuestionnaire, value);
  };

  componentDidMount() {
    this.props.restorePurchaseInformationFromStorage();
  }

  validateDiscountCode = (discountCode) => {
    this.props.validateDiscountCode({
      code: discountCode,
      numberOfLicenses: this.props.buyForm.numberOfStudents,
      simulationId: "",
      targetSimulation: "Creambow",
    });
  };

  removeDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const creambowQuestionnaire = getStorage(LOCAL_STORAGE.creambowQuestionnaire);
    delete creambowQuestionnaire.discountActivated;
    delete creambowQuestionnaire.discountedPrice;
    delete creambowQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.creambowQuestionnaire, creambowQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const { t: translate, discountCodeStatus, buyForm, resetDiscountCode } = this.props;
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent title={translate("payment")} reducedSubTitle={true} />
        <div className="cardFormContainer" onFocus={fixChatWidgetPlacement}>
          <PaymentValidationComponent
            successPaymentRoute={ROUTES.CREAMBOW_CREATE_SIMULATION}
            removeDiscountCode={this.removeDiscountCode}
            discountCodeStatus={discountCodeStatus}
            validateDiscountCode={this.validateDiscountCode}
            resetDiscountCode={resetDiscountCode}
            numberOfStudents={buyForm.numberOfStudents}
            updateStorage={this.updateStorage}
          />
          <BackButton translate={translate} history={this.props.history} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  discountCodeStatus: state.creambowBuySimulation.discountCode,
  buyForm: state.creambowBuySimulation.buyForm,
});

const mapDispatchToProps = (dispatch) => ({
  restorePurchaseInformationFromStorage: () => dispatch(restoreCreambowPurchaseInformationFromStorage()),
  validateDiscountCode: (discountCode) => dispatch(creambowValidateDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(creambowResetDiscountCode()),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("Payment", "PaymentForio", "Common"),
  withRouter
);
export default HOC(ActivationCreamBow);
