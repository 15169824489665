export const HEADERS_GET = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": window.location.origin,
    "ZUMO-API-VERSION": "2.0.0",
    Authorization: "",
  },
};
export const HEADERS_POST = {
  method: "POST",
  body: "",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": window.location.origin,
    "ZUMO-API-VERSION": "2.0.0",
    Authorization: "",
  },
};

export const MEDICA_BASE_API_URL = process.env.REACT_APP_MEDICA_BASE_API_URL;
export const SIMUNATION_BASE_API_URL = process.env.REACT_APP_SIMUNATION_BASE_API_URL;
export const CRISIS_BASE_API_URL = process.env.REACT_APP_CRISIS_BASE_API_URL;
export const SUPPLY_CHAIN_BASE_API_URL = process.env.REACT_APP_SUPPLY_CHAIN_BASE_API_URL;
export const ADMINISTRATION_BASE_API_URL = process.env.REACT_APP_ADMINISTRATION_BASE_API_URL;
export const FORIO_APPS_BASE_API_URL = process.env.REACT_APP_FORIO_APPS_BASE_API_URL;

//GET//
export const GET_SIMULATIONS_MEDICA = `${MEDICA_BASE_API_URL}/api/WebGetSimulations`;
export const GET_SIMULATIONS_SIMUNATION = `${SIMUNATION_BASE_API_URL}/api/WebGetSimulations`;
export const GET_SIMULATIONS_CRISIS = `${CRISIS_BASE_API_URL}/WebGetSimulations`;
export const GET_SIMULATIONS_SUPPLY_CHAIN = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetSimulations`;
export const GET_SIMULATION_DETAIL_MEDICA = `${MEDICA_BASE_API_URL}/api/WebGetSimulationDetails?simulationId=`;
export const GET_SIMULATION_DETAIL_SIMUNATION = `${SIMUNATION_BASE_API_URL}/api/WebGetSimulationDetails?simulationName=`;
export const GET_REGISTERED_STUDENTS_MEDICA = `${MEDICA_BASE_API_URL}/api/WebGetSimulationStudents?simulationName=`;
export const GET_REGISTERED_STUDENTS_SIMUNATION = `${SIMUNATION_BASE_API_URL}/api/WebGetSimulationStudents?simulationName=`;
export const GET_STANDINGS_MEDICA = `${MEDICA_BASE_API_URL}/api/WebGetStandings?simulationName=`;
export const GET_STANDINGS_MEDICA_PUBLIC = `${MEDICA_BASE_API_URL}/api/WebUnauthorizeGetStandings?simulationName=`;
export const GET_STANDINGS_SIMUNATION = `${SIMUNATION_BASE_API_URL}/api/WebGetStandings?simulationName=`;
export const GET_TEAMS_MEDICA = `${MEDICA_BASE_API_URL}/api/WebGetSimulationTeams?simulationName=`;
export const GET_SIMULATION_LINK = `${MEDICA_BASE_API_URL}/api/WebGetActivationLink?controlSimulationId=`;
export const GET_SIMUNATION_VALIDATE_DISCOUNT_CODE = `${SIMUNATION_BASE_API_URL}/api/WebValidateDiscountCode?discountCode=`;
export const GET_PROFESSOR_SCENARIOS = `${MEDICA_BASE_API_URL}/api/WebGetProfessorScenarios`;
export const GET_STUDENT_PREVIOUS_PAYMENT = `${MEDICA_BASE_API_URL}/api/WebCheckStudentPayment?simulationId=`;
export const GET_MEDICA_DOCUMENTS = `${MEDICA_BASE_API_URL}/api/WebGetDocumentationLinks?simulationId=`;
export const GET_MEDICA_PERFORMANCE_DATA = `${MEDICA_BASE_API_URL}/api/WebGetSimulationPerformanceData?SimulationName=`;
export const GET_VIDEO_TUTORIALS = `${ADMINISTRATION_BASE_API_URL}/GetVideos`;
export const GET_MEDICA_DECISIONS_DATA = `${MEDICA_BASE_API_URL}/api/WebGetChangeRequestsHistory?SimulationName=`;
export const GET_MEDICA_SCENARIO_INFORMATION = `${MEDICA_BASE_API_URL}/api/WebGetDefaultScenariosInformation`;
export const GET_MEDICA_CHANGE_REQUEST_HISTORY = `${MEDICA_BASE_API_URL}/api/WebGetDecisionHistory`;
export const GET_MEDICA_SUGGESTIONS = `${MEDICA_BASE_API_URL}/api/WebGetSuggestions`;
export const CRISIS_GET_SIMULATION_DETAILS = `${CRISIS_BASE_API_URL}/WebGetSimulationDetails?simulationId=`;
export const CRISIS_GET_REGISTERED_STUDENTS = `${CRISIS_BASE_API_URL}/WebGetRegisteredStudents?simulationId=`;
export const CRISIS_GET_NUMBER_REGISTERED_STUDENTS = `${CRISIS_BASE_API_URL}/WebGetNumberOfRegisteredStudents?simulationId=`;
export const CRISIS_GET_PERFORMANCE_DATA = `${CRISIS_BASE_API_URL}/WebGetSimulationPerformance?simulationId=`;
export const CRISIS_GET_STANDINGS = `${CRISIS_BASE_API_URL}/WebGetStandings?simulationId=`;
export const CRISIS_GET_STUDENT_HAS_PAID = `${CRISIS_BASE_API_URL}/WebStudentHasPaid?simulationId=`;
export const CRISIS_GET_PROFESSOR_SCENARIOS = `${CRISIS_BASE_API_URL}/WebGetProfessorScenarios`;
export const SUPPLY_CHAIN_GET_SIMULATION_DETAILS = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetSimulationDetails?simulationId=`;
export const SUPPLY_CHAIN_GET_REGISTERED_STUDENTS = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetRegisteredStudents?simulationId=`;
export const SUPPLY_CHAIN_GET_NUMBER_REGISTERED_STUDENTS = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetNumberOfRegisteredStudents?simulationId=`;
export const SUPPLY_CHAIN_GET_STANDINGS = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetStandings?simulationId=`;
export const SUPPLY_CHAIN_GET_PERFORMANCE_DATA = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetSimulationPerformance?simulationId=`;
export const SUPPLY_CHAIN_GET_DECISIONS_DATA = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetChangeRequestsHistory?simulationId=`;
export const SUPPLY_CHAIN_GET_STUDENT_HAS_PAID = `${SUPPLY_CHAIN_BASE_API_URL}/WebStudentHasPaid?simulationId=`;
export const SUPPLY_CHAIN_GET_PROFESSOR_SCENARIOS = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetProfessorScenarios`;
export const GET_SIMULATIONS_FORIO = `${FORIO_APPS_BASE_API_URL}/WebGetSimulations`;
export const FORIO_GET_SIMULATION_DETAILS = `${FORIO_APPS_BASE_API_URL}/WebGetSimulationDetails?simulationId=`;
export const FORIO_GET_STANDINGS = `${FORIO_APPS_BASE_API_URL}/WebGetStandings?simulationId=`;
export const FORIO_GET_REGISTERED_STUDENTS = `${FORIO_APPS_BASE_API_URL}/WebGetRegisteredStudents?simulationId=`;
export const CRISIS_GET_DOCUMENTATION = `${CRISIS_BASE_API_URL}/WebGetDocumentationLinks?simulationId=`;
export const SUPPLY_CHAIN_GET_DOCUMENTATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebGetDocumentationLinks?simulationId=`;

//POST//
export const MODIFY_SIMULATION_DATES_MEDICA = `${MEDICA_BASE_API_URL}/api/WebModifySimulation`;
export const PAYMENT_MEDICA = `${MEDICA_BASE_API_URL}/api/WebProcessPayment`;
export const SEND_PUSH_NOTIFICATION_MEDICA = `${MEDICA_BASE_API_URL}/api/WebSendPushNotification`;
export const MEDICA_CREATE_SIMULATION = `${MEDICA_BASE_API_URL}/api/WebSimpleCreateSimulation`;
export const MEDICA_CREATE_SIMULATION_MANAGED_PROGRESS = `${MEDICA_BASE_API_URL}/api/WebCreateProfessorManagedSimulation`;
export const MEDICA_RUN_AGAIN_SIMULATION = `${MEDICA_BASE_API_URL}/api/WebNextRunSimulation`;
export const CREATE_TEAM = `${MEDICA_BASE_API_URL}/api/WebCreateTeam`;
export const JOIN_TEAM = `${MEDICA_BASE_API_URL}/api/WebJoinTeam`;
export const ADVANCE_SIMULATION_MEDICA = `${MEDICA_BASE_API_URL}/api/WebProfessorSimulationExecution`;
export const ADVANCE_SIMULATION_SIMUNATION = `${SIMUNATION_BASE_API_URL}/api/WebProfessorAdvanceSimulation`;
export const SIMUNATION_CREATE_SIMULATION = `${SIMUNATION_BASE_API_URL}/api/WebCreateSimulation`;
export const MEDICA_VALIDATE_DISCOUNT_CODE = `${MEDICA_BASE_API_URL}/api/WebValidateDiscountCode`;
export const REQUEST_FEATURE = `${MEDICA_BASE_API_URL}/api/WebRequestFeature`;
export const PROFESSOR_CREATE_NEW_SCENARIO = `${MEDICA_BASE_API_URL}/api/WebCreateProfessorScenario`;
export const PAYMENT_CRISIS = `${CRISIS_BASE_API_URL}/WebProcessPayment`;
export const CRISIS_CREATE_SIMULATION = `${CRISIS_BASE_API_URL}/WebCreateSimulation`;
export const CRISIS_CREATE_AUTOMATIC_SIMULATION = `${CRISIS_BASE_API_URL}/WebCreateAutomaticSimulation`;
export const CRISIS_JOIN_SIMULATION = `${CRISIS_BASE_API_URL}/JoinSimulation`;
export const CRISIS_ADVANCE_SIMULATION = `${CRISIS_BASE_API_URL}/WebAdvanceSimulation`;
export const CRISIS_START_SIMULATION = `${CRISIS_BASE_API_URL}/WebStartSimulation`;
export const CRISIS_VALIDATE_DISCOUNT_CODE = `${CRISIS_BASE_API_URL}/WebValidateDiscountCode`;
export const CRISIS_CREATE_NEXT_RUN = `${CRISIS_BASE_API_URL}/WebCreateNextRun`;
export const CRISIS_MODIFY_SIMULATION = `${CRISIS_BASE_API_URL}/WebModifySimulation`;
export const SUPPLY_CHAIN_CREATE_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebCreateSimulation`;
export const SUPPLY_CHAIN_CREATE_AUTOMATIC_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebCreateAutomaticSimulation`;
export const SUPPLY_CHAIN_JOIN_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebJoinSimulation`;
export const SUPPLY_CHAIN_VALIDATE_DISCOUNT_CODE = `${SUPPLY_CHAIN_BASE_API_URL}/WebValidateDiscountCode`;
export const PAYMENT_SUPPLY_CHAIN = `${SUPPLY_CHAIN_BASE_API_URL}/WebProcessPayment`;
export const SUPPLY_CHAIN_ADVANCE_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebAdvanceSimulation`;
export const SUPPLY_CHAIN_START_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/StartSimulation`;
export const SUPPLY_CHAIN_CREATE_NEXT_RUN = `${SUPPLY_CHAIN_BASE_API_URL}/WebCreateNextRun`;
export const SUPPLY_CHAIN_MODIFY_SIMULATION = `${SUPPLY_CHAIN_BASE_API_URL}/WebModifySimulation`;

export const FORIO_VALIDATE_DISCOUNT_CODE = `${FORIO_APPS_BASE_API_URL}/WebValidateDiscountCode`;
export const PAYMENT_FORIO = `${FORIO_APPS_BASE_API_URL}/WebProcessPayment`;
export const FORIO_CREATE_SIMULATION = `${FORIO_APPS_BASE_API_URL}/WebCreateSimulation`;
export const FORIO_ADVANCE_SIMULATION = `${FORIO_APPS_BASE_API_URL}/WebAdvanceSimulation`;

export const MEDICA_GET_PAYMENT_INTENT = `${MEDICA_BASE_API_URL}/api/WebPaymentIntent`;
export const CRISIS_GET_PAYMENT_INTENT = `${CRISIS_BASE_API_URL}/api/WebPaymentIntent`;
export const SUPPLY_CHAIN_GET_PAYMENT_INTENT = `${SUPPLY_CHAIN_BASE_API_URL}/api/WebPaymentIntent`;
export const SIMUNATION_GET_PAYMENT_INTENT = `${SIMUNATION_BASE_API_URL}/api/WebPaymentIntent`;
export const MEDICA_CONFIRM_PAYMENT = `${MEDICA_BASE_API_URL}/api/WebPaymentConfirmation`;
export const CRISIS_CONFIRM_PAYMENT = `${CRISIS_BASE_API_URL}/api/WebPaymentConfirmation`;
export const SUPPLY_CHAIN_CONFIRM_PAYMENT = `${SUPPLY_CHAIN_BASE_API_URL}/api/WebPaymentConfirmation`;
export const SIMUNATION_CONFIRM_PAYMENT = `${SIMUNATION_BASE_API_URL}/api/WebPaymentConfirmation`;

//DELETE//
export const PROFESSOR_DELETE_SCENARIO = `${MEDICA_BASE_API_URL}/api/WebDeleteProfessorScenario?idProfessorScenario=`;
