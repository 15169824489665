import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { redirectionHandler } from "../../services/AuthService";
import { isLoggedIn, login } from "../../store/User/actions";
import LoadingComponent from "../helpers/LoadingComponent";
import InstructionsComponent from "./InstructionsComponent";
import { getCookie } from "../../services/CookieService";
import { setStorage, removeStorage } from "../../services/StorageService";
import * as COOKIE from "../../constants/Cookies";
import * as LOCALSTORAGE from "../../constants/LocalStorage";

class CheckAuth extends Component {
  state = {
    openTeamLeaderConfirmation: false,
    openStudentInstructionsModal: false,
  };

  componentDidMount() {
    this.getPathParams();
  }

  getPathParams = () => {
    const searchParams = this.props.location.search.replace("?", "").split("&");
    if (searchParams[0] === "") {
      this.handleUserWithoutLink();
    } else {
      this.removeOldStoredData();
      searchParams.forEach((element) => {
        const equalPosition = element.indexOf("=");
        const storageName = element.substring(0, equalPosition);
        const formattedElement = element.substring(equalPosition + 1).replace(/[+]/g, "%20");
        const decodedElement = decodeURIComponent(formattedElement);
        setStorage(storageName, decodedElement);
        if (storageName === LOCALSTORAGE.activationCode) {
          this.handleConfirmationModal();
        } else if (storageName === LOCALSTORAGE.teamCode) {
          this.checkIsLoggedIn();
        }
      }, this);
    }
  };

  checkIsLoggedIn = async () => {
    const isLogin = await this.props.isLoggedIn();
    if (isLogin) {
      const user = getCookie(COOKIE.AUTH_INFORMATION);
      redirectionHandler(user.userProfile);
    }
  };

  handleUserWithoutLink = () => {
    this.removeOldStoredData();
    this.checkIsLoggedIn();
  };

  handleConfirmationModal = () => {
    this.setState((state, props) => ({
      openTeamLeaderConfirmation: !state.openTeamLeaderConfirmation,
    }));
  };

  handleStudentInstructionsModal = (isTeamLeader) => {
    this.setState((state, props) => ({
      openTeamLeaderConfirmation: !state.openTeamLeaderConfirmation,
      openStudentInstructionsModal: !state.openStudentInstructionsModal,
      isTeamLeader: isTeamLeader,
    }));
  };

  removeOldStoredData = () => {
    removeStorage(LOCALSTORAGE.teamCode);
    removeStorage(LOCALSTORAGE.securityCode);
    removeStorage(LOCALSTORAGE.teamName);
    removeStorage(LOCALSTORAGE.simulationId);
    removeStorage(LOCALSTORAGE.activationCode);
    removeStorage(LOCALSTORAGE.purchasedProduct);
  };

  render() {
    const translate = this.props.t;
    const { openTeamLeaderConfirmation, openStudentInstructionsModal, isTeamLeader } = this.state;

    return (
      <>
        <InstructionsComponent
          translate={translate}
          openTeamLeaderConfirmation={openTeamLeaderConfirmation}
          handleStudentInstructionsModal={this.handleStudentInstructionsModal}
          openStudentInstructionsModal={openStudentInstructionsModal}
          isTeamLeader={isTeamLeader}
          checkIsLoggedIn={this.checkIsLoggedIn}
        />
        <LoadingComponent fullSize={true} image={true} message={translate("loadingPage")} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(isLoggedIn()),
  login: (options) => dispatch(login(options)),
});

export default compose(connect(null, mapDispatchToProps))(
  withTranslation("AuthComponents", "StudentCreateTeam", "InstructionsModal", "Common")(withRouter(CheckAuth))
);
