import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import BackButton from "../helpers/BackButton";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import { fixChatWidgetPlacement } from "../../services/ChatWidgetPositionService";
import PaymentValidationComponent from "../activationForio/PaymentValidationComponent";
import {
  abogadosResetDiscountCode,
  abogadosValidateDiscountCode,
  restoreAbogadosPurchaseInformationFromStorage,
} from "../../store/AbogadosBuySimulation/actions";
import { getStorage, mergeDataToStorage, setStorage } from "../../services/StorageService";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";

class ActivationAbogados extends Component {
  updateStorage = (value) => {
    mergeDataToStorage(LOCAL_STORAGE.abogadosQuestionnaire, value);
  };

  componentDidMount() {
    this.props.restorePurchaseInformationFromStorage();
  }

  validateDiscountCode = (discountCode) => {
    this.props.validateDiscountCode({
      code: discountCode,
      numberOfLicenses: this.props.buyForm.numberOfStudents,
      simulationId: "",
      targetSimulation: "LaneAndSlinger",
    });
  };

  removeDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const abogadosQuestionnaire = getStorage(LOCAL_STORAGE.abogadosQuestionnaire);
    delete abogadosQuestionnaire.discountActivated;
    delete abogadosQuestionnaire.discountedPrice;
    delete abogadosQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.abogadosQuestionnaire, abogadosQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const { t: translate, discountCodeStatus, buyForm, resetDiscountCode } = this.props;
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent title={translate("payment")} reducedSubTitle={true} />
        <div className="cardFormContainer" onFocus={fixChatWidgetPlacement}>
          <PaymentValidationComponent
            successPaymentRoute={ROUTES.ABOGADOS_CREATE_SIMULATION}
            removeDiscountCode={this.removeDiscountCode}
            discountCodeStatus={discountCodeStatus}
            validateDiscountCode={this.validateDiscountCode}
            resetDiscountCode={resetDiscountCode}
            numberOfStudents={buyForm.numberOfStudents}
            updateStorage={this.updateStorage}
          />
          <BackButton translate={translate} history={this.props.history} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  discountCodeStatus: state.abogadosBuySimulation.discountCode,
  buyForm: state.abogadosBuySimulation.buyForm,
  user: {
    role: state.user.userProfile.role,
    language: state.user.userProfile.language,
  },
});

const mapDispatchToProps = (dispatch) => ({
  restorePurchaseInformationFromStorage: () => dispatch(restoreAbogadosPurchaseInformationFromStorage()),
  validateDiscountCode: (discountCode) => dispatch(abogadosValidateDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(abogadosResetDiscountCode()),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("Payment", "PaymentForio", "Common"),
  withRouter
);
export default HOC(ActivationAbogados);
