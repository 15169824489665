import React from "react";
import { Header, Modal } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const VideoViewerModal = (props) => {
  const {
    openVideoViewerModal,
    handleVideoViewerModal,
    handleDontShowVideoAgain,
    hasHeader,
    hasDescription,
    hasActionButtons,
    title,
    description,
    buttonTitle,
    url,
    isTutorial,
  } = props;

  let closeButton = null;
  let modalHeader = null;
  let modalDescription = null;
  let modalActionButtons = null;

  if (hasHeader) {
    modalHeader = (
      <Header as="h3" textAlign="center">
        {title}
      </Header>
    );
    closeButton = (
      <button onClick={isTutorial ? handleVideoViewerModal : null} className="closeChartModalButton">
        <FontAwesomeIcon size="2x" icon={faWindowClose} />
      </button>
    );
  }

  if (hasDescription) {
    modalDescription = (
      <Modal.Description>
        <p className="modalVideoDescription">{description}</p>
      </Modal.Description>
    );
  }

  if (hasActionButtons) {
    modalActionButtons = (
      <Modal.Actions className="modalVideoActionButtons">
        <button className="modalVideoButton" onClick={handleDontShowVideoAgain}>
          {buttonTitle}
        </button>
      </Modal.Actions>
    );
  }

  return (
    <Modal open={openVideoViewerModal} closeIcon={closeButton} size="tiny" onClose={handleVideoViewerModal}>
      {modalHeader}
      <Modal.Content className="modalVideoContent">
        {modalDescription}
        <iframe
          width="100%"
          height="345"
          frameBorder="0"
          type="text/html"
          allowFullScreen
          src={url}
          title={title}></iframe>
      </Modal.Content>
      {modalActionButtons}
    </Modal>
  );
};

export default VideoViewerModal;
