import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Form, Grid } from "semantic-ui-react";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";

const predefinedScenarios = (t) => [
  {
    title: t("SupplyChainAdvancedOptions:defaultScenarioTitle"),
    id: "",
    description: t("SupplyChainAdvancedOptions:defaultScenarioDescription"),
  },
];

class AdvancedOptions extends Component {
  scenarioLabel = (title, description) => {
    return (
      <label className="advancedOptionsLabelWrapper">
        <p className="advancedOptionsRadioNameLabel">{title}</p>
        <p className="advancedOptionsRadioDescriptionLabel">{description}</p>
      </label>
    );
  };

  render() {
    const { loading, errorMessage, scenarios, advancedOptions, advancedOptionsChanged, t } = this.props;

    const scenarioOptions = predefinedScenarios(t).concat(scenarios);

    let advancedOptionsBody = null;

    if (typeof scenarios === "object" && Array.isArray(scenarios)) {
      advancedOptionsBody = (
        <Form>
          <h4>{t("SupplyChainAdvancedOptions:selectYourScenario")}</h4>
          {scenarioOptions.map((scenario) => {
            return (
              <Form.Field key={scenario.name}>
                <Checkbox
                  radio
                  label={this.scenarioLabel(scenario.title, scenario.description)}
                  name="checkboxRadioGroup"
                  value={scenario.name}
                  checked={advancedOptions.scenarioId === scenario.id}
                  onChange={() => advancedOptionsChanged("scenarioId", scenario.id)}
                />
              </Form.Field>
            );
          })}
        </Form>
      );
    }

    if (loading === true) {
      advancedOptionsBody = <LoadingComponent />;
    }

    if (typeof errorMessage === "string" && errorMessage.length > 0) {
      advancedOptionsBody = <MessagesComponent message={errorMessage}></MessagesComponent>;
    }

    return (
      <Grid>
        <Grid.Row>
          <div className="scenarioInformation">
            <div className="scenarioInformationContent">{advancedOptionsBody}</div>
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withTranslation("SupplyChainAdvancedOptions")(AdvancedOptions);
