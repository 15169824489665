import React from "react";
import { Label, List } from "semantic-ui-react";

const FaqTag = (props) => {
  const { onSelect, isSelected, tagTitle, tagId } = props;
  return (
    <List.Item onClick={() => onSelect(tagId)}>
      <Label basic={isSelected} color="blue">
        {tagTitle}
      </Label>
    </List.Item>
  );
};

export default FaqTag;
