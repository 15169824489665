import React from "react";
import { Button, Modal } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import "./modals.css";

const RedirectModal = (props) => {
  const translate = props.translate;
  return (
    <Modal size="tiny" open={props.open} onClose={() => props.history.push(props.route)}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <MessagesComponent info={true} message={props.message} />
      </Modal.Content>
      <Modal.Actions>
        <Button content={translate("Common:accept")} onClick={() => props.history.push(props.route)} />
      </Modal.Actions>
    </Modal>
  );
};

export default RedirectModal;
