import React, { Component } from "react";
import CustomTableHeader from "./CustomTableHeader";
import { scenarioPlayerValuesSection } from "../utilities/ScenarioInformationUtilities";

class ScenarioPlayerValuesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerValuesSectionData: [],
    };
  }

  componentDidMount() {
    this.prepareTableSectionData();
  }

  prepareTableSectionData = () => {
    const { scenarioData } = this.props;
    const playerValuesSectionData = scenarioPlayerValuesSection(scenarioData);
    this.setState({
      playerValuesSectionData: playerValuesSectionData,
    });
  };

  getValueTranslation = (configuration, values) => {
    const { translate } = this.props;

    const value = values.value;
    let pluralKey = "";
    let dollarValue = Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (value > 1) pluralKey = "_plural";

    switch (configuration.units) {
      case "Dollars":
        return dollarValue;
      case "Percentage":
        return `${value} %`;
      default:
        return `${value} ${translate(`${configuration.units}${pluralKey}`)}`;
    }
  };

  getConfigurationItem = (index, configuration, values) => {
    const valueTranslation = this.getValueTranslation(configuration, values);
    return (
      <div className="rowValue blockValue" key={index}>
        {valueTranslation}
      </div>
    );
  };

  render() {
    const { translate, scenarioData } = this.props;
    const { playerValuesSectionData } = this.state;
    const tableHeaderColumns = scenarioData.map((x) => x.name);
    return (
      <div className="customTable smallTable">
        <CustomTableHeader
          translate={translate}
          tableTitle={translate("playerConfigurable")}
          tableColumnNames={tableHeaderColumns}
        />
        {playerValuesSectionData.map((section, index) => {
          return (
            <div key={index} className="body">
              <div className="sectionName">
                <p>{translate(section.name)}</p>
              </div>
              <div className="sectionConfigurations">
                {section.configurations.map((configuration, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="rowItem">
                        <p>{translate(configuration.name)}</p>
                      </div>
                      {configuration.values.map((values, index) => {
                        const configurationItem = this.getConfigurationItem(index, configuration, values);
                        return configurationItem;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ScenarioPlayerValuesSection;
