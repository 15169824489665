import * as actionTypes from "./action-types";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { getStorage, setStorage, removeStorage, mergeDataToStorage } from "../../services/StorageService";
import { sendGetRequest, sendPostRequest } from "../../services/FetchService.js";
import * as API_PATHS from "../../constants/FetchApi.js";
import { sentryReportEvent } from "../../services/SentryReportService";

export const restoreSupplyChainPurchaseInformationFromStorage = () => {
  return async function (dispatch) {
    const cachedQuestionnaireInformation = getStorage(LOCALSTORAGE.supplychainQuestionnaire);
    if (cachedQuestionnaireInformation) {
      dispatch({
        type: actionTypes.RESTORE_STORAGE_PURCHASE_INFORMATION,
        payload: cachedQuestionnaireInformation,
      });
    }
    return true;
  };
};

export const commitSupplyChainBuyFormData = (data) => {
  return async function (dispatch) {
    setStorage(LOCALSTORAGE.supplychainQuestionnaire, data);
    dispatch({
      type: actionTypes.COMMIT_PURCHASE_FORM_DATA,
      payload: data,
    });
  };
};

export const resetCreateSimulationDefaults = () => {
  return { type: actionTypes.RESET_CREATE_SIMULATION_DEFAULTS };
};

export const resetSupplyChainBuySimulationStoreDefaults = () => {
  removeStorage(LOCALSTORAGE.supplychainQuestionnaire);
  return {
    type: actionTypes.RESET_SUPPLYCHAIN_BUY_SIMULATION_DEFAULTS,
  };
};

export const updateTransactionSupplyChainData = (transactionData) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_TRANSACTION_DATA, payload: transactionData });
  };
};

export const supplychainCreateSimulation = (createSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_CREATE_SIMULATION, createSimulationRequest);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: err });
    }
  };
};

export const supplychainCreateAutomaticSimulation = (createAutomaticSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_START });
    try {
      const response = await sendPostRequest(
        API_PATHS.SUPPLY_CHAIN_CREATE_AUTOMATIC_SIMULATION,
        createAutomaticSimulationRequest
      );
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.CREATE_SIMULATION_REQUEST_FAIL, payload: err });
    }
  };
};

export const supplyChainValidateDiscountCode = (discountCode) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE });
    try {
      const discountCodeResponse = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_VALIDATE_DISCOUNT_CODE, discountCode);
      if (!discountCodeResponse.data?.payload?.validDiscountCode) {
        dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_FAILED, payload: discountCodeResponse.data.message });
        return;
      }
      const payload = {
        discountCode: discountCodeResponse.data.payload.discountCode,
        discountedPrice: discountCodeResponse.data.payload.discountedPrice,
      };
      mergeDataToStorage(LOCALSTORAGE.supplychainQuestionnaire, payload);

      if (discountCodeResponse.data?.payload?.paymentSummaryId) {
        payload.paymentSummaryId = discountCodeResponse.data.payload.paymentSummaryId;
        mergeDataToStorage(LOCALSTORAGE.supplychainQuestionnaire, {
          paymentSummaryId: discountCodeResponse.data.payload.paymentSummaryId,
        });
        dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS, payload: payload });
        return;
      }

      dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_SUCCESS, payload: payload });
    } catch (err) {
      const sentryTag = {
        name: "supplychainDiscountCodeFailed",
        data: discountCode,
      };
      sentryReportEvent(err, sentryTag);
      dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_FAILED, payload: err });
    }
  };
};

export const supplyChainResetDiscountCode = () => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.VALIDATE_DISCOUNT_CODE_RESET });
  };
};

export const checkIfStudentHasPaid = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.CHECK_STUDENT_PAYMENT });
    try {
      const response = await sendGetRequest(API_PATHS.SUPPLY_CHAIN_GET_STUDENT_HAS_PAID, simulationId);
      if (response.data.outcome === false && response.data.message === "StudentHasPaidController_StudentHasNotPaid") {
        dispatch({ type: actionTypes.CHECK_STUDENT_PAYMENT_SUCCESSFUL, payload: false });
        return;
      }

      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CHECK_STUDENT_PAYMENT_FAILED, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CHECK_STUDENT_PAYMENT_SUCCESSFUL, payload: response.data.outcome });
    } catch (err) {
      dispatch({ type: actionTypes.CHECK_STUDENT_PAYMENT_FAILED, payload: err });
    }
  };
};

export const supplyChainConfirmPayment = (paymentIntentId) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.CONFIRM_PAYMENT_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_CONFIRM_PAYMENT, { paymentIntentId });
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.CONFIRM_PAYMENT_FAIL, payload: response.data.message });
        return;
      }
      dispatch({ type: actionTypes.CONFIRM_PAYMENT_SUCCESS, payload: response.data.payload });
    } catch (error) {
      dispatch({ type: actionTypes.CONFIRM_PAYMENT_FAIL, payload: error });
    }
  };
};
