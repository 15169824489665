import React from "react";
import { Grid } from "semantic-ui-react";
import TutorialFrame from "./TutorialFrame";

const TutorialsComponent = (props) => {
  const { translate, tutorials, handleVideoViewerModal } = props;

  return (
    <Grid>
      {tutorials
        .filter((productSection) => productSection.videos.length > 0)
        .sort((first, second) => first.product.localeCompare(second.product))
        .map((section, index) => {
          return (
            <Grid.Row key={index}>
              <Grid.Column width={16}>
                <h3 className="sectionVideoHeader">{translate(section.product)}</h3>
              </Grid.Column>
              {section.videos.map((video, index) => {
                return (
                  <TutorialFrame
                    key={index}
                    translate={translate}
                    video={video}
                    handleVideoViewerModal={handleVideoViewerModal}
                  />
                );
              })}
            </Grid.Row>
          );
        })}
    </Grid>
  );
};

export default TutorialsComponent;
