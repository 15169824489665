import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import InputComponent from "../helpers/InputComponent";
import "./purchasedCreambowStyles.css";

class CreateCreambowSimulationForm extends Component {
  handleFormChange = (id, data) => {
    this.props.formChanged(id, data);
  };

  render() {
    const translate = this.props.t;

    return (
      <>
        <InputComponent
          label={translate("simulationName")}
          id="simulationName"
          specialLabel={true}
          placeholder="i.e. OM 101"
          type="text"
          onError={!this.props.formData.simulationName.valid}
          errorMessage={translate(this.props.simulationNameErrorText)}
          value={this.props.formData.simulationName.value}
          handleChange={this.handleFormChange.bind(this)}
          labelAlignment="left"
        />
      </>
    );
  }
}

export default withTranslation(["Purchased", "Common", "CrisisCommon"])(CreateCreambowSimulationForm);
