import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false,
  //nsSeparator should be explicitly set. https://github.com/i18next/i18next/issues/1665
  nsSeparator: ":",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
