import React from "react";
import { Grid, Header } from "semantic-ui-react";
import VideoIframe from "./VideoIframe";
const TutorialFrame = (props) => {
  const { video, translate, handleVideoViewerModal } = props;
  const { title, description, url, thumbnailURL } = video;

  let customDescription = description;

  if (description.length > 150) {
    customDescription = description.substring(0, 150) + "...";
  }

  return (
    <Grid.Column mobile={16} tablet={8} computer={5}>
      <div className="videoFrameContainer">
        <VideoIframe
          thumbnailURL={thumbnailURL}
          url={url}
          title={title}
          handleVideoViewerModal={handleVideoViewerModal}
        />
        <div className="videoDescriptionContainer">
          <Header as="h5">{translate(title)}</Header>
          <p className="descriptionText">{customDescription}</p>
        </div>
      </div>
    </Grid.Column>
  );
};

export default TutorialFrame;
