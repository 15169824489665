import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import SimunationBuyQuestionnaireComponent from "./SimunationBuyQuestionnaireComponent";
import BackButton from "../helpers/BackButton";
import payIcon from "../../assets/pay-icon.png";
import {
  resetSimunationQuestionnaireStore,
  cacheSimunationQuestionnaireData,
} from "../../store/SimunationQuestionnaire/actions";
import "./simunationBuyStyles.css";

class SimunationBuyQuestionnaireScreen extends Component {
  componentDidMount() {
    this.props.resetSimunationQuestionnaireStore();
  }

  render() {
    const translate = this.props.t;
    const { numberOfStudents, isInstitution, advanceSimunation } = this.props.simunationQuestionnaire;
    const simunationBuyQuestionnaireComponent = (
      <SimunationBuyQuestionnaireComponent
        translate={translate}
        history={this.props.history}
        numberOfStudents={numberOfStudents}
        advanceSimunation={advanceSimunation}
        isInstitution={isInstitution}
        cacheSimunationQuestionnaireData={this.props.cacheSimunationQuestionnaireData}
      />
    );
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent hasIcon={payIcon} />
        <div className="questionnaireForm">{simunationBuyQuestionnaireComponent}</div>
        <BackButton translate={translate} history={this.props.history} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetSimunationQuestionnaireStore: () => dispatch(resetSimunationQuestionnaireStore()),
  cacheSimunationQuestionnaireData: (data) => dispatch(cacheSimunationQuestionnaireData(data)),
});

const mapStateToProps = (state) => {
  return {
    simunationQuestionnaire: {
      numberOfStudents: state.simunationQuestionnaire.numberOfStudents,
      isInstitution: state.simunationQuestionnaire.isInstitution,
      advanceSimunation: state.simunationQuestionnaire.advanceSimunation,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("Purchased", "Common")(withRouter(SimunationBuyQuestionnaireScreen))
);
