import React from "react";
import { Form, Input, TextArea } from "semantic-ui-react";
import HelpHoverComponent from "./HelpHoverComponent";
import "./input.css";

const InputComponent = (props) => {
  const {
    handleChange,
    label,
    onError,
    errorMessage,
    multiLine,
    id,
    rows,
    placeholder,
    type,
    value,
    min,
    counter,
    maxLength,
    length,
    helpMessage,
    labelAlignment,
    disabled,
    boldContent,
  } = props;

  const handleInputChange = (event, { value }) => {
    let id = event.target.id,
      data = value;
    handleChange(id, data);
  };

  let descriptionLabel = null;
  let inputSection = null;
  let errorMessageSpan = null;
  let counterSpan = null;
  let inputClassNames = null;
  let helpHoverComponent = null;

  if (helpMessage && helpMessage.length > 0) {
    helpHoverComponent = <HelpHoverComponent helpMessage={helpMessage} />;
  }

  if (label) {
    let labelClassNames = "labelContainer";
    if (labelAlignment === "left") {
      labelClassNames += " leftLabelAlignment";
    }

    descriptionLabel = (
      <div className={labelClassNames}>
        <label className="specialLabel">{label}</label>
        {helpHoverComponent}
      </div>
    );
  }

  if (onError && errorMessage) {
    errorMessageSpan = <span className="errorMessage">{errorMessage}</span>;
  }

  if (multiLine) {
    inputClassNames = "textAreaContainer";
    if (onError) {
      inputClassNames += " errorIndicator";
    }
    inputSection = (
      <div className={inputClassNames}>
        <TextArea
          className="customTextArea"
          id={id}
          rows={rows}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(event, { value }) => handleInputChange(event, { value })}
          disabled={disabled}
        />
      </div>
    );
  } else {
    inputClassNames = "inputContainer";
    if (onError) {
      inputClassNames += " errorIndicator";
    } else if (boldContent) {
      inputClassNames += " boldContent";
    }

    inputSection = (
      <div className={inputClassNames}>
        <Input
          className="customInput"
          id={id}
          placeholder={placeholder}
          type={type}
          min={min}
          value={value}
          error={onError}
          onChange={(event, { value }) => handleInputChange(event, { value })}
          disabled={disabled}
        />
      </div>
    );
  }

  if (counter) {
    let counterClassNames = "numberIndicators";
    if (length > maxLength) {
      counterClassNames += " errorCounter";
    }

    counterSpan = <span className={counterClassNames}>{`${length}/${maxLength}`}</span>;
  }

  return (
    <Form.Field>
      {descriptionLabel}
      {inputSection}
      {errorMessageSpan}
      {counterSpan}
    </Form.Field>
  );
};
export default InputComponent;
