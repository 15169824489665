import { discountCodeStatuses } from "./storeStatusDictionary";

export function generateDiscountCodeStatus(discountCode, discountedPrice) {
  if (typeof discountCode !== "string" || discountCode.length === 0) {
    return discountCodeStatuses.initial;
  }

  if (Number.isInteger(discountedPrice) && discountedPrice === 0) {
    return discountCodeStatuses.activatedPaid;
  }

  if (Number.isInteger(discountedPrice) && discountedPrice > 0) {
    return discountCodeStatuses.activated;
  }

  return discountCodeStatuses.initial;
}
