import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import StudentJoinTeamComponent from "./StudentJoinTeamComponent";
import { joinTeam } from "../../store/Medica/actions";
import { medicaConfirmPayment, updateTransactionData } from "../../store/MedicaQuestionnaire/actions";
import { getStorage, removeStorage, setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import JoinedTeamModal from "../modals/JoinedTeamModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import ErrorModal from "../modals/ErrorModal";
import PaymentVerificationModal from "../modals/PaymentVerificationModal";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";

class StudentJoinTeamScreen extends Component {
  constructor(props) {
    super(props);

    let paymentIntentId;
    const params = new URLSearchParams(window.location.search);
    if (params.has("payment_intent")) {
      paymentIntentId = params.get("payment_intent");
    }

    this.state = {
      openConfirmModal: false,
      teamName: "",
      showNoTeamMessage: false,
      showErrorModal: false,
      paymentIntentId: paymentIntentId,
    };
  }

  async componentDidMount() {
    await this.validatePayment();
    this.getTeamName();
  }

  validatePayment = async () => {
    if (typeof this.state.paymentIntentId === "string") {
      await this.props.confirmPayment(this.state.paymentIntentId);
    }
  };

  getTeamName = () => {
    const teamName = getStorage(LOCALSTORAGE.teamName);
    if (teamName) {
      this.handleConfirmModal(teamName);
    } else {
      this.setState({
        showNoTeamMessage: !this.state.showNoTeamMessage,
      });
    }
  };

  handleConfirmModal = (teamName) => {
    this.setState({
      openConfirmModal: !this.state.openConfirmModal,
      teamName: teamName,
    });
  };

  handleRedirection = () => {
    this.props.history.push(ROUTES.STUDENTS_LOGIN);
  };

  joinTeam = () => {
    this.getTeamName();
    const teamCode = getStorage(LOCALSTORAGE.teamCode);
    const { discountCode } = getStorage(LOCALSTORAGE.medicaQuestionnaire).discountCode || "";

    if (!teamCode || teamCode.length === 0) {
      this.setState({ showErrorModal: true });
    }

    this.props.joinTeam({
      teamCode: teamCode,
      discountCode: discountCode,
    });
    this.handleConfirmModal();
  };

  cancelJoinAction = () => {
    this.removeOldStoredData();
    this.props.history.push(ROUTES.STUDENTS_LOGIN);
  };

  removeOldStoredData = () => {
    removeStorage(LOCALSTORAGE.teamCode);
    removeStorage(LOCALSTORAGE.securityCode);
    removeStorage(LOCALSTORAGE.teamName);
    removeStorage(LOCALSTORAGE.simulationId);
    removeStorage(LOCALSTORAGE.activationCode);
    removeStorage(LOCALSTORAGE.medicaQuestionnaire);
  };

  closeErrorModal = () => {
    this.setState({ showErrorModal: false });
    this.props.history.replace(ROUTES.STUDENT_FAQ);
  };

  render() {
    const { t: translate, confirmPaymentStatus } = this.props;
    const { joiningTeam, joinTeamError, joinTeamSuccessfully } = this.props.medica;
    const userAlreadyRegisteredError = "User already registered in this team";
    let needRetry = false;
    const { showErrorModal } = this.state;

    if (
      confirmPaymentStatus.status === confirmPaymentStatuses.loading ||
      confirmPaymentStatus.status === confirmPaymentStatuses.fail
    ) {
      return <PaymentVerificationModal state={confirmPaymentStatus} retryAction={this.validatePayment} />;
    }

    if (joinTeamError !== userAlreadyRegisteredError) needRetry = true;

    let joinTeamMessage = null;
    let messageType = null;
    let teamNameFormatted = null;
    let confirmationModal = null;
    if (joinTeamSuccessfully) {
      this.removeOldStoredData();
      setStorage(LOCALSTORAGE.purchasedProduct, CONSTANT.PRODUCTS.MEDICA);
      joinTeamMessage = `${translate("joinTeamSuccess")} ${joinTeamSuccessfully.team}`;
      messageType = true;
    } else if ((joinTeamError || this.state.showNoTeamMessage) && !showErrorModal) {
      if (this.state.showNoTeamMessage) {
        joinTeamMessage = translate("noTeamMessageError");
      } else {
        joinTeamMessage = translate(joinTeamError);
      }
      messageType = false;
    }

    if (this.state.teamName) {
      teamNameFormatted = this.state.teamName.replace(/[+]/g, " ");
    }
    const confirmationBody = (
      <p>
        {translate("sureYouWantJoinTeam")} <strong className="teamName">{teamNameFormatted}</strong>?
      </p>
    );

    if (this.state.openConfirmModal && !showErrorModal) {
      confirmationModal = (
        <ConfirmationModal
          openConfirmModal={this.state.openConfirmModal}
          onConfirm={this.joinTeam}
          onCancel={this.cancelJoinAction}
          confirmationBody={confirmationBody}
          confirmButton={translate("Common:yes")}
          cancelButton={translate("Common:cancel")}
          headerContent={translate("Common:confirmation")}
        />
      );
    }

    return (
      <Container>
        {confirmationModal}
        <HeaderComponent translate={translate} showLanguageSelector={true} />
        <StudentJoinTeamComponent translate={translate} />
        <ErrorModal
          openErrorModal={this.state.showErrorModal}
          title={translate("Common:error")}
          message={translate("StudentJoinTeamScreen:noJoinTeamTeamCode")}
          handleErrorModal={this.closeErrorModal}
          translate={translate}
        />

        <JoinedTeamModal
          open={!joiningTeam && !showErrorModal}
          message={joinTeamMessage}
          messageType={messageType}
          joinTeam={this.joinTeam}
          translate={translate}
          history={this.props.history}
          updateTransactionData={this.props.updateTransactionData}
          showNoTeamMessage={this.state.showNoTeamMessage}
          removeStorage={removeStorage}
          needRetry={needRetry}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmPayment: (intentId) => dispatch(medicaConfirmPayment(intentId)),
  joinTeam: (data) => dispatch(joinTeam(data)),
  updateTransactionData: (data) => dispatch(updateTransactionData(data)),
});

const mapStateToProps = (state) => {
  return {
    confirmPaymentStatus: state.medicaQuestionnaire.confirmPayment,
    medica: {
      joiningTeam: state.medica.joiningTeam,
      joinTeamError: state.medica.joinTeamError,
      joinTeamSuccessfully: state.medica.joinTeamSuccessfully,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("StudentJoinTeamScreen", "Common")(withRouter(StudentJoinTeamScreen))
);
