import React, { Component, Fragment } from "react";
import { Grid, Header } from "semantic-ui-react";
import DashBoardContainer from "./DashBoardContainer";
import CurrentDayRefreshSection from "./CurrentDayRefreshSection";
import { withTranslation } from "react-i18next";

class DashBoardSection extends Component {
  shouldComponentUpdate(nextProps) {
    const { selectedTeams, selectedGraphs } = this.props;
    if (nextProps && nextProps.selectedTeams && nextProps.selectedGraphs) {
      if (nextProps.selectedTeams === selectedTeams && nextProps.selectedGraphs === selectedGraphs) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { t: translate, selectedTeams, currentSimulationDay, handleGraphModal, sections } = this.props;
    let currentDayRefreshSection = null;
    const dashboadRow = sections.map((section) => {
      return (
        <Fragment key={section.index}>
          <Grid.Column className="sectionGraphHeaderCrisis" width={16}>
            <Header as="h3">{translate(section.name)}</Header>
          </Grid.Column>
          <DashBoardContainer selectedTeams={selectedTeams} handleGraphModal={handleGraphModal} data={section.data} />
        </Fragment>
      );
    });

    if (currentSimulationDay) {
      currentDayRefreshSection = (
        <CurrentDayRefreshSection translate={translate} currentSimulationDay={currentSimulationDay} />
      );
    }

    return (
      <>
        {currentDayRefreshSection}
        <Grid>
          <Grid.Column width={16}>
            <Grid id="sectionTop">
              <Grid.Row only="computer" columns={3} stretched>
                {dashboadRow}
              </Grid.Row>
              <Grid.Row only="tablet" columns={2} stretched>
                {dashboadRow}
              </Grid.Row>
              <Grid.Row only="mobile" columns={1} stretched>
                {dashboadRow}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

export default withTranslation("CrisisCommon")(DashBoardSection);
