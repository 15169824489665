import React, { Component } from "react";
import BackButton from "../helpers/BackButton";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import { Container } from "semantic-ui-react";
import payIcon from "../../assets/pay-icon.png";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BuyQuestionnaireForm from "./buyQuestionnaireForm";

class SupplyChainBuyQuestionnaire extends Component {
  render() {
    const translate = this.props.t;
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent hasIcon={payIcon} />
        <div className="questionnaireForm">
          <BuyQuestionnaireForm />
        </div>
        <BackButton translate={translate} history={this.props.history} />
      </Container>
    );
  }
}

export default withTranslation("Common")(withRouter(SupplyChainBuyQuestionnaire));