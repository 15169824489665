import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { CHECK_AUTH, SUPPLY_CHAIN_JOIN_SIMULATION, SUPPLY_CHAIN_STUDENT_PAYMENT } from "../../constants/Routes";
import { getCurrentSession } from "../../services/AuthService";
import { setStorage } from "../../services/StorageService";
import LoadingComponent from "../helpers/LoadingComponent";

class SupplyChainJoinSimulationPreamble extends Component {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let simulationId;
    let paymentSummaryId;
    for (let param of query.entries()) {
      if (param[0] === "simulationId") {
        simulationId = param[1];
      }
      if (param[0] === "paymentSummaryId") {
        paymentSummaryId = param[1];
      }
    }

    if (!simulationId) {
      this.props.history.replace(CHECK_AUTH);
      return;
    }

    setStorage(LOCALSTORAGE.supplyChainJoinSimulationId, simulationId);
    setStorage(LOCALSTORAGE.supplyChainPaymentSummaryId, paymentSummaryId);
    setStorage(LOCALSTORAGE.activationCode, simulationId);

    const session = await getCurrentSession();
    if (!session) {
      return;
    }
    if (typeof paymentSummaryId === "string" && paymentSummaryId.length > 0) {
      this.props.history.push(SUPPLY_CHAIN_JOIN_SIMULATION);
    } else {
      this.props.history.push(SUPPLY_CHAIN_STUDENT_PAYMENT);
    }
  }

  render() {
    const translate = this.props.t;
    return (
      <>
        <LoadingComponent fullSize={true} image={true} message={translate("loadingPage")} />
      </>
    );
  }
}

const HOC = compose(withRouter, withTranslation("AuthComponents", "Common"));

export default HOC(SupplyChainJoinSimulationPreamble);
