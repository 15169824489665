import React from "react";
import "./modals.css";
import { Button, Modal, List } from "semantic-ui-react";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import * as ROUTES from "../../constants/Routes";
import { flattenItemSections } from "../utilities/ScenarioInformationUtilities";

const CreateNewScenarioModal = (props) => {
  const {
    translate,
    openCreateNewScenarioModal,
    scenarioData,
    handleCreateScenarioModal,
    professorCreateNewScenario,
    scenario,
    history,
    resetProfessorCreateNewScenarioStore,
  } = props;

  const flattenItems = flattenItemSections();

  const { loadingCreateNewScenario, createNewScenarioSuccess, createNewScenarioMessage } = scenario;

  const newScenarioItems = Object.keys(scenarioData);

  const postProfessorCreateNewScenario = () => {
    professorCreateNewScenario(scenarioData);
  };

  const goScenarioManagerScreenAndResetStore = () => {
    resetProfessorCreateNewScenarioStore();
    history.replace(ROUTES.SCENARIO_MANAGER);
  };

  const closeModalAndResetStore = () => {
    handleCreateScenarioModal();
    resetProfessorCreateNewScenarioStore();
  };

  let actionButtonsContent = null;
  let content = (
    <>
      <p className="createNewScenarioConfirmationText">{translate("areYouSureDoYouWantCreateNewScenario")}</p>
      <List divided verticalAlign="middle">
        {newScenarioItems.map((item, index) => {
          const itemFinded = flattenItems.find((flatItem) => flatItem.name === item);
          let valueData = scenarioData[item];
          let itemWithUnits = "";

          if (itemFinded) {
            itemWithUnits = `${valueData} ${translate(itemFinded.units)}`;
          }

          if (typeof valueData === "boolean") {
            if (valueData === true) {
              itemWithUnits = translate("Common:yes");
            } else {
              itemWithUnits = translate("Common:No");
            }
          }

          if (item === "initialCash") {
            itemWithUnits = Number(valueData).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
          }

          return (
            <List.Item key={index}>
              <div className="itemContainer">
                <div className="itemName">{translate(item)}</div>
                <div className="itemValue">{itemWithUnits}</div>
              </div>
            </List.Item>
          );
        })}
      </List>
    </>
  );

  if (loadingCreateNewScenario) {
    actionButtonsContent = <LoadingComponent />;
  } else {
    actionButtonsContent = (
      <>
        <Button content={translate("create")} onClick={postProfessorCreateNewScenario} />
        <Button content={translate("Common:cancel")} onClick={handleCreateScenarioModal} />
      </>
    );

    if (createNewScenarioMessage !== "") {
      const messageType = createNewScenarioSuccess;

      if (createNewScenarioSuccess) {
        actionButtonsContent = (
          <Button content={translate("goToList")} onClick={goScenarioManagerScreenAndResetStore} />
        );
      } else {
        actionButtonsContent = <Button content={translate("Common:cancel")} onClick={closeModalAndResetStore} />;
      }

      content = <MessagesComponent messageType={messageType} message={translate(createNewScenarioMessage)} />;
    }
  }

  return (
    <Modal
      className="createNewScenarioModal"
      size="small"
      open={openCreateNewScenarioModal}
      onClose={closeModalAndResetStore}>
      <Modal.Header>{translate("newScenarioConfirmation")}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>{actionButtonsContent}</Modal.Actions>
    </Modal>
  );
};

export default CreateNewScenarioModal;
