import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import InputComponent from "../helpers/InputComponent";
import NavigationButton from "../helpers/NavigationButton";
import { connect } from "react-redux";
import { compose } from "redux";
import { commitSupplyChainBuyFormData } from "../../store/SupplyChainBuySimulation/actions";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";
import DropDownComponent from "../helpers/DropDownComponent";

class BuyQuestionnaireForm extends Component {
  state = {
    form: {
      numberOfStudents: {
        value: 4,
        valid: true,
        rules: {
          minValue: 4,
        },
      },
      isInstitution: {
        value: true,
        valid: true,
        rules: {},
      },
    },
  };

  handleFormChange = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormErrors(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;

    this.setState({ form: updatedForm });
  };

  onSelectionInstitution = (val) => {
    this.handleFormChange("isInstitution", val);
  };

  validateFormErrors = (value, rules) => {
    let isValid = true;
    if (rules.minValue) {
      isValid = isValid && value >= rules.minValue;
    }
    return isValid;
  };

  handleContinue = () => {
    const dataForm = this.state.form;
    const formValid = Object.keys(dataForm).every((key) => {
      const dataEntry = dataForm[key];
      return dataEntry.valid;
    });

    if (!formValid) {
      return;
    }

    const storeData = {};
    Object.keys(dataForm).forEach((key) => {
      const dataEntry = dataForm[key];
      storeData[key] = dataEntry["value"];
    });

    this.props.commitSupplyChainBuyFormData(storeData);
    if (this.state.form.isInstitution.value) {
      this.props.history.push(ROUTES.SUPPLY_CHAIN_PAYMENT);
    } else {
      this.props.history.push(ROUTES.SUPPLY_CHAIN_CREATE_SIMULATION);
    }
  };

  optionsWhoPaying = () => [
    {
      key: 1,
      text: this.props.t("Common:institution"),
      value: true,
    },
    {
      key: 2,
      text: this.props.t("Common:Students"),
      value: false,
    },
  ];

  render() {
    const { t: translate } = this.props;
    const { isInstitution } = this.state.form;

    const inputComponent = (
      <InputComponent
        label={translate("numberOfStudents")}
        id="numberOfStudents"
        type="number"
        min="0"
        onError={!this.state.form.numberOfStudents.valid}
        errorMessage={translate("numberOfStudentsError")}
        value={this.state.form.numberOfStudents.value}
        handleChange={this.handleFormChange.bind(this)}
      />
    );

    return (
      <>
        <Form>
          <DropDownComponent
            label={translate("whoPaysSelected")}
            id="isInstitution"
            helpMessage={translate("whoPaysHelp")}
            options={this.optionsWhoPaying()}
            placeholder={translate("pleaseChooseOne")}
            onSelection={this.onSelectionInstitution}
            selectError={!this.state.form.isInstitution.valid}
            value={this.state.form.isInstitution.value}
          />
          {isInstitution.value === true ? inputComponent : null}

          <NavigationButton action={this.handleContinue} label={translate("Common:next")} />
        </Form>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  commitSupplyChainBuyFormData: (data) => dispatch(commitSupplyChainBuyFormData(data)),
});

const HOC = compose(
  connect(null, mapDispatchToProps),
  withTranslation(["SupplyChainBuySimulation", "Common"]),
  withRouter
);
export default HOC(BuyQuestionnaireForm);
