export const CONFIRM_PAYMENT_START = "MedicaQuestionnaire/CONFIRM_PAYMENT_START";
export const CONFIRM_PAYMENT_SUCCESS = "MedicaQuestionnaire/CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAIL = "MedicaQuestionnaire/CONFIRM_PAYMENT_FAIL";

export const CHECK_STORAGE_INFORMATION = "MedicaQuestionnaire/CHECK_STORAGE_INFORMATION";
export const UPDATE_INSTITUTION_STUDENTS_NUMBER = "MedicaQuestionnaire/UPDATE_INSTITUTION_STUDENTS_NUMBER";
export const UPDATE_SIMULATION_INFORMATION = "MedicaQuestionnaire/UPDATE_SIMULATION_INFORMATION";
export const VALIDATE_DISCOUNT_CODE = "MedicaQuestionnaire/VALIDATE_DISCOUNT_CODE";
export const VALIDATE_DISCOUNT_CODE_SUCCESSFUL = "MedicaQuestionnaire/VALIDATE_DISCOUNT_CODE_SUCCESSFUL";
export const VALIDATE_DISCOUNT_CODE_FAILED = "MedicaQuestionnaire/VALIDATE_DISCOUNT_CODE_FAILED";
export const UPDATE_TRANSACTION_DATA = "MedicaQuestionnaire/UPDATE_TRANSACTION_DATA";
export const RESET_QUESTIONNAIRE_STORE = "MedicaQuestionnaire/RESET_QUESTIONNAIRE_STORE";
export const RESET_DISCOUNT_CODE = "MedicaQuestionnaire/RESET_DISCOUNT_CODE_STORE";
