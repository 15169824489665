import React from "react";
import HelpHoverComponent from "../../helpers/HelpHoverComponent";
import "./title.css";

const MainTitleComponent = (props) => {
  let reducedSubTitle = null;
  let icon = null;
  let helpHoverComponent = null;

  if (props.hasIcon) {
    icon = <img src={props.hasIcon} alt="Pay Icon" />;
  }

  if (props.reducedSubTitle) {
    reducedSubTitle = "reducedSubTitle";
  }

  if (props.hasHelp) {
    helpHoverComponent = <HelpHoverComponent helpMessage={props.helpMessage} size="large" iconSize="lg" />;
  }

  return (
    <div className="titleContainer">
      <span className="blackLine" />
      <div className="titleTextContent">
        <p className="title">{props.title}</p>
        {helpHoverComponent}
      </div>
      <p className={`subtitle ${reducedSubTitle}`}>{props.subtitle}</p>
      {icon}
    </div>
  );
};

export default MainTitleComponent;
