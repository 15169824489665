import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import CalendarComponent from "../helpers/CalendarComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import DropDownComponent from "../helpers/DropDownComponent";
import InputComponent from "../helpers/InputComponent";
import "./purchasedSupplyChainStyles.css";
import * as CONSTANT from "../../constants/UniversalConstants";
import {
  supplyChainGameModeOptions,
  supplyChainTimeBetweenAdvancesOptions,
} from "../utilities/SupplyChainSimulationUtilities";

class CreateSupplyChainSimulationForm extends Component {
  state = {
    dateToModify: "",
    openCalendar: false,
  };

  handleFormChange = (id, data) => {
    this.props.formChanged(id, data);
  };

  handleCalendar = (action) => {
    this.setState(() => ({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    }));
  };

  onChangeTimeBetweenAdvances = (id, pace) => {
    this.handleFormChange(id, pace);
    const { startTime, endTime } = this.props.formData;

    this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, pace);
  };

  onChangeStartTime = (value) => {
    const { professorAdvance, timeBetweenAdvances, startTime, endTime } = this.props.formData;
    this.props.setHour("startTime", value);

    if (professorAdvance.value === false) {
      this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, timeBetweenAdvances.value);
    }
  };

  matchEndAndStartTimeHourDivisibility = (startHour, endHour, dividerInt) => {
    if (dividerInt === 1) return;

    const startTimeDivisibility = startHour % dividerInt;
    const endTimeDivisibility = endHour % dividerInt;

    if (startTimeDivisibility !== endTimeDivisibility) {
      const newEndHour = CONSTANT.HOUR_NUMBERS.find((x) => x.value % dividerInt === startTimeDivisibility).value;
      this.props.setHour("endTime", newEndHour);
    }
  };

  render() {
    const translate = this.props.t;
    let calendarComponent = null;
    let selectedDate = null;

    const gameModeOptions = supplyChainGameModeOptions(translate);

    const professorManagedProgressOptions = [
      {
        key: 1,
        text: translate("Common:yes"),
        value: true,
      },
      {
        key: 2,
        text: translate("Common:no"),
        value: false,
      },
    ];

    const timeBetweenAdvancesOptions = supplyChainTimeBetweenAdvancesOptions(translate);

    if (this.state.dateToModify === "startDate") {
      selectedDate = this.props.segmentedStartTime.date;
    } else {
      selectedDate = this.props.segmentedEndTime.date;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <div className="calendarPosition">
          <CalendarComponent
            translate={translate}
            handleCalendar={this.handleCalendar}
            dateToModify={this.state.dateToModify}
            onDateClick={(value) => this.props.setDate(value, this.state.dateToModify)}
            selectedDate={selectedDate}
            language={this.props.language}
          />
        </div>
      );
    }

    let endTimeOptions = null;
    if (this.props.formData.professorAdvance.value === false && this.props.formData.timeBetweenAdvances.value > 1) {
      const startTimeDivisibility = this.props.segmentedStartTime.hour % this.props.formData.timeBetweenAdvances.value;
      endTimeOptions = CONSTANT.HOUR_NUMBERS.filter(
        (x) => x.value % this.props.formData.timeBetweenAdvances.value === startTimeDivisibility
      ).map((hour) => ({
        key: hour.key,
        text: hour.value,
        value: hour.value,
      }));
    }

    const automaticSimulationEntries = this.props.formData.professorAdvance.value ? null : (
      <>
        <DropDownComponent
          id="advancePeriod"
          label={translate("SupplyChainPurchased:advancePeriod")}
          options={timeBetweenAdvancesOptions}
          onSelection={this.onChangeTimeBetweenAdvances.bind(this, "timeBetweenAdvances")}
          value={this.props.formData.timeBetweenAdvances.value}
          labelAlignment="left"
        />
        <DateTimeSelector
          label={translate("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar.bind(this)}
          onFocus={() => this.setState({ dateToModify: "startDate" })}
          dateToSet={CONSTANT.START_DATE}
          settingHour={(event, value) => this.onChangeStartTime(value.value)}
          date={this.props.segmentedStartTime.date}
          hour={this.props.segmentedStartTime.hour}
          hourFormat={this.props.segmentedStartTime.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={translate("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar.bind(this)}
          onFocus={() => this.setState({ dateToModify: "endDate" })}
          dateToSet={CONSTANT.END_DATE}
          settingHour={(event, value) => this.props.setHour("endTime", value.value)}
          date={this.props.segmentedEndTime.date}
          hour={this.props.segmentedEndTime.hour}
          hourFormat={this.props.segmentedEndTime.hourFormat}
          language={this.props.language}
          labelAlignment="left"
          timeOptions={endTimeOptions}
        />
      </>
    );

    return (
      <>
        {calendarComponent}
        <InputComponent
          label={translate("simulationName")}
          id="simulationName"
          specialLabel={true}
          placeholder="i.e. OM 101"
          type="text"
          onError={!this.props.formData.simulationName.valid}
          errorMessage={translate(this.props.simulationNameErrorText)}
          value={this.props.formData.simulationName.value}
          handleChange={this.handleFormChange.bind(this)}
          labelAlignment="left"
        />
        <DropDownComponent
          id="professorAdvance"
          label={translate("professorAdvanceSimulation")}
          helpMessage={translate("medicaProfessorAdvanceSimulationHelp")}
          options={professorManagedProgressOptions}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.handleFormChange.bind(this, "professorAdvance")}
          value={this.props.formData.professorAdvance.value}
          labelAlignment="left"
        />
        {automaticSimulationEntries}
        <DropDownComponent
          id="gameMode"
          label={translate("SupplyChainPurchased:GameModeTitle")}
          options={gameModeOptions}
          onSelection={this.handleFormChange.bind(this, "gameMode")}
          value={this.props.formData.gameMode.value}
          labelAlignment="left"
        />

        <p>{translate("SupplyChainCommon:GameModeHelp")}</p>
        <ul className="create-supplychain-simulation__game-mode-help-list">
          <li>{translate("SupplyChainCommon:OneStationUnknownPeersHelp")}</li>
          <li>{translate("SupplyChainCommon:OneStationKnownPeersHelp")}</li>
          <li>{translate("SupplyChainCommon:AllStationsKnownPeersHelp")}</li>
          <li>{translate("SupplyChainCommon:LastMileHelp")}</li>
        </ul>
      </>
    );
  }
}

export default withTranslation(["Purchased", "Common", "SupplyChainCommon", "SupplyChainPurchased"])(
  CreateSupplyChainSimulationForm
);
