import React, { Component } from "react";
import "./Calendar.css";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getLanguagePrefix } from "../utilities/LanguageUtilities";

class CalendarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: props.selectedDate ? moment(props.selectedDate) : moment(),
      selectedDate: props.selectedDate ? props.selectedDate : moment(),
      dateToModify: this.props.dateToModify,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.calendar && !this.calendar.contains(event.target)) {
      this.handleCalendar();
    }
  };

  renderHeader() {
    const dateFormat = "MMMM YYYY";
    const { language, translate } = this.props;
    const languagePrefix = getLanguagePrefix(language);

    return (
      <Grid>
        <Grid.Column className="headerCalendar" width={8}>
          {moment(this.state.currentMonth).locale(languagePrefix).format(dateFormat)}
        </Grid.Column>
        <Grid.Column className="calendarHeaderButton" width={8} onClick={this.handleCalendar}>
          {translate("close")}
        </Grid.Column>
      </Grid>
    );
  }

  renderDays() {
    const { language } = this.props;
    const languagePrefix = getLanguagePrefix(language);
    const dateFormat = "ddd";
    const days = [];
    let startDate = moment(this.state.currentMonth).startOf("week");

    for (let i = 0; i < 7; i++) {
      days.push(
        <Grid.Column className="daysNames" key={i}>
          {moment(moment(startDate).locale(languagePrefix).add(i, "days")).format(dateFormat)}
        </Grid.Column>
      );
    }

    return <Grid columns={7}>{days}</Grid>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = moment(currentMonth).startOf("month");
    const monthEnd = moment(monthStart).endOf("month");
    const startDate = moment(monthStart).startOf("week");
    const endDate = moment(monthEnd).endOf("week");
    const dateFormat = "D";

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = moment(day).format(dateFormat);
        const cloneDay = day;
        days.push(
          <Grid.Column
            className={`daysNumbers ${
              !moment(monthStart).isSame(day, "month") || moment(day) < moment().subtract(1, "days")
                ? "disabled"
                : moment(selectedDate).isSame(day, "day")
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => this.onDateClick(moment(cloneDay))}>
            <span
              className={`number ${
                !moment(monthStart).isSame(day, "month")
                  ? "disabled"
                  : moment(selectedDate).isSame(day, "day")
                  ? "selected"
                  : ""
              }`}>
              {formattedDate}
            </span>
          </Grid.Column>
        );
        day = moment(day).add(1, "day");
      }
    }
    return <Grid columns={7}>{days}</Grid>;
  }

  onDateClick = (day) => {
    this.props.onDateClick(moment(day).format("YYYY-MM-DD"), this.state.dateToModify);
    this.handleCalendar();
  };

  nextMonth = () => {
    this.setState({
      currentMonth: moment(this.state.currentMonth).add(1, "month"),
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: moment(this.state.currentMonth).subtract(1, "month"),
    });
  };

  handleCalendar = () => {
    this.props.handleCalendar();
  };

  setToday = () => {
    this.onDateClick(moment().startOf("day"));
    this.handleCalendar();
  };

  render() {
    return (
      <div ref={(node) => (this.calendar = node)}>
        <Grid verticalAlign="middle" className="calendarContainer">
          <Grid.Column textAlign="center" width={2} onClick={this.prevMonth}>
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth color="#0379ba" />
          </Grid.Column>
          <Grid.Column width={12}>
            {this.renderHeader()}
            {this.renderDays()}
            {this.renderCells()}
          </Grid.Column>
          <Grid.Column textAlign="center" width={2} onClick={this.nextMonth}>
            <FontAwesomeIcon icon={faChevronRight} fixedWidth color="#0379ba" />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default CalendarComponent;
