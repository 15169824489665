import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import InputComponent from "../helpers/InputComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import { sentryReportEvent } from "../../services/SentryReportService";

class ActivationCodeModal extends Component {
  state = {
    discountCode: "",
    retryActivated: false,
    errorEmptyInput: false,
    errorInputMessage: "",
  };

  handleChange = (id, data) => {
    this.setState({
      [id]: data,
      errorEmptyInput: false,
    });
  };

  handleClose = () => {
    this.setState({
      discountCode: "",
      retryActivated: false,
    });
    this.resetDiscountCodeStore();
    this.props.handleDiscountModal();
  };

  validateDiscountCode = () => {
    const { validateDiscountCode, translate } = this.props;
    const { discountCode } = this.state;

    if (discountCode === "") {
      this.setState({
        errorEmptyInput: true,
        errorInputMessage: translate("writeValidCode"),
      });
    }
    this.setState({ retryActivated: false });
    validateDiscountCode(discountCode);
  };

  retryCode = () => {
    this.setState({
      retryActivated: true,
      discountCode: "",
    });
    this.resetDiscountCodeStore();
  };

  resetDiscountCodeStore = () => {
    const { resetDiscountCode, updateStorage, discountCodeStatus } = this.props;
    if (!discountCodeStatus.discountActivated) {
      const discountCodeInitialValues = {
        discountActivated: null,
        discountCodeMessage: "",
      };
      resetDiscountCode();
      updateStorage(discountCodeInitialValues);
    }
  };

  render() {
    const translate = this.props.translate;
    const { discountCode, errorEmptyInput, errorInputMessage, retryActivated } = this.state;
    const { loadingDiscountCode, discountCodeMessage, discountActivated } = this.props.discountCodeStatus;
    let actionButton = null;
    let inputComponent = null;
    let loader = null;
    let messagesComponent = null;

    if (discountActivated === false && discountCodeMessage === "discountSuccess") {
      const sentryTag = {
        name: "falseDiscountActivated",
        data: discountCode,
      };
      const sentryExtras = [
        { title: "discountActivated", value: discountActivated },
        { title: "discountCodeMessage", value: discountCodeMessage },
        { title: "retryActivated", value: retryActivated },
      ];
      sentryReportEvent("Failed abogados discount code validation", sentryTag, sentryExtras);
    }

    if (!loadingDiscountCode) {
      if ((discountActivated || discountCodeMessage) && !retryActivated) {
        const messageToDisplay = discountActivated ? translate("discountSuccess") : translate(discountCodeMessage);
        messagesComponent = <MessagesComponent messageType={discountActivated} message={messageToDisplay} />;
      } else {
        inputComponent = (
          <InputComponent
            type="text"
            id="discountCode"
            placeholder={translate("yourCode")}
            handleChange={this.handleChange}
            onError={errorEmptyInput}
            errorMessage={errorInputMessage}
            value={discountCode}
          />
        );
      }
    } else {
      loader = <div className="loader" />;
    }

    if (discountActivated || (discountCodeMessage && !retryActivated)) {
      if (!discountActivated) {
        actionButton = <Button content={translate("retry")} onClick={() => this.retryCode()} />;
      }
    } else {
      actionButton = (
        <Button disabled={loadingDiscountCode} content={translate("validate")} onClick={this.validateDiscountCode} />
      );
    }

    return (
      <Modal size="tiny" open={this.props.open} onClose={this.handleClose}>
        <Modal.Header>{translate("discountCode")}</Modal.Header>
        <Modal.Content>
          <>
            {loader}
            {inputComponent}
            {messagesComponent}
          </>
        </Modal.Content>
        <Modal.Actions>
          {actionButton}
          <Button
            content={discountActivated ? translate("accept") : translate("cancel")}
            onClick={() => this.handleClose()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ActivationCodeModal;
