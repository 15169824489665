import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import TeamsHandlers from "../dashBoards/TeamsHandlers";
import { checkControlStanding } from "./utilities";

class TeamsListModal extends Component {
  state = {
    teamsList: [],
    areAllChecked: true,
    areAllUnchecked: false,
    standingModified: false,
  };

  componentDidMount() {
    this.setState({
      teamsList: this.props.teamsList,
    });
  }

  componentDidUpdate(lastProps) {
    const { teamsList } = this.props;

    if (lastProps.selectedGraphs !== this.props.selectedGraphs) {
      this.setState({
        teamsList: teamsList,
      });
    }
  }

  handleCheckChildElement = (event) => {
    const { teamsList } = this.state;
    let teams = [];
    this.setState({
      checkType: "",
    });
    teamsList.forEach((team) => {
      if (team.name === event.target.value) {
        team.isChecked = event.target.checked;
      }
      teams.push(team);
    });
    this.setState({
      teamsList: teams,
      selection: 0,
    });
    this.props.modifySelectedTeamsList(teams);
  };

  handleAllChecked = (event) => {
    const { teamsList, checkType } = this.state;
    let teams = [];
    if (checkType !== event.target.id) {
      if (event.target.id === "selectAll") {
        teamsList.forEach((team) => {
          team.isChecked = true;
          teams.push(team);
        });
      } else {
        teamsList.forEach((team) => {
          team.isChecked = false;
          teams.push(team);
        });
      }
      this.props.modifySelectedTeamsList(teams);
      this.setState({
        teamsList: teams,
        checkType: event.target.id,
        selection: 0,
      });
    }
  };

  handleTopSelection = (selection) => {
    const { standings } = this.props;
    const { teamsList, standingModified } = this.state;
    const controlStanding = checkControlStanding(standings, selection);
    let newTeamsList = [];

    teamsList.forEach((team) => {
      if (!standingModified) {
        this.setState({
          standingModified: true,
        });

        if (controlStanding <= selection) {
          if (team.standing > controlStanding) {
            team.standing = team.standing - 1;
          }
        }
      }

      if (team.standing > selection || team.name === "CONTROL") {
        team.isChecked = false;
      } else {
        team.isChecked = true;
      }
      newTeamsList.push(team);
    });

    this.props.modifySelectedTeamsList(newTeamsList);
    this.setState({
      teamsList: newTeamsList,
      checkType: "top",
      selection: selection,
    });
  };

  showStandingNumber = (team) => {
    const { checkType, selection } = this.state;
    let showStanding = "";
    if (checkType === "top" && selection >= team.standing && team.name !== "CONTROL") {
      showStanding = ` (${team.standing})`;
    }

    return showStanding;
  };

  render() {
    const { translate, openTeamsListModal, handleTeamsListModal } = this.props;
    const { teamsList, selection } = this.state;
    const itemsList = teamsList.map((team) => {
      const showStanding = this.showStandingNumber(team);
      let disableTeam = false;

      return (
        <Form.Field key={team.index} disabled={disableTeam}>
          <div className="dashBoardTeamItem">
            <input
              disabled={disableTeam}
              type="checkbox"
              className="dashBoardTeamCheckbox"
              checked={team.isChecked}
              id={team.name}
              value={team.name}
              onChange={this.handleCheckChildElement}
            />

            <label htmlFor={team.name} className="teamItemLabel">
              <span style={{ color: team.color }}>{team.icon}</span>
              {team.name}
              {showStanding}
            </label>
          </div>
        </Form.Field>
      );
    });

    return (
      <Modal size="tiny" open={openTeamsListModal} onClose={handleTeamsListModal}>
        <Modal.Header>
          {translate("chooseATeam")}
          <TeamsHandlers
            translate={translate}
            teamsList={teamsList}
            handleAllChecked={this.handleAllChecked}
            handleTopSelection={this.handleTopSelection}
            selection={selection}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form>{itemsList}</Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content={"Ok"} onClick={handleTeamsListModal} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default TeamsListModal;
