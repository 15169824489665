export const GET_SCENARIO_INFORMATION = "Scenario/GET_SCENARIO_INFORMATION";

export const GET_SCENARIO_INFORMATION_SUCCESSFULL = "Scenario/GET_SCENARIO_INFORMATION_SUCCESSFULL";

export const GET_SCENARIO_INFORMATION_FAILED = "Scenario/GET_SCENARIO_INFORMATION_FAILED";

export const GET_PROFESSOR_SCENARIO_LIST = "Scenario/GET_PROFESSOR_SCENARIO_LIST";

export const GET_PROFESSOR_SCENARIO_LIST_SUCCESSFULL = "Scenario/GET_PROFESSOR_SCENARIO_LIST_SUCCESSFULL";
export const GET_PROFESSOR_SCENARIO_LIST_FAILED = "Scenario/GET_PROFESSOR_SCENARIO_LIST_FAILED";
export const PROFESSOR_CREATE_NEW_SCENARIO = "Scenario/PROFESSOR_CREATE_NEW_SCENARIO";
export const PROFESSOR_CREATE_NEW_SCENARIO_SUCCESSFULL = "Scenario/PROFESSOR_CREATE_NEW_SCENARIO_SUCCESSFULL";
export const PROFESSOR_CREATE_NEW_SCENARIO_FAILED = "Scenario/PROFESSOR_CREATE_NEW_SCENARIO_FAILED";
export const RESET_PROFESSOR_CREATE_NEW_SCENARIO_STORE = "Scenario/RESET_PROFESSOR_CREATE_NEW_SCENARIO_STORE";
export const PROFESSOR_DELETE_SCENARIO = "Scenario/PROFESSOR_DELETE_SCENARIO";
export const PROFESSOR_DELETE_SCENARIO_SUCCESSFULL = "Scenario/PROFESSOR_DELETE_SCENARIO_SUCCESSFULL";
export const PROFESSOR_DELETE_SCENARIO_FAILED = "Scenario/PROFESSOR_DELETE_SCENARIO_FAILED";
export const RESET_PROFESSOR_DELETE_SCENARIO_STORE = "Scenario/RESET_PROFESSOR_DELETE_SCENARIO_STORE";
