import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Container, Grid } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import ActionButtons from "./ActionButtons";
import CreambowDetailComponent from "./CreambowDetailComponent";
import ErrorModal from "../modals/ErrorModal";
import CrisisShareSimulationModal from "../modals/CrisisShareModal";
import AdvanceSimulationModal from "../modals/CreambowAdvanceSimulationModal";
import "./SimulationDetailStyles.css";
import * as ROUTES from "../../constants/Routes";
import { getStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { creambowGetSimulationDetails } from "../../store/CreambowSimulationDetails/actions";
import { createCreambowTeamLink } from "../utilities/ForioSimulationUtilities";

class CreambowDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: false,
      openShareModal: false,
      openAdvanceModal: false,
      messageSuccess: true,
      simulationSetUpError: false,
      isWarning: false,
    };
  }

  componentDidMount() {
    this.getSimulationDetails();
  }

  getSimulationId = () => {
    const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation);
    return requestedSimulationId;
  };

  getSimulationDetails = () => {
    const { creambowGetSimulationDetails } = this.props;
    const requestedSimulationId = this.getSimulationId();
    creambowGetSimulationDetails(requestedSimulationId);
  };

  handleAdvanceModal = () => {
    this.setState((state) => ({ openAdvanceModal: !state.openAdvanceModal }));
  };

  handleShareModal = () => {
    this.setState((state) => ({ openShareModal: !state.openShareModal }));
  };

  handleErrorModal = () => {
    this.setState((state) => ({
      openErrorModal: !state.openErrorModal,
      isWarning: false,
    }));
  };

  handleAdvanceModal = () => {
    this.setState((state) => ({ openAdvanceModal: !state.openAdvanceModal }));
  };

  render() {
    const translate = this.props.t;
    const { openErrorModal, openShareModal, openAdvanceModal, messageSuccess, errorTitle, isWarning } = this.state;
    let shareSimulationModal = null;
    let errorModal = null;
    let advanceModal = null;
    let actionButtons = null;
    let header = null;
    let dropdown = null;

    const errorMessage = this.props.creambowSimulationDetailsRequestStatus.errorMessage;
    const errorMessageTranslation = this.props.i18n.exists(errorMessage)
      ? translate(errorMessage)
      : translate(`CrisisDetailScreen:${errorMessage}`);
    errorModal = (
      <ErrorModal
        handleErrorModal={this.handleErrorModal}
        translate={translate}
        warning={isWarning}
        openErrorModal={openErrorModal}
        title={translate(errorTitle)}
        message={errorMessageTranslation}
      />
    );

    if (errorMessage !== "") {
      errorModal = (
        <ErrorModal
          handleErrorModal={this.handleErrorModal}
          translate={translate}
          openErrorModal={errorMessage !== "" && !openErrorModal}
          message={errorMessageTranslation}
        />
      );
    }

    const loadingDetails = this.props.creambowSimulationDetailsRequestStatus.loading;
    const detailsValid = this.props.creambowSimulationDetailsRequestStatus.detailsValid;
    const simulationDetails = this.props.creambowSimulationDetails;
    if (detailsValid) {
      shareSimulationModal = (
        <CrisisShareSimulationModal
          translate={translate}
          open={openShareModal}
          shareLink={createCreambowTeamLink(simulationDetails.id)}
          titleModal={translate("ShareModal:title")}
          titleSecondaryButton={translate("ShareModal:cancel")}
          handleSecondaryButton={this.handleShareModal}
          messageType={messageSuccess}
        />
      );

      advanceModal = (
        <AdvanceSimulationModal
          open={openAdvanceModal}
          handleAdvanceModal={this.handleAdvanceModal}
          getSimulationDetails={this.getSimulationDetails}
        />
      );

      actionButtons = (
        <ActionButtons
          translate={translate}
          location={this.props.location}
          history={this.props.history}
          handleShareModal={this.handleShareModal}
          handleAdvanceModal={this.handleAdvanceModal}
          simulationDetails={simulationDetails}
          detailsLoaded={detailsValid}
        />
      );
      dropdown = <ActionButtons {...actionButtons.props} isMobile={true} />;
      header = <HeaderComponent translate={translate} dropdown={dropdown} />;
    } else {
      header = <HeaderComponent />;
    }

    return (
      <Container>
        {errorModal}
        {advanceModal}
        {shareSimulationModal}
        <Grid>
          <Grid.Column mobile={16} computer={10} tablet={10}>
            {header}
            <CreambowDetailComponent
              translate={translate}
              location={this.props.location}
              simulationDetails={simulationDetails}
              loadingDetails={loadingDetails}
            />
          </Grid.Column>
          <Grid.Column only="mobile" mobile={16}>
            <div className="bottomArea">
              <Button className="goBackButton" onClick={() => this.props.history.push(ROUTES.HOME)}>
                {translate("Common:back").toLowerCase()}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column className="rightColumn" only="tablet computer" computer={6} tablet={6}>
            {actionButtons}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  creambowGetSimulationDetails: (simulationId) => dispatch(creambowGetSimulationDetails(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    creambowSimulationDetails: state.creambowSimulationDetails.simulationDetails,
    creambowSimulationDetailsRequestStatus: state.creambowSimulationDetails.simulationDetailsRequestStatus,
    language: state.user.userProfile.language,
  };
};

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(
    "MedicaDetailScreen",
    "CrisisDetailScreen",
    "ModifyModal",
    "ShareModal",
    "MedicaAdvancedOptions",
    "Common"
  ),
  withRouter
);

export default HOC(CreambowDetail);
