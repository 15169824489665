import * as actions from "./action-types";

export const initialState = {
  loadingRequestFeature: false,
  requestFeatureSuccess: null,
  requestFeatureMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST_FEATURE:
      return {
        loadingRequestFeature: true,
        requestFeatureSuccess: null,
        requestFeatureMessage: "",
      };
    case actions.REQUEST_FEATURE_SUCCESSFULL:
      return {
        loadingRequestFeature: false,
        requestFeatureSuccess: true,
        requestFeatureMessage: action.payload,
      };
    case actions.REQUEST_FEATURE_FAILED:
      return {
        loadingRequestFeature: false,
        requestFeatureSuccess: false,
        requestFeatureMessage: action.payload,
      };
    case actions.RESET_REQUEST_FEATURE_STORE:
      return {
        loadingRequestFeature: false,
        requestFeatureSuccess: null,
        requestFeatureMessage: "",
      };
    default:
      return state;
  }
}
