import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Divider, Form, Modal } from "semantic-ui-react";
import DropDownComponent from "../helpers/DropDownComponent";
import {
  supplyChainGameModeOptions,
  supplyChainTimeBetweenAdvancesOptions,
  supplyChainValidateDates,
} from "../utilities/SupplyChainSimulationUtilities";
import { switchTo12HourFormat, unifyDateTime } from "../utilities/DateTimeUtilities";
import DateTimeSelector from "../helpers/DateTimeSelector";
import CalendarComponent from "../helpers/CalendarComponent";
import { supplyChainModifyReset, supplyChainModifySimulation } from "../../store/SupplyChainSimulationDetails/actions";
import MessagesComponent from "../helpers/MessagesComponent";
import { generateTranslationKey } from "../utilities/LanguageUtilities";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as SUPPLYCHAIN_CONSTANTS from "../../constants/SupplyChainConstants";

export class ModifySimulationModal extends Component {
  constructor(props) {
    super(props);

    const { startTime, endTime, gameMode, pace } = props.simulationDetails;
    this.state = {
      formValidated: false,
      openCalendar: false,
      advancedOptions: {
        scenarioId: "",
      },
      dateValidationError: {},
      dateToModify: "startTime",
      form: {
        gameMode: {
          value: gameMode,
          valid: true,
          rules: {},
        },
        startTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(startTime).format("HH"))),
            hourFormat: moment(startTime).format("a"),
            date: startTime,
          },
          valid: true,
          rules: {},
        },
        endTime: {
          value: {
            hour: switchTo12HourFormat(Number(moment(endTime).format("HH"))),
            hourFormat: moment(endTime).format("a"),
            date: endTime,
          },
          valid: true,
          rules: {},
        },
        timeBetweenAdvances: {
          value: pace,
          valid: true,
          rules: {},
        },
      },
    };
  }

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    if (rules.minHoursBeforeDate) {
      const timeNow = moment();
      const unifiedDateTime = unifyDateTime(value);
      isValid = isValid && unifiedDateTime.diff(timeNow) >= rules.minHoursBeforeDate;
    }
    return isValid;
  };

  setHour = (statePropertyToModify, value) => {
    let updatedForm = { ...this.state.form };
    const segmentedStartTime = updatedForm[statePropertyToModify].value;

    if (isNaN(value)) {
      segmentedStartTime.hourFormat = value;
    } else {
      segmentedStartTime.hour = value;
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  setDate = (date, dateToModify) => {
    const updatedDateValue = { ...this.state.form[dateToModify].value, date: date };
    const updatedDateField = { ...this.state.form[dateToModify], value: updatedDateValue };
    let updatedForm = { ...this.state.form, [dateToModify]: updatedDateField };

    if (dateToModify === "startTime" && date >= moment(updatedForm.endTime.value.date).format("YYYY-MM-DD")) {
      const adjustedEndDateValue = { ...this.state.form.endTime.value, date: moment(date).add(1, "day") };
      const adjustedEndDateField = { ...this.state.form.endTime, value: adjustedEndDateValue };
      updatedForm = { ...updatedForm, endTime: adjustedEndDateField };
    }

    updatedForm = this.updateFormValidation(updatedForm);
    this.setState({ form: updatedForm, formValidated: true, dateValidationError: "" });
  };

  onChangeTimeBetweenAdvances = (id, pace) => {
    this.onFormChanged(id, pace);
    const { startTime, endTime } = this.state.form;
    this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, pace);
  };

  onChangeStartTime = (value) => {
    const { timeBetweenAdvances, startTime, endTime } = this.state.form;
    const { professorManagedProgress } = this.props.simulationDetails;
    this.setHour("startTime", value);

    if (professorManagedProgress === false) {
      this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, timeBetweenAdvances.value);
    }
  };

  matchEndAndStartTimeHourDivisibility = (startHour, endHour, dividerInt) => {
    if (dividerInt === 1) return;

    const startTimeDivisibility = startHour % dividerInt;
    const endTimeDivisibility = endHour % dividerInt;

    if (startTimeDivisibility !== endTimeDivisibility) {
      const newEndHour = CONSTANT.HOUR_NUMBERS.find((x) => x.value % dividerInt === startTimeDivisibility).value;
      this.setHour("endTime", newEndHour);
    }
  };

  handleCalendar = (action) => {
    this.setState((state) => ({
      dateToModify: action,
      openCalendar: !state.openCalendar,
    }));
  };

  submitModifySimulationRequest = (event) => {
    event.preventDefault();

    const { professorManagedProgress, id } = this.props.simulationDetails;

    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const { form } = this.state;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    let request = {
      simulationId: id,
      gameMode: form.gameMode.value,
    };

    if (professorManagedProgress === false) {
      const convertedStartTime = unifyDateTime(form.startTime.value);
      const convertedEndTime = unifyDateTime(form.endTime.value);
      const pace = this.state.form.timeBetweenAdvances.value;

      const dateValidation = supplyChainValidateDates(convertedStartTime, convertedEndTime, pace);

      if (dateValidation.invalidEndTime || dateValidation.invalidStartTime) {
        this.setState({
          form: {
            ...form,
            startTime: {
              ...form.startTime,
              valid: !dateValidation.invalidStartTime,
            },
            endTime: {
              ...form.endTime,
              valid: !dateValidation.invalidEndTime,
            },
          },
          formValidated: true,
          dateValidationError: dateValidation.dateValidationError,
        });
        return;
      }

      request = {
        simulationId: id,
        gameMode: form.gameMode.value,
        pace: pace,
        startTime: convertedStartTime,
        endTime: convertedEndTime,
      };
    }

    this.props.modifySimulation(request);
  };

  renderDates = (t) => {
    const timeBetweenAdvancesOptions = supplyChainTimeBetweenAdvancesOptions(t);
    const { startTime, endTime, timeBetweenAdvances } = this.state.form;
    let endTimeOptions = null;
    if (timeBetweenAdvances.value > 1) {
      const startTimeDivisibility = startTime.value.hour % timeBetweenAdvances.value;
      endTimeOptions = CONSTANT.HOUR_NUMBERS.filter(
        (x) => x.value % timeBetweenAdvances.value === startTimeDivisibility
      ).map((hour) => ({
        key: hour.key,
        text: hour.value,
        value: hour.value,
      }));
    }

    return (
      <div>
        <DropDownComponent
          id="advancePeriod"
          label={t("SupplyChainPurchased:advancePeriod")}
          options={timeBetweenAdvancesOptions}
          onSelection={this.onChangeTimeBetweenAdvances.bind(this, "timeBetweenAdvances")}
          value={timeBetweenAdvances.value}
          labelAlignment="left"
        />
        <DateTimeSelector
          label={t("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "startTime" })}
          settingHour={(event, { value }) => this.onChangeStartTime(value)}
          dateToSet={"startTime"}
          date={startTime.value.date}
          hour={startTime.value.hour}
          hourFormat={startTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={t("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "endTime" })}
          settingHour={(event, { value }) => this.setHour("endTime", value)}
          dateToSet={"endTime"}
          date={endTime.value.date}
          hour={endTime.value.hour}
          hourFormat={endTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
          timeOptions={endTimeOptions}
        />
      </div>
    );
  };

  renderGameModeDropdown = (t) => {
    const gameModeOptions = supplyChainGameModeOptions(t).filter((x) => x.value !== SUPPLYCHAIN_CONSTANTS.LastMile);
    return (
      <DropDownComponent
        id="gameMode"
        label={t("SupplyChainPurchased:GameModeTitle")}
        options={gameModeOptions}
        onSelection={(data) => this.onFormChanged("gameMode", data)}
        value={this.state.form.gameMode.value}
        labelAlignment="left"
      />
    );
  };

  renderCalendar = (t) => {
    const { dateToModify } = this.state;
    const calendarSelectedDay = this.state.form[dateToModify].value.date;

    return (
      <div className="calendarPosition">
        <CalendarComponent
          translate={t}
          handleCalendar={this.handleCalendar}
          dateToModify={this.state.dateToModify}
          onDateClick={(value) => this.setDate(value, this.state.dateToModify)}
          selectedDate={calendarSelectedDay}
          language={this.props.language}
        />
      </div>
    );
  };

  renderMessage = (message, { success } = {}) => {
    return (
      <>
        <Divider hidden />
        <MessagesComponent messageType={success} message={message}></MessagesComponent>
      </>
    );
  };

  onClose = () => {
    const { modifySimulationStatus } = this.props;
    if (modifySimulationStatus.success === true) {
      this.props.getSimulationDetails(this.props.simulationDetails.id);
    }
    this.props.modifySimulationReset();
    this.props.onClose();
  };

  render() {
    const { openCalendar, dateValidationError } = this.state;
    const { simulationDetails } = this.props;
    const { t, modifySimulationStatus } = this.props;
    const formOnSubmit = modifySimulationStatus.loading === false ? this.submitModifySimulationRequest : undefined;
    const shouldRenderGameModeDropDown = simulationDetails.gameMode !== SUPPLYCHAIN_CONSTANTS.LastMile;

    const apiErrorNamespace = modifySimulationStatus.errorMessage.includes(":") ? "" : "SupplyChainModifyModal";
    const errors = [
      { ns: "", key: dateValidationError.message, format: { hours: dateValidationError.hours } },
      { ns: apiErrorNamespace, key: modifySimulationStatus.errorMessage },
    ];
    const errorToDisplay = errors.find((x) => typeof x.key === "string" && x.key.length > 0);

    return (
      <Modal open size="small">
        <Modal.Header>{t("SupplyChainModifyModal:ModifySimulation")}</Modal.Header>
        <Modal.Content>
          {openCalendar === true ? this.renderCalendar(t) : null}

          <Form id="modifySimulationForm" onSubmit={formOnSubmit}>
            {shouldRenderGameModeDropDown ? this.renderGameModeDropdown(t) : null}
            {simulationDetails.professorManagedProgress === false ? this.renderDates(t) : null}
          </Form>
          {typeof errorToDisplay !== "undefined"
            ? this.renderMessage(t(generateTranslationKey(errorToDisplay), errorToDisplay.format))
            : null}
          {modifySimulationStatus.success === true
            ? this.renderMessage(t("SupplyChainModifyModal:Success"), { success: true })
            : null}
        </Modal.Content>
        <Modal.Actions>
          {modifySimulationStatus.success !== true ? (
            <Button
              loading={modifySimulationStatus.loading}
              type="submit"
              form="modifySimulationForm"
              content={t("SupplyChainModifyModal:accept")}
            />
          ) : null}
          <Button content={t("SupplyChainModifyModal:close")} onClick={this.onClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  modifySimulationStatus: state.supplyChainSimulationDetails.modifySimulationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  modifySimulation: (request) => dispatch(supplyChainModifySimulation(request)),
  modifySimulationReset: () => dispatch(supplyChainModifyReset()),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(["SupplyChainCommon", "SupplyChainModifyModal", "SupplyChainPurchased"])
);

export default HOC(ModifySimulationModal);
