import React from "react";
import { Grid, Image, GridColumn } from "semantic-ui-react";
import { Trans } from "react-i18next";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import androidDownload from "../../assets/android.png";
import iosDownload from "../../assets/ios.png";
import * as CONSTANTS from "../../constants/UniversalConstants";

const DownloadLinksComponent = (props) => {
  const { appInformation, translate } = props;
  let userPlayedBefore = null;
  let windowsLink = null;

  if (appInformation === CONSTANTS.MEDICA_NAME) {
    userPlayedBefore = <p className="downloadAppInstructionsText">{translate("userPlayedBefore")}</p>;
  }
  if (appInformation.links.windows)
    windowsLink = (
      <div className="windowsLink">
        <Trans i18nKey="DownloadApp:orClickHereToDownloadItToYourWindowsComputer">
          Or click
          <a className="windowsLink" href={appInformation.links.windows} target="_blank" rel="noopener noreferrer">
            here
          </a>
          to download it to your Windows computer.
        </Trans>
      </div>
    );

  return (
    <div className="infoFrame">
      <Grid stackable centered>
        <Grid.Column stretched only="mobile" width={16}>
          <div className={`downloadLinkEmbeddedIcon ${appInformation.logoClassName}`}>
            <Image src={appInformation.logo} />
          </div>
        </Grid.Column>
        <Grid.Column width={16}>
          <MainTitleComponent title={translate("youAreGoodToGo")} />
          <p className="downloadAppInstructionsText">{translate("pleaseDownloadOurMobileApp")}</p>
        </Grid.Column>
        <Grid.Row>
          <Grid.Column mobile={8} tablet={6} computer={6}>
            <a href={appInformation.links.android} target="_blank" rel="noopener noreferrer">
              <Image centered className="storeDownloadIcon" src={androidDownload} alt="Android Download Icon" />
            </a>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={6} computer={6}>
            <a href={appInformation.links.ios} target="_blank" rel="noopener noreferrer">
              <Image centered className="storeDownloadIcon" src={iosDownload} alt="iOS Download Icon" />
            </a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column width={16} verticalAlign="middle">
          {windowsLink}
        </Grid.Column>
        <GridColumn width={16}>
          <p className="downloadAppInstructionsText">{translate("sameCredentials")}</p>
        </GridColumn>
        <Grid.Column width={16}>{userPlayedBefore}</Grid.Column>
      </Grid>
    </div>
  );
};

export default DownloadLinksComponent;
