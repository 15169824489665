import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { medicaGetDecisionHistory } from "../../store/Medica/actions";
import DecisionHistory from "./DecisionHistory";

class MedicaDecisionHistory extends Component {
  state = {
    simulationName: "",
  };

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const simulationName = params.has("simulationName") ? params.get("simulationName") : "";
    const language = params.has("language") ? params.get("language") : "en";

    await this.props.getDecisionHistory(simulationName, language);
    this.setState({ simulationName });
  }

  render() {
    const { simulationName } = this.state;
    const { decisionHistoryStatus } = this.props;

    return <DecisionHistory simulationName={simulationName} decisionHistoryStatus={decisionHistoryStatus} />;
  }
}

const mapStateToProps = (state) => ({
  decisionHistoryStatus: state.medica.decisionHistoryStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getDecisionHistory: (simulationName, language) => dispatch(medicaGetDecisionHistory(simulationName, language)),
});

const HOC = compose(connect(mapStateToProps, mapDispatchToProps), withTranslation());

export default HOC(MedicaDecisionHistory);
