import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import StudentsLoginComponent from "./StudentsLoginComponent";
import "./studentsLogin.css";

const StudentsLoginScreen = (props) => {
  const translate = props.t;
  const user = props.user;
  return (
    <Container>
      <HeaderComponent translate={translate} showLogoutButton={true} showLanguageSelector={true} />
      <StudentsLoginComponent translate={translate} user={user} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      fullName: state.user.userProfile.fullName,
    },
  };
};

export default compose(connect(mapStateToProps, null))(
  withTranslation("StudentsLoginScreen", "Common")(withRouter(StudentsLoginScreen))
);
