import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  DiscreteColorLegend,
} from "react-vis";
import { abbreviateNumber } from "../utilities/DashBoardUtilities";
import { scenarioGraphSection } from "../utilities/ScenarioInformationUtilities";
export const COLOR_PALETTE = ["#B1B2B2", "#2F83AD", "#F4C454"];

class ScenarioGraphSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphSectionData: [],
    };
  }

  componentDidMount() {
    this.prepareGraphSectionData();
  }

  prepareGraphSectionData = () => {
    const { scenarioData } = this.props;
    const graphSectionData = scenarioGraphSection(scenarioData);
    this.setState({
      graphSectionData: graphSectionData,
    });
  };

  showHideLine = (line, index) => {
    this.setState((state) => {
      const graphSectionData = state.graphSectionData;

      const targetLineSeries = graphSectionData.find((graph) => graph.graphName === line.graphName);
      const targetLineData = targetLineSeries.graphData[line.lineIndex];

      if (targetLineData.opacity === 1) {
        targetLineData.opacity = 0;
      } else {
        targetLineData.opacity = 1;
      }

      return {
        graphSectionData: graphSectionData,
      };
    });
  };

  render() {
    const { translate } = this.props;
    const { graphSectionData } = this.state;

    let lineSeries = null;
    let legend = null;

    return (
      <Grid centered>
        <Grid.Row>
          {graphSectionData.map((graph, index) => {
            const graphName = graph.graphName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");

            lineSeries = graph.graphData.map((line, index) => {
              return (
                <LineSeries
                  key={index}
                  animation={false}
                  data={line.dataPoints}
                  stroke={COLOR_PALETTE[index]}
                  strokeWidth={1.5}
                  opacity={line.opacity}
                />
              );
            });

            const legendData = graph.graphData.map((line, index) => {
              return {
                title: translate(line.scenario),
                color: COLOR_PALETTE[index],
                graphName: graph.graphName,
                lineIndex: index,
              };
            });

            if (legendData.length > 0) {
              legend = (
                <DiscreteColorLegend
                  style={{
                    width: 100 + "%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    margin: "-15px 0",
                  }}
                  onItemClick={this.showHideLine}
                  items={legendData}
                  orientation="horizontal"
                />
              );
            }

            return (
              <Grid.Column className="scenarioGraphContainer" key={index} computer={7} tablet={7} mobile={16}>
                <Header>{translate(`DashBoardsScreen:${graphName}`)}</Header>
                <FlexibleWidthXYPlot height={300} margin={{ left: 50 }}>
                  <HorizontalGridLines
                    style={{
                      opacity: 0.6,
                      strokeWidth: 0.5,
                    }}
                  />
                  <VerticalGridLines
                    style={{
                      opacity: 0.6,
                      strokeWidth: 0.5,
                    }}
                  />
                  <XAxis title={translate("DashBoardsScreen:days")} />
                  <YAxis
                    tickFormat={(v) => abbreviateNumber(v)}
                    title={translate(`DashBoardsScreen:${graph.graphUnits}`)}
                  />
                  {legend}
                  {lineSeries}
                </FlexibleWidthXYPlot>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    );
  }
}

export default ScenarioGraphSection;
