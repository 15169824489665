import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import InputComponent from "../helpers/InputComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import NavigationButton from "../helpers/NavigationButton";
import { connect } from "react-redux";
import { compose } from "redux";
import { commitCreambowBuyFormData } from "../../store/CreambowBuySimulation/actions";
import { withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/Routes";

class BuyQuestionnaireForm extends Component {
  state = {
    form: {
      numberOfStudents: {
        value: 4,
        valid: true,
        rules: {
          minValue: 4,
        },
      },
    },
    formValid: true,
  };

  handleFormChange = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormErrors(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;

    this.setState({ form: updatedForm, formValid: true });
  };

  validateFormErrors = (value, rules) => {
    if (rules) {
      let isValid = true;
      if (rules.minValue) {
        isValid = isValid && value >= rules.minValue;
      }
      return isValid;
    }
  };

  handleContinue = () => {
    const dataForm = this.state.form;
    const formValid = Object.keys(dataForm).every((key) => {
      const dataEntry = dataForm[key];
      return dataEntry.valid;
    });

    if (!formValid) {
      this.setState({ formValid: false });
      return;
    }

    const storeData = {};
    Object.keys(dataForm).forEach((key) => {
      const dataEntry = dataForm[key];
      storeData[key] = dataEntry["value"];
    });
    this.props.commitCreambowBuyFormData(storeData);
    this.props.history.push(ROUTES.CREAMBOW_PAYMENT);
  };

  optionsWhoPaying = [
    {
      key: 1,
      text: this.props.t("Common:institution"),
      value: true,
    },
  ];

  render() {
    const { t: translate } = this.props;

    let inputComponent = null;
    let messagesComponent = null;

    inputComponent = (
      <InputComponent
        label={translate("numberOfStudents")}
        id="numberOfStudents"
        type="number"
        min="0"
        onError={!this.state.form.numberOfStudents.valid}
        errorMessage={translate("numberOfStudentsError")}
        value={this.state.form.numberOfStudents.value}
        handleChange={this.handleFormChange.bind(this)}
      />
    );

    if (!this.state.formValid) {
      messagesComponent = <MessagesComponent message={translate("verifyFieldsCorrectlyFilled")} />;
    }

    return (
      <>
        <Form>
          {inputComponent}
          {messagesComponent}
          <NavigationButton action={this.handleContinue} label={translate("Common:next")} />
        </Form>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  commitCreambowBuyFormData: (data) => dispatch(commitCreambowBuyFormData(data)),
});

const HOC = compose(connect(null, mapDispatchToProps), withTranslation("CrisisBuySimulation", "Common"), withRouter);
export default HOC(BuyQuestionnaireForm);
