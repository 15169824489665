import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { Message } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import DashBoardsComponent from "./DashBoardsComponent";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import LoadingComponent from "../helpers/LoadingComponent";
import {
  supplyChainGetDecisionsData,
  supplyChainGetPerformanceData,
  supplyChainGetSimulationStandings,
} from "../../store/SupplyChainSimulationDetails/actions";
import { getStorage } from "../../services/StorageService";
import "./dashBoardsStyles.css";

class SupplyChainDashboards extends Component {
  state = {
    requestedSimulationId: getStorage(LOCALSTORAGE.requestedSimulation),
  };

  componentDidMount() {
    const { requestedSimulationId } = this.state;
    const transaction = Sentry.startTransaction({
      name: "/Dashboard",
      op: "showDashboard",
    });

    const spanCheckIfPerformanceDataIsCached = transaction.startChild({
      op: "checkIfPerformanceDataIsCached",
      description: "Check if performance Data is cached",
    });

    const performanceDataIsCached = this.checkIfPerformanceDataIsCached();

    spanCheckIfPerformanceDataIsCached.finish();

    if (!performanceDataIsCached) {
      const spanGetPerformanceData = transaction.startChild({
        op: "getPerformanceData",
        description: "Get Performance Data",
      });

      this.props.getPerformanceData(requestedSimulationId);

      spanGetPerformanceData.finish();

      const spanGetStandings = transaction.startChild({
        op: "getStandings",
        description: "Get standings",
      });

      this.props.getStandings(requestedSimulationId);

      spanGetStandings.finish();

      const spanGetDecisionsData = transaction.startChild({
        op: "getDecisionsData",
        description: "Get decisions data",
      });

      this.getDecisionsData();

      spanGetDecisionsData.finish();
    }

    transaction.finish();
  }

  getPerformanceData = () => {
    const { requestedSimulationId } = this.state;
    this.props.getPerformanceData(requestedSimulationId);
    this.props.getStandings(requestedSimulationId);
    this.getDecisionsData();
  };

  getDecisionsData = () => {
    const { requestedSimulationId } = this.state;
    this.props.getDecisionsData(requestedSimulationId);
  };

  checkIfPerformanceDataIsCached = () => {
    // const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation); //ya no es necesario
    // const { teamsPerformance, simulationDetails } = this.props;
    // const { performanceData } = teamsPerformance;
    // if (requestedSimulationId !== performanceData.name) {
    //   return false;
    // } else {
    //   if (simulationDetails.currentSimulationDay !== performanceData.currentSimulationDay) {
    //     return false;
    //   }
    // }
    // return true;
    return false;
  };

  render() {
    const { t, teamsPerformance, history, location, standings, teamsDecisions } = this.props;
    const translate = t;
    const { loadingPerformanceData, performanceData, performanceDataError } = teamsPerformance;
    const { simulationStandings, loadingStandings, getStandingsError } = standings;
    const { loadingDecisionsData, decisionsDataError, decisionsData } = teamsDecisions;
    const showLoadingComponent = loadingPerformanceData || loadingStandings;
    const showMessageComponent = performanceDataError !== "" || getStandingsError !== "";
    const showNoDashboardContainer = showLoadingComponent || showMessageComponent;
    const showDashboardComponent = simulationStandings
      ? performanceData !== null && performanceData.length > 0 && simulationStandings.length > 0
      : false;
    let noDashboardContainer = null;
    let dashBoardsComponent = null;
    let loader = null;
    let messageComponent = null;

    if (showLoadingComponent) {
      loader = <LoadingComponent message={translate("loadingTeamData")} />;
    } else {
      if (showMessageComponent) {
        let errorMessage = this.props.t(performanceDataError, {
          ns: "DashBoardsScreen",
        });
        if (this.props.i18n.exists(getStandingsError, { ns: "StandingsScreen" })) {
          errorMessage = translate(getStandingsError, { ns: "StandingsScreen" });
        }
        messageComponent = (
          <Message negative>
            <Message.Header>{errorMessage}</Message.Header>
          </Message>
        );
      } else {
        if (showDashboardComponent) {
          dashBoardsComponent = (
            <DashBoardsComponent
              translate={translate}
              performanceData={performanceData}
              getPerformanceData={this.getPerformanceData}
              history={history}
              location={location}
              standings={simulationStandings}
              decisionsData={decisionsData}
              decisionsDataError={decisionsDataError}
              loadingDecisionsData={loadingDecisionsData}
              getDecisionsData={this.getDecisionsData}
            />
          );
        }
      }
    }

    if (showNoDashboardContainer) {
      noDashboardContainer = (
        <div className="noDashboardContainer">
          <HeaderComponent translate={translate} showBackButton={true} />
          <MainTitleComponent title={translate("Common:dashboards")} />
          {loader}
          {messageComponent}
        </div>
      );
    }

    return (
      <>
        {dashBoardsComponent}
        {noDashboardContainer}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPerformanceData: (simulationId) => dispatch(supplyChainGetPerformanceData(simulationId)),
  getStandings: (simulationId) => dispatch(supplyChainGetSimulationStandings(simulationId)),
  getDecisionsData: (simulationId) => dispatch(supplyChainGetDecisionsData(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    teamsPerformance: {
      performanceData: state.supplyChainSimulationDetails.simulationPerformanceDataRequestStatus.performanceData,
      loadingPerformanceData: state.supplyChainSimulationDetails.simulationPerformanceDataRequestStatus.loading,
      performanceDataError: state.supplyChainSimulationDetails.simulationPerformanceDataRequestStatus.errorMessage,
    },
    teamsDecisions: {
      decisionsData: state.supplyChainSimulationDetails.simulationDecisionsDataRequestStatus.decisionsData,
      loadingDecisionsData: state.supplyChainSimulationDetails.simulationDecisionsDataRequestStatus.loading,
      decisionsDataError: state.supplyChainSimulationDetails.simulationDecisionsDataRequestStatus.errorMessage,
    },
    standings: {
      simulationStandings: state.supplyChainSimulationDetails.standings.simulationStandings,
      loadingStandings: state.supplyChainSimulationDetails.standings.loading,
      getStandingsError: state.supplyChainSimulationDetails.standings.errorMessage,
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("DashBoardsScreen", "StandingsScreen", "SupplyChainCommon"),
  withRouter
)(SupplyChainDashboards);
