import * as actionTypes from "./action-types";

export const initialState = {
  code: "",
  loading: false,
  errorMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_START:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload,
      };
    default:
      return state;
  }
}
