import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, Message } from "semantic-ui-react";

class ShareSimulationStandingsModal extends Component {
  state = { copySuccess: "", simulationLink: "" };

  handleCopyToClipboard = () => {
    const translate = this.props.t;
    var fakeInput = document.createElement("input");
    document.body.appendChild(fakeInput);
    fakeInput.setAttribute("value", this.props.sharedLink);
    fakeInput.select();
    document.execCommand("copy");
    document.body.removeChild(fakeInput);
    this.setState({ copySuccess: translate("StandingsScreen:shareModalCopySuccess") });
  };

  render() {
    const translate = this.props.t;
    let shareLabel = null;

    shareLabel = translate("StandingsScreen:shareModalInformation");
    return (
      <div>
        <Modal size="tiny" open={this.props.open} onClose={this.props.onClose}>
          <Modal.Header>{this.props.titleModal}</Modal.Header>
          <Modal.Content>
            <label>{shareLabel}</label>
            <Message info>
              <Message.Header>{this.props.sharedLink}</Message.Header>
            </Message>
            <label>{this.state.copySuccess}</label>
          </Modal.Content>
          <Modal.Actions>
            <Button content={translate("StandingsScreen:shareModalCopy")} onClick={this.handleCopyToClipboard} />
            <Button content={translate("Common:close")} onClick={this.props.onClose} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation("StandingsScreen, Common")(ShareSimulationStandingsModal);
