import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import DropDownComponent from "../helpers/DropDownComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import { withTranslation } from "react-i18next";
import * as CONSTANT from "../../constants/UniversalConstants";

class AdvanceSimulationModal extends Component {
  state = {
    errorEmptyInput: true,
    daysToAdvance: 0,
    futureDays: 0,
    remainingDays: {},
    actionToAdvance: "",
  };

  componentDidMount() {
    this.setRemainingDaysToState();
  }

  setRemainingDaysToState = () => {
    const simulationDetails = this.props.simulationDetails;
    const {
      initializationPeriod,
      studentInteractionPeriod,
      endOfSimulationPeriod,
      currentSimulationDay,
    } = simulationDetails;
    this.setSimulationStatusToState(simulationDetails);
    const maxDaysToRun = initializationPeriod + studentInteractionPeriod + endOfSimulationPeriod;
    const studentInteractionToRun = initializationPeriod + studentInteractionPeriod;

    const daysToRun = {
      maxDaysToRun: maxDaysToRun - currentSimulationDay,
      studentInteractionToRun: studentInteractionToRun - currentSimulationDay,
    };
    this.setState({
      remainingDays: daysToRun,
    });
  };

  setSimulationStatusToState = (simulationDetails) => {
    const { currentSimulationDay, initializationPeriod } = simulationDetails;

    if (currentSimulationDay === initializationPeriod) {
      this.setState({
        actionToAdvance: CONSTANT.START_SIMULATION,
      });
    } else {
      this.setAdvanceOrFinishSimulationToState(simulationDetails);
    }
  };

  setAdvanceOrFinishSimulationToState = (simulationDetails) => {
    const { currentSimulationDay, initializationPeriod, studentInteractionPeriod } = simulationDetails;

    if (currentSimulationDay < initializationPeriod + studentInteractionPeriod) {
      this.setState({
        actionToAdvance: CONSTANT.ADVANCE_SIMULATION,
      });
    } else {
      this.setState({
        actionToAdvance: CONSTANT.FINISH_SIMULATION,
      });
    }
  };

  handleClose = () => {
    this.setState({
      daysToAdvance: 0,
      futureDays: 0,
      errorEmptyInput: true,
    });

    this.props.handleAdvanceModal();
  };

  onSelectionDay = (val) => {
    const { initializationPeriod, studentInteractionPeriod, currentSimulationDay } = this.props.simulationDetails;
    let futureDays = currentSimulationDay + val;
    let idealFutureDays = initializationPeriod + studentInteractionPeriod;
    if (futureDays > idealFutureDays) {
      futureDays = idealFutureDays;
    }
    this.setState({
      daysToAdvance: val,
      futureDays: futureDays,
      errorEmptyInput: false,
    });
  };

  advanceSimulation = () => {
    const { name, endOfSimulationPeriod } = this.props.simulationDetails;
    const { actionToAdvance, daysToAdvance } = this.state;
    let customDaysToAdvance = daysToAdvance;
    if (actionToAdvance === CONSTANT.FINISH_SIMULATION) {
      customDaysToAdvance = endOfSimulationPeriod;
    }
    if (customDaysToAdvance !== 0) {
      let postData = {
        simulationName: name,
        daysToSimulate: customDaysToAdvance,
      };
      this.props.advanceSimulation(postData);
    }
  };

  populateOptions = () => {
    return [
      {
        key: 1,
        text: "5",
        value: 5,
      },
      {
        key: 2,
        text: "10",
        value: 10,
      },
      {
        key: 3,
        text: "15",
        value: 15,
      },
      {
        key: 4,
        text: "20",
        value: 20,
      },
      {
        key: 5,
        text: "30",
        value: 30,
      },
    ];
  };

  successAction = () => {
    this.props.getSimulationDetails(this.props.simulationDetails.id);
    this.props.resetAdvanceSimulationProps();
    this.handleClose();
  };

  render() {
    const translate = this.props.t;
    const { daysToAdvance, futureDays, remainingDays, actionToAdvance } = this.state;
    const { loading, errorMessage, simulationDetails, successMessage, open } = this.props;
    const { endOfSimulationPeriod, currentSimulationDay } = simulationDetails;
    const { studentInteractionToRun } = remainingDays;
    let statusComponent = null;
    let optionsDaysToAdvance = [];
    let actionButtons = null;
    let customfutureDays = futureDays;
    let remainingDaysTemplate = null;
    let messageComponent = null;
    let buttonContent = translate("AdvanceSimulationModal:advance");
    let customSuccessMessage = translate(`AdvanceSimulationModal:${successMessage}`);
    let oncloseFunction = this.handleClose;
    if (studentInteractionToRun > 0 && customSuccessMessage === "") {
      optionsDaysToAdvance = this.populateOptions();
    }

    let daysToAdvanceItem = (
      <DropDownComponent
        options={optionsDaysToAdvance}
        placeholder="-"
        onSelection={this.onSelectionDay}
        value={daysToAdvance}
      />
    );

    if (actionToAdvance === CONSTANT.FINISH_SIMULATION) {
      daysToAdvanceItem = <div>{endOfSimulationPeriod}</div>;
      customfutureDays = currentSimulationDay + endOfSimulationPeriod;
      buttonContent = translate("Common:finish");
      customSuccessMessage = translate("AdvanceSimulationModal:simulationFinishedSuccessfully");

      if (successMessage === "") {
        messageComponent = (
          <MessagesComponent warning={true} message={translate("AdvanceSimulationModal:warningFinishMessage")} />
        );
      }
    } else {
      remainingDaysTemplate = (
        <div>
          <strong>{translate("AdvanceSimulationModal:remainingDays")}</strong> {studentInteractionToRun}{" "}
          {translate("AdvanceSimulationModal:days")}
        </div>
      );
    }

    actionButtons = (
      <Modal.Actions>
        <Button disabled={loading === true} content={buttonContent} onClick={this.advanceSimulation} />

        <Button content={translate("ModifyModal:cancel")} onClick={() => this.handleClose()} />
      </Modal.Actions>
    );

    if (loading === true) {
      statusComponent = <div className="loader" />;
    } else if (errorMessage !== "") {
      statusComponent = <MessagesComponent messageType={false} message={translate(errorMessage)} />;
    } else if (successMessage !== "") {
      oncloseFunction = this.successAction;
      statusComponent = <MessagesComponent messageType={true} message={customSuccessMessage} />;

      actionButtons = (
        <Modal.Actions>
          <Button content={"Ok"} onClick={this.successAction} />
        </Modal.Actions>
      );
    }

    return (
      <Modal size="tiny" open={open} onClose={oncloseFunction}>
        <Modal.Header>{translate(`AdvanceSimulationModal:${actionToAdvance}`)}</Modal.Header>
        <Modal.Content>
          <div className="contentAdvanceModal">
            {remainingDaysTemplate}
            <div className="columnModal">
              <strong>{translate("AdvanceSimulationModal:currentDays")}</strong>
              <p>{currentSimulationDay}</p>
            </div>
            <div className="columnModal">
              <strong>{translate("AdvanceSimulationModal:daysToAdvance")}</strong>
              {daysToAdvanceItem}
            </div>
            <div className="columnModal">
              <strong>{translate("AdvanceSimulationModal:futureDays")}</strong>
              <p>{customfutureDays}</p>
            </div>
          </div>
          {messageComponent}
          {statusComponent}
        </Modal.Content>
        {actionButtons}
      </Modal>
    );
  }
}

export default withTranslation("AdvanceSimulationModal", "Common")(AdvanceSimulationModal);
