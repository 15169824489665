import _ from "lodash";
import React, { Component } from "react";
import { Table, Menu, Pagination } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { setStorage } from "../../services/StorageService";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as ROUTES from "../../constants/Routes";

class HomeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: null,
      chunkedSimulationsArray: [],
      simulationList: props.simulationsList,
      simulationsChunk: [],
      direction: null,
      entries: {},
      activePage: 1,
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.sortSimulationsByCreationDate();
  }

  sortSimulationsByCreationDate = () => {
    const { simulationList } = this.state;
    let sortedSimulationsList = simulationList.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    this.setState({
      simulationList: sortedSimulationsList,
    });

    this.getPagination();
  };

  handleSort = (clickedColumn) => () => {
    const { column, simulationList, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        simulationList: _.sortBy(simulationList, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      simulationList: simulationList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
    this.getPagination();
  };

  getPagination = () => {
    const size = 10;
    let chunkedSimulationsArray = [];
    let index = 0;
    while (index < this.state.simulationList.length) {
      chunkedSimulationsArray.push(this.state.simulationList.slice(index, size + index));
      index += size;
    }

    this.setState({
      chunkedSimulationsArray: chunkedSimulationsArray,
      simulationsChunk: chunkedSimulationsArray[this.state.activePage - 1],
      totalPages: chunkedSimulationsArray.length,
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, this.topTable.offsetTop);
    const { chunkedSimulationsArray } = this.state;
    let valuesArr = [];
    for (let n = 0; n < chunkedSimulationsArray.length; n++) {
      valuesArr.push({
        maxValue: Number(`${n + 1}0`),
        minValue: n * 10 + 1,
      });
    }
    this.setState({
      activePage: activePage,
      simulationsChunk: chunkedSimulationsArray[activePage - 1],
      entries: valuesArr[activePage - 1],
    });
  };

  goToPage = (simulation) => {
    if (simulation.type === CONSTANT.MEDICA_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.id);
      this.props.history.push({
        pathname: ROUTES.MEDICA_DETAIL,
        search: simulation.id,
      });
      return;
    }
    if (simulation.type === CONSTANT.SIMUNATION_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.name);
      this.props.history.push({
        pathname: ROUTES.SIMUNATION_DETAIL,
        search: simulation.name,
      });
      return;
    }
    if (simulation.type === CONSTANT.CRISIS_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.id);
      this.props.history.push({
        pathname: ROUTES.CRISIS_SIMULATION_DETAILS,
        search: simulation.name,
      });
    }
    if (simulation.type === CONSTANT.SUPPLY_CHAIN_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.id);
      this.props.history.push({
        pathname: ROUTES.SUPPLY_CHAIN_SIMULATION_DETAILS,
        search: simulation.name,
      });
    }
    if (simulation.type === CONSTANT.ABOGADOS_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.id);
      this.props.history.push({
        pathname: ROUTES.ABOGADOS_SIMULATION_DETAILS,
        search: simulation.name,
      });
    }
    if (simulation.type === CONSTANT.CREAM_BOW_NAME) {
      setStorage(LOCALSTORAGE.requestedSimulation, simulation.id);
      this.props.history.push({
        pathname: ROUTES.CREAMBOW_SIMULATION_DETAILS,
        search: simulation.name,
      });
    }
  };

  render() {
    const translate = this.props.translate;
    const { column, direction, simulationsChunk, entries, activePage, totalPages, simulationList } = this.state;

    let sorted = null;
    let tableBody = null;
    let tableRow = null;
    let icon = <FontAwesomeIcon className="tableIcon headerCellIcon" icon={faSort} />;

    if (
      column === "simulation" ||
      column === "name" ||
      column === "startTime" ||
      column === "status" ||
      column === "students"
    ) {
      sorted = direction;
    }
    if (simulationList) {
      tableBody = simulationsChunk.map((simulation) => {
        let customStartTime = simulation.startTime;
        if (simulation.type === CONSTANT.MEDICA_NAME && simulation.professorManagedProgress) {
          customStartTime = translate("professorManaged");
        }
        const showPendingForio =
          simulation.status === "AwaitingInitialization" &&
          (simulation.type === CONSTANT.CREAM_BOW_NAME || simulation.type === CONSTANT.ABOGADOS_NAME);

        const showPendingBindzSupply =
          simulation.status === "AwaitingInitialization" &&
          simulation.professorManagedProgress &&
          (simulation.type === CONSTANT.CRISIS_NAME || simulation.type === CONSTANT.SUPPLY_CHAIN_NAME);
        if (showPendingForio || showPendingBindzSupply) {
          customStartTime = translate("pending");
        }
        return (
          <Table.Row key={simulation.id} onClick={() => this.goToPage(simulation)}>
            <Table.Cell singleLine>{simulation.type}</Table.Cell>
            <Table.Cell singleLine>{simulation.name}</Table.Cell>
            <Table.Cell singleLine>{customStartTime}</Table.Cell>
            <Table.Cell singleLine>{simulation.status ? translate(`Common:${simulation.status}`) : ""}</Table.Cell>
            <Table.Cell singleLine>{simulation.currentSimulationDay}</Table.Cell>
            <Table.Cell singleLine>{simulation.maxNumberOfStudents || simulation.numberOfStudents}</Table.Cell>
          </Table.Row>
        );
      });
    }

    if (simulationList && simulationList.length > 10) {
      tableRow = (
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            <Menu fluid>
              <Menu.Item>
                <p>
                  {`${translate("Common:showing")} 
                                    ${entries.minValue ? entries.minValue : 1} 
                                    ${translate("Common:to")} 
                                    ${
                                      !entries.maxValue
                                        ? 10
                                        : simulationList.length < entries.maxValue
                                        ? simulationList.length
                                        : entries.maxValue
                                    } 
                                    ${translate("Common:of")} 
                                    ${simulationList.length} 
                                    ${translate("simulations")}`}
                </p>
              </Menu.Item>
              <Menu.Item position="right">
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  size="mini"
                  siblingRange={1}
                  totalPages={totalPages}
                  ellipsisItem={undefined}
                  firstItem={undefined}
                  lastItem={undefined}
                  prevItem={translate("Common:previous")}
                  nextItem={translate("Common:next")}
                />
              </Menu.Item>
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
      );
    }

    return (
      <div className="tableContainer" ref={(ref) => (this.topTable = ref)}>
        <Table id="homeSimulationsTable" unstackable sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("simulation")}>
                <div className="headerCell">
                  {translate("Common:simulation").toUpperCase()}
                  {icon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("name")}>
                <div className="headerCell">
                  {translate("Common:name").toUpperCase()}
                  {icon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("startTime")}>
                <div className="headerCell">
                  {translate("startDate").toUpperCase()}
                  {icon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("status")}>
                <div className="headerCell">
                  {translate("Common:status").toUpperCase()}
                  {icon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("currentSimulationDay")}>
                <div className="headerCell">
                  {translate("Common:currentDay").toUpperCase()}
                  {icon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sorted} onClick={this.handleSort("maxNumberOfStudents")}>
                <div className="headerCell">
                  {`# ${translate("students").toUpperCase()}`}
                  {icon}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
          <Table.Footer>{tableRow}</Table.Footer>
        </Table>
      </div>
    );
  }
}

export default HomeComponent;
