import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Container, Form, Grid, Image } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import CrisisShareSimulationModal from "../modals/CrisisShareModal";
import LeftTitleComponent from "../titles/leftTitle/LeftTitleComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import * as CONSTANT from "../../constants/UniversalConstants";
import abogadosLogo from "../../assets/abogados-logo.png";
import CreateAbogadosSimulationForm from "./createAbogadosSimulationForm";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  abogadosCreateSimulation,
  resetCreateSimulationDefaults,
  resetAbogadosBuySimulationStoreDefaults,
  restoreAbogadosPurchaseInformationFromStorage,
} from "../../store/AbogadosBuySimulation/actions";
import "./purchasedAbogadosStyles.css";
import { createAbogadosTeamLink } from "../utilities/ForioSimulationUtilities";
import { withRouter } from "react-router-dom";
import { ABOGADOS_SIMULATION_DETAILS } from "../../constants/Routes";
import { setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";

class PurchasedAbogados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      form: {
        simulationName: {
          value: "",
          valid: true,
          rules: {
            minLength: 1,
          },
        },
      },
    };
  }

  componentDidMount() {
    this.props.restoreAbogadosPurchaseInformationFromStorage();
    this.props.resetCreateSimulationDefaults();
  }

  submitCreateSimulationRequest = (event) => {
    event.preventDefault();
    if (!this.state.formValidated) {
      const updatedForm = this.updateFormValidation(this.state.form);
      this.setState({ form: updatedForm, formValidated: true });
      return;
    }

    const form = this.state.form;
    const formValid = Object.keys(form).every((key) => {
      const formEntry = form[key];
      return formEntry.valid;
    });

    if (!formValid) {
      return;
    }

    const request = {
      simulationName: this.state.form.simulationName.value,
      paymentSummaryId: this.props.paymentSummaryId,
      simulationType: "LaneAndSlinger",
    };

    this.props.abogadosCreateSimulation(request);
  };

  onFormChanged = (id, data) => {
    const updatedForm = { ...this.state.form };
    const updatedFormElement = { ...updatedForm[id] };
    updatedFormElement.value = data;
    updatedFormElement.valid = this.validateFormValue(updatedFormElement.value, updatedFormElement.rules);
    updatedForm[id] = updatedFormElement;
    this.setState({ form: updatedForm, formValidated: true });
  };

  updateFormValidation = (form) => {
    const updatedForm = {};
    Object.keys(form).forEach((key) => {
      const updatedElement = { ...form[key] };
      updatedElement.valid = this.validateFormValue(updatedElement.value, updatedElement.rules);
      updatedForm[key] = updatedElement;
    });

    return updatedForm;
  };

  validateFormValue = (value, rules) => {
    let isValid = true;
    if (rules.minLength) {
      isValid = isValid && value.length >= rules.minLength;
    }

    return isValid;
  };

  onModalDismiss = () => {
    const simulationId = this.props.createSimulation.simulationId;
    this.props.resetAbogadosBuySimulationStoreDefaults();
    setStorage(LOCALSTORAGE.requestedSimulation, simulationId);
    this.props.history.push(ABOGADOS_SIMULATION_DETAILS);
  };

  render() {
    const translate = this.props.t;
    const { simulationCreated, createdSimulationMessage, simulationId, errorMessage, loading } =
      this.props.createSimulation;

    let navigationButton = null;
    navigationButton = (
      <div className="abogadosBottomArea">
        <Button basic type="submit" form="createSimulationForm">
          {translate("continue")}
        </Button>
      </div>
    );

    if (loading) {
      navigationButton = <LoadingComponent message={translate("creatingSimulation")} />;
    }

    let messagesComponent = null;
    if (errorMessage !== "") {
      messagesComponent = <MessagesComponent message={translate(errorMessage)} />;
    }

    const shareSimulationModal = (
      <CrisisShareSimulationModal
        translate={translate}
        open={simulationCreated}
        shareLink={createAbogadosTeamLink(simulationId)}
        titleModal={translate(createdSimulationMessage)}
        titleSecondaryButton={translate("ModifyModal:accept")}
        handleSecondaryButton={() => this.onModalDismiss()}
        messageType={true}
      />
    );

    return (
      <Container>
        {shareSimulationModal}
        <HeaderComponent translate={translate} />
        <Grid className="customGrid">
          <Grid.Column only="mobile" mobile={16}>
            <MainTitleComponent title={CONSTANT.ABOGADOS_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column only="computer tablet" width={16}>
            <LeftTitleComponent title={CONSTANT.ABOGADOS_NAME.toUpperCase()} />
          </Grid.Column>
          <Grid.Column className="formColumn" mobile={16} computer={8} tablet={10}>
            <Form id="createSimulationForm" onSubmit={this.submitCreateSimulationRequest}>
              <CreateAbogadosSimulationForm
                formData={this.state.form}
                formChanged={this.onFormChanged.bind(this)}
                language={this.props.language}
              />
            </Form>
          </Grid.Column>
          <Grid.Column className="logoColumn" only="tablet computer" computer={8} tablet={6}>
            <Image className="abogadosLogo" src={abogadosLogo} />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            {messagesComponent}
            <Grid.Column width={16}>{navigationButton}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  paymentSummaryId: state.abogadosBuySimulation.payment.paymentSummaryId,
  language: state.user.userProfile.language,
  createSimulation: state.abogadosBuySimulation.createSimulation,
});

const mapDispatchToProps = (dispatch) => ({
  abogadosCreateSimulation: (request) => dispatch(abogadosCreateSimulation(request)),
  restoreAbogadosPurchaseInformationFromStorage: () => dispatch(restoreAbogadosPurchaseInformationFromStorage()),
  resetCreateSimulationDefaults: () => dispatch(resetCreateSimulationDefaults()),
  resetAbogadosBuySimulationStoreDefaults: () => dispatch(resetAbogadosBuySimulationStoreDefaults()),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("CrisisBuySimulation", "CrisisPurchased", "Common"),
  withRouter
);

export default HOC(PurchasedAbogados);
