import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { redirectionHandler } from "../../services/AuthService";
import { login, loginSuccessful } from "../../store/User/actions";
import * as ROUTES from "../../constants/Routes";
import LoadingComponent from "../helpers/LoadingComponent";
import { getCookie } from "../../services/CookieService";
import * as COOKIE from "../../constants/Cookies";

class CallBackComponent extends Component {
  async componentDidMount() {
    const pathContainsHash = /access_token|id_token|error/.test(window.location.hash);
    if (pathContainsHash) {
      await this.checkLoginSuccessful();
    } else {
      this.props.history.push(ROUTES.CHECK_AUTH);
    }
  }

  checkLoginSuccessful = async () => {
    const isLogin = await this.props.loginSuccessful();
    if (isLogin) {
      const user = getCookie(COOKIE.AUTH_INFORMATION);
      redirectionHandler(user.userProfile);
    }
  };

  render() {
    const translate = this.props.t;
    return <LoadingComponent fullSize={true} image={true} message={translate("loadingPage")} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginSuccessful: () => dispatch(loginSuccessful()),
  login: () => dispatch(login()),
});

export default compose(connect(null, mapDispatchToProps))(
  withTranslation("AuthComponents")(withRouter(CallBackComponent))
);
