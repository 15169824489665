import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import GraphModal from "../modals/GraphModal";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import DashBoardSection from "./DashBoardSection";
import TeamListMobile from "./TeamListMobile";
import TeamsListAside from "./TeamsListAside";
import DataSourceNavigator from "./DataSourceNavigator";
import LoadingComponent from "../helpers/LoadingComponent";
import DefaultButton from "../helpers/DefaultButton";
import TeamsListModal from "./TeamsListModal";
import { createTeamsList, getGraphSections, GRAPHS_GROUP } from "./utilities";

class DashBoardsComponent extends Component {
  constructor(props) {
    super(props);

    const selectedGraphs = this.props.location.state.selectedGraphs;

    this.state = {
      openTeamsListModal: false,
      openGraphModal: false,
      selectedTeams: [],
      teams: [],
      sections: [],
      plot: {},
      selectedGraphs: selectedGraphs,
      loadingSections: false,
      performanceData: null,
    };
  }

  componentDidMount = () => {
    this.getTeamsList();
  };

  componentDidUpdate(lastProps) {
    const decisionsData = this.props.decisionsData;
    const lastDecisionsData = lastProps.decisionsData;
    const decisionsDataLength = decisionsData ? decisionsData.length : 0;
    const lastDecisionsDataLength = lastDecisionsData ? lastDecisionsData.length : 0;

    if (decisionsDataLength !== lastDecisionsDataLength) {
      const { selectedGraphs } = this.state;
      this.configureDashboardData(selectedGraphs);
    }
  }

  configureDashboardData = (selectedGraphs, teamsList) => {
    const { performanceData, decisionsData } = this.props;
    const { selectedTeams } = this.state;
    let teams = selectedTeams;
    let graphsData = {};

    if (teamsList) {
      teams = teamsList;
    }

    switch (selectedGraphs) {
      case GRAPHS_GROUP.DECISIONS:
        graphsData = decisionsData;
        break;
      default:
        graphsData = performanceData;
        break;
    }

    if (graphsData && graphsData.length > 0) {
      const sectionsData = getGraphSections(selectedGraphs, graphsData, teams);
      this.setState({
        sections: sectionsData,
      });
    } else {
      this.setState({
        sections: [],
      });
    }
  };

  getTeamsList = () => {
    const { performanceData, standings } = this.props;
    const { selectedGraphs } = this.state;
    const teamsList = createTeamsList(performanceData, standings);

    if (teamsList.length > 0) {
      this.configureDashboardData(selectedGraphs, teamsList);
    }

    this.setState({
      teams: teamsList,
      selectedTeams: teamsList,
    });
  };

  modifySelectedTeamsList = (teamsList) => {
    this.setState({
      selectedTeams: teamsList,
    });
  };

  handleTeamsListModal = () => {
    this.setState({
      openTeamsListModal: !this.state.openTeamsListModal,
    });
  };

  handleGraphModal = (plot) => {
    this.setState({
      openGraphModal: !this.state.openGraphModal,
      plot: plot,
    });
  };

  handleGraphSelector = (event) => {
    const { location } = this.props;
    const { selectedGraphs } = this.state;
    const userSelectedGraphs = event.target.id;

    location.state = {
      selectedGraphs: userSelectedGraphs,
    };

    if (userSelectedGraphs !== selectedGraphs) {
      this.setState({
        selectedGraphs: userSelectedGraphs,
        loadingSections: true,
      });
      this.configureDashboardData(userSelectedGraphs);

      setTimeout(() => {
        this.setState({
          loadingSections: false,
        });
      }, 500);
    }
  };

  render() {
    const {
      teams,
      selectedTeams,
      openTeamsListModal,
      sections,
      openGraphModal,
      plot,
      selectedGraphs,
      loadingSections,
    } = this.state;
    const {
      translate,
      performanceData,
      getPerformanceData,
      decisionsDataError,
      getDecisionsData,
      loadingDecisionsData,
      decisionsData,
      standings,
    } = this.props;
    let teamsListAside = null;
    let dashBoardSection = null;
    let teamsListModal = null;
    let graphModal = null;
    let actionComponent = null;
    let defaultButton = null;

    let selectedTeamsFiltered =
      selectedGraphs === GRAPHS_GROUP.PERFORMANCE
        ? selectedTeams
        : selectedTeams.filter((team) => {
            return team.name !== "CONTROL";
          });

    let anyTeamSelected = selectedTeamsFiltered.some((team) => {
      return team.isChecked;
    });

    const teamListMobile = (
      <TeamListMobile handleTeamsListModal={this.handleTeamsListModal} buttonTitle={translate("manageTeams")} />
    );

    if (loadingSections) {
      dashBoardSection = <LoadingComponent />;
    } else {
      if (loadingDecisionsData) {
        dashBoardSection = <LoadingComponent message={translate("loadingTeamData")} />;
      } else {
        if (selectedTeams.length > 0 && sections.length > 0) {
          dashBoardSection = (
            <div id="dashboardsBody">
              <DashBoardSection
                translate={translate}
                handleGraphModal={this.handleGraphModal}
                getPerformanceData={getPerformanceData}
                selectedTeams={selectedTeams}
                currentSimulationDay={performanceData.currentSimulationDay}
                sections={sections}
                modifySelectedTeamsList={this.modifySelectedTeamsList}
                selectedGraphs={selectedGraphs}
              />
            </div>
          );
        } else {
          if (decisionsDataError !== "") {
            actionComponent = <MessagesComponent message={translate(decisionsDataError)} />;
            defaultButton = <DefaultButton title={translate("Common:tryAgain")} action={getDecisionsData} />;
          } else {
            if (selectedTeams.length === 0) {
              actionComponent = <MessagesComponent info={true} message={translate("noTeamsToDisplayInformation")} />;
            }
          }

          dashBoardSection = (
            <>
              {actionComponent}
              <div className="tryAgainContent">{defaultButton}</div>
            </>
          );
        }
      }
    }

    if (!anyTeamSelected) {
      dashBoardSection = (
        <div className="messageSeparator">
          <MessagesComponent info={true} message={translate("noTeamSelectedToDisplayInformation")} />
        </div>
      );
    }

    if (teams.length > 0) {
      teamsListAside = (
        <TeamsListAside
          translate={translate}
          teamsList={teams}
          modifySelectedTeamsList={this.modifySelectedTeamsList}
          selectedGraphs={selectedGraphs}
          standings={standings}
        />
      );

      teamsListModal = (
        <TeamsListModal
          translate={translate}
          openTeamsListModal={openTeamsListModal}
          teamsList={teams}
          handleTeamsListModal={this.handleTeamsListModal}
          modifySelectedTeamsList={this.modifySelectedTeamsList}
          standings={standings}
        />
      );
    }

    if (openGraphModal) {
      graphModal = (
        <GraphModal
          openGraphModal={openGraphModal}
          handleGraphModal={this.handleGraphModal}
          plot={plot}
          selectedTeams={selectedTeams}
          translate={this.props.t}
        />
      );
    }

    return (
      <div className="dashBoardContainer">
        {teamsListModal}
        {graphModal}
        <div className="leftSideContainer">{teamsListAside}</div>
        <div className="rightSideContainer">
          <HeaderComponent translate={translate} showBackButton={true} />
          <div className="titleSection">
            <MainTitleComponent title={translate("Common:dashboards")} />
            <DataSourceNavigator
              translate={translate}
              selectedGraphs={selectedGraphs}
              handleGraphSelector={this.handleGraphSelector}
              loadingDecisionsData={loadingDecisionsData}
              decisionsData={decisionsData}
              decisionsDataError={decisionsDataError}
            />
          </div>
          {teamListMobile}
          {dashBoardSection}
        </div>
      </div>
    );
  }
}

export default withTranslation("SupplyChainCommon")(DashBoardsComponent);
