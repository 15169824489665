import { formatHistoricData } from "./DashBoardUtilities";
const tableNames = {
  initialSections: "initialSections",
  playerSections: "playerSections",
};

export const CREATE_NEW_SCENARIO_SECTIONS = [
  {
    sectionName: "general",
    items: [
      {
        name: "name",
        type: "string",
        minValue: null,
        maxValue: null,
        units: "",
      },
      {
        name: "description",
        type: "string",
        minValue: null,
        maxValue: null,
        units: "",
      },
      {
        name: "seed",
        type: "string",
        minValue: 0,
        maxValue: 20000,
        units: "",
        helpMessage: "seedValueHelp",
      },
      {
        name: "initialCash",
        type: "number",
        minValue: 500000,
        maxValue: 2500000,
        units: "DollarsInterpolation",
        step: 100000,
        helpMessage: "initialCashHelp",
      },
      {
        name: "stationOneMachineCapacity",
        type: "number",
        minValue: 45,
        maxValue: 90,
        toggle: "stationOneMachineHasVariance",
        units: "UnitPerDayInterpolation",
        step: 1,
        helpMessage: "stationOneMachineCapacityHelp",
      },
      {
        name: "stationTwoMachineCapacity",
        type: "number",
        minValue: 90,
        maxValue: 180,
        toggle: "stationTwoMachineHasVariance",
        units: "UnitPerDayInterpolation",
        step: 1,
        helpMessage: "stationTwoMachineCapacityHelp",
      },
      {
        name: "stationThreeMachineCapacity",
        type: "number",
        minValue: 120,
        maxValue: 240,
        toggle: "stationThreeMachineHasVariance",
        units: "UnitPerDayInterpolation",
        step: 1,
        helpMessage: "stationThreeMachineCapacityHelp",
      },
      {
        name: "inventoryLeadTime",
        type: "number",
        minValue: 3,
        maxValue: 8,
        units: "DayInterpolation",
        step: 1,
        helpMessage: "inventoryLeadTimeHelp",
      },
      {
        name: "batchSetupTime",
        type: "number",
        minValue: 3,
        maxValue: 8,
        units: "DayInterpolation",
        step: 1,
        helpMessage: "batchSetupTimeHelp",
      },
      {
        name: "customLineWipLimit",
        type: "number",
        minValue: 600,
        maxValue: 1200,
        units: "UnitInterpolation",
        step: 1,
        helpMessage: "customLineWipLimitHelp",
      },
    ],
  },
  {
    sectionName: "customDemand",
    items: [
      {
        name: "customDemandHasVariance",
        type: "toggle",
      },
      {
        name: "customDemandInitialValue",
        type: "number",
        minValue: 0,
        maxValue: 50,
        units: "OrderInterpolation",
        helpMessage: "customDemandInitialValueHelp",
      },
      {
        name: "customDemandFinalValue",
        type: "number",
        minValue: 100,
        maxValue: 300,
        units: "OrderInterpolation",
        helpMessage: "customDemandFinalValueHelp",
      },
      {
        name: "customDemandStabilizationDay",
        type: "number",
        minValue: 1,
        maxValue: 400,
        units: "DayInterpolation",
        helpMessage: "customDemandStabilizationDayHelp",
      },
    ],
  },
];

export const scenarioGraphSection = (scenarioData) => {
  const graphSectionData = createGraphsSection(scenarioData);

  return graphSectionData;
};

const createGraphsSection = (scenarioData) => {
  const allGraphSeries = [];
  scenarioData.forEach((segmentData) => {
    segmentData.graphs.forEach((data, index) => {
      const graphSerie = {
        graphName: segmentData.graphs[index].name,
        graphUnits: segmentData.graphs[index].units,
        graphsData: {
          dataPoints: formatHistoricData(data.dataPoints),
          scenario: segmentData.name,
          opacity: 1,
        },
      };
      allGraphSeries.push(graphSerie);
    });
  });

  const graphData = groupByGraph(allGraphSeries);
  return graphData;
};

const groupByGraph = (graphSeries) => {
  const rawGraphNames = graphSeries.map((graph) => {
    return graph.graphName;
  });

  const graphNames = Array.from(new Set(rawGraphNames));

  return graphNames.map((name) => {
    const currentGraphSeries = graphSeries.filter((graph) => graph.graphName === name);

    const currentGraphData = currentGraphSeries.map((graph) => {
      return graph.graphsData;
    });

    return {
      graphData: currentGraphData,
      graphName: currentGraphSeries[0].graphName,
      graphUnits: currentGraphSeries[0].graphUnits,
    };
  });
};

export const scenarioInitialValuesSection = (templatesData) => {
  const flatInitialValuesData = flattenValuesData(templatesData, tableNames.initialSections);
  const scenarioInitialValues = inflateScenarioValues(flatInitialValuesData);

  return scenarioInitialValues;
};

export const scenarioPlayerValuesSection = (templatesData) => {
  const flatPlayerValuesData = flattenValuesData(templatesData, tableNames.playerSections);
  const scenarioPlayerValues = inflateScenarioValues(flatPlayerValuesData);

  return scenarioPlayerValues;
};

const flattenValuesData = (templatesData, tableName) => {
  const result = []
    .concat(
      ...templatesData.map((templateData) =>
        templateData[tableName].map((section) =>
          section.configurations.map((configuration) => ({
            templateName: templateData.name,
            sectionName: section.section,
            configurationName: configuration.name,
            units: configuration.units,
            values: configuration.values,
          }))
        )
      )
    )
    .flat();
  return result;
};

const inflateScenarioValues = (flatValuesData) =>
  flatValuesData.reduce((formattedValues, currentValue) => {
    const currentSectionIndex = formattedValues.findIndex((section) => section.name === currentValue.sectionName);
    if (currentSectionIndex === -1) {
      return formattedValues.concat([
        {
          name: currentValue.sectionName,
          configurations: [
            {
              name: currentValue.configurationName,
              units: currentValue.units,
              values: [currentValue.values],
            },
          ],
        },
      ]);
    }

    const currentConfigurationIndex = formattedValues[currentSectionIndex].configurations.findIndex(
      (configuration) => configuration.name === currentValue.configurationName
    );
    if (currentConfigurationIndex === -1) {
      formattedValues[currentSectionIndex].configurations.push({
        name: currentValue.configurationName,
        units: currentValue.units,
        values: [currentValue.values],
      });
      return formattedValues;
    }

    formattedValues[currentSectionIndex].configurations[currentConfigurationIndex].values.push(currentValue.values);
    return formattedValues;
  }, []);

export const createInitialFormItemsState = () => {
  const initialFormItemsState = {};

  CREATE_NEW_SCENARIO_SECTIONS.forEach((section) => {
    section.items.forEach((item) => {
      const stateItem = `${section.sectionName}_${item.name}`;
      const stateToggleItem = `${section.sectionName}_${item.toggle}`;
      const middleValue = Math.round((item.maxValue + item.minValue) / 2);
      if (item.type === "number") {
        initialFormItemsState[stateItem] = middleValue;
        if (item.toggle) {
          initialFormItemsState[stateToggleItem] = false;
        }
      } else if (item.type === "toggle") {
        initialFormItemsState[stateItem] = false;
      } else {
        if (item.name === "seed") {
          initialFormItemsState[stateItem] = middleValue;
        } else {
          initialFormItemsState[stateItem] = "";
        }
      }
    });
  });

  return initialFormItemsState;
};

export const getItemOperationData = (itemId) => {
  const splitedId = itemId.split("_");
  const sectionName = splitedId[0];
  const itemName = splitedId[1];

  const section = CREATE_NEW_SCENARIO_SECTIONS.find((section) => section.sectionName === sectionName);

  const item = section.items.find((item) => item.name === itemName);

  return item;
};

export const getOperationValue = (itemId) => {
  let operationValue = 1;

  if (itemId === "general_seed") {
    operationValue = 100;
  } else if (itemId === "general_initialCash") {
    operationValue = 100000;
  }

  return operationValue;
};

export const flattenItemSections = () => {
  const flatSingle = CREATE_NEW_SCENARIO_SECTIONS.reduce((acc, val) => acc.concat(val.items), []);

  return flatSingle;
};
