export const discountCodeStatuses = {
  initial: "initial",
  loading: "loading",
  activated: "activated",
  activatedPaid: "activatedPaid",
};

export const confirmPaymentStatuses = {
  initial: "initial",
  loading: "loading",
  fail: "fail",
  success: "success",
};
