import React from "react";
import { Grid } from "semantic-ui-react";
import DefaultButton from "../helpers/DefaultButton";

const TeamListMobile = (props) => {
  const { handleTeamsListModal, buttonTitle } = props;
  return (
    <Grid>
      <Grid.Column>
        <div className="teamSelector">
          <div className="selectTeamButton">
            <DefaultButton action={handleTeamsListModal} title={buttonTitle} />
          </div>
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default TeamListMobile;
