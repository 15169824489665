import React from "react";
import { Button, Modal } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";

const ErrorModal = (props) => {
  const {
    openErrorModal,
    title,
    message,
    translate,
    handleErrorModal,
    retryText,
    hasRetry,
    handleRetry,
    warning,
  } = props;
  let customTitle = translate("Common:error");
  let actionButton = null;

  if (title) {
    customTitle = title;
  }

  if (hasRetry) {
    actionButton = <Button content={retryText} onClick={handleRetry} />;
  } else {
    actionButton = <Button content="Ok" onClick={handleErrorModal} />;
  }

  return (
    <div>
      <Modal size="tiny" open={openErrorModal} onClose={handleErrorModal}>
        <Modal.Header>{customTitle}</Modal.Header>
        <Modal.Content>
          <MessagesComponent message={message} warning={warning} />
        </Modal.Content>
        <Modal.Actions>{actionButton}</Modal.Actions>
      </Modal>
    </div>
  );
};

export default ErrorModal;
