import React, { Component } from "react";
import { Container, Divider } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import HeaderComponent from "../header/HeaderComponent";
import BackButton from "../helpers/BackButton";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import ErrorModal from "../modals/ErrorModal";
import LanguageSelector from "../helpers/LanguageSelector";
import "./paymentStyles.css";
import { fixChatWidgetPlacement, resetChatWidgetPlacement } from "../../services/ChatWidgetPositionService";
import PaymentManagerStripe from "../paymentManagerStripe/index";
import AmountsComponent from "./AmountsComponent";
import DiscountCodeModal from "./DiscountCodeModal";
import { compose } from "redux";
import RedirectModal from "../modals/RedirectModal";
import { discountCodeStatuses } from "../../utilities/storeStatusDictionary";

class PaymentComponent extends Component {
  state = {
    openDiscountCodeModal: false,
    openRedirectModal: false,
    loadingCache: true,
  };

  componentDidMount() {
    fixChatWidgetPlacement();
    this.props.checkStorageInformation();
    const { studentPayment } = this.props;
    if (studentPayment) {
      this.validatePreviousStudentPayment();
    }
    this.setState({
      loadingCache: false,
    });
  }

  componentWillUnmount() {
    resetChatWidgetPlacement();
  }

  handleDiscountModal = (status) => {
    this.setState({
      openDiscountCodeModal: !this.state.openDiscountCodeModal,
      openRedirectModal: status === discountCodeStatuses.activatedPaid,
    });
  };

  validatePreviousStudentPayment() {
    const { simulationId } = this.props;
    this.props.checkIfStudentHasPaid(simulationId);
  }

  getAmount() {
    const { discountCodeStatus } = this.props;

    if ([discountCodeStatuses.activated, discountCodeStatuses.activatedPaid].includes(discountCodeStatus.status)) {
      return this.getNumberOfLicenses() * this.props.discountCodeStatus.discountedPrice;
    }

    return this.getNumberOfLicenses() * this.props.productPrice;
  }

  getDescription() {
    const { studentPayment, simulatorType } = this.props;
    const numberOfStudents = this.getNumberOfLicenses();

    if (studentPayment) {
      return `Student Payment of ${numberOfStudents} license of ${simulatorType} simulator`;
    }
    return `Professor payment of ${numberOfStudents} licenses of ${simulatorType} simulator`;
  }

  getNumberOfLicenses() {
    const { studentPayment } = this.props;
    return studentPayment ? 1 : this.props.questionnaire.numberOfStudents;
  }

  render() {
    const {
      t: translate,
      studentPayment,
      simulationId,
      studentHasAlreadyPaid,
      discountCodeStatus,
      successRoute,
      productPrice,
      paymentIntentAPI,
    } = this.props;
    const { openDiscountCodeModal } = this.state;

    const paymentData = {
      amount: this.getAmount(),
      description: this.getDescription(),
      numberOfLicenses: this.getNumberOfLicenses(),
      paymentSuccessRoute: successRoute,
      simulationId: simulationId,
    };
    const numberOfLicenses = this.getNumberOfLicenses();

    let mainComponent = null;
    let errorComponent = null;
    let languageSelector = null;
    let backButton = null;
    let discountCodeButton = null;

    if ((studentPayment && studentHasAlreadyPaid.loading) || this.state.loadingCache) {
      mainComponent = <LoadingComponent fullSize={true} message={translate("checkingStudentPayment")} />;
    }

    if (studentPayment && studentHasAlreadyPaid.studentHasPaid) {
      this.props.history.push(successRoute);
    }

    discountCodeButton = (
      <p className={`discountLabel`} onClick={this.handleDiscountModal}>
        {translate("discountCode")}
      </p>
    );

    if (
      discountCodeStatus.status === discountCodeStatuses.activated ||
      discountCodeStatus.status === discountCodeStatuses.activatedPaid
    ) {
      discountCodeButton = (
        <p className={`discountLabel`} onClick={this.props.resetDiscountCode}>
          {translate("removeDiscountCode")}
        </p>
      );
    }

    if (studentPayment && studentHasAlreadyPaid.errorMessage) {
      errorComponent = (
        <ErrorModal
          handleErrorModal={() => this.validatePreviousStudentPayment()}
          translate={translate}
          openErrorModal={true}
          message={translate(this.props.studentHasAlreadyPaid.errorMessage)}
        />
      );
    }

    const redirectModal = (
      <RedirectModal
        open={this.state.openRedirectModal}
        translate={translate}
        history={this.props.history}
        title={translate("paymentSuccess")}
        message={translate("yourPaymentHasBeenSuccessfullyRegistered")}
        route={successRoute}
      />
    );

    if (studentPayment) {
      languageSelector = <LanguageSelector />;
    } else {
      backButton = <BackButton translate={translate} history={this.props.history} />;
    }
    const { discountedPrice } = discountCodeStatus;
    const currentAmountByStudent = typeof discountedPrice === "number" ? discountedPrice : productPrice;
    if (mainComponent === null && errorComponent === null) {
      mainComponent = (
        <Container>
          <HeaderComponent translate={translate} />
          <MainTitleComponent title={translate("payment")} reducedSubTitle={true} />
          <Divider hidden />
          <PaymentManagerStripe
            simulationId={simulationId}
            discountCode={discountCodeStatus.discountCode}
            numberOfLicenses={numberOfLicenses}
            amount={this.getAmount()}
            description={this.getDescription()}
            successRoute={successRoute}
            paymentIntentAPI={paymentIntentAPI}
            getPaymentIntent={this.props.paymentIntentAPI}
            creatingSimulation={studentPayment !== true}
          />
          <Divider hidden />
          <AmountsComponent translate={translate} paymentData={paymentData} amountByStudent={currentAmountByStudent} />
          {discountCodeButton}
          {backButton}
          {languageSelector}
          <DiscountCodeModal
            open={openDiscountCodeModal}
            handleDiscountModal={this.handleDiscountModal}
            validateDiscountCode={this.props.validateDiscountCode}
            amountByStudent={currentAmountByStudent}
            discountCodeStatus={discountCodeStatus}
            paymentData={paymentData}
            updateTransactionData={this.props.updateTransactionData}
          />
          {redirectModal}
        </Container>
      );
    }

    return (
      <Container>
        {mainComponent}
        {errorComponent}
      </Container>
    );
  }
}

const HOC = compose(withTranslation(["Payment", "Common"]), withRouter);
export default HOC(PaymentComponent);
