import React from "react";
import { List } from "semantic-ui-react";
import DefaultButton from "../helpers/DefaultButton";

const ProfileDetailComponent = (props) => {
  const { user, translate, handleRequestFeatureModal } = props;
  const listDetails = [
    {
      key: 1,
      item: translate("email"),
      content: user.email,
    },
    {
      key: 2,
      item: translate("fullName"),
      content: user.fullName,
    },
    {
      key: 3,
      item: translate("institution"),
      content: user.institution,
    },
  ];

  return (
    <div className="profileDetailsContainer">
      <div className="detailsData">
        <p>{translate("profileDetails").toUpperCase()}</p>
        <List>
          {listDetails.map((detail) => {
            return (
              <List.Item key={detail.key}>
                <div>
                  <strong>{detail.item}: </strong>
                  {detail.content}
                </div>
              </List.Item>
            );
          })}
        </List>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DefaultButton title={translate("requestFeature")} action={handleRequestFeatureModal} />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailComponent;
