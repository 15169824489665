import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container } from "semantic-ui-react";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { getUrlSearchParam } from "../../services/NavigationService";
import { getStorage } from "../../services/StorageService";
import { creambowGetRegisteredStudents } from "../../store/CreambowSimulationDetails/actions";
import HeaderComponent from "../header/HeaderComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import RegisteredStudentsComponent from "./RegisteredStudentsComponent";

class CreambowRegisteredStudents extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  componentDidMount() {
    const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation);
    this.props.getRegisteredStudents(requestedSimulationId);
  }

  render() {
    const translate = this.props.t;
    const registeredStudentsStatus = this.props.registeredStudentsRequestStatus;
    let body = (
      <RegisteredStudentsComponent
        translate={translate}
        simulationRegisteredStudents={registeredStudentsStatus.registeredStudents}
      />
    );

    if (!registeredStudentsStatus.success) {
      body = <MessagesComponent info={false} message={translate(registeredStudentsStatus.errorMessage)} />;
    }
    if (registeredStudentsStatus.loading) {
      body = <LoadingComponent message={translate("loadingRegisteredStudents")} />;
    }

    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent title={translate("registeredStudentsOf")} subtitle={this.state.requestedSimulationName} />
        {body}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRegisteredStudents: (simulationId) => dispatch(creambowGetRegisteredStudents(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    registeredStudentsRequestStatus: state.creambowSimulationDetails.registeredStudents,
    language: state.user.userProfile.language,
  };
};

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("RegisteredStudentsScreen", "Common"),
  withRouter
);

export default HOC(CreambowRegisteredStudents);
