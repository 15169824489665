import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import "./crisisBadLink.css";

function CrisisBadLinkComponent(props) {
  const translate = props.t;
  return (
    <Container>
      <HeaderComponent translate={translate} location={props.location} showLanguageSelector />
      <MainTitleComponent title={"Oops"} />
      <div className={"crisis-bad-link__body-container"}>
        <p className={"crisis-bad-link__error-text"}>
          <b>{translate("badLink")}</b>
        </p>
        <p>
          <Trans t={translate} i18nKey="tryFollowing">
            Please double-check with your professor that you have the correct link. If that's the case, please let us
            know at
            <a href="mailto:support@processimlabs.com">support@processimlabs.com</a>
            or using the chat on the bottom right corner of this page.
          </Trans>
        </p>
      </div>
    </Container>
  );
}

const HOC = compose(withRouter, withTranslation("CrisisBadLink", "Common"));

export default HOC(CrisisBadLinkComponent);
