import React from "react";
import "./modals.css";
import { Button, Modal, Message } from "semantic-ui-react";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";
import * as LOCALSTORAGE from "../../constants/LocalStorage";

const JoinedTeamModal = (props) => {
  const translate = props.translate;
  let actionButton = null;
  let cancelButton = null;

  const closeJoinTeam = () => {
    props.history.replace(ROUTES.STUDENTS_LOGIN);
    props.removeStorage(LOCALSTORAGE.teamCode);
    props.removeStorage(LOCALSTORAGE.securityCode);
    props.removeStorage(LOCALSTORAGE.teamName);
    props.removeStorage(LOCALSTORAGE.simulationId);
    props.removeStorage(LOCALSTORAGE.activationCode);
  };

  const goToDownloadApp = () => {
    const transactionData = {
      paymentId: "",
      purchasedSimulationName: CONSTANT.MEDICA_NAME,
    };
    props.updateTransactionData(transactionData);
    props.history.push(ROUTES.DOWNLOAD_APP);
  };

  if (props.messageType) {
    actionButton = <Button content="Ok" onClick={goToDownloadApp} />;
  } else {
    if (!props.showNoTeamMessage && !props.needRetry) {
      actionButton = <Button content="Ok" onClick={closeJoinTeam} />;
    } else {
      actionButton = <Button content={translate("JoinTeamModal:retry")} onClick={props.joinTeam} />;
    }
  }

  return (
    <Modal open={props.open} size="tiny">
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <Message visible={true} positive={props.messageType} negative={!props.messageType}>
          <Message.Header>{props.message}</Message.Header>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        {actionButton}
        {cancelButton}
      </Modal.Actions>
    </Modal>
  );
};

export default JoinedTeamModal;
