import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Container, Grid } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import ActionButtons from "./ActionButtons";
import CrisisDetailComponent from "./CrisisDetailComponent";
import ErrorModal from "../modals/ErrorModal";
import CrisisShareSimulationModal from "../modals/CrisisShareModal";
import "./SimulationDetailStyles.css";
import AdvanceSimulationModal from "../modals/CrisisAdvanceSimulationModal";
import * as ROUTES from "../../constants/Routes";
import { getStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as Sentry from "@sentry/react";
import { crisisGetDocumentation, crisisGetSimulationDetails } from "../../store/CrisisSimulationDetails/actions";
import { createCrisisLink } from "../utilities/CrisisSimulationUtilities";
import ModifySimulationModal from "./ModifySimulationModal";
import DocumentsModal from "../modals/DocumentsModal";

class CrisisDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: false,
      openShareModal: false,
      openModifyModal: false,
      openAdvanceModal: false,
      openDocumentsModal: false,
      messageSuccess: true,
      simulationSetUpError: false,
      isWarning: false,
    };
  }

  componentDidMount() {
    const transaction = Sentry.startTransaction({
      name: "/CrisisDetail",
      op: "showCrisisDetail",
    });
    window.scrollTo(0, 0);
    const simulationIsCached = this.checkIfSimulationIsCached();
    if (!simulationIsCached) {
      const spanGetSimulationDetail = transaction.startChild({
        op: "getSimulationDetail",
        description: `Getting Simulation Detail`,
      });
      this.getSimulationDetails();
      this.getDocumentation();
      spanGetSimulationDetail.finish();
    }
    transaction.finish();
  }

  checkIfSimulationIsCached = () => {
    // const requestedSimulationId = prepareSimulationName(true);
    // const simulationSaved = this.props.medica.simulationDetails.simulationId;
    // if (requestedSimulationId !== simulationSaved) {
    //   return false;
    // }
    // return true;
    return false;
  };

  getSimulationId = () => {
    const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation);
    return requestedSimulationId;
  };

  getDocumentation = () => {
    const { getDocumentation } = this.props;
    getDocumentation(this.getSimulationId());
  };

  getSimulationDetails = () => {
    const { crisisGetSimulationDetails } = this.props;
    const requestedSimulationId = this.getSimulationId();
    crisisGetSimulationDetails(requestedSimulationId);
  };

  handleShareModal = () => {
    this.setState((state) => ({ openShareModal: !state.openShareModal }));
  };

  handleErrorModal = () => {
    this.setState((state) => ({
      openErrorModal: !state.openErrorModal,
      isWarning: false,
    }));
  };

  handleAdvanceModal = () => {
    this.setState((state) => ({ openAdvanceModal: !state.openAdvanceModal }));
  };

  handleModifyModal = () => {
    this.setState((state) => ({ openModifyModal: !state.openModifyModal }));
  };

  handleDocumentsModal = () => {
    const { documentationStatus } = this.props;
    if (documentationStatus.success === false) {
      this.getDocumentation();
    }
    this.setState((state) => ({ openDocumentsModal: !state.openDocumentsModal }));
  };

  dashboardsAction = () => {
    this.props.history.push({
      pathname: ROUTES.CRISIS_SIMULATION_DASHBOARDS,
      search: this.props.crisisSimulationDetails.simulationDetails?.name,
      state: {},
    });
  };

  render() {
    const { t: translate, documentationStatus } = this.props;
    const {
      openErrorModal,
      openShareModal,
      openAdvanceModal,
      openModifyModal,
      openDocumentsModal,
      messageSuccess,
      errorTitle,
      isWarning,
    } = this.state;
    let shareSimulationModal = null;
    let advanceModal = null;
    let errorModal = null;
    let actionButtons = null;
    let header = null;
    let dropdown = null;
    let modifyModal = null;

    const errorMessage = this.props.crisisSimulationDetailsRequestStatus.errorMessage;
    const errorMessageTranslation = this.props.i18n.exists(errorMessage)
      ? translate(errorMessage)
      : translate(`CrisisDetailScreen:${errorMessage}`);
    errorModal = (
      <ErrorModal
        handleErrorModal={this.handleErrorModal}
        translate={translate}
        warning={isWarning}
        openErrorModal={openErrorModal}
        title={translate(errorTitle)}
        message={errorMessageTranslation}
      />
    );

    if (errorMessage !== "") {
      errorModal = (
        <ErrorModal
          handleErrorModal={this.handleErrorModal}
          translate={translate}
          openErrorModal={errorMessage !== "" && !openErrorModal}
          message={errorMessageTranslation}
        />
      );
    }
    const documentationModal = (
      <DocumentsModal
        translate={translate}
        handleDocumentsModal={this.handleDocumentsModal}
        openDocumentsModal={openDocumentsModal}
        simulationDocumentation={documentationStatus}
      />
    );

    const loadingDetails = this.props.crisisSimulationDetailsRequestStatus.loading;
    const detailsValid = this.props.crisisSimulationDetailsRequestStatus.detailsValid;
    const simulationDetails = this.props.crisisSimulationDetails;
    let shareLink = simulationDetails.shortLink;
    if (shareLink === "") {
      shareLink = createCrisisLink(
        simulationDetails.id,
        simulationDetails.professorPaid ? simulationDetails.paymentSummaryId : ""
      );
    }
    if (detailsValid) {
      shareSimulationModal = (
        <CrisisShareSimulationModal
          translate={translate}
          open={openShareModal}
          shareLink={shareLink}
          titleModal={translate("ShareModal:title")}
          titleSecondaryButton={translate("ShareModal:cancel")}
          handleSecondaryButton={this.handleShareModal}
          messageType={messageSuccess}
        />
      );

      advanceModal = (
        <AdvanceSimulationModal
          open={true}
          handleAdvanceModal={this.handleAdvanceModal}
          getSimulationDetails={this.getSimulationDetails}
        />
      );

      modifyModal = (
        <ModifySimulationModal
          onClose={this.handleModifyModal}
          simulationDetails={simulationDetails}
          getSimulationDetails={this.getSimulationDetails}
        />
      );

      actionButtons = (
        <ActionButtons
          translate={translate}
          location={this.props.location}
          history={this.props.history}
          handleShareModal={this.handleShareModal}
          handleAdvanceModal={this.handleAdvanceModal}
          handleModifyModal={this.handleModifyModal}
          openDocumentationModal={this.handleDocumentsModal}
          simulationDetails={simulationDetails}
          detailsLoaded={detailsValid}
          dashboardsAction={this.dashboardsAction}
        />
      );
      dropdown = <ActionButtons {...actionButtons.props} isMobile={true} />;
      header = <HeaderComponent translate={translate} dropdown={dropdown} />;
    } else {
      header = <HeaderComponent />;
    }

    return (
      <Container>
        {errorModal}
        {openModifyModal ? modifyModal : null}
        {openAdvanceModal ? advanceModal : null}
        {shareSimulationModal}
        {documentationModal}
        <Grid>
          <Grid.Column mobile={16} computer={10} tablet={10}>
            {header}
            <CrisisDetailComponent
              translate={translate}
              location={this.props.location}
              simulationDetails={simulationDetails}
              loadingDetails={loadingDetails}
            />
          </Grid.Column>
          <Grid.Column only="mobile" mobile={16}>
            <div className="bottomArea">
              <Button className="goBackButton" onClick={() => this.props.history.push(ROUTES.HOME)}>
                {translate("Common:back").toLowerCase()}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column className="rightColumn" only="tablet computer" computer={6} tablet={6}>
            {actionButtons}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDocumentation: (simulationId) => dispatch(crisisGetDocumentation(simulationId)),
  crisisGetSimulationDetails: (simulationId) => dispatch(crisisGetSimulationDetails(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    documentationStatus: state.crisisSimulationDetails.documentationStatus,
    crisisSimulationDetails: state.crisisSimulationDetails.simulationDetails,
    crisisSimulationDetailsRequestStatus: state.crisisSimulationDetails.simulationDetailsRequestStatus,
    language: state.user.userProfile.language,
  };
};

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(
    "MedicaDetailScreen",
    "CrisisDetailScreen",
    "ModifyModal",
    "ShareModal",
    "MedicaAdvancedOptions",
    "Common"
  ),
  withRouter
);

export default HOC(CrisisDetail);
