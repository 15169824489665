import React from "react";
import HelpHoverComponent from "../../helpers/HelpHoverComponent";
import "./leftTitle.css";

const LeftTitleComponent = (props) => {
  const { hasHelp, title, subtitle, helpMessage } = props;
  let helpHoverComponent = null;

  if (hasHelp) {
    helpHoverComponent = <HelpHoverComponent helpMessage={helpMessage} size="large" iconSize="lg" />;
  }

  return (
    <div className="leftTitle">
      <span className="blackLine" />
      <div className="leftTitleContent">
        <p>{title}</p>
        {helpHoverComponent}
      </div>
      <p>{subtitle}</p>
    </div>
  );
};

export default LeftTitleComponent;
