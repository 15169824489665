import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import RegisteredStudentsComponent from "./RegisteredStudentsComponent";
import { getRegisteredStudents } from "../../store/Simunation/actions";
import { getUrlSearchParam } from "../../services/NavigationService";

class SimunationRegisteredStudentsScreen extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  componentDidMount() {
    this.getRegisteredStudents();
  }

  getRegisteredStudents = () => {
    this.props.getRegisteredStudents(this.state.requestedSimulationName);
  };

  render() {
    const translate = this.props.t;
    const { registeredStudents, error, loading } = this.props.simunation;
    let loader = null;
    let messageComponent = null;
    let registeredStudentsComponent = null;

    if (loading) {
      loader = <LoadingComponent message={translate("loadingRegisteredStudents")} />;
    } else {
      if (error !== "") {
        messageComponent = <MessagesComponent message={translate(error)} />;
      } else {
        if (registeredStudents.outcome) {
          registeredStudentsComponent = (
            <RegisteredStudentsComponent
              translate={translate}
              simulationRegisteredStudents={registeredStudents.payload}
            />
          );
        }
      }
    }
    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent title={translate("registeredStudentsOf")} subtitle={this.state.requestedSimulationName} />
        {loader}
        {messageComponent}
        {registeredStudentsComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getRegisteredStudents: (simulationName) => dispatch(getRegisteredStudents(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    simunation: {
      registeredStudents: state.simunation.simulationRegisteredStudents,
      loading: state.simunation.loadingRegisteredStudents,
      error: state.simunation.getRegisteredStudentsError,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("RegisteredStudentsScreen", "Common")(withRouter(SimunationRegisteredStudentsScreen))
);
