import { SUPPLY_CHAIN_JOIN_SIMULATION_PREAMBLE } from "../../constants/Routes";
import * as SUPPLYCHAIN_CONSTANTS from "../../constants/SupplyChainConstants";
import moment from "moment";
import { convertHoursToMilliseconds } from "./DateTimeUtilities";

export const createSupplyChainLink = (simulationId) => {
  return `${window.location.origin}${SUPPLY_CHAIN_JOIN_SIMULATION_PREAMBLE}?simulationId=${simulationId}`;
};

export const supplyChainGameModeOptions = (t) => [
  {
    key: 1,
    text: t(`SupplyChainCommon:${SUPPLYCHAIN_CONSTANTS.OneStationUnknownPeers}`),
    value: SUPPLYCHAIN_CONSTANTS.OneStationUnknownPeers,
  },
  {
    key: 2,
    text: t(`SupplyChainCommon:${SUPPLYCHAIN_CONSTANTS.OneStationKnownPeers}`),
    value: SUPPLYCHAIN_CONSTANTS.OneStationKnownPeers,
  },
  {
    key: 3,
    text: t(`SupplyChainCommon:${SUPPLYCHAIN_CONSTANTS.AllStationsKnownPeers}`),
    value: SUPPLYCHAIN_CONSTANTS.AllStationsKnownPeers,
  },
  {
    key: 4,
    text: t(`SupplyChainCommon:${SUPPLYCHAIN_CONSTANTS.LastMile}`),
    value: SUPPLYCHAIN_CONSTANTS.LastMile,
  },
];

export const supplyChainTimeBetweenAdvancesOptions = (t) => [
  {
    key: "time1",
    text: t("SupplyChainPurchased:TimeBetweenAdvancesOneHour"),
    value: 1,
  },
  {
    key: "time2",
    text: t("SupplyChainPurchased:TimeBetweenAdvancesTwoHours"),
    value: 2,
  },
];

export const supplyChainValidateDates = (startDate, endDate, pace) => {
  if (startDate.diff(moment()) < SUPPLYCHAIN_CONSTANTS.minTimeBeforeSimulationStartsInMiliseconds) {
    return {
      invalidStartTime: true,
      invalidEndTime: false,
      dateValidationError: { message: "SupplyChainPurchased:SimulationStartInTheFuture" },
    };
  }

  const { minimumNumberOfHoursToRunByPace, maximumNumberOfHoursToRunByPace } = SUPPLYCHAIN_CONSTANTS;
  const minimumHoursToRun =
    typeof minimumNumberOfHoursToRunByPace[pace] === "number" ? minimumNumberOfHoursToRunByPace[pace] : 50;
  const maximumHoursToRun =
    typeof maximumNumberOfHoursToRunByPace[pace] === "number" ? maximumNumberOfHoursToRunByPace[pace] : 122;

  if (endDate.diff(startDate) < convertHoursToMilliseconds(minimumHoursToRun)) {
    return {
      invalidStartTime: false,
      invalidEndTime: true,
      dateValidationError: { message: "SupplyChainPurchased:SimulationMinDurationError", hours: minimumHoursToRun },
    };
  }

  if (endDate.diff(startDate) > convertHoursToMilliseconds(maximumHoursToRun)) {
    return {
      invalidStartTime: false,
      invalidEndTime: true,
      dateValidationError: { message: "SupplyChainPurchased:SimulationMaxDurationError", hours: maximumHoursToRun },
    };
  }

  return {
    invalidStartTime: false,
    invalidEndTime: false,
    dateValidationError: {},
  };
};
