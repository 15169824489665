import isDeviceMobile from "../components/utilities/MobileBrowserChecker";

let chatWidgetBottomPosition = null;

export const fixChatWidgetPlacement = () => {
  if (!isDeviceMobile()) {
    return;
  }

  const chatWidget = window.document.getElementById("chat-application");
  if (chatWidget?.style?.bottom) {
    chatWidgetBottomPosition = chatWidget.style.bottom;
    chatWidget.style.bottom = null;
  }
};

export const resetChatWidgetPlacement = () => {
  const chatWidget = window.document.getElementById("chat-application");
  if (chatWidget && chatWidgetBottomPosition) {
    chatWidget.style.bottom = chatWidgetBottomPosition;
  }
};
