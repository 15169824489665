const REGEXP = /^[0-9a-zA-Z\-_\s]+$/;

export const checkNameIsValid = (data) => {
  if (data.length > 0) {
    const nameTester = REGEXP.test(data);
    if (nameTester) {
      return true;
    }
    return false;
  }
  return true;
};

export const isJsonString = (jsonData) => {
  if (
    /^[\],:{}\s]*$/.test(
      jsonData
        .replace(/\["\\\/bfnrtu]/g, "@")
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?/g, "]")
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  }
  return false;
};
