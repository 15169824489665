import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import * as CONSTANT from "../../constants/UniversalConstants";
import moment from "moment";
import DropDownComponent from "../helpers/DropDownComponent";
import CalendarComponent from "../helpers/CalendarComponent";
import InputComponent from "../helpers/InputComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import AdvancedOptionsComponent from "./AdvancedOptionsComponent";
import { checkNameIsValid } from "../utilities/DataValidationUtilities";
import { switchTo12HourFormat, unifyDateTime } from "../utilities/DateTimeUtilities";

class PurchasedMedicaComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      simulationName: this.props.simulationName,
      dateToModify: "",
      startHour: switchTo12HourFormat(Number(moment(this.props.startDate).format("HH"))),
      startHourFormat: moment(this.props.startDate).format("a"),
      startDate: this.props.startDate,
      endHour: switchTo12HourFormat(Number(moment(this.props.endDate).format("HH"))),
      endHourFormat: moment(this.props.endDate).format("a"),
      endDate: this.props.endDate,
      simulationNameError: false,
      error: false,
      openCalendar: false,
      daysToRunError: "",
      daysToRun: this.props.totalSimulatedDays,
    };
  }

  handleCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  onDateClick = (date, dateToModify) => {
    if (dateToModify === "startDate" && date >= moment(this.state.endDate).format("YYYY-MM-DD")) {
      this.setState({
        [dateToModify]: date,
        endDate: moment(date).add(1, "day"),
      });
    } else {
      this.setState({
        [dateToModify]: date,
      });
      this.props.onError("");
    }
  };

  settingStartHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        startHourFormat: value,
      });
    } else {
      this.setState({
        startHour: value,
      });
      this.props.onError("");
    }
  };

  settingEndHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        endHourFormat: value,
      });
    } else {
      this.setState({
        endHour: value,
      });
    }
    this.props.onError("");
  };

  endDateMustBeGreaterError = () => {
    const translate = this.props.translate;
    this.props.onError(translate("ModifyModal:End Date must be after Start Date"));
    return;
  };

  fieldsError = () => {
    const translate = this.props.translate;
    this.props.onError(translate("verifyFieldsCorrectlyFilled"));
    return;
  };

  sixHoursError = () => {
    const translate = this.props.translate;
    this.props.onError(translate("ModifyModal:Simulation must run for at least 6 hours"));
  };

  sevenDaysError = () => {
    const translate = this.props.translate;
    this.props.onError(translate("ModifyModal:Simulation can only run for 7 days or less"));
    return;
  };

  endDateMustBeGreaterError = () => {
    const translate = this.props.translate;
    this.props.onError(translate("ModifyModal:End Date must be after Start Date"));
  };

  settingSimulationName = (id, data) => {
    const isValidName = checkNameIsValid(data);
    if (isValidName) {
      this.setState({
        [id]: data,
        simulationNameError: false,
      });
    } else {
      this.setState({
        simulationNameError: true,
        simulationNameErrorText: "invalidSimulationNameCharacterError",
      });
    }
  };

  validateFormData = () => {
    const { simulationName, startDate, endDate, startHour, endHour, startHourFormat, endHourFormat, daysToRun } =
      this.state;
    const { managedProgress, advancedOptions } = this.props;

    if (simulationName === "") {
      this.setState({
        simulationNameError: true,
        simulationNameErrorText: "notSimulationName",
      });
      return;
    }

    if (managedProgress === true) {
      if (daysToRun === null) {
        this.setState({
          daysToRunError: true,
        });
        this.fieldsError();
        return;
      }
      return {
        simulationName: simulationName,
        totalSimulatedDays: daysToRun,
        ...advancedOptions,
      };
    }

    if (managedProgress === false) {
      const newDates = {
        endDate: unifyDateTime({ date: endDate, hour: endHour, hourFormat: endHourFormat }),
        startDate: unifyDateTime({ date: startDate, hour: startHour, hourFormat: startHourFormat }),
      };

      const differenceBetweenDates = newDates.endDate.diff(newDates.startDate);

      if (differenceBetweenDates < 0) {
        this.endDateMustBeGreaterError();
        return;
      } else if (differenceBetweenDates >= 0 && differenceBetweenDates < CONSTANT.SIX_HOURS_MILLISECONDS) {
        this.sixHoursError();
        return;
      } else if (differenceBetweenDates > CONSTANT.SEVEN_DAYS_MILLISECONDS) {
        this.sevenDaysError();
        return;
      }

      return {
        startDate: moment(newDates.startDate).toISOString(),
        endDate: moment(newDates.endDate).toISOString(),
        simulationName: simulationName,
        ...advancedOptions,
      };
    }
  };

  handleContinue = () => {
    return this.validateFormData();
  };

  onSelectionDay = (val) => {
    this.setState({
      daysToRun: val,
      daysToRunError: false,
    });
    this.props.onError("");
  };

  render() {
    const translate = this.props.translate;
    const optionsDaysToRun = [
      {
        key: 1,
        text: 100,
        value: 100,
      },
      {
        key: 2,
        text: 200,
        value: 200,
      },
      {
        key: 3,
        text: 300,
        value: 300,
      },
    ];
    let calendarComponent = null;
    let selectedDate = null;
    let daysToRunDropDown = null;
    let dateStartDateSelector = (
      <DateTimeSelector
        label={translate("ModifyModal:startDate")}
        onFocus={() => this.setState({ dateToModify: "startDate" })}
        error={this.state.error}
        handleCalendar={this.handleCalendar}
        dateToSet={CONSTANT.START_DATE}
        settingHour={this.settingStartHour}
        date={this.state.startDate}
        hour={this.state.startHour}
        hourFormat={this.state.startHourFormat}
        language={this.props.language}
        labelAlignment="left"
      />
    );
    let dateEndDateSelector = (
      <DateTimeSelector
        label={translate("ModifyModal:endDate")}
        onFocus={() => this.setState({ dateToModify: "endDate" })}
        error={this.state.error}
        handleCalendar={this.handleCalendar}
        dateToSet={CONSTANT.END_DATE}
        settingHour={this.settingEndHour}
        date={this.state.endDate}
        hour={this.state.endHour}
        hourFormat={this.state.endHourFormat}
        language={this.props.language}
        labelAlignment="left"
      />
    );

    if (this.state.dateToModify === "startDate") {
      selectedDate = this.state.startDate;
    } else {
      selectedDate = this.state.endDate;
    }

    if (this.props.managedProgress) {
      daysToRunDropDown = (
        <DropDownComponent
          label={translate("daysToPlay")}
          helpMessage={translate("daysToPlayHelp")}
          options={optionsDaysToRun}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.onSelectionDay}
          selectError={this.state.daysToRunError}
          value={this.state.daysToRun}
          labelAlignment="left"
        />
      );
      calendarComponent = null;
      dateStartDateSelector = null;
      dateEndDateSelector = null;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <div className="calendarPosition">
          <CalendarComponent
            translate={translate}
            handleCalendar={this.handleCalendar}
            dateToModify={this.state.dateToModify}
            onDateClick={this.onDateClick}
            selectedDate={selectedDate}
            language={this.props.language}
          />
        </div>
      );
    }

    return (
      <Form>
        {calendarComponent}
        <InputComponent
          label={translate("simulationName")}
          id="simulationName"
          specialLabel={true}
          placeholder="i.e. OM 101"
          type="text"
          onError={this.state.simulationNameError}
          errorMessage={translate(this.state.simulationNameErrorText)}
          value={this.state.simulationName}
          handleChange={this.settingSimulationName}
          labelAlignment="left"
        />
        {dateStartDateSelector}
        {dateEndDateSelector}
        {daysToRunDropDown}
        <AdvancedOptionsComponent
          title={translate("advancedOptionsButtonText")}
          advancedOptionsClick={this.props.advancedOptionsClick}
        />
      </Form>
    );
  }
}

export default PurchasedMedicaComponent;
