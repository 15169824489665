import React from "react";
import { Button } from "semantic-ui-react";
import "./buttonStyles.css";

const BackButton = (props) => {
  const translate = props.translate;
  return (
    <div className={props.showBackButton ? "noButtonArea" : "bottomArea"}>
      <Button className="goBackButton" onClick={() => props.history.goBack()}>
        {translate("Common:back")}
      </Button>
    </div>
  );
};

export default BackButton;
