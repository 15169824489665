import React from "react";
import { FlexibleWidthXYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries } from "react-vis";
import { abbreviateNumber, statusAlwaysPresent } from "./utilities";

const VisChart = (props) => {
  const { plot, translate, selectedTeams } = props;
  const { lineValues } = plot;
  let lineSeries = <LineSeries data={[{ x: 0, y: 0 }]} />;
  let maxYDomain = 1;
  let minYDomain = 0;

  const selectedTeamNames = selectedTeams.filter((team) => team.isChecked).map((x) => x.name);
  const setLinesToShow = (lineValues) => {
    if (statusAlwaysPresent(plot.name)) {
      lineValues[0].isChecked = true;
    } else {
      for (let i = 0; i < lineValues.length; i++) {
        const currentLine = lineValues[i];
        const teamName = currentLine.name;
        if (selectedTeamNames.includes(teamName)) {
          currentLine.isChecked = true;
        } else {
          currentLine.isChecked = false;
        }
      }
    }

    const filteredLines = lineValues.filter((line) => line.isChecked);
    return filteredLines;
  };

  const linesToShow = setLinesToShow(lineValues);

  if (linesToShow.length > 0) {
    lineSeries = linesToShow.map((line) => {
      const lineYvalues = line.historicDataSimplified.map((x) => {
        return x.y;
      });
      maxYDomain = Math.max(...lineYvalues, maxYDomain);
      minYDomain = Math.min(...lineYvalues, minYDomain);
      return (
        <LineSeries
          key={line.index}
          animation={false}
          data={line.historicDataSimplified}
          stroke={line.color}
          strokeWidth={1}
          opacity={0.6}
        />
      );
    });
  }

  return (
    <FlexibleWidthXYPlot yDomain={[minYDomain, maxYDomain]} height={220} margin={{ left: 50 }}>
      <HorizontalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
      <VerticalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
      <XAxis title={translate("days")} />
      <YAxis tickFormat={(v) => abbreviateNumber(v)} title={translate(plot.units)} />
      {lineSeries}
    </FlexibleWidthXYPlot>
  );
};

export default VisChart;
