import _ from "lodash";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Table } from "semantic-ui-react";

class RegisteredStudentsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column: null,
      registeredStudentList: props.simulationRegisteredStudents,
      direction: null,
    };
  }

  handleSort = (clickedColumn) => () => {
    const { column, registeredStudentList, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        registeredStudentList: _.sortBy(registeredStudentList, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      registeredStudentList: registeredStudentList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const translate = this.props.translate;
    const { column, direction, registeredStudentList } = this.state;

    const arrowsIcon = <FontAwesomeIcon className="tableIcon" icon={faSort} size="lg" color="#FFF" />;

    return (
      <div className="tableContainer" ref={(ref) => (this.topTable = ref)}>
        <p>{`${translate("registeredStudentsOf")}: ${registeredStudentList.length}`}</p>
        <Table unstackable sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === "teamName" ? direction : null} onClick={this.handleSort("teamName")}>
                <div className="headerCell">
                  {translate("Common:team").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "name" ? direction : null} onClick={this.handleSort("name")}>
                <div className="headerCell">
                  {translate("name").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === "email" ? direction : null} onClick={this.handleSort("email")}>
                <div className="headerCell">
                  {translate("email").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {registeredStudentList.map((student) => {
              return (
                <Table.Row key={student.email}>
                  <Table.Cell singleLine>{student.teamName}</Table.Cell>
                  <Table.Cell singleLine>{student.name}</Table.Cell>
                  <Table.Cell singleLine>{student.email}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default RegisteredStudentsComponent;
