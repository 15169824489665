import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import BackButton from "../helpers/BackButton";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MedicaBuyQuestionnaireComponent from "./MedicaBuyQuestionnaireComponent";
import { cacheMedicaQuestionnaireData, resetMedicaQuestionnaireStore } from "../../store/MedicaQuestionnaire/actions";
import "./medicaBuyStyles.css";
import payIcon from "../../assets/pay-icon.png";

class MedicaBuyQuestionnaireScreen extends Component {
  componentDidMount() {
    this.props.resetMedicaQuestionnaireStore();
  }

  render() {
    const translate = this.props.t;
    const { numberOfStudents, isInstitution, professorAdvanceSimulation } = this.props.medicaQuestionnaire;
    let medicaBuyQuestionnaireComponent = (
      <MedicaBuyQuestionnaireComponent
        translate={translate}
        history={this.props.history}
        numberOfStudents={numberOfStudents}
        professorAdvanceSimulation={professorAdvanceSimulation}
        isInstitution={isInstitution}
        cacheMedicaQuestionnaireData={this.props.cacheMedicaQuestionnaireData}
      />
    );
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent hasIcon={payIcon} />
        <div className="questionnaireForm">{medicaBuyQuestionnaireComponent}</div>
        <BackButton translate={translate} history={this.props.history} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  cacheMedicaQuestionnaireData: (data) => dispatch(cacheMedicaQuestionnaireData(data)),
  resetMedicaQuestionnaireStore: () => dispatch(resetMedicaQuestionnaireStore()),
});

const mapStateToProps = (state) => {
  return {
    medicaQuestionnaire: {
      numberOfStudents: state.medicaQuestionnaire.numberOfStudents,
      isInstitution: state.medicaQuestionnaire.isInstitution,
      professorAdvanceSimulation: state.medicaQuestionnaire.professorAdvanceSimulation,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("Purchased", "MedicaBuyQuestionnaire", "Common")(withRouter(MedicaBuyQuestionnaireScreen))
);
