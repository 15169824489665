import React, { Component } from "react";
import { Container, Grid, Image } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import HeaderComponent from "../header/HeaderComponent";
import DownloadLink from "./DownloadLinksComponent";
import { preventBack } from "../../services/NavigationService";
import medicaLogoTransparent from "../../assets/medica-logo-transparent.png";
import simunationLogo from "../../assets/simunation-logo.png";
import crisisLogo from "../../assets/crisis-logo.png";
import supplyChainLogo from "../../assets/supplychain-logo.png";
import * as CONSTANTS from "../../constants/UniversalConstants";
import "./SimulationCreatedStyles.css";
import { compose } from "redux";

const PRODUCT_APPS = {
  SIMUNATION: {
    name: CONSTANTS.SIMUNATION_NAME,
    logo: simunationLogo,
    logoClassName: "simunationDownloadAppLogo",
    links: {
      android: "https://play.google.com/store/apps/details?id=com.processimlabs.simunation",
      ios: "https://apps.apple.com/cr/app/simunation/id1419711371",
    },
  },
  MEDICA: {
    name: CONSTANTS.MEDICA_NAME,
    logo: medicaLogoTransparent,
    logoClassName: "medicaDownloadAppLogo",
    links: {
      android: "https://play.google.com/store/apps/details?id=com.redcouch.medicascientific",
      ios: "https://itunes.apple.com/us/app/medica-scientific/id1116555200",
      windows: "https://apps.microsoft.com/store/detail/9NGRXDSLJNS0",
    },
  },
  BINDZ: {
    name: CONSTANTS.CRISIS_NAME,
    logo: crisisLogo,
    logoClassName: "crisisDownloadAppLogo",
    links: {
      android: "https://play.google.com/store/apps/details?id=com.processimlabs.bindz",
      ios: "https://itunes.apple.com/us/app/bindz/id1549085511",
    },
  },
  SUPPLY_GRAIN: {
    name: CONSTANTS.SUPPLY_CHAIN_NAME,
    logo: supplyChainLogo,
    logoClassName: "supplyChainDownloadAppLogo",
    links: {
      android: "https://play.google.com/store/apps/details?id=com.processimlabs.supplygrain",
      ios: "https://apps.apple.com/cr/app/supply-grain/id1593468685",
    },
  },
};

class SimulationCreatedPage extends Component {
  state = {
    appToDownload: "",
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    for (let param of query.entries()) {
      if (param[0].toLowerCase() === "app") {
        const appToDownload = param[1];
        this.setState({ appToDownload });
      }
    }
    preventBack();
  }

  render() {
    const translate = this.props.t;
    let appToDownload = this.state.appToDownload;
    if (!Object.keys(PRODUCT_APPS).includes(appToDownload.toUpperCase().replace(" ", "_"))) {
      appToDownload = "Medica";
    }
    const productApp = PRODUCT_APPS[appToDownload.toUpperCase().replace(" ", "_")];
    let logoImageSrc = productApp.logo;
    let logo = <Image src={logoImageSrc} />;

    return (
      <Container>
        <HeaderComponent translate={translate} showLogoutButton={true} />
        <Grid centered verticalAlign="middle" className=" downloadAppGrid ">
          <Grid.Column mobile={16} computer={10} tablet={10}>
            <DownloadLink appInformation={productApp} translate={translate} />
          </Grid.Column>
          <Grid.Column only="tablet computer" width={3}>
            <div className={`downloadAppIconBackground ${productApp.logoClassName}`}>{logo}</div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const HOC = compose(withRouter, withTranslation("DownloadApp", "Common"));

export default HOC(SimulationCreatedPage);
