import React, { Component } from "react";
import moment from "moment";
import "./modals.css";
import { Button, Modal, Form } from "semantic-ui-react";
import CalendarComponent from "../helpers/CalendarComponent";
import * as CONSTANT from "../../constants/UniversalConstants";
import { sendPostRequest } from "../../services/FetchService";
import * as FETCHAPI from "../../constants/FetchApi";
import MessagesComponent from "../helpers/MessagesComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import "../authComponents/authComponents.css";
import { getUrlSearchParam } from "../../services/NavigationService";
import { switchTo12HourFormat, switchTo24HourFormat } from "../utilities/DateTimeUtilities";

class ModifySimulationModal extends Component {
  constructor(props) {
    super(props);

    const simulationDetails = props.simulationDetails;
    this.state = {
      simulationName: "",
      startDate: simulationDetails.startTime,
      endDate: simulationDetails.endDate,
      error: false,
      dateToModify: "",
      startHour: switchTo12HourFormat(Number(moment(simulationDetails.startTime).format("HH"))),
      startHourFormat: moment(simulationDetails.startTime).format("a"),
      endHour: switchTo12HourFormat(Number(moment(simulationDetails.endDate).format("HH"))),
      endHourFormat: moment(simulationDetails.endDate).format("a"),
      errorMessage: "",
      posting: false,
      isSuccessResponse: null,
      openCalendar: false,
    };
  }

  handleModifyModal = () => {
    const simulationDetails = this.props.simulationDetails;
    this.setState({
      simulationName: "",
      startDate: simulationDetails.startTime,
      endDate: simulationDetails.endDate,
      error: false,
      dateToModify: "",
      startHour: switchTo12HourFormat(Number(moment(simulationDetails.startTime).format("HH"))),
      startHourFormat: moment(simulationDetails.startTime).format("a"),
      endHour: switchTo12HourFormat(Number(moment(simulationDetails.endDate).format("HH"))),
      endHourFormat: moment(simulationDetails.endDate).format("a"),
      errorMessage: "",
      posting: false,
      isSuccessResponse: null,
      openCalendar: false,
    });
    this.props.handleModifyModal();
  };

  handleCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  onDateClick = (date, dateToModify) => {
    if (dateToModify === "startDate" && date >= moment(this.state.endDate).format("YYYY-MM-DD")) {
      this.setState({
        [dateToModify]: date,
        endDate: moment(date).add(1, "day"),
        errorMessage: "",
      });
    } else {
      this.setState({
        [dateToModify]: date,
        errorMessage: "",
        isSuccessResponse: false,
      });
    }
  };

  settingStartHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        startHourFormat: value,
        errorMessage: "",
      });
    } else {
      this.setState({
        startHour: value,
        errorMessage: "",
      });
    }
  };

  settingEndHour = (event, { value }) => {
    if (isNaN(value)) {
      this.setState({
        endHourFormat: value,
        errorMessage: "",
      });
    } else {
      this.setState({
        endHour: value,
        errorMessage: "",
      });
    }
  };

  endDateMustBeGreaterError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("ModifyModal:End Date must be after Start Date"),
    });
    return;
  };

  sixHoursError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("ModifyModal:Simulation must run for at least 6 hours"),
    });
    return;
  };

  sevenDaysError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("ModifyModal:Simulation can only run for 7 days or less"),
    });
    return;
  };

  simulationMustStartLeastOneHourInFutureError = () => {
    const translate = this.props.translate;
    this.setState({
      errorMessage: translate("ModifyModal:simulationMustStartLeastOneHourInFuture"),
    });
    return;
  };

  handleSubmit = () => {
    const timeFormat = "YYYY-MM-DD HH:mm:ss.SSS";
    const { startDate, endDate, startHour, endHour, startHourFormat, endHourFormat } = this.state;
    const formatedStartHour = switchTo24HourFormat(startHour, startHourFormat);
    const formatedEndHour = switchTo24HourFormat(endHour, endHourFormat);

    const todayString = `${moment().format("YYYY-MM-DD")} ${moment().add(1, "hour").format("HH")}:00:00.000`;
    const startDateString = `${moment(startDate).format("YYYY-MM-DD")} ${formatedStartHour}:00:00.000`;
    const endDateString = `${moment(endDate).format("YYYY-MM-DD")} ${formatedEndHour}:00:00.000`;

    const today = moment(todayString, timeFormat);
    const newDates = {
      startDate: moment(startDateString, timeFormat),
      endDate: moment(endDateString, timeFormat),
    };

    const differenceBetweenDates = newDates.endDate.diff(newDates.startDate);
    const validateStartDateRealTime = newDates.startDate.diff(today);

    if (differenceBetweenDates < 0) {
      this.endDateMustBeGreaterError();
    } else if (differenceBetweenDates >= 0 && differenceBetweenDates < CONSTANT.SIX_HOURS_MILLISECONDS) {
      this.sixHoursError();
    } else if (differenceBetweenDates > CONSTANT.SEVEN_DAYS_MILLISECONDS) {
      this.sevenDaysError();
    } else if (differenceBetweenDates >= CONSTANT.SIX_HOURS_MILLISECONDS) {
      if (validateStartDateRealTime >= 0) {
        this.postSimulationDates(newDates);
      } else {
        this.simulationMustStartLeastOneHourInFutureError();
      }
    }
  };

  postSimulationDates = (newDates) => {
    const simulationRequested = getUrlSearchParam();
    const translate = this.props.translate;
    const simulationDetails = this.props.simulationDetails;
    const actualDates = moment(simulationDetails.startTime) + moment(simulationDetails.endDate);
    const toChangeDates = moment(newDates.startDate) + moment(newDates.endDate);
    if (actualDates === toChangeDates) {
      this.setState({
        posting: false,
        isSuccessResponse: false,
        errorMessage: translate("ModifyModal:equalDatesSimulationError"),
      });
    } else {
      this.setState({ errorMessage: "", posting: true });
      let postData = {
        endDate: moment(newDates.endDate).toISOString(),
        startDate: moment(newDates.startDate).toISOString(),
        simulationName: simulationDetails.name,
      };
      const postNewDates = sendPostRequest(FETCHAPI.MODIFY_SIMULATION_DATES_MEDICA, postData);
      postNewDates.then((response) => {
        if (!response.data.outcome) {
          this.setState({
            posting: false,
            isSuccessResponse: false,
            errorMessage: translate(`ModifyModal:${response.data.message}`),
          });
        } else {
          this.setState({
            posting: false,
            isSuccessResponse: true,
            errorMessage: translate("ModifyModal:modifyDatesSuccess"),
          });
          this.handleModifyModal();
          this.props.getSimulationDetails(simulationRequested);
        }
      });
    }
  };

  render() {
    const { language, translate } = this.props;
    let calendarComponent = null;
    let loader = null;
    let messagesComponent = null;
    let selectedDate = null;

    if (this.state.dateToModify === "startDate") {
      selectedDate = this.state.startDate;
    } else {
      selectedDate = this.state.endDate;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <CalendarComponent
          translate={translate}
          handleCalendar={this.handleCalendar}
          dateToModify={this.state.dateToModify}
          onDateClick={this.onDateClick}
          selectedDate={selectedDate}
          language={this.props.language}
        />
      );
    }
    if (this.state.errorMessage !== "") {
      messagesComponent = (
        <div className="modifyModalError">
          <MessagesComponent
            messageType={this.state.isSuccessResponse}
            message={translate(`ModifyModal:${this.state.errorMessage}`)}
          />
        </div>
      );
    }
    if (this.state.posting) {
      loader = <div className="loader" />;
    }
    return (
      <div>
        <Modal size="tiny" open={this.props.openModifyModal} onClose={this.handleModifyModal}>
          <Modal.Header>{translate("ModifyModal:modify")}</Modal.Header>
          <Modal.Content>
            {calendarComponent}
            <Modal.Description>
              <Form>
                <DateTimeSelector
                  label={translate("ModifyModal:startDate")}
                  onFocus={() => this.setState({ dateToModify: "startDate" })}
                  error={this.state.error}
                  handleCalendar={this.handleCalendar}
                  dateToSet={CONSTANT.START_DATE}
                  settingHour={this.settingStartHour}
                  date={this.state.startDate}
                  hour={this.state.startHour}
                  hourFormat={this.state.startHourFormat}
                  language={language}
                  labelAlignment="left"
                />
                <DateTimeSelector
                  label={translate("ModifyModal:endDate")}
                  onFocus={() => this.setState({ dateToModify: "endDate" })}
                  error={this.state.error}
                  handleCalendar={this.handleCalendar}
                  dateToSet={CONSTANT.END_DATE}
                  settingHour={this.settingEndHour}
                  date={this.state.endDate}
                  hour={this.state.endHour}
                  hourFormat={this.state.endHourFormat}
                  language={language}
                  labelAlignment="left"
                />
              </Form>
            </Modal.Description>
            {messagesComponent}
            {loader}
          </Modal.Content>
          <Modal.Actions>
            <Button content={translate("ModifyModal:accept")} onClick={this.handleSubmit} />
            <Button content={translate("ModifyModal:cancel")} onClick={this.handleModifyModal} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ModifySimulationModal;
