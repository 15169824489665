import React, { Component } from "react";
import { connect } from "react-redux";
import Payment from "../payment";
import * as ROUTES from "../../constants/Routes";
import * as API_PATHS from "../../constants/FetchApi.js";
import {
  checkStorageSimunationInformation,
  simunationResetDiscountCode,
  updateTransactionSimunationData,
  validateSimunationDiscountCode,
} from "../../store/SimunationQuestionnaire/actions";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import { getStorage, setStorage } from "../../services/StorageService";

class PaymentSimunation extends Component {
  resetDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const simunationQuestionnaire = getStorage(LOCAL_STORAGE.simunationQuestionnaire);
    simunationQuestionnaire.discountedPrice = Number(process.env.REACT_APP_STRIPE_PRICE);
    delete simunationQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.simunationQuestionnaire, simunationQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const successRoute = ROUTES.PURCHASED_SIMUNATION;
    const productPrice = process.env.REACT_APP_SIMUNATION_PRICE;
    const paymentIntentAPI = API_PATHS.SIMUNATION_GET_PAYMENT_INTENT;
    const {
      discountCodeStatus,
      user,
      questionnaire,
      checkStorageInformation,
      updateTransactionData,
      validateDiscountCode,
    } = this.props;

    return (
      <Payment
        simulatorType={"Simunation"}
        discountCodeStatus={discountCodeStatus}
        user={user}
        questionnaire={questionnaire}
        checkStorageInformation={checkStorageInformation}
        updateTransactionData={updateTransactionData}
        validateDiscountCode={validateDiscountCode}
        resetDiscountCode={this.resetDiscountCode}
        productPrice={productPrice}
        paymentIntentAPI={paymentIntentAPI}
        successRoute={successRoute}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkStorageInformation: () => dispatch(checkStorageSimunationInformation()),
  updateTransactionData: (data) => dispatch(updateTransactionSimunationData(data)),
  validateDiscountCode: (discountCode) => dispatch(validateSimunationDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(simunationResetDiscountCode()),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      language: state.user.userProfile.language,
    },
    discountCodeStatus: state.simunationQuestionnaire.discountCode,
    questionnaire: {
      numberOfStudents: state.simunationQuestionnaire.numberOfStudents,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSimunation);
