import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as SUPPLY_CHAIN_CONSTANTS from "../../constants/SupplyChainConstants";
import CalendarComponent from "../helpers/CalendarComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import * as CONSTANT from "../../constants/UniversalConstants";
import InputComponent from "../helpers/InputComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import { Checkbox } from "semantic-ui-react";

import "./supplyChainRunAgain.css";

class NextRunFormComponent extends Component {
  state = {
    dateToModify: "",
    openCalendar: false,
  };

  handleFormChange = (id, data) => {
    this.props.formChanged(id, data);
  };

  onChangeTimeBetweenAdvances = (id, pace) => {
    this.handleFormChange(id, pace);
    const { startTime, endTime } = this.props.formData;

    this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, pace);
  };

  onChangeStartTime = (value) => {
    const { professorAdvance, timeBetweenAdvances, startTime, endTime } = this.props.formData;
    this.props.setHour("startTime", value);

    if (professorAdvance.value === false) {
      this.matchEndAndStartTimeHourDivisibility(startTime.value.hour, endTime.value.hour, timeBetweenAdvances.value);
    }
  };

  matchEndAndStartTimeHourDivisibility = (startHour, endHour, dividerInt) => {
    if (dividerInt === 1) return;

    const startTimeDivisibility = startHour % dividerInt;
    const endTimeDivisibility = endHour % dividerInt;

    if (startTimeDivisibility !== endTimeDivisibility) {
      const newEndHour = CONSTANT.HOUR_NUMBERS.find((x) => x.value % dividerInt === startTimeDivisibility).value;
      this.props.setHour("endTime", newEndHour);
    }
  };

  gameModeOptions = [
    {
      key: 1,
      text: this.props.t(`SupplyChainCommon:${SUPPLY_CHAIN_CONSTANTS.OneStationUnknownPeers}`),
      value: SUPPLY_CHAIN_CONSTANTS.OneStationUnknownPeers,
    },
    {
      key: 2,
      text: this.props.t(`SupplyChainCommon:${SUPPLY_CHAIN_CONSTANTS.OneStationKnownPeers}`),
      value: SUPPLY_CHAIN_CONSTANTS.OneStationKnownPeers,
    },
    {
      key: 3,
      text: this.props.t(`SupplyChainCommon:${SUPPLY_CHAIN_CONSTANTS.AllStationsKnownPeers}`),
      value: SUPPLY_CHAIN_CONSTANTS.AllStationsKnownPeers,
    },
    {
      key: 4,
      text: this.props.t(`SupplyChainCommon:${SUPPLY_CHAIN_CONSTANTS.LastMile}`),
      value: SUPPLY_CHAIN_CONSTANTS.LastMile,
    },
  ];

  advanceSimulationOptions = [
    {
      key: 1,
      text: this.props.t("Common:yes"),
      value: true,
    },
    {
      key: 2,
      text: this.props.t("Common:no"),
      value: false,
    },
  ];

  timeBetweenAdvancesOptions = [
    {
      key: "time1",
      text: this.props.t("SupplyChainPurchased:TimeBetweenAdvancesOneHour"),
      value: 1,
    },
    {
      key: "time2",
      text: this.props.t("SupplyChainPurchased:TimeBetweenAdvancesTwoHours"),
      value: 2,
    },
  ];

  handleCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    const formValues = this.checkWithDatesSelected();
    this.props.handleContinue(formValues);
  }

  render() {
    const translate = this.props.t;
    let calendarComponent = null;
    const { formData } = this.props;
    let selectedDate = null;

    if (this.state.dateToModify === "startDate") {
      selectedDate = formData.startTime.value.date;
    } else {
      selectedDate = formData.endTime.value.date;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <div className="calendarPosition">
          <CalendarComponent
            translate={translate}
            handleCalendar={this.handleCalendar}
            dateToModify={this.state.dateToModify}
            onDateClick={(value) => this.props.setDate(value, this.state.dateToModify)}
            selectedDate={selectedDate}
            language={this.props.language}
          />
        </div>
      );
    }

    let endTimeOptions = null;
    if (this.props.formData.professorAdvance.value === false && this.props.formData.timeBetweenAdvances.value > 1) {
      const startTimeDivisibility = formData.startTime.value.hour % this.props.formData.timeBetweenAdvances.value;
      endTimeOptions = CONSTANT.HOUR_NUMBERS.filter(
        (x) => x.value % this.props.formData.timeBetweenAdvances.value === startTimeDivisibility
      ).map((hour) => ({
        key: hour.key,
        text: hour.value,
        value: hour.value,
      }));
    }

    const automaticSimulationEntries = formData.professorAdvance.value ? null : (
      <>
        <DropDownComponent
          id="advancePeriod"
          label={translate("SupplyChainPurchased:advancePeriod")}
          options={this.timeBetweenAdvancesOptions}
          onSelection={this.onChangeTimeBetweenAdvances.bind(this, "timeBetweenAdvances")}
          value={formData.timeBetweenAdvances.value}
          labelAlignment="left"
        />
        <DateTimeSelector
          label={translate("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar.bind(this)}
          onFocus={() => this.setState({ dateToModify: "startDate" })}
          dateToSet={CONSTANT.START_DATE}
          settingHour={(event, value) => this.onChangeStartTime(value.value)}
          date={formData.startTime.value.date}
          hour={formData.startTime.value.hour}
          hourFormat={formData.startTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={translate("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar.bind(this)}
          onFocus={() => this.setState({ dateToModify: "endDate" })}
          dateToSet={CONSTANT.END_DATE}
          settingHour={(event, value) => this.props.setHour("endTime", value.value)}
          date={formData.endTime.value.date}
          hour={formData.endTime.value.hour}
          hourFormat={formData.endTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
          timeOptions={endTimeOptions}
        />
      </>
    );

    return (
      <>
        {calendarComponent}
        <InputComponent
          label={translate("Purchased:simulationName")}
          id="simulationName"
          specialLabel={true}
          disabled
          type="text"
          value={this.props.simulationName}
          labelAlignment="left"
        />

        <DropDownComponent
          id="professorAdvance"
          label={translate("Purchased:professorAdvanceSimulation")}
          helpMessage={translate("Purchased:medicaProfessorAdvanceSimulationHelp")}
          options={this.advanceSimulationOptions}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.handleFormChange.bind(this, "professorAdvance")}
          value={formData.professorAdvance.value}
          labelAlignment="left"
        />
        {automaticSimulationEntries}
        <DropDownComponent
          id="gameMode"
          label={translate("SupplyChainPurchased:GameModeTitle")}
          options={this.gameModeOptions}
          onSelection={this.handleFormChange.bind(this, "gameMode")}
          value={formData.gameMode.value}
          labelAlignment="left"
        />
        <div className="create-next-supply-chain-simulation__game-mode--help">
          <p>{translate("SupplyChainCommon:GameModeHelp")}</p>
          <ul className="create-supply-chain-simulation__game-mode-help-list">
            <li>{translate("SupplyChainCommon:OneStationUnknownPeersHelp")}</li>
            <li>{translate("SupplyChainCommon:OneStationKnownPeersHelp")}</li>
            <li>{translate("SupplyChainCommon:AllStationsKnownPeersHelp")}</li>
            <li>{translate("SupplyChainCommon:LastMileHelp")}</li>
          </ul>
        </div>
        <div className="supply-chain-_teams-stay-the-same__toggle">
          <label className="specialLabel">{translate("scrambleTeamMembers")}</label>
          <Checkbox
            toggle
            checked={!formData.teamsStayTheSame.value}
            onChange={(e, data) => this.handleFormChange("teamsStayTheSame", !data.checked)}
          />
        </div>
      </>
    );
  }
}

export default withTranslation(["SupplyChainRunAgain", "Purchased", "Common", "SupplyChainCommon"])(
  NextRunFormComponent
);
