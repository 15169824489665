import React from "react";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import FAQContent from "../helpers/FAQContent";

const StudentsLoginComponent = (props) => {
  const translate = props.translate;
  const user = props.user;
  let userRole = "";
  let userFullName = "";
  if (user.role) {
    userRole = translate(`Common:${user.role}`);
  }
  if (user.fullName) {
    userFullName = user.fullName;
  }

  return (
    <div>
      <MainTitleComponent title={`${translate("Common:welcome")} ${userRole}`} subtitle={userFullName} />
      <div className="messageComponentContainer">
        <MessagesComponent message={translate("itSeemsYouAreNotInTheRightPlace")} info={true} />
      </div>
      <FAQContent translate={translate} />
    </div>
  );
};

export default StudentsLoginComponent;
