import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import StandingsComponent from "./StandingsComponent";
import { getStandings } from "../../store/Simunation/actions";
import { getUrlSearchParam } from "../../services/NavigationService";

class SimunationStandingsScreen extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  componentDidMount() {
    this.getStandings();
  }

  getStandings = () => {
    this.props.getStandings(this.state.requestedSimulationName);
  };

  render() {
    const translate = this.props.t;
    const { standings, error, loading } = this.props.simunation;
    let loader = null,
      messageComponent = null,
      standingsComponent = null;

    if (loading) {
      loader = <LoadingComponent message={translate("loadingStandings")} />;
    } else {
      if (error !== "") {
        messageComponent = <MessagesComponent message={translate(error)} />;
      } else {
        if (standings.outcome) {
          standingsComponent = <StandingsComponent translate={translate} simulationStandings={standings.payload} />;
        }
      }
    }
    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent
          title={translate("standings")}
          subtitle={this.state.requestedSimulationName}
          helpMessage={translate("standingsSimunationHelpMessage")}
        />
        {loader}
        {messageComponent}
        {standingsComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getStandings: (simulationName) => dispatch(getStandings(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    simunation: {
      standings: state.simunation.simulationStandings,
      loading: state.simunation.loadingStandings,
      error: state.simunation.getStandingsError,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("StandingsScreen", "Common")(withRouter(SimunationStandingsScreen))
);
