import React from "react";
import DefaultButton from "../helpers/DefaultButton";

const CurrentDayRefreshSection = (props) => {
  const { translate, currentSimulationDay, getPerformanceData } = props;

  return (
    <div className="simulationRefresh">
      <p>{`${translate("Common:currentDay")}: ${currentSimulationDay}`}</p>
      <DefaultButton title={translate("refresh")} action={getPerformanceData} />
    </div>
  );
};

export default CurrentDayRefreshSection;
