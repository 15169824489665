import { sendGetRequest, sendPostRequest } from "../services/FetchService";
import * as FETCHAPI from "../constants/FetchApi";
import moment from "moment";
import { getLanguagePrefix } from "../components/utilities/LanguageUtilities";

export const getMedicaDetail = (simulationId) => {
  return sendGetRequest(FETCHAPI.GET_SIMULATION_DETAIL_MEDICA, simulationId);
};

export const getMedicaLink = (simulationId) => {
  return sendGetRequest(FETCHAPI.GET_SIMULATION_LINK, simulationId);
};

export const configureMedicaDetails = (medicaDetailResponse, medicaLinkResponse, language, translate) => {
  let response = {
    outcome: null,
    simulationId: null,
    data: null,
    medicaLink: null,
    message: "",
  };
  if (medicaDetailResponse.data.outcome && medicaLinkResponse.data.outcome) {
    response.outcome = true;
    response.simulationId = medicaDetailResponse.data.payload.id;
    response.data = medicaDetailsFormatter(medicaDetailResponse.data.payload, language, translate);
    response.medicaLink = medicaLinkResponse.data.payload.activationLink;
  } else {
    response.outcome = false;
    response.message = medicaDetailResponse.data.message;
  }
  return response;
};

const medicaDetailsFormatter = (details, language, translate) => {
  const languagePrefix = getLanguagePrefix(language);
  const calculateSimulationHours = moment(details.endDate).diff(moment(details.startTime));
  details.virtualDaysPerHour = details.studentInteractionPeriod / Math.round(calculateSimulationHours / 1000 / 60 / 60);
  details.startTimeFormatted = moment(details.startTime)
    .locale(languagePrefix)
    .format(`MMMM D, YYYY [${translate("at")}] hh:mm A`);
  details.endDateFormatted = moment(details.endDate)
    .locale(languagePrefix)
    .format(`MMMM D, YYYY [${translate("at")}] hh:mm A`);

  return details;
};

export const getStandings = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_STANDINGS_MEDICA, simulationName);
};

export const getRegisteredStudents = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_REGISTERED_STUDENTS_MEDICA, simulationName);
};

export const getTeams = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_TEAMS_MEDICA, simulationName);
};

export const createTeam = (team) => {
  return sendPostRequest(`${FETCHAPI.CREATE_TEAM}`, team);
};

export const joinTeam = (team) => {
  return sendPostRequest(FETCHAPI.JOIN_TEAM, team);
};

export const advanceSimulation = (simulationExecution) => {
  return sendPostRequest(FETCHAPI.ADVANCE_SIMULATION_MEDICA, simulationExecution);
};

export const getProfessorScenarios = () => {
  return sendGetRequest(`${FETCHAPI.GET_PROFESSOR_SCENARIOS}`, "");
};

export const prepareCustomResponse = (response, action, emptyError) => {
  let customResponse = null;
  if (response.data.outcome && response.data.payload.length === 0) {
    const data = {
      message: emptyError,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }

  if (response.data.outcome && response.data.payload.length > 0) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: response.data,
    };
  }

  if (!response.data.outcome) {
    const data = {
      message: response.data.message,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }

  return customResponse;
};

export const prepareAdvanceResponse = (response, action) => {
  let customResponse = null;

  if (response.data.outcome) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: response.data,
    };
  } else {
    const data = {
      message: response.data.message,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }

  return customResponse;
};

export const prepareJoinTeamResponse = (response, action) => {
  let customResponse = null;

  if (response.data.outcome) {
    customResponse = {
      type: `${action}_SUCCESSFUL`,
      payload: response.data.payload,
    };
  } else {
    const data = {
      message: response.data.message,
    };
    customResponse = {
      type: `${action}_FAILED`,
      payload: data,
    };
  }

  return customResponse;
};

export const checkForPreviousPayment = (simulationId) => {
  return sendGetRequest(FETCHAPI.GET_STUDENT_PREVIOUS_PAYMENT, simulationId);
};

export const sendPushNotification = (pushData) => {
  return sendPostRequest(FETCHAPI.SEND_PUSH_NOTIFICATION_MEDICA, pushData);
};

export const getDocuments = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_MEDICA_DOCUMENTS, simulationName);
};

export const getPerformanceData = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_MEDICA_PERFORMANCE_DATA, simulationName);
};

export const runAgain = (simulation) => {
  return sendPostRequest(FETCHAPI.MEDICA_RUN_AGAIN_SIMULATION, simulation);
};

export const getDecisionsData = (simulationName) => {
  return sendGetRequest(FETCHAPI.GET_MEDICA_DECISIONS_DATA, simulationName);
};
