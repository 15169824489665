import React from "react";
import { Button, Modal } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";

const SuccessModal = (props) => {
  const translate = props.translate;

  const handleSuccessModal = () => {
    props.handleSuccessModal();
  };

  return (
    <div>
      <Modal size="tiny" open={props.openSuccessModal} onClose={() => handleSuccessModal()}>
        <Modal.Header>{translate("Common:success")}</Modal.Header>
        <Modal.Content>
          <MessagesComponent messageType={true} message={props.message} />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Ok" onClick={() => handleSuccessModal()} />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default SuccessModal;
