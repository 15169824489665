import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import StandingsComponent from "./StandingsComponent";
import { getStandings } from "../../store/PublicPages/actions";
import { getUrlSearchParam } from "../../services/NavigationService";
class MedicaStandingsPublic extends Component {
  constructor(props) {
    super(props);

    const simulationNameBase64 = getUrlSearchParam();
    const simulationName = Buffer.from(simulationNameBase64, "base64").toString("ascii");

    this.state = {
      requestedSimulationName: simulationName,
    };
  }

  componentDidMount() {
    this.props.getStandings(this.state.requestedSimulationName);
  }

  render() {
    const translate = this.props.t;
    const { outcome, standings, error, loading } = this.props.standingsStore;
    let loader = null,
      messageComponent = null,
      standingsComponent = null;

    if (loading) {
      loader = <LoadingComponent message={translate("loadingStandings")} />;
    } else {
      if (error !== "") {
        messageComponent = <MessagesComponent message={translate(error)} />;
      } else {
        if (outcome) {
          standingsComponent = <StandingsComponent translate={translate} simulationStandings={standings} />;
        }
      }
    }
    return (
      <Container>
        <HeaderComponent translate={translate} />
        <MainTitleComponent
          title={translate("standings")}
          subtitle={this.state.requestedSimulationName}
          helpMessage={translate("standingsHelpMessage")}
        />
        {loader}
        {messageComponent}
        {standingsComponent}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getStandings: (simulationName) => dispatch(getStandings(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    standingsStore: {
      outcome: state.publicPages.medicaPublicStandings.outcome,
      standings: state.publicPages.medicaPublicStandings.standingsTable,
      loading: state.publicPages.medicaPublicStandings.loading,
      error: state.publicPages.medicaPublicStandings.errorMessage,
    },
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("StandingsScreen", "Common"),
  withRouter
)(MedicaStandingsPublic);
