export const faq = {
  Medica: {
    image: "/assets/medica-logo-transparent.png",
    avatarBackgroundColor: "#FFFFFF",
    faq: [
      {
        section: "Create Simulation",
        question: {
          en: "Where can I choose the scenario I want my students to play?",
          es: "¿Dónde puedo elegir el escenario que quiero que jueguen mis alumnos?",
        },
        answer: {
          en: 'When setting up your simulation, in the same screen were you give it a name, click where it says "Advanced Options" and scroll down a bit. You should be able to see all of the scenarios available to you.',
          es: 'Al configurar su simulación, en la misma pantalla le dio un nombre, haga clic donde dice "Opciones avanzadas" y desplácese hacia abajo un poco. Debería poder ver todos los escenarios disponibles para usted.',
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong dates for my simulation.",
          es: "Elegí las fechas incorrectas para mi simulación.",
        },
        answer: {
          en: 'You can change the selected dates from the Simulation Details view of that simulation by clicking "Modify" in the right hand side menu. You are only able to do so if the current simulation start time is at least one hour away.',
          es: 'Puede cambiar las fechas seleccionadas de la vista Detalles de simulación de esa simulación haciendo clic en "Modificar" en el menú del lado derecho. Solo puede hacerlo si la hora de inicio de simulación actual está al menos a una hora de distancia.',
        },
      },
      {
        section: "Create Simulation",
        question: { en: "Can I delete a team or simulation?", es: "¿Puedo eliminar un equipo o simulación?" },
        answer: {
          en: "Contact us at support@processimlabs.com with the name of the team or simulation you want deleted and we will be happy to help you with that.",
          es: "Contáctenos en support@processimlabs.com con el nombre del equipo o la simulación que desea eliminar y estaremos encantados de ayudarlo con eso.",
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong scenario for my simulation.",
          es: "Elegí el escenario incorrecto para mi simulación.",
        },
        answer: {
          en: "Unfortunately you/we cannot change the scenario of a simulation. You create a new simulation with the correct scenario and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el escenario de una simulación. Usted crea una nueva simulación con el escenario correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: { en: "Can I change the name of a simulation?", es: "¿Puedo cambiar el nombre de una simulación?" },
        answer: {
          en: "Unfortunately you/we cannot change the name of a simulation. You create a new simulation with the correct name and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el nombre de una simulación. Usted crea una nueva simulación con el nombre correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong game mode (Manual vs Automatic).",
          es: "Elegí el modo de juego incorrecto (manual vs automático).",
        },
        answer: {
          en: "Unfortunately you/we cannot change the game mode of a simulation. You create a new simulation with the correct game mode and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el modo de juego de una simulación. Creas una nueva simulación con el modo de juego correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I want to modify the scenario I chose last time.",
          es: "Quiero modificar el escenario que elegí la última vez.",
        },
        answer: {
          en: 'When setting up your simulation, in the same screen were you give it a name, click where it says "Advanced Options" and scroll down a bit.  From there, you can enable the "Add variation" feature that slightly adds small variations to your chosen scenario in case you want the scenario to be slightly but not significantly different from the last time you used it.',
          es: 'Al configurar su simulación, en la misma pantalla le dio un nombre, haga clic donde dice "Opciones avanzadas" y desplácese hacia abajo un poco. A partir de ahí, puede habilitar la función "Agregar variación" que agrega pequeñas variaciones al escenario elegido en caso de que desee que el escenario sea ligeramente pero no significativamente diferente de la última vez que lo usó.',
        },
      },
      {
        section: "Simulation Details",
        question: {
          en: "Can I use the same documents from the last time I played?",
          es: "¿Puedo usar los mismos documentos de la última vez que jugué?",
        },
        answer: {
          en: "Our plaform automatically generates the documentation for each simulation depending on the scenario that you selected. We recommend to always download the documentation created for your specific simulation. ",
          es: "Nuestro PlaForm genera automáticamente la documentación para cada simulación dependiendo del escenario que seleccionó. Recomendamos siempre descargar la documentación creada para su simulación específica.",
        },
      },
      {
        section: "Simulation Details",
        question: {
          en: "How do I set up the second run for my simulation?",
          es: "¿Cómo configuré la segunda ejecución para mi simulación?",
        },
        answer: {
          en: 'Go to the Simulation Details view of the first simulation run and click where it says "Run Again". Remember that you will only be able to configure the second run after the first one has started. It is important that all students are registered to the first run before you set up the second run as our platform will copy all registered students from the first run into the second one.',
          es: 'Vaya a la vista Detalles de simulación de la primera ejecución de simulación y haga clic donde dice "Ejecutar nuevamente". Recuerde que solo podrá configurar la segunda ejecución después de que haya comenzado la primera. Es importante que todos los estudiantes estén registrados en la primera ejecución antes de configurar la segunda ejecución, ya que nuestra plataforma copiará a todos los estudiantes registrados desde la primera ejecución con la segunda.',
        },
      },
      {
        section: "Simulation Details",
        question: {
          en: "A student didn't join the first run of the simulation in time, can still I have them play the second run? ",
          es: "Un estudiante no se unió a la primera ejecución de la simulación a tiempo, ¿puede someterme a que jueguen la segunda carrera?",
        },
        answer: {
          en: 'Yes you can. You will need to first set up the second simulation run. Go to the Simulation Details view of the second simulation run. If you want the student to create a new team, share the link found by clicking "Share Simulation". If you want the student to join an existing team, go to "Standings", share the link that you\'ll find next to the team you want them to join.',
          es: 'Sí tu puedes. Primero deberá configurar la segunda ejecución de simulación. Vaya a la vista Detalles de simulación de la segunda ejecución de simulación. Si desea que el estudiante cree un nuevo equipo, comparta el enlace que se encuentra haciendo clic en "Simulación de compartir". Si desea que el estudiante se una a un equipo existente, vaya a "clasificación", comparta el enlace que encontrará junto al equipo al que desea que se unan.',
        },
      },
      {
        section: "Student FAQ",
        question: {
          en: "Please direct your students to our student FAQ.",
          es: "Dirija a sus alumnos a nuestras preguntas frecuentes para estudiantes.",
        },
        answer: {
          en: "Link to Student FAQ: {studentFaq}",
          es: "Enlace a las preguntas frecuentes del estudiante: {studentFaq}",
        },
      },
    ],
  },
  Simunation: {
    image: "/assets/simunation-logo.png",
    avatarBackgroundColor: "#7E217E",
    faq: [
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong number of students when creating the simulation.",
          es: "Elegí el número incorrecto de estudiantes al crear la simulación.",
        },
        answer: { en: "Contact us at support@processimlabs.com", es: "Contáctenos en support@processimlabs.com" },
      },
      {
        section: "Advance Simulation",
        question: {
          en: "I am getting an error when I try to advance the simulation.",
          es: "Recibo un error cuando intento avanzar en la simulación.",
        },
        answer: {
          en: "Ask students not to make any changes in the simulation for a moment as you try again.",
          es: "Pídale a los estudiantes que no realicen cambios en la simulación por un momento mientras lo intenta nuevamente.",
        },
      },
      {
        section: "Advance Simulation",
        question: { en: "I can't advance the simulation.", es: "No puedo avanzar en la simulación." },
        answer: {
          en: 'You may have chosen a different game mode. If you can\'t see the option "Advance Simulation" on the right hand side menu in the "Simulation Details" view you likely selected the game mode in which each one of the teams in your simulation can each control the progress of their simulation. ',
          es: 'Es posible que hayas elegido un modo de juego diferente. Si no puede ver la opción "Simulación avanzada" en el menú del lado de la mano derecha en la vista "Detalles de simulación" que probablemente seleccionó el modo de juego en el que cada uno de los equipos en su simulación puede controlar el progreso de su simulación.',
        },
      },
      {
        section: "Student FAQ",
        question: {
          en: "Please direct your students to our student FAQ.",
          es: "Dirija a sus alumnos a nuestras preguntas frecuentes para estudiantes.",
        },
        answer: {
          en: "Link to Student FAQ: {studentFaq}",
          es: "Enlace a las preguntas frecuentes del estudiante: {studentFaq}",
        },
      },
    ],
  },
  Bindz: {
    image: "/assets/crisis-logo.png",
    avatarBackgroundColor: "#18B1B9",
    faq: [
      {
        section: "Create Simulation",
        question: {
          en: "Can I change the selected dates or times for my simulation?",
          es: "¿Puedo cambiar las fechas o tiempos seleccionados para mi simulación?",
        },
        answer: {
          en: 'You can change the selected dates/times from the Simulation Details view of that simulation by clicking "Modify" in the right hand side menu. You are only able to do so if the current simulation start time is at least one hour away.',
          es: 'Puede cambiar las fechas/tiempos seleccionadas desde la vista Detalles de simulación de esa simulación haciendo clic en "Modificar" en el menú del lado derecho. Solo puede hacerlo si la hora de inicio de simulación actual está al menos a una hora de distancia.',
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong scenario for my simulation.",
          es: "Elegí el escenario incorrecto para mi simulación.",
        },
        answer: {
          en: "Unfortunately you/we cannot change the scenario of a simulation. You create a new simulation with the correct scenario and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el escenario de una simulación. Usted crea una nueva simulación con el escenario correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: { en: "Can I change the name of a simulation?", es: "¿Puedo cambiar el nombre de una simulación?" },
        answer: {
          en: "Unfortunately you/we cannot change the name of a simulation. You can create a new simulation with the correct name and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, no es posible cambiar el nombre de una simulación. Usted puede crear una nueva simulación con el nombre correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong game mode (Manual vs Automatic).",
          es: "Elegí el modo de juego incorrecto (manual vs automático).",
        },
        answer: {
          en: "Unfortunately you/we cannot change the game mode of a simulation. You create a new simulation with the correct game mode and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el modo de juego de una simulación. Creas una nueva simulación con el modo de juego correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Student FAQ",
        question: {
          en: "Please direct your students to our student FAQ.",
          es: "Dirija a sus alumnos a nuestras preguntas frecuentes para estudiantes.",
        },
        answer: {
          en: "Link to Student FAQ: {studentFaq}",
          es: "Enlace a las preguntas frecuentes del estudiante: {studentFaq}",
        },
      },
    ],
  },
  SupplyGrain: {
    image: "/assets/supplychain-logo.png",
    avatarBackgroundColor: "#006F5D",
    faq: [
      {
        section: "Create Simulation",
        question: {
          en: "Can I change the selected dates or times for my simulation?",
          es: "¿Puedo cambiar las fechas o tiempos seleccionados para mi simulación?",
        },
        answer: {
          en: 'You can change the selected dates/times from the Simulation Details view of that simulation by clicking "Modify" in the right hand side menu. You are only able to do so if the current simulation start time is at least one hour away.',
          es: 'Puede cambiar las fechas/tiempos seleccionadas desde la vista Detalles de simulación de esa simulación haciendo clic en "Modificar" en el menú del lado derecho. Solo puede hacerlo si la hora de inicio de simulación actual está al menos a una hora de distancia.',
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong scenario for my simulation.",
          es: "Elegí el escenario incorrecto para mi simulación.",
        },
        answer: {
          en: "Unfortunately you/we cannot change the scenario of a simulation. You create a new simulation with the correct scenario and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el escenario de una simulación. Usted crea una nueva simulación con el escenario correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: { en: "Can I change the name of a simulation?", es: "¿Puedo cambiar el nombre de una simulación?" },
        answer: {
          en: "Unfortunately you/we cannot change the name of a simulation. You create a new simulation with the correct name and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el nombre de una simulación. Usted crea una nueva simulación con el nombre correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Create Simulation",
        question: {
          en: "I chose the wrong game mode (Manual vs Automatic).",
          es: "Elegí el modo de juego incorrecto (manual vs automático).",
        },
        answer: {
          en: "Unfortunately you/we cannot change the game mode of a simulation. You create a new simulation with the correct game mode and contact us at support@processimlabs.com with the name of the other simulation for us to delete it.",
          es: "Desafortunadamente, usted/no podemos cambiar el modo de juego de una simulación. Creas una nueva simulación con el modo de juego correcto y contáctenos a support@processimlabs.com con el nombre de la otra simulación para que lo eliminemos.",
        },
      },
      {
        section: "Simulation Details",
        question: {
          en: "How do I set up the second run for my simulation?",
          es: "¿Cómo configuré la segunda ejecución para mi simulación?",
        },
        answer: {
          en: 'Go to the Simulation Details view of the first simulation run and click where it says "Run Again". Remember that you will only be able to configure the second run after the first one has started. It is important that all students are registered to the first run before you set up the second run as our platform will copy all registered students from the first run into the second one.',
          es: 'Vaya a la vista Detalles de simulación de la primera ejecución de simulación y haga clic donde dice "Ejecutar nuevamente". Recuerde que solo podrá configurar la segunda ejecución después de que haya comenzado la primera. Es importante que todos los estudiantes estén registrados en la primera ejecución antes de configurar la segunda ejecución, ya que nuestra plataforma copiará a todos los estudiantes registrados desde la primera ejecución con la segunda.',
        },
      },
      {
        section: "Student FAQ",
        question: {
          en: "Please direct your students to our student FAQ.",
          es: "Dirija a sus alumnos a nuestras preguntas frecuentes para estudiantes.",
        },
        answer: {
          en: "Link to Student FAQ: {studentFaq}",
          es: "Enlace a las preguntas frecuentes del estudiante: {studentFaq}",
        },
      },
    ],
  },
};

export const studentFaq = {
  Medica: {
    image: "/assets/medica-logo-transparent.png",
    avatarBackgroundColor: "#FFFFFF",
    faq: [
      {
        section: "Login",
        question: { en: "I can't log in.", es: "No puedo iniciar sesión." },
        answer: {
          en: "Check if you typing your email and password correctly. You can also reset your password or contact us at support@processimlabs.com if none of this works.",
          es: "Verifique haber escrito su correo electrónico y contraseña correctamente. También puede restablecer su contraseña o contactarnos en support@processimlabs.com si ninguna de las dos opciones funciona.",
        },
      },
      {
        section: "Login",
        question: {
          en: "The app is telling me I have no simulations.",
          es: "La aplicación me dice que no tengo simulaciones.",
        },
        answer: {
          en: "Make sure you downloaded the correct mobile app, we have several. Also, remember that when logging in to our app, you should be using the same email/password you chose when you created your account on our website.",
          es: "Asegúrese de descargar la aplicación móvil correcta, tenemos varias. Además, recuerde que al iniciar sesión en nuestra aplicación, debe usar el mismo correo electrónico/contraseña que eligió cuando creó su cuenta en nuestro sitio web.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I can't find the app in my phone's app store.",
          es: "No puedo encontrar la aplicación en la tienda de aplicaciones de mi teléfono.",
        },
        answer: {
          en: "You can use the links shown after you join the simulation to be directed directly to your phone's app store. If you still can't see it your device may not be supported.",
          es: "Puede usar los enlaces que se muestran después de unirse a la simulación para dirigirse directamente a la App Store de su teléfono. Si aún no puede verlo, su dispositivo no será compatible.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I already have an account with Processim Labs, do I need to create a new one?",
          es: "Ya tengo una cuenta con Processim Labs, ¿necesito crear una nueva?",
        },
        answer: {
          en: "No need, you can use your existing account. ",
          es: "No hay necesidad, puede usar su cuenta existente.",
        },
      },
      {
        section: "Login",
        question: { en: "I forgot my password.", es: "Olvidé mi contraseña." },
        answer: {
          en: 'Just click the "Forgot it?" link in the login page and follow the steps.',
          es: 'Simplemente haga clic en el enlace "¿La Olvidé?" en la página de inicio de sesión y siga los pasos.',
        },
      },
      {
        section: "Create Account",
        question: {
          en: "My email is already linked to an account.",
          es: "Mi correo electrónico ya está vinculado a una cuenta.",
        },
        answer: {
          en: "This is likely because you played another Processim Labs simulation in the past. In this case there is no need to create a new account, you can just log in to your existing one.",
          es: "Esto es probable porque jugaste otra simulación de Processim Labs en el pasado. En este caso, no hay necesidad de crear una nueva cuenta, puede iniciar sesión en su cuenta existente.",
        },
      },
      {
        section: "Create Account",
        question: { en: "I misspelled my email.", es: "Escribí mal mi correo electrónico." },
        answer: {
          en: "You can play with a misspelled email no problem (if you remember how you misspelled it) or contact us at support@processimlabs.com so that we can further assist you.",
          es: "Puede jugar con un correo electrónico mal escrito sin problema (si recuerda cómo lo deletreó) o contáctenos a support@processimlabs.com.com para que podamos ayudarlo aún más.",
        },
      },
      {
        section: "Create Team",
        question: { en: "How do I join a simulation?", es: "¿Cómo unirse a una simulación?" },
        answer: {
          en: "You need to use the link that your professor shared or will share with you. ",
          es: "Debe usar el enlace que su profesor compartió o compartirá con usted.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "Can I join a simulation that is already over?",
          es: "¿Puedo unir a una simulación que ya ha terminado?",
        },
        answer: {
          en: "You cannot join a simulation that is already over. If your class will be playing a second run of the simulation, contact your professor in order to get the a link to join it.",
          es: "No puede unirse a una simulación que ya ha terminado. Si su clase jugará una segunda ejecución de la simulación, comuníquese con su profesor para obtener el enlace para unirse.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "Can I join a simulation that already started?",
          es: "¿Puedo unir a una simulación que ya comenzó?",
        },
        answer: { en: "Yes, not a problem.", es: "Sí, no es un problema." },
      },
      {
        section: "Create Team",
        question: {
          en: "Where can I find the link I need to share with my teammates?",
          es: "¿Dónde puedo encontrar el enlace que necesito compartir con mis compañeros de equipo?",
        },
        answer: {
          en: "We sent an email with the link to the email address you used when creating your account. Your professor also has access to this link from their account on our website, you can request the link from them as well.",
          es: "Enviamos un correo electrónico con el enlace a la dirección de correo electrónico que utilizó al crear su cuenta. Su profesor también tiene acceso a este enlace desde su cuenta en nuestro sitio web, también puede solicitar el enlace.",
        },
      },
      {
        section: "Create Team",
        question: { en: "Can I change my team name?", es: "¿Puedo cambiar el nombre de mi equipo?" },
        answer: {
          en: "No, thats not possible. Choose your team name wisely.",
          es: "No, eso no es posible. Elija sabiamente el nombre de su equipo.",
        },
      },
      {
        section: "Create Team",
        question: { en: "Can I created another team?", es: "¿Puedo crear otro equipo?" },
        answer: {
          en: "You cannot. Another team member can create a new team and you can then join it.",
          es: "No puedes. Otro miembro del equipo puede crear un nuevo equipo y luego puede unirse a él.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "The link that was shared with me is not working.",
          es: "El enlace que se compartió conmigo no está funcionando.",
        },
        answer: {
          en: "Doble check that you are using the correct link. It should look like https://medicascientific.app/XXXX",
          es: "Verifique que esté utilizando el enlace correcto. Debería parecerse a https://medicascientific.app/xxxx",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "I'm in the wrong team, can I change teams?",
          es: "Estoy en el equipo equivocado, ¿puedo cambiar equipos?",
        },
        answer: {
          en: "Yes you can. Get the team link from the team you want to join, click it and log in to your account.",
          es: "Sí tu puedes. Obtenga el enlace del equipo del equipo al que desea unirse, haga clic en él e inicie sesión con su cuenta.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "I created my account directly on your website without using a simulation link.",
          es: "Creé mi cuenta directamente en su sitio web sin usar un enlace de simulación.",
        },
        answer: {
          en: "Not a problem! Click on the simulation link shared by your professor and then log in to your account.",
          es: "¡No es un problema! Haga clic en el enlace de simulación compartido por su profesor y luego inicie sesión en su cuenta.",
        },
      },
      {
        section: "Join Team",
        question: { en: "How do I join a team?", es: "¿Cómo unirme a un equipo?" },
        answer: {
          en: "Wait for your team leader to create the team and share the team link with you. Click the link shared and follow the steps on our website.",
          es: "Espere a que el líder de su equipo cree el equipo y comparta el enlace del equipo con usted. Haga clic en el enlace compartido y siga los pasos de nuestro sitio web.",
        },
      },
      {
        section: "Join Team",
        question: {
          en: "I'm in the wrong team, can I change teams?",
          es: "Estoy en el equipo equivocado, ¿puedo cambiar equipos?",
        },
        answer: {
          en: "Yes you can. Get the team link from the team you want to join, click it and log in to your account.",
          es: "Sí tu puedes. Obtenga el enlace del equipo del equipo al que desea unirse, haga clic en él e inicie sesión en su cuenta.",
        },
      },
      {
        section: "Join Team",
        question: {
          en: "Can I join a simulation that is already over?",
          es: "¿Puedo unir a una simulación que ya ha terminado?",
        },
        answer: {
          en: "You cannot join a simulation that is already over. If your class will be playing a second run of the simulation, contact your professor in order to get the a link to join it.",
          es: "No puede unirse a una simulación que ya ha terminado. Si su clase reproducirá una segunda ejecución de la simulación, comuníquese con su profesor para obtener el enlace A para unirse.",
        },
      },
      {
        section: "Join Team",
        question: {
          en: "Can I join a simulation that already started?",
          es: "¿Puedo unir a una simulación que ya comenzó?",
        },
        answer: { en: "Yes, not a problem.", es: "Sí, no es un problema." },
      },
      {
        section: "Join Team",
        question: {
          en: "How can I tell if I'm in the correct team?",
          es: "¿Cómo puedo saber si estoy en el equipo correcto?",
        },
        answer: {
          en: "In the main screen of our app, after you are logged it, you can find the name of the team you are currently in.",
          es: "En la pantalla principal de nuestra aplicación, después de registrarla, puede encontrar el nombre del equipo en el que se encuentra actualmente.",
        },
      },
      {
        section: "Join Team",
        question: {
          en: "I created my account directly on your website without using a simulation link.",
          es: "Creé mi cuenta directamente en su sitio web sin usar un enlace de simulación.",
        },
        answer: {
          en: "Not a problem! Click on the team link shared by your team leader and then log in to your account.",
          es: "¡No es un problema! Haga clic en el enlace del equipo compartido por el líder de su equipo y luego inicie sesión en su cuenta.",
        },
      },
      {
        section: "Join Team",
        question: {
          en: "The link that was shared with me is not working.",
          es: "El enlace que se compartió conmigo no está funcionando.",
        },
        answer: {
          en: "Doble check that you are using the correct link. It should look like https://medicascientific.app/XXXX",
          es: "DOBLE verifique que esté utilizando el enlace correcto. Debería parecerse a https://medicascientific.app/xxxx",
        },
      },
      {
        section: "Payment",
        question: { en: "My payment won't go through.", es: "Tengo problemas para pagar." },
        answer: {
          en: "You may want to try with a different card and/or check if you have enough funds available. Don't hesitate to contact us on our chat if you still need help with this.",
          es: "Por favor pruebe con una tarjeta diferente y/o verifique si tiene suficientes fondos disponibles. No dude en contactarnos en nuestro chat si aún necesita ayuda con esto.",
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "How does the simulation move forward in time?",
          es: "¿Cómo avanza la simulación en el tiempo?",
        },
        answer: {
          en: "When setting up the simulation you professor could've chosen between our Manual or Automatic modes. In Manual mode, they would control when and by how many virtual days the simulation would advance each time. In Automatic mode, the simulation will advance a set number of virtual days every hour on the dot.",
          es: "Al configurar la simulación, el profesor podría haber elegido entre nuestros modo manual o automático. En modo manual, el profesor controla cuándo y en cuántos días virtuales la simulación avanzaría cada vez. En modo automático, la simulación avanzará un número establecido de días virtuales cada hora en el punto.",
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "The simulation advanced for everyone but mine didn't.",
          es: "La simulación avanzó para todos, pero la mía no lo hizo.",
        },
        answer: {
          en: "Check your internet connection and tapping the refresh button in the Home screen of the app. If this doesn't work contact us at support@processimlabs.com.",
          es: "Verifique su conexión a Internet y tocando el botón de actualización en la pantalla de inicio de la aplicación. Si esto no funciona, comuníquese con nosotros en support@processimlabs.com.",
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "How can I check the Standings table?",
          es: "¿Cómo puedo comprobar la tabla de clasificación?",
        },
        answer: {
          en: 'You can find the Standings table by going to the app\'s side menu and tapping on "Standings".',
          es: 'Puede encontrar la tabla de clasificación yendo al menú lateral de la aplicación y aprovechando "clasificación".',
        },
      },
      {
        section: "Gameplay",
        question: { en: "How can I change the app's language?", es: "¿Cómo puedo cambiar el idioma de la aplicación?" },
        answer: {
          en: "You can change the app's language by going to the app's side menu and tapping \"Settings\" and selecting the language of your choosing there.",
          es: 'Puede cambiar el idioma de la aplicación desde el menú lateral de la aplicación y pulsando el botón de "configuración", ahi puede seleccionar el lenguaje de su preferencia.',
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "How can I move from a first simulation run to the second one?",
          es: "¿Cómo puedo pasar de una primera ejecución de simulación al segundo?",
        },
        answer: {
          en: 'If your professor decides to setup a second simulation run for your class, you can switch from one simulation run to the next by going to the app\'s side menu and tapping "Settings", tapping the "Switch Simulation" button there and selecting the simulation run you want to join from the list.',
          es: 'Si su profesor decide configurar una segunda ejecución de simulación para su clase, puede cambiar de una ejecución de simulación a la siguiente yendo al menú lateral de la aplicación y aprovechando la "configuración", tocando el botón "Simulación de conmutación" allí y seleccionando la ejecución de la simulación Desea unirse desde la lista.',
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "I didn't get my certificate at the end of the simulation.",
          es: "No obtuve mi certificado al final de la simulación.",
        },
        answer: {
          en: "The certificate is generated automatically at the end of each simulation. If you dismissed the popup by accident you can close and open the app to get the popup again. If you dismissed the popup and chose for it not to be shown again you will need to log out and back in to get the popup again.",
          es: "El certificado se genera automáticamente al final de cada simulación. Si descarta la ventana emergente por accidente, puede cerrar y abrir la aplicación para volver a obtener la ventana emergente. Si descartó la ventana emergente y eligió que no se muestre nuevamente, deberá iniciar sesión y volver para obtener la ventana emergente nuevamente.",
        },
      },
      {
        section: "Standard Line",
        question: { en: "I have too many Accumulated Orders.", es: "Tengo demasiados órdenes acumuladas." },
        answer: {
          en: "Accumulated Orders happen when there is not enough raw material inventory to produce the number of units that you need to build. You may want to check your levels of Inventory.",
          es: "Los pedidos acumulados ocurren cuando no hay suficiente inventario de materia prima para producir el número de unidades que necesita construir. Es posible que desee verificar sus niveles de inventario.",
        },
      },
      {
        section: "Standard Line",
        question: {
          en: "How can I determine the Standard Line's demand?",
          es: "¿Cómo puedo determinar la demanda de la línea Standard?",
        },
        answer: {
          en: "Demand in the Standard Line is given by the relation between your Product Price and the Market Price. Setting your Product Price below the current Market Price will increase demand for your Standard products and vice versa. It is possible to determine price elasticity by analyzing the data in the simulation.",
          es: "La demanda en la línea Standard viene dada por la relación entre el precio de su producto y el precio del mercado. Establecer el precio de su producto por debajo del precio de mercado actual aumentará la demanda de sus productos Standard y viceversa. Es posible determinar la elasticidad del precio analizando los datos en la simulación.",
        },
      },
      {
        section: "Standard Line",
        question: { en: "I sold a machine but I didn't mean to.", es: "Vendí una máquina por accidente." },
        answer: {
          en: "The sale of a machine in any of the stations is irreversible. You can purchase a new machine to replace the one you sold but remember that you sell machines at a much lower price than you buy them.",
          es: "La venta de una máquina en cualquiera de las estaciones es irreversible. Puede comprar una nueva máquina para reemplazar la que vendió, pero recuerde que vende máquinas a un precio mucho más bajo que los compra.",
        },
      },
      {
        section: "Standard Line",
        question: {
          en: "Does my Product Price have an influence on the Market Price?",
          es: "¿El precio de mi producto influye en el precio del mercado?",
        },
        answer: {
          en: "Market Price is the same for all teams and it does not dependent on any of the decisions made by any of the teams.",
          es: "El precio de mercado es el mismo para todos los equipos y no depende de ninguna de las decisiones tomadas por ninguno de los equipos.",
        },
      },
      {
        section: "Standard Line",
        question: { en: "How do Batching Stations work?", es: "¿Cómo funcionan las estaciones de lotes?" },
        answer: {
          en: "Each Batching Station has a different batch processing time. The station will start building a new batch as soon as there are enough units in the previous Queue to match your current desired Batch Size.",
          es: "Cada estación de lotes tiene un tiempo de procesamiento por lotes diferente. La estación comenzará a construir un nuevo lote tan pronto como haya suficientes unidades en la cola anterior para que coincida con su tamaño de lote deseado actual.",
        },
      },
      {
        section: "Custom Line",
        question: { en: "I have too many Accumulated Orders.", es: "Tengo demasiados órdenes acumuladas." },
        answer: {
          en: "Accumulated Orders happen when there is not enough raw material inventory to produce the number of units that you need to build. You may want to check your levels of Inventory. Keep in mind that the Standard Line has priority over the Custom Line whenever when receiving Inventory.",
          es: "Los pedidos acumulados ocurren cuando no hay suficiente inventario de materia prima para producir el número de unidades que necesita construir. Es posible que desee verificar sus niveles de inventario. Recuerda que la línea Standard tiene prioridad sobre la línea Custom cuando al recibir el inventario.",
        },
      },
      {
        section: "Custom Line",
        question: { en: "I sold a machine but I didn't mean to.", es: "Vendí una máquina accidentalmente." },
        answer: {
          en: "The sale of a machine in any of the stations is irreversible. You can purchase a new machine to replace the one you sold but remember that you sell machines at a much lower price than you buy them.",
          es: "La venta de una máquina en cualquiera de las estaciones es irreversible. Puede comprar una nueva máquina para reemplazar la que vendió, pero recuerde que vende máquinas a un precio mucho más bajo que los compra.",
        },
      },
      {
        section: "Custom Line",
        question: {
          en: "How is the selling price of my Custom products determined?",
          es: "¿Cómo se determina el precio de venta de mis productos Custom?",
        },
        answer: {
          en: "The price of your Custom products depends on how fast or slow you deliver them (Average Lead Time). The faster you deliver them the higher your selling price will be and vice versa. ",
          es: "El precio de sus productos Custom depende de qué tan rápido o lento los entregue (tiempo de entrega promedio). Cuanto más rápido los entregue, mayor será su precio de venta y viceversa.",
        },
      },
      {
        section: "Inventory",
        question: { en: "Why do I have no Inventory?", es: "¿Por qué no tengo inventario?" },
        answer: {
          en: "You may not have enough cash on hand to place your next order. Check your finances and either get a loan or reduce your current Order Size. ",
          es: "Es posible que no tenga suficiente efectivo disponible para realizar su próximo pedido. Verifique sus finanzas y obtenga un préstamo o reduzca el tamaño de su pedido actual.",
        },
      },
      {
        section: "Inventory",
        question: {
          en: "What's the company's inventory management policy?",
          es: "¿Cuál es la política de gestión de inventario de la compañía?",
        },
        answer: {
          en: "Medica Scientific uses a continuos Review inventory management policy.",
          es: "Medica Scientific utiliza una política de gestión de inventario de revisión continua.",
        },
      },
      {
        section: "Work Force",
        question: {
          en: "Why is my number of Experts decreasing?",
          es: "¿Por qué está disminuyendo mi número de expertos?",
        },
        answer: {
          en: "Your employees may be quitting due to burn out caused by working extra shifts. ",
          es: "Es posible que sus empleados renunciaran debido al estrés causado por el trabajo de turnos adicionales.",
        },
      },
    ],
  },
  Simunation: {
    image: "/assets/simunation-logo.png",
    avatarBackgroundColor: "#7E217E",
    faq: [
      {
        section: "Login",
        question: { en: "I can't log in.", es: "No puedo iniciar sesión." },
        answer: {
          en: "Check if you typing your email and password correctly. You can also reset your password or contact us at support@processimlabs.com if none of this works.",
          es: "Verifique si escribe su correo electrónico y contraseña correctamente. También puede restablecer su contraseña o contactarnos a support@processimlabs.com.com si nada de esto funciona.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I can't find the app in my phone's app store.",
          es: "No puedo encontrar la aplicación en la tienda de aplicaciones de mi teléfono.",
        },
        answer: {
          en: "You can use the links shown after you join the simulation to be directed directly to your phone's app store. If you still can't see it your device may not be supported.",
          es: "Puede usar los enlaces que se muestran después de unirse a la simulación para dirigirse directamente a la App Store de su teléfono. Si aún no puede verlo, su dispositivo no será compatible.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I already have an account with Processim Labs, do I need to create a new one?",
          es: "Ya tengo una cuenta con ProcessIM Labs, ¿necesito crear una nueva?",
        },
        answer: {
          en: "No need, you can use your existing account. ",
          es: "No hay necesidad, puede usar su cuenta existente.",
        },
      },
      {
        section: "Login",
        question: { en: "I forgot my password.", es: "Olvidé mi contraseña." },
        answer: {
          en: 'Just click the "Forgot it?" link in the login page and follow the steps.',
          es: 'Simplemente haga clic en el enlace de "¡Olvidé mi Contraseña!" en la página de inicio de sesión y siga los pasos.',
        },
      },
      {
        section: "Create Account",
        question: {
          en: "My email is already linked to an account.",
          es: "Mi correo electrónico ya está vinculado a una cuenta.",
        },
        answer: {
          en: "This is likely because you played another Processim Labs simulation in the past. In this case there is no need to create a new account, you can just log in to your existing one.",
          es: "Esto es probable porque jugaste otra simulación de Processim Labs en el pasado. En este caso, no hay necesidad de crear una nueva cuenta, puede iniciar sesión en su existente.",
        },
      },
      {
        section: "Create Account",
        question: { en: "I misspelled my email.", es: "Escribí mal mi correo electrónico." },
        answer: {
          en: "You can play with a misspelled email no problem (if you remember how you misspelled it) or contact us at support@processimlabs.com so that we can further assist you.",
          es: "Puede jugar con un correo electrónico mal escrito sin problema (si recuerda cómo lo escribió mal) o contáctenos a support@processimlabs.com.com para que podamos ayudarlo aún más.",
        },
      },
      {
        section: "Activation Code",
        question: { en: "My Activation Code is not working.", es: "Mi código de activación no funciona." },
        answer: {
          en: "Verify that you are typing the code correctly and contact us at support@processimlabs.com if it is still not working.",
          es: "Verifique que esté escribiendo el código correctamente y contáctenos a support@processimlabs.com.com si aún no funciona.",
        },
      },
      {
        section: "Join Team",
        question: { en: "I can't read the QR code.", es: "No puedo leer el código QR." },
        answer: {
          en: "Try asking your team leader for the invitation link or restarting the app. ",
          es: "Intente pedirle al líder de su equipo el enlace de invitación o reiniciar la aplicación.",
        },
      },
      {
        section: "Join Team",
        question: { en: "The invitation link is not working.", es: "El enlace de invitación no funciona." },
        answer: {
          en: "Remember that you need to have downloaded the mobile app before hand. Try opening the link by pasting it into a different browser or scanning the QR code invitation if that fails.",
          es: "Recuerde que debe haber descargado la aplicación móvil de antemano. Intente abrir el enlace pegándolo en un navegador diferente o escaneando la invitación del código QR si eso falla.",
        },
      },
      {
        section: "Join Team",
        question: { en: "I'm in the wrong team.", es: "Estoy en el equipo equivocado." },
        answer: { en: "Contact us at support@processimlabs.com", es: "Contáctenos en support@processimlabs.com.com" },
      },
    ],
  },
  Bindz: {
    image: "/assets/crisis-logo.png",
    avatarBackgroundColor: "#18B1B9",
    faq: [
      {
        section: "Login",
        question: { en: "I can't log in.", es: "No puedo iniciar sesión." },
        answer: {
          en: "Check if you typing your email and password correctly. You can also reset your password or contact us at support@processimlabs.com if none of this works.",
          es: "Verifique si escribe su correo electrónico y contraseña correctamente. También puede restablecer su contraseña o contactarnos a support@processimlabs.com.com si nada de esto funciona.",
        },
      },
      {
        section: "Login",
        question: {
          en: "The app is telling me I have no simulations.",
          es: "La aplicación me dice que no tengo simulaciones.",
        },
        answer: {
          en: "Make sure you downloaded the correct mobile app, we have several. Also, remember that when logging in to our app, you should be using the same email/password you chose when you created your account on our website.",
          es: "Asegúrese de descargar la aplicación móvil correcta, tenemos varias. Además, recuerde que al iniciar sesión en nuestra aplicación, debe usar el mismo correo electrónico/contraseña que eligió cuando creó su cuenta en nuestro sitio web.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I can't find the app in my phone's app store.",
          es: "No puedo encontrar la aplicación en la tienda de aplicaciones de mi teléfono.",
        },
        answer: {
          en: "You can use the links shown after you join the simulation to be directed directly to your phone's app store. If you still can't see it your device may not be supported.",
          es: "Puede usar los enlaces que se muestran después de unirse a la simulación para dirigirse directamente a la App Store de su teléfono. Si aún no puede verlo, su dispositivo no será compatible.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I already have an account with Processim Labs, do I need to create a new one?",
          es: "Ya tengo una cuenta con Processim Labs, ¿necesito crear una nueva?",
        },
        answer: {
          en: "No need, you can use your existing account. ",
          es: "No hay necesidad, puede usar su cuenta existente.",
        },
      },
      {
        section: "Login",
        question: { en: "I forgot my password.", es: "Olvidé mi contraseña." },
        answer: {
          en: 'Just click the "Forgot it?" link in the login page and follow the steps.',
          es: 'Simplemente haga clic en el enlace de "¡Olvidé mi Contraseña!" en la página de inicio de sesión y siga los pasos.',
        },
      },
      {
        section: "Create Account",
        question: {
          en: "My email is already linked to an account.",
          es: "Mi correo electrónico ya está vinculado a una cuenta.",
        },
        answer: {
          en: "This is likely because you played another Processim Labs simulation in the past. In this case there is no need to create a new account, you can just log in to your existing one.",
          es: "Esto es probable porque jugaste otra simulación de Processim Labs en el pasado. En este caso, no hay necesidad de crear una nueva cuenta, puede iniciar sesión en su existente.",
        },
      },
      {
        section: "Create Account",
        question: { en: "I misspelled my email.", es: "Escribí mal mi correo electrónico." },
        answer: {
          en: "You can play with a misspelled email no problem (if you remember how you misspelled it) or contact us at support@processimlabs.com so that we can further assist you.",
          es: "Puede jugar con un correo electrónico mal escrito sin problema (si recuerda cómo lo escribió mal) o contáctenos a support@processimlabs.com.com para que podamos ayudarlo aún más.",
        },
      },
      {
        section: "Join Simulation",
        question: { en: "How do I join a simulation?", es: "¿Cómo me uniré a una simulación?" },
        answer: {
          en: "You need to use the link that your professor shared or will share with you. ",
          es: "Debe usar el enlace que su profesor compartió o compartirá con usted.",
        },
      },
      {
        section: "Join Simulation",
        question: {
          en: "Can I join a simulation that is already over?",
          es: "¿Puedo unir a una simulación que ya ha terminado?",
        },
        answer: {
          en: "Yes you can. Please consider that if your professor is having your class play a second run, you can run that second simulation directly with his/her help.",
          es: "Sí tu puedes. Tenga en cuenta que si su profesor está haciendo que su clase juegue una segunda carrera, puede ejecutar esa segunda simulación directamente con su ayuda.",
        },
      },
      {
        section: "Join Simulation",
        question: {
          en: "Can I join a simulation that already started?",
          es: "¿Puedo unir a una simulación que ya comenzó?",
        },
        answer: { en: "Yes, not a problem.", es: "Sí, no es un problema." },
      },
      {
        section: "Join Simulation",
        question: { en: "Can I choose what team I want to play in?", es: "¿Puedo elegir en qué equipo quiero jugar?" },
        answer: { en: "You get assigned to a team randomly.", es: "Te asignan a un equipo al azar." },
      },
      {
        section: "Join Simulation",
        question: { en: "Can I change my team?", es: "¿Puedo cambiar mi equipo?" },
        answer: { en: "You cannot.", es: "No puedes." },
      },
      {
        section: "Join Simulation",
        question: {
          en: "The link that was shared with me is not working.",
          es: "El enlace que se compartió conmigo no está funcionando.",
        },
        answer: {
          en: "Doble check that you are using the correct link. It should look like https://bindz.processimlabs.com/XXXX",
          es: "DOBLE verifique que esté utilizando el enlace correcto. Debería parecerse a https://bindz.processimlabs.com/xxxx",
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "I can only access one section in the simulation.",
          es: "Solo puedo acceder a una sección en la simulación.",
        },
        answer: {
          en: "The current game mode that your professor selected does not allow for you to access the other sections in the supply chain.",
          es: "El modo de juego actual que su profesor seleccionó no le permite acceder a las otras secciones en la cadena de suministro.",
        },
      },
      {
        section: "Gameplay",
        question: { en: "I'm not getting any incoming material.", es: "No estoy obteniendo ningún material entrante." },
        answer: {
          en: "Your provider does not have enough material to send your way.",
          es: "Su proveedor no tiene suficiente material para enviar su camino.",
        },
      },
      {
        section: "Gameplay",
        question: { en: "How do I get a loan?", es: "¿Cómo obtengo un préstamo?" },
        answer: {
          en: "You can't. If you use more money than you have you'll see negative cash in your simulation.",
          es: "No puedes. Si usa más dinero del que tiene, verá efectivo negativo en su simulación.",
        },
      },
      {
        section: "Gameplay",
        question: { en: "My products have bad quality.", es: "Mis productos tienen mala calidad." },
        answer: {
          en: "Your product's quality depends not only from your processes but the quality of the products you receive from the previous section.",
          es: "La calidad de su producto depende no solo de sus procesos sino también de la calidad de los productos que recibe de la sección anterior.",
        },
      },
      {
        section: "Gameplay",
        question: { en: "I have a huge backlog.", es: "Tengo una gran cartera de pedidos." },
        answer: {
          en: "Try ordering more from the previous section. Be careful not to overcompensate.",
          es: "Intente ordenar más de la sección anterior. Tenga cuidado de no compensar en exceso.",
        },
      },
      {
        section: "Supply and Assembly",
        question: { en: "I'm not producing anything.", es: "No estoy produciendo nada." },
        answer: {
          en: "Make sure you have enough Material X or Y.",
          es: "Asegúrese de tener suficiente material X o Y.",
        },
      },
      {
        section: "Production 1",
        question: {
          en: "My indicators show low quality in my products.",
          es: "Mis indicadores muestran baja calidad en mis productos.",
        },
        answer: {
          en: "Older machines will lower the quality of your products.",
          es: "Las máquinas más antiguas reducirán la calidad de sus productos.",
        },
      },
      {
        section: "Production 2",
        question: {
          en: "My indicators show low quality in my products.",
          es: "Mis indicadores muestran baja calidad en mis productos.",
        },
        answer: {
          en: "Overworked employees are likely the cause of low quality in your products.",
          es: "Los empleados con exceso de trabajo son probablemente la causa de baja calidad en sus productos.",
        },
      },
    ],
  },
  SupplyGrain: {
    image: "/assets/supplychain-logo.png",
    avatarBackgroundColor: "#006F5D",
    faq: [
      {
        section: "Login",
        question: { en: "I can't log in.", es: "No puedo iniciar sesión." },
        answer: {
          en: "Check if you typing your email and password correctly. You can also reset your password or contact us at support@processimlabs.com if none of this works.",
          es: "Verifique si escribe su correo electrónico y contraseña correctamente. También puede restablecer su contraseña o contactarnos a support@processimlabs.com.com si nada de esto funciona.",
        },
      },
      {
        section: "Login",
        question: {
          en: "The app is telling me I have no simulations.",
          es: "La aplicación me dice que no tengo simulaciones.",
        },
        answer: {
          en: "Make sure you downloaded the correct mobile app, we have several. Also, remember that when logging in to our app, you should be using the same email/password you chose when you created your account on our website.",
          es: "Asegúrese de descargar la aplicación móvil correcta, tenemos varias. Además, recuerde que al iniciar sesión en nuestra aplicación, debe usar el mismo correo electrónico/contraseña que eligió cuando creó su cuenta en nuestro sitio web.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I can't find the app in my phone's app store.",
          es: "No puedo encontrar la aplicación en la tienda de aplicaciones de mi teléfono.",
        },
        answer: {
          en: "You can use the links shown after you join the simulation to be directed directly to your phone's app store. If you still can't see it your device may not be supported.",
          es: "Puede usar los enlaces que se muestran después de unirse a la simulación para dirigirse directamente a la App Store de su teléfono. Si aún no puede verlo, su dispositivo no será compatible.",
        },
      },
      {
        section: "Login",
        question: {
          en: "I already have an account with Processim Labs, do I need to create a new one?",
          es: "Ya tengo una cuenta con ProcessIM Labs, ¿necesito crear una nueva?",
        },
        answer: {
          en: "No need, you can use your existing account. ",
          es: "No hay necesidad, puede usar su cuenta existente.",
        },
      },
      {
        section: "Login",
        question: { en: "I forgot my password.", es: "Olvidé mi contraseña." },
        answer: {
          en: 'Just click the "Forgot it?" link in the login page and follow the steps.',
          es: 'Simplemente haga clic en el enlace de "¿Olvide mi Contraseña?" en la página de inicio de sesión y siga los pasos.',
        },
      },
      {
        section: "Create Account",
        question: {
          en: "My email is already linked to an account.",
          es: "Mi correo electrónico ya está vinculado a una cuenta.",
        },
        answer: {
          en: "This is likely because you played another Processim Labs simulation in the past. In this case there is no need to create a new account, you can just log in to your existing one.",
          es: "Esto es probable porque jugaste otra simulación de Processim Labs en el pasado. En este caso, no hay necesidad de crear una nueva cuenta, puede iniciar sesión en su existente.",
        },
      },
      {
        section: "Create Account",
        question: { en: "I misspelled my email.", es: "Escribí mal mi correo electrónico." },
        answer: {
          en: "You can play with a misspelled email no problem (if you remember how you misspelled it) or contact us at support@processimlabs.com so that we can further assist you.",
          es: "Puede jugar con un correo electrónico mal escrito sin problema (si recuerda cómo lo escribió mal) o contáctenos a support@processimlabs.com.com para que podamos ayudarlo aún más.",
        },
      },
      {
        section: "Create Team",
        question: { en: "How do I join a simulation?", es: "¿Cómo me uniré a una simulación?" },
        answer: {
          en: "You need to use the link that your professor shared or will share with you. ",
          es: "Debe usar el enlace que su profesor compartió o compartirá con usted.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "Can I join a simulation that is already over?",
          es: "¿Puedo unir a una simulación que ya ha terminado?",
        },
        answer: {
          en: "Yes you can. Please consider that if your professor is having your class play a second run, you can run that second simulation directly with his/her help.",
          es: "Sí tu puedes. Tenga en cuenta que si su profesor está haciendo que su clase juegue una segunda carrera, puede ejecutar esa segunda simulación directamente con su ayuda.",
        },
      },
      {
        section: "Create Team",
        question: {
          en: "Can I join a simulation that already started?",
          es: "¿Puedo unir a una simulación que ya comenzó?",
        },
        answer: { en: "Yes, not a problem.", es: "Sí, no es un problema." },
      },
      {
        section: "Create Team",
        question: { en: "Can I choose what team I want to play in?", es: "¿Puedo elegir en qué equipo quiero jugar?" },
        answer: { en: "You get assigned to a team randomly.", es: "Te asignan a un equipo al azar." },
      },
      {
        section: "Create Team",
        question: { en: "Can I change my team?", es: "¿Puedo cambiar mi equipo?" },
        answer: { en: "You cannot.", es: "No puedes." },
      },
      {
        section: "Create Team",
        question: {
          en: "The link that was shared with me is not working.",
          es: "El enlace que se compartió conmigo no está funcionando.",
        },
        answer: {
          en: "Doble check that you are using the correct link. It should look like https://supplygrain.processimlabs.com/XXXX",
          es: "DOBLE verifique que esté utilizando el enlace correcto. Debería parecerse a https://supplygrain.processimlabs.com/xxxx",
        },
      },
      {
        section: "Gameplay",
        question: {
          en: "I can only access one section in the simulation.",
          es: "Solo puedo acceder a una sección en la simulación.",
        },
        answer: {
          en: "The current game mode that your professor selected does not allow for you to access the other sections in the supply chain.",
          es: "El modo de juego actual que su profesor seleccionó no le permite acceder a las otras secciones en la cadena de suministro.",
        },
      },
    ],
  },
};
