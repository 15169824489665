import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import InputComponent from "../helpers/InputComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import "./modals.css";
const featureDescriptionMaxLength = 1000;
const productOptions = [
  {
    key: 1,
    text: "Medica Scientific",
    value: "Medica",
  },
  {
    key: 2,
    text: "Simunation",
    value: "Simunation",
  },
  {
    key: 3,
    text: "Website",
    value: "Website",
  },
];

class RequestFeatureModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      featureDescription: "",
      showDescriptionError: false,
      descriprionErrorMessage: "",
      product: "Medica",
    };
  }

  onSelectProduct = (val) => {
    this.setState({
      product: val,
    });
  };

  onChangeFeatureDescription = (id, data) => {
    this.setState({
      featureDescription: data,
      showDescriptionError: false,
    });
  };

  closeModalResetStateAndStore = () => {
    const { resetRequestFeatureStore, handleRequestFeatureModal } = this.props;
    this.setState({
      featureDescription: "",
      showDescriptionError: false,
    });
    resetRequestFeatureStore();
    handleRequestFeatureModal();
  };

  postFeatureRequest = () => {
    const { requestFeature } = this.props;
    const { featureDescription, product } = this.state;

    if (featureDescription.length === 0) {
      this.setState({
        showDescriptionError: true,
        descriprionErrorMessage: "pleaseDescribeYourFeature",
      });
    } else {
      if (featureDescription.length <= featureDescriptionMaxLength) {
        const requestData = {
          product: product,
          description: featureDescription,
        };
        requestFeature(requestData);
      }
    }
  };

  render() {
    const { translate, openRequestFeatureModal, requestFeatureData } = this.props;
    const { featureDescription, showDescriptionError, descriprionErrorMessage, product } = this.state;
    const { loadingRequestFeature, requestFeatureSuccess, requestFeatureMessage } = requestFeatureData;

    let loadingComponent = null;
    let modalContent = null;
    let actionButtons = null;

    if (loadingRequestFeature) {
      loadingComponent = <LoadingComponent />;
    } else {
      if (requestFeatureMessage !== "") {
        modalContent = (
          <MessagesComponent message={translate(requestFeatureMessage)} messageType={requestFeatureSuccess} />
        );

        if (requestFeatureSuccess) {
          actionButtons = <Button content="Ok" onClick={this.closeModalResetStateAndStore} />;
        } else {
          actionButtons = (
            <>
              <Button content={translate("tryAgain")} onClick={this.postFeatureRequest} />
              <Button content={translate("Common:cancel")} onClick={this.closeModalResetStateAndStore} />
            </>
          );
        }
      } else {
        modalContent = (
          <>
            <DropDownComponent
              options={productOptions}
              onSelection={this.onSelectProduct}
              value={product}
              label={translate("product")}
              labelAlignment="left"
            />
            <div className="descriptionInput">
              <InputComponent
                multiLine={true}
                specialLabel={true}
                handleChange={this.onChangeFeatureDescription}
                value={featureDescription}
                label={translate("featureDescription")}
                onError={showDescriptionError}
                counter={true}
                length={featureDescription.length}
                maxLength={featureDescriptionMaxLength}
                errorMessage={translate(descriprionErrorMessage)}
                rows={12}
                labelAlignment="left"
              />
            </div>
          </>
        );

        actionButtons = (
          <>
            <Button content={translate("send")} onClick={this.postFeatureRequest} disabled={loadingRequestFeature} />
            <Button content={translate("Common:cancel")} onClick={this.closeModalResetStateAndStore} />
          </>
        );
      }
    }

    return (
      <Modal size="small" open={openRequestFeatureModal}>
        <Modal.Header>{translate("requestFeature")}</Modal.Header>
        <Modal.Content>
          {modalContent}
          {loadingComponent}
        </Modal.Content>
        <Modal.Actions>{actionButtons}</Modal.Actions>
      </Modal>
    );
  }
}

export default RequestFeatureModal;
