import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import HomeComponent from "./HomeComponent";
import NavigationButton from "../helpers/NavigationButton";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import { withTranslation } from "react-i18next";
import { getSimulations } from "../../store/Simulations/actions";
import VersionComponent from "../helpers/VersionComponent";
import { sentryReportEvent } from "../../services/SentryReportService";
import { getStorage, setStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as ROUTE from "../../constants/Routes";
import * as Sentry from "@sentry/react";
import "./HomeStyles.css";
import WelcomeModal from "./WelcomeModal";

class HomeScreen extends Component {
  state = {
    openWelcomeModal: null,
    counter: 0,
  };

  componentDidMount() {
    const transaction = Sentry.startTransaction({
      name: "/Home",
      op: "showHome",
    });

    this.homeLoadedCheck = setTimeout(this.reportHomeDidntLoad, 120000);

    const span = transaction.startChild({
      op: "getAllSimulations",
      description: `Getting all simulations`,
    });

    this.getAllSimulations();

    span.finish();
    transaction.finish();

    const dontShowWelcomeAgain = getStorage(LOCALSTORAGE.dontShowWelcomeModalAgain);
    const openWelcomeModal = !dontShowWelcomeAgain;
    this.setState({ openWelcomeModal });
  }

  getAllSimulations = () => {
    const { simulations, getSimulations, user, t } = this.props;
    const translate = t;
    const { language } = user;
    if (simulations || simulations.data.length === 0) {
      getSimulations(language, translate);
    }
  };

  handleWelcomeModal = (neverShowAgain) => {
    if (neverShowAgain === true) {
      setStorage(LOCALSTORAGE.dontShowWelcomeModalAgain, true);
    }
    this.setState((state) => ({ openWelcomeModal: !state.openWelcomeModal }));
  };

  reportHomeDidntLoad() {
    sentryReportEvent("Time out error: Home", { name: "Time", data: 120 }, []);
  }

  render() {
    const { t, simulations, user, history } = this.props;
    const { openWelcomeModal } = this.state;
    const translate = t;
    const { loadingSimulations, data, message } = simulations;

    let userRole = "";
    let userFullName = "";
    let welcomeModal = null;
    let mainComponent = null;
    let addSimulationButton = null;

    if (loadingSimulations) {
      mainComponent = <LoadingComponent message={translate("loadingSimulations")} />;
    } else {
      clearTimeout(this.homeLoadedCheck);
      addSimulationButton = (
        <NavigationButton route={ROUTE.CATALOGUE} history={history} label={translate("addNewSimulation")} />
      );

      if (message !== "") {
        mainComponent = (
          <div className="homeMiddle">
            <MessagesComponent message={translate(message)} />
          </div>
        );
      }

      if (data && data.length > 0) {
        mainComponent = (
          <div className="homeMiddle">
            <HomeComponent simulationsList={data} translate={translate} history={history} />
          </div>
        );
      }

      if (data && data.length === 0) {
        mainComponent = (
          <div className="homeMiddle">
            <MessagesComponent info message={translate("noSimulationList")} />
          </div>
        );
      }
    }

    welcomeModal = <WelcomeModal open={openWelcomeModal} handleModal={this.handleWelcomeModal.bind(this)} />;

    if (user.role) {
      userRole = translate(`Common:${user.role}`);
    }

    if (user.fullName) {
      userFullName = user.fullName;
    }
    return (
      <Container>
        {welcomeModal}
        <HeaderComponent translate={translate} showCompleteMenu={true} />
        <MainTitleComponent
          title={`${translate("Common:welcome")} ${userRole.toLowerCase()}`}
          subtitle={userFullName}
        />
        {mainComponent}
        {addSimulationButton}
        <VersionComponent />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSimulations: (language, translate) => dispatch(getSimulations(language, translate)),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      fullName: state.user.userProfile.fullName,
      firstName: state.user.userProfile.firstName,
      lastName: state.user.userProfile.lastName,
      email: state.user.userProfile.email,
      institution: state.user.userProfile.institution,
      language: state.user.userProfile.language,
    },
    simulations: state.simulations,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("HomeScreen", "Common")(withRouter(HomeScreen))
);
