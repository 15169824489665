import React from "react";
import { FlexibleWidthXYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries } from "react-vis";
import { abbreviateNumber, statusAlwaysPresent } from "./utilities";

//https://github.com/uber/react-vis/blob/premodern/showcase/axes/custom-axes.js

const VisChart = (props) => {
  const { plot, translate, selectedTeams } = props;
  const { lineValues } = plot;
  let lineSeries = <LineSeries data={[{ x: 0, y: 0 }]} />;
  let maxYDomain = 1;
  let minYDomain = 0;

  const selectedTeamNames = selectedTeams.filter((team) => team.isChecked).map((x) => x.name);
  const setLinesToShow = (lineValues) => {
    if (statusAlwaysPresent(plot.name)) {
      lineValues[0].isChecked = true;
    } else {
      for (let i = 0; i < lineValues.length; i++) {
        const currentLine = lineValues[i];
        const teamName = currentLine.name;
        if (selectedTeamNames.includes(teamName)) {
          currentLine.isChecked = true;
        } else {
          currentLine.isChecked = false;
        }
      }
    }

    const filteredLines = lineValues.filter((line) => line.isChecked);
    return filteredLines;
  };

  const linesToShow = setLinesToShow(lineValues);

  if (linesToShow.length > 0) {
    lineSeries = linesToShow.map((line) => {
      const lineYvalues = line.historicDataSimplified.map((x) => {
        return x.y;
      });
      maxYDomain = Math.max(...lineYvalues, maxYDomain);
      minYDomain = Math.min(...lineYvalues, minYDomain);
      return (
        <LineSeries
          key={line.index}
          animation={false}
          data={line.historicDataSimplified}
          stroke={line.color}
          strokeWidth={1}
          opacity={0.6}
        />
      );
    });
  }

  const info = [
    {
      name: "Decision_RiceSupplier",
      values: {
        0: translate("Decision_SupplierB"),
        1: translate("Decision_SupplierA"),
      },
    },
    {
      name: "Decision_AuroraTransportMethod",
      values: {
        0: translate("Decision_TransportMethodDrone"),
        1: translate("Decision_TransportMethodTruck"),
      },
    },
    {
      name: "Decision_AuroraDispatcher",
      values: {
        1: "Aurora",
        2: "Barcelona",
        3: "Geneva",
      },
    },
    {
      name: "Decision_BarcelonaWarehouseState",
      values: {
        0: translate("Decision_WarehouseClosed"),
        1: translate("Decision_WarehouseBuilt"),
      },
    },
    {
      name: "Decision_BarcelonaSupplier",
      values: {
        0: "Aurora",
        1: "Local",
      },
    },
    {
      name: "Decision_BarcelonaTransportMethod",
      values: {
        0: translate("Decision_TransportMethodDrone"),
        1: translate("Decision_TransportMethodTruck"),
      },
    },
    {
      name: "Decision_BarcelonaDispatcher",
      values: {
        1: "Aurora",
        2: "Barcelona",
        3: "Geneva",
      },
    },
    {
      name: "Decision_GenevaWarehouseState",
      values: {
        0: translate("Decision_WarehouseClosed"),
        1: translate("Decision_WarehouseBuilt"),
      },
    },
    {
      name: "Decision_GenevaSupplier",
      values: {
        0: "Aurora",
        1: "Local",
      },
    },
    {
      name: "Decision_GenevaTransportMethod",
      values: {
        0: translate("Decision_TransportMethodDrone"),
        1: translate("Decision_TransportMethodTruck"),
      },
    },
    {
      name: "Decision_GenevaDispatcher",
      values: {
        1: "Aurora",
        2: "Barcelona",
        3: "Geneva",
      },
    },
    {
      name: "Decision_AlamedaWarehouseState",
      values: {
        0: translate("Decision_WarehouseClosed"),
        1: translate("Decision_WarehouseBuilt"),
      },
    },
    {
      name: "Decision_AlamedaSupplier",
      values: {
        0: "Local",
        1: "Alameda",
        2: "Belmont",
        3: "Grant",
      },
    },
    {
      name: "Decision_AlamedaTransportMethod",
      values: {
        0: translate("Decision_TransportMethodAir"),
        1: translate("Decision_TransportMethodLand"),
      },
    },
    {
      name: "Decision_BelmontWarehouseState",
      values: {
        0: translate("Decision_WarehouseClosed"),
        1: translate("Decision_WarehouseBuilt"),
      },
    },
    {
      name: "Decision_BelmontSupplier",
      values: {
        0: "Local",
        1: "Alameda",
        2: "Belmont",
        3: "Grant",
      },
    },
    {
      name: "Decision_BelmontTransportMethod",
      values: {
        0: translate("Decision_TransportMethodAir"),
        1: translate("Decision_TransportMethodLand"),
      },
    },
    {
      name: "Decision_GrantWarehouseState",
      values: {
        0: translate("Decision_WarehouseClosed"),
        1: translate("Decision_WarehouseBuilt"),
      },
    },
    {
      name: "Decision_GrantSupplier",
      values: {
        0: "Local",
        1: "Alameda",
        2: "Belmont",
        3: "Grant",
      },
    },
    {
      name: "Decision_GrantTransportMethod",
      values: {
        0: translate("Decision_TransportMethodAir"),
        1: translate("Decision_TransportMethodLand"),
      },
    },
  ];

  const plotAxisTranslation = info.find((element) => element.name === plot.name);
  if (plotAxisTranslation) {
    plot.YAxisNeedsTranslation = true;
    plot.Conversions = plotAxisTranslation.values;

    return (
      <FlexibleWidthXYPlot yDomain={[minYDomain, maxYDomain]} height={220} margin={{ left: 80 }}>
        <HorizontalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
        <VerticalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
        <XAxis title={translate("days")} />
        <YAxis tickFormat={(v) => plotAxisTranslation.values[v]} title={translate(plot.units)} />
        {lineSeries}
      </FlexibleWidthXYPlot>
    );
  }
  return (
    <FlexibleWidthXYPlot yDomain={[minYDomain, maxYDomain]} height={220} margin={{ left: 50 }}>
      <HorizontalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
      <VerticalGridLines style={{ opacity: 0.6, strokeWidth: 0.5 }} />
      <XAxis title={translate("days")} />
      <YAxis tickFormat={(v) => abbreviateNumber(v)} title={translate(plot.units)} />
      {lineSeries}
    </FlexibleWidthXYPlot>
  );
};

export default VisChart;
