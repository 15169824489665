import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Container, Grid } from "semantic-ui-react";
import HeaderComponent from "../header/HeaderComponent";
import ActionButtons from "../actionButtonComponent/ActionButtons";
import MobileActionButtons from "../actionButtonComponent/MobileActionButtons";
import SimunationDetailComponent from "./SimunationDetailComponent";
import ErrorModal from "../modals/ErrorModal";
import AdvanceSimunationModal from "../modals/AdvanceSimunationModal";
import {
  getSimulationDetails,
  advanceSimunationAction,
  resetAdvancedSimunationData,
} from "../../store/Simunation/actions";
import "./SimulationDetailStyles.css";
import * as ROUTES from "../../constants/Routes";
import { preventBack, allowBack, getUrlSearchParam } from "../../services/NavigationService";
import * as Sentry from "@sentry/react";
import { filterActionButtons } from "./actionButtonBuilder";
import * as ButtonKeys from "./actionButtonConstants";

class SimunationDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorModal: true,
      openShareModal: false,
      openModifyModal: false,
      messageSuccess: true,
      openAdvanceSimunationModal: false,
    };
  }

  componentDidMount() {
    const transaction = Sentry.startTransaction({
      name: "/SimunationDetail",
      op: "showSimunationDetail",
    });

    window.scrollTo(0, 0);
    if (this.props.location.pathname === ROUTES.PURCHASED_SIMUNATION_DETAIL) {
      preventBack();
    }

    const span = transaction.startChild({
      op: "getSimulationDetails",
      description: `Getting Simunation detail`,
    });

    this.getSimulationDetails();

    span.finish();
    transaction.finish();
  }

  componentWillUnmount() {
    if (this.props.location.pathname === ROUTES.PURCHASED_SIMUNATION_DETAIL) {
      allowBack();
    }
  }

  getSimulationDetails = () => {
    const requestedSimulation = getUrlSearchParam();
    if (this.props.simunation.simulationDetails.payload) {
      const simulationSaved = this.props.simunation.simulationDetails.payload.name;
      if (requestedSimulation !== simulationSaved) {
        this.props.getSimulationDetails(requestedSimulation);
      }
    } else {
      this.props.getSimulationDetails(requestedSimulation);
    }
  };

  handleShareModal = () => {
    this.setState({
      openShareModal: !this.state.openShareModal,
    });
  };

  handleModifyModal = () => {
    this.setState({
      openModifyModal: !this.state.openModifyModal,
    });
  };

  handleErrorModal = () => {
    this.setState({
      openErrorModal: !this.state.openErrorModal,
    });
  };

  handleAdvanceSimunationModal = () => {
    this.setState({
      openAdvanceSimunationModal: !this.state.openAdvanceSimunationModal,
    });
    this.props.resetAdvancedSimunationData();
  };

  registeredStudentsAction = () => {
    this.props.history.push({
      pathname: ROUTES.SIMUNATION_REGISTERED_STUDENTS,
      search: this.props.simunation.simulationDetails?.payload?.name,
    });
  };

  standingsAction = () => {
    this.props.history.push({
      pathname: ROUTES.SIMUNATION_STANDINGS,
      search: this.props.simunation.simulationDetails?.payload?.name,
    });
  };

  actionButtonsMapping = {
    [ButtonKeys.advanceSimulationKey]: {
      name: "Common:advanceSimulation",
      action: this.handleAdvanceSimunationModal.bind(this),
    },
    [ButtonKeys.standingsKey]: {
      name: "standings",
      action: this.standingsAction.bind(this),
    },
    [ButtonKeys.registeredStudentsKey]: {
      name: "registeredStudents",
      action: this.registeredStudentsAction.bind(this),
    },
    [ButtonKeys.backKey]: {
      name: "Common:back",
      action: () => this.props.history.push(ROUTES.HOME),
    },
  };

  render() {
    const translate = this.props.t;
    const { simulationDetails, loadingDetails, getSimulationDetailsError } = this.props.simunation;
    let actionButtons = null;
    let mobileActionButtons = null;
    let header = null;

    const errorModal = (
      <ErrorModal
        handleErrorModal={this.handleErrorModal}
        translate={translate}
        openErrorModal={this.state.openErrorModal && getSimulationDetailsError !== ""}
        message={translate(getSimulationDetailsError)}
      />
    );
    const advanceSimunationModal = (
      <AdvanceSimunationModal
        handleAdvanceSimunationModal={this.handleAdvanceSimunationModal}
        translate={translate}
        openAdvanceSimunationModal={this.state.openAdvanceSimunationModal}
        advanceSimunationAction={this.props.advanceSimunationAction}
        advanceSimunationData={this.props.advanceSimunationData}
        simulation={simulationDetails.payload}
        getSimulationDetails={this.props.getSimulationDetails}
      />
    );

    if (!loadingDetails && simulationDetails.payload) {
      const actionButtonsList = filterActionButtons(simulationDetails.payload, this.actionButtonsMapping);
      actionButtons = <ActionButtons translate={translate} actionButtons={actionButtonsList} />;
      mobileActionButtons = <MobileActionButtons translate={translate} actionButtons={actionButtonsList} />;
      header = (
        <HeaderComponent
          translate={translate}
          showDropdown={true}
          isMedica={false}
          simulation={simulationDetails.payload}
          handleAdvanceModal={this.handleAdvanceSimunationModal}
          dropdown={mobileActionButtons}
        />
      );
    } else {
      header = <HeaderComponent />;
    }

    return (
      <Container>
        {errorModal}
        {advanceSimunationModal}
        <Grid>
          <Grid.Column mobile={16} computer={10} tablet={10}>
            {header}
            <SimunationDetailComponent
              translate={translate}
              location={this.props.location}
              simulationDetails={simulationDetails}
              loadingDetails={loadingDetails}
            />
          </Grid.Column>
          <Grid.Column only="mobile" mobile={16}>
            <div className="bottomArea">
              <Button className="goBackButton" onClick={() => this.props.history.push(ROUTES.HOME)}>
                {translate("Common:back").toLowerCase()}
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column className="rightColumn" only="tablet computer" computer={6} tablet={6}>
            {actionButtons}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSimulationDetails: (data) => dispatch(getSimulationDetails(data)),
  advanceSimunationAction: (simunationId) => dispatch(advanceSimunationAction(simunationId)),
  resetAdvancedSimunationData: () => dispatch(resetAdvancedSimunationData()),
});

const mapStateToProps = (state) => {
  return {
    simunation: {
      simulationDetails: state.simunation.simulationDetails,
      loadingDetails: state.simunation.loadingDetails,
      getSimulationDetailsError: state.simunation.getSimulationDetailsError,
    },
    advanceSimunationData: {
      advanceSimunationSuccess: state.simunation.advanceSimunationSuccess,
      loadingAdvanceSimunation: state.simunation.loadingAdvanceSimunation,
      advanceSimunationErrorMessage: state.simunation.advanceSimunationErrorMessage,
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("SimunationDetailScreen", "AdvanceSimulationModal", "Common")(withRouter(SimunationDetailScreen))
);
