import React from "react";
import { Grid, Image, List } from "semantic-ui-react";

const ProductOption = (props) => {
  const onSelect = typeof props.onSelect === "function" ? () => props.onSelect(props.id) : undefined;

  const willDisplayIcon = typeof props.showBack === "boolean" && props.showBack;
  const icon = willDisplayIcon ? <List.Icon name="arrow left" verticalAlign="middle" size="big" /> : undefined;

  return (
    <List.Item className="product-item__faq-products" as={Grid.Column} onClick={onSelect}>
      {icon}
      <div className="product-avatar__faq_products" style={{ backgroundColor: props.backgroundColor }}>
        <Image className="productAvatar" size="tiny" src={props.image}></Image>
      </div>
      <List.Content>
        <List.Header>{props.title}</List.Header>
      </List.Content>
    </List.Item>
  );
};

export default ProductOption;
