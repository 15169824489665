import React from "react";
import { Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import "./helpers.css";

const MessagesComponent = (props) => {
  const translate = props.t;
  return (
    <div className="centerContainer">
      <div className="loadingInformation">
        <Message
          warning={props.warning}
          positive={props.messageType}
          info={props.info}
          negative={!props.messageType && !props.info && !props.warning}>
          <Message.Header>{translate(props.message)}</Message.Header>
        </Message>
      </div>
    </div>
  );
};

export default withTranslation("helpers")(MessagesComponent);
