import * as actionTypes from "./action-types";

export const initialState = {
  simulationDetailsRequestStatus: {
    loading: false,
    errorMessage: "",
    detailsValid: false,
  },
  simulationDetails: {
    id: "",
    name: "",
    status: "",
    paymentSummaryId: "",
    professorPaid: false
  },
  advanceSimulationRequestStatus: {
    loading: false,
    errorMessage: "",
    success: false,
    failedTeams: null,
  },
  registeredStudents: {
    loading: false,
    errorMessage: "",
    success: null,
    registeredStudents: null,
  },
  standings: {
    loading: false,
    errorMessage: "",
    success: null,
    teamMembers: null,
    simulationStandings: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_SIMULATION_DETAILS_START:
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: true, errorMessage: "", detailsValid: false },
      };
    case actionTypes.GET_SIMULATION_DETAILS_FAIL:
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: false, errorMessage: action.payload, detailsValid: false },
      };
    case actionTypes.GET_SIMULATION_DETAILS_SUCCESS:
      const { id, name, status, currentSimulationDay, paymentSummaryId, professorPaid } = action.payload;
      return {
        ...state,
        simulationDetailsRequestStatus: { loading: false, errorMessage: "", detailsValid: true },
        simulationDetails: { id, name, status, currentSimulationDay, paymentSummaryId, professorPaid },
      };
    case actionTypes.ADVANCE_SIMULATION_START:
      return {
        ...state,
        advanceSimulationRequestStatus: { loading: true, errorMessage: "", success: false },
      };
    case actionTypes.ADVANCE_SIMULATION_FAIL:
      return {
        ...state,
        advanceSimulationRequestStatus: { 
          loading: false, 
          errorMessage: action.payload.message, 
          success: false,
          failedTeams: action.payload.failedTeams,
        },
      };
    case actionTypes.ADVANCE_SIMULATION_SUCCESS:
      return {
        ...state,
        advanceSimulationRequestStatus: { loading: false, errorMessage: "", success: true },
      };
    case actionTypes.RESET_REQUESTS_STATE:
      const { advanceSimulationRequestStatus } = initialState;
      return {
        ...state,
        advanceSimulationRequestStatus,
      };
    case actionTypes.GET_REGISTERED_STUDENTS_START:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: true,
          errorMessage: "",
          success: null,
        },
      };
    case actionTypes.GET_REGISTERED_STUDENTS_SUCCESS:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: false,
          success: true,
          registeredStudents: action.payload.registeredStudents,
        },
      };
    case actionTypes.GET_REGISTERED_STUDENTS_FAIL:
      return {
        ...state,
        registeredStudents: {
          ...state.registeredStudents,
          loading: false,
          errorMessage: action.payload,
          success: false,
        },
      };
    case actionTypes.GET_SIMULATION_STANDINGS_START:
      return {
        ...state,
        standings: {
          loading: true,
          errorMessage: "",
          success: null,
          teamMembers: null,
          simulationStandings: null,
        },
      };
    case actionTypes.GET_SIMULATION_STANDINGS_SUCCESS:
      return {
        ...state,
        standings: {
          loading: false,
          errorMessage: "",
          success: true,
          teamMembers: action.payload.teamMembers,
          simulationStandings: action.payload.standings,
        },
      };
    case actionTypes.GET_SIMULATION_STANDINGS_FAIL:
      return {
        ...state,
        standings: {
          loading: false,
          errorMessage: action.payload,
          success: false,
          teamMembers: null,
          simulationStandings: null,
        },
      };
    default:
      return state;
  }
}