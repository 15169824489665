import React from "react";
import { Button, Modal, Grid } from "semantic-ui-react";
import MessagesComponent from "../helpers/MessagesComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import "./modals.css";

const AdvanceSimunationModal = (props) => {
  const translate = props.translate;
  const advanceSimunationData = props.advanceSimunationData;
  let oncloseFunction = props.handleAdvanceSimunationModal;

  const advanceSimunation = () => {
    const postData = {
      simulationName: props.simulation.name,
    };
    props.advanceSimunationAction(postData);
  };

  const closeAndRefreshSimulation = () => {
    props.handleAdvanceSimunationModal();
    props.getSimulationDetails(props.simulation.name);
  };

  let showComponent = null;
  const loadingAdvance = props.advanceSimunationData.loadingAdvanceSimunation;
  let messageToShow = "";
  if (loadingAdvance) {
    showComponent = <LoadingComponent />;
  } else {
    if (advanceSimunationData.advanceSimunationSuccess === true) {
      messageToShow = "AdvanceSimulationModal:Simulation advanced successfully.";
    } else if (advanceSimunationData.advanceSimunationSuccess === false) {
      messageToShow = `AdvanceSimulationModal:${advanceSimunationData.advanceSimunationErrorMessage}`;
    }
    if (messageToShow !== "") {
      const translatedMessage = translate(messageToShow);
      showComponent = (
        <div className="messageSeparator">
          <MessagesComponent message={translatedMessage} messageType={advanceSimunationData.advanceSimunationSuccess} />
        </div>
      );
    }
  }

  let mostAdvancedTeamYear = null;
  if (props.simulation) {
    mostAdvancedTeamYear = props.simulation.mostAdvancedTeamYear;
  }

  let controlButtons = null;
  if (advanceSimunationData.advanceSimunationSuccess === true) {
    oncloseFunction = closeAndRefreshSimulation;
    controlButtons = (
      <Modal.Actions>
        <Button content={"Ok"} onClick={() => closeAndRefreshSimulation()} />
      </Modal.Actions>
    );
  } else {
    controlButtons = (
      <Modal.Actions>
        <Button
          disabled={loadingAdvance}
          content={translate("AdvanceSimulationModal:advance")}
          onClick={() => advanceSimunation()}
        />
        <Button content={translate("Common:cancel")} onClick={() => props.handleAdvanceSimunationModal()} />
      </Modal.Actions>
    );
  }

  return (
    <div>
      <Modal size="tiny" open={props.openAdvanceSimunationModal} onClose={oncloseFunction}>
        <Modal.Header>{translate("Common:advanceSimulation")}</Modal.Header>
        <Modal.Content>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={5}>
                <div className="columnContent">
                  <p className="columnTitle">{translate("AdvanceSimulationModal:currentYear")}</p>
                  <p className="columnText">{mostAdvancedTeamYear}</p>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <div className="columnContent">
                  <p className="columnTitle">{translate("AdvanceSimulationModal:yearsToAdvance")}</p>
                  <p className="columnText">{5}</p>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <div className="columnContent">
                  <p className="columnTitle">{translate("AdvanceSimulationModal:resultingYear")}</p>
                  <p className="columnText">{mostAdvancedTeamYear + 5}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {showComponent}
        </Modal.Content>
        {controlButtons}
      </Modal>
    </div>
  );
};

export default AdvanceSimunationModal;
