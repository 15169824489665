export const CRISIS_GET_SIMULATION_DETAILS_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_DETAILS_START";
export const CRISIS_GET_SIMULATION_DETAILS_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_DETAILS_FAILED";
export const CRISIS_GET_SIMULATION_DETAILS_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_DETAILS_SUCCESS";
export const CRISIS_ADVANCE_SIMULATION_START = "CRISIS_SIMULATION_DETAILS/CRISIS_ADVANCE_SIMULATION_START";
export const CRISIS_ADVANCE_SIMULATION_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_ADVANCE_SIMULATION_FAILED";
export const CRISIS_ADVANCE_SIMULATION_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_ADVANCE_SIMULATION_SUCCESS";
export const CRISIS_START_SIMULATION_START = "CRISIS_SIMULATION_DETAILS/CRISIS_START_SIMULATION_START";
export const CRISIS_START_SIMULATION_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_START_SIMULATION_FAILED";
export const CRISIS_START_SIMULATION_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_START_SIMULATION_SUCCESS";
export const CRISIS_RESET_REQUESTS_STATE = "CRISIS_SIMULATION_DETAILS/CRISIS_RESET_REQUESTS_STATE";
export const CRISIS_GET_PERFORMANCE_DATA_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PERFORMANCE_DATA_START";
export const CRISIS_GET_PERFORMANCE_DATA_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PERFORMANCE_DATA_FAILED";
export const CRISIS_GET_PERFORMANCE_DATA_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PERFORMANCE_DATA_SUCCESS";
export const CRISIS_GET_SIMULATION_STANDINGS_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_STANDINGS_START";
export const CRISIS_GET_SIMULATION_STANDINGS_SUCCESS =
  "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_STANDINGS_SUCCESS";
export const CRISIS_GET_SIMULATION_STANDINGS_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_SIMULATION_STANDINGS_FAIL";
export const CRISIS_GET_REGISTERED_STUDENTS_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_REGISTERED_STUDENTS_START";
export const CRISIS_GET_REGISTERED_STUDENTS_SUCCESS =
  "CRISIS_SIMULATION_DETAILS/CRISIS_GET_REGISTERED_STUDENTS_SUCCESS";
export const CRISIS_GET_REGISTERED_STUDENTS_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_REGISTERED_STUDENTS_FAIL";
export const CRISIS_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS =
  "CRISIS_SIMULATION_DETAILS/CRISIS_GET_NUMBER_REGISTERED_STUDENTS_SUCCESS";
export const CRISIS_GET_NUMBER_REGISTERED_STUDENTS_FAIL =
  "CRISIS_SIMULATION_DETAILS/CRISIS_GET_NUMBER_REGISTERED_STUDENTS_FAIL";
export const CRISIS_CREATE_NEXT_RUN_START = "CRISIS_SIMULATION_DETAILS/CRISIS_CREATE_NEXT_RUN_START";
export const CRISIS_CREATE_NEXT_RUN_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_CREATE_NEXT_RUN_SUCCESS";
export const CRISIS_CREATE_NEXT_RUN_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_CREATE_NEXT_RUN_FAIL";
export const CRISIS_CREATE_NEXT_RUN_CLEAN = "CRISIS_SIMULATION_DETAILS/CRISIS_CREATE_NEXT_RUN_CLEAN";

export const CRISIS_GET_PROFESSOR_SCENARIOS_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PROFESSOR_SCENARIOS_START";
export const CRISIS_GET_PROFESSOR_SCENARIOS_SUCCESS =
  "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PROFESSOR_SCENARIOS_SUCCESS";
export const CRISIS_GET_PROFESSOR_SCENARIOS_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_PROFESSOR_SCENARIOS_FAIL";

export const CRISIS_MODIFY_SIMULATION_START = "CRISIS_SIMULATION_DETAILS/CRISIS_MODIFY_SIMULATION_START";
export const CRISIS_MODIFY_SIMULATION_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_MODIFY_SIMULATION_SUCCESS";
export const CRISIS_MODIFY_SIMULATION_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_MODIFY_SIMULATION_FAIL";
export const CRISIS_MODIFY_SIMULATION_RESET = "CRISIS_SIMULATION_DETAILS/CRISIS_MODIFY_SIMULATION_RESET";

export const CRISIS_GET_DOCUMENTATION_START = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_DOCUMENTATION_START";
export const CRISIS_GET_DOCUMENTATION_SUCCESS = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_DOCUMENTATION_SUCCESS";
export const CRISIS_GET_DOCUMENTATION_FAIL = "CRISIS_SIMULATION_DETAILS/CRISIS_GET_DOCUMENTATION_FAIL";
