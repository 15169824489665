import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import DashBoardsComponent from "./DashBoardsComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import { getPerformanceData, getStandings, getDecisionsData } from "../../store/Medica/actions";
import "./dashBoardsStyles.css";
import * as Sentry from "@sentry/react";
import { getUrlSearchParam } from "../../services/NavigationService";
import { Message } from "semantic-ui-react";

class DashBoardsScreen extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  componentDidMount() {
    const { requestedSimulationName } = this.state;
    const transaction = Sentry.startTransaction({
      name: "/Dashboard",
      op: "showDashboard",
    });

    const spanCheckIfPerformanceDataIsCached = transaction.startChild({
      op: "checkIfPerformanceDataIsCached",
      description: "Check if performance Data is cached",
    });

    const performanceDataIsCached = this.checkIfPerformanceDataIsCached();

    spanCheckIfPerformanceDataIsCached.finish();

    if (!performanceDataIsCached) {
      const spanGetPerformanceData = transaction.startChild({
        op: "getPerformanceData",
        description: "Get Performance Data",
      });

      this.props.getPerformanceData(requestedSimulationName);

      spanGetPerformanceData.finish();

      const spanGetStandings = transaction.startChild({
        op: "getStandings",
        description: "Get standings",
      });

      this.props.getStandings(requestedSimulationName);

      spanGetStandings.finish();

      const spanGetDecisionsData = transaction.startChild({
        op: "getDecisionsData",
        description: "Get decisions data",
      });

      this.getDecisionsData();

      spanGetDecisionsData.finish();
    }

    transaction.finish();
  }

  getPerformanceData = () => {
    const { requestedSimulationName } = this.state;
    this.props.getPerformanceData(requestedSimulationName);
    this.props.getStandings(requestedSimulationName);
    this.getDecisionsData();
  };

  getDecisionsData = () => {
    const { requestedSimulationName } = this.state;
    this.props.getDecisionsData(requestedSimulationName);
  };

  checkIfPerformanceDataIsCached = () => {
    const requestedSimulationName = getUrlSearchParam();
    const { teamsPerformance, simulationDetails } = this.props;
    const { performanceData } = teamsPerformance;
    if (requestedSimulationName !== performanceData.name) {
      return false;
    } else {
      if (simulationDetails.currentSimulationDay !== performanceData.currentSimulationDay) {
        return false;
      }
    }
    return true;
  };

  render() {
    const { t, teamsPerformance, history, location, standings, teamsDecisions } = this.props;
    const translate = t;
    const { loadingPerformanceData, performanceData, performanceDataError } = teamsPerformance;
    const { simulationStandings, loadingStandings, getStandingsError } = standings;
    const { loadingDecisionsData, decisionsDataError, decisionsData } = teamsDecisions;
    const showLoadingComponent = loadingPerformanceData || loadingStandings;
    const showMessageComponent = performanceDataError !== "" || getStandingsError !== "";
    const showNoDashboardContainer = showLoadingComponent || showMessageComponent;
    const showDashboardComponent =
      simulationStandings.payload && Object.keys(performanceData).length > 0 && simulationStandings.payload.length > 0;
    let noDashboardContainer = null;
    let dashBoardsComponent = null;
    let loader = null;
    let messageComponent = null;

    if (showLoadingComponent) {
      loader = <LoadingComponent message={translate("loadingTeamData")} />;
    } else {
      if (showMessageComponent) {
        let errorMessage = this.props.t(performanceDataError, {
          ns: "DashBoardsScreen",
        });
        if (this.props.i18n.exists(getStandingsError, { ns: "StandingsScreen" })) {
          errorMessage = translate(getStandingsError, { ns: "StandingsScreen" });
        }
        messageComponent = (
          <Message negative>
            <Message.Header>{errorMessage}</Message.Header>
          </Message>
        );
      } else {
        if (showDashboardComponent) {
          dashBoardsComponent = (
            <DashBoardsComponent
              translate={translate}
              performanceData={performanceData}
              getPerformanceData={this.getPerformanceData}
              history={history}
              location={location}
              standings={simulationStandings.payload}
              decisionsData={decisionsData}
              decisionsDataError={decisionsDataError}
              loadingDecisionsData={loadingDecisionsData}
              getDecisionsData={this.getDecisionsData}
            />
          );
        }
      }
    }

    if (showNoDashboardContainer) {
      noDashboardContainer = (
        <div className="noDashboardContainer">
          <HeaderComponent translate={translate} showBackButton={true} />
          <MainTitleComponent title={translate("Common:dashboards")} />
          {loader}
          {messageComponent}
        </div>
      );
    }

    return (
      <>
        {dashBoardsComponent}
        {noDashboardContainer}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPerformanceData: (simulationName) => dispatch(getPerformanceData(simulationName)),
  getStandings: (simulationName) => dispatch(getStandings(simulationName)),
  getDecisionsData: (simulationName) => dispatch(getDecisionsData(simulationName)),
});

const mapStateToProps = (state) => {
  return {
    teamsPerformance: {
      performanceData: state.medica.simulationPerformanceData,
      loadingPerformanceData: state.medica.loadingPerformanceData,
      performanceDataError: state.medica.simulationPerformanceDataError,
    },
    teamsDecisions: {
      decisionsData: state.medica.simulationDecisionsData,
      loadingDecisionsData: state.medica.loadingDecisionsData,
      decisionsDataError: state.medica.simulationDecisionsDataError,
    },
    standings: {
      simulationStandings: state.medica.simulationStandings,
      loadingStandings: state.medica.loadingStandings,
      getStandingsError: state.medica.getStandingsError,
    },
    simulationDetails: state.medica.simulationDetails.data,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation("DashBoardsScreen", "StandingsScreen", "Common"),
  withRouter
)(DashBoardsScreen);
