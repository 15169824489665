export const CHECK_AUTH = "/";
export const CALLBACK = "/callback";
export const LOADING = "/Loading";
export const LOGOUT = "/Logout";
export const HOME = "/Home";
export const CATALOGUE = "/Catalogue";
export const MEDICA_DETAIL = "/MedicaDetail";
export const PURCHASED_MEDICA_DETAIL = "/PurchasedMedicaDetail";
export const SIMUNATION_DETAIL = "/SimunationDetail";
export const PURCHASED_SIMUNATION_DETAIL = "/PurchasedSimunationDetail";
export const MEDICA_REGISTERED_STUDENTS = "/MedicaRegisteredStudents";
export const MEDICA_STANDINGS = "/MedicaStandings";
export const MEDICA_STANDINGS_PUBLIC = "/MedicaLeaderboard";
export const MEDICA_BAD_LINK = "/MedicaBadLink";
export const PUSH_NOTIFICATIONS = "/Push_Notifications";
export const PURCHASED_MEDICA = "/PurchasedMedica";
export const MEDICA_BUY_QUESTIONNAIRE = "/MedicaBuy";
export const PAYMENT_MEDICA = "/PaymentMedica";
export const PAYMENT_MEDICA_STUDENT = "/PaymentStudentMedica";
export const CHARGE = "/Charge";
export const RUN_AGAIN = "/RunAgain";
export const STUDENTS_LOGIN = "/StudentsLogin";
export const STUDENT_PAYMENT = "/StudentPayment";
export const STUDENT_CREATE_TEAM = "/StudentCreateTeam";
export const STUDENT_JOIN_TEAM = "/StudentJoinTeam";
export const DOWNLOAD_APP = "/DownloadApp";
export const SIMUNATION_BUY_QUESTIONNAIRE = "/SimunationBuy";
export const SIMUNATION_PAYMENT = "/PaymentSimunation";
export const PURCHASED_SIMUNATION = "/PurchasedSimunation";
export const SIMUNATION_STANDINGS = "/SimunationStandings";
export const SIMUNATION_REGISTERED_STUDENTS = "/SimunationRegisteredStudents";
export const MEDICA_DASHBOARDS = "/MedicaDashBoards";
export const MEDICA_DETAILS_DECISION_HISTORY = "/MedicaDecisionsHistory";
export const TUTORIALS = "/Videos";
export const PROFILE = "/Profile";
export const FAQ = "/FAQ";
export const STUDENT_FAQ = "/StudentFAQ";
export const SCENARIO_MANAGER = "/ScenarioManager";
export const CREATE_NEW_SCENARIO = "/CreateNewScenario";
export const CRISIS_BUY_QUESTIONNAIRE = "/CrisisBuy";
export const CRISIS_PAYMENT = "/CrisisPayment";
export const CRISIS_STUDENT_PAYMENT = "/CrisisStudentPayment";
export const CRISIS_CREATE_SIMULATION = "/PurchasedCrisis";
export const CRISIS_JOIN_SIMULATION_PREAMBLE = "/CrisisJoinSimulationPreamble";
export const CRISIS_JOIN_SIMULATION = "/CrisisJoinSimulation";
export const CRISIS_SIMULATION_DETAILS = "/CrisisSimulationDetails";
export const CRISIS_SIMULATION_DASHBOARDS = "/CrisisSimulationDashboards";
export const CRISIS_STANDINGS = "/CrisisStandings";
export const CRISIS_REGISTERED_STUDENTS = "/RegisteredStudents";
export const CRISIS_CREATE_NEXT_RUN = "/CrisisCreateNextRun";
export const CRISIS_BAD_LINK = "/CrisisBadLink";

export const SUPPLY_CHAIN_BUY_QUESTIONNAIRE = "/SupplyChainBuy";
export const SUPPLY_CHAIN_CREATE_SIMULATION = "/PurchasedSupplyChain";
export const SUPPLY_CHAIN_JOIN_SIMULATION_PREAMBLE = "/SupplyChainJoinSimulationPreamble";
export const SUPPLY_CHAIN_JOIN_SIMULATION = "/SupplyChainJoinSimulation";
export const SUPPLY_CHAIN_PAYMENT = "/SupplyChainPayment";
export const SUPPLY_CHAIN_STUDENT_PAYMENT = "/SupplyChainStudentPayment";
export const SUPPLY_CHAIN_SIMULATION_DETAILS = "/SupplyChainSimulationDetails";
export const SUPPLY_CHAIN_REGISTERED_STUDENTS = "/SupplyChainRegisteredStudents";
export const SUPPLY_CHAIN_STANDINGS = "/SupplyChainStandings";
export const SUPPLY_CHAIN_SIMULATION_DASHBOARDS = "/SupplyChainSimulationDashboards";
export const SUPPLY_CHAIN_CREATE_NEXT_RUN = "/SupplyChainCreateNextRun";

export const ABOGADOS_BUY_QUESTIONNAIRE = "/AbogadosBuy";
export const ABOGADOS_CREATE_SIMULATION = "/PurchasedAbogados";
export const ABOGADOS_PAYMENT = "/AbogadosPayment";
export const ABOGADOS_SIMULATION_DETAILS = "/AbogadosSimulationDetails";
export const ABOGADOS_STANDINGS = "/AbogadosStandings";
export const ABOGADOS_REGISTERED_STUDENTS = "/AbogadosRegisteredStudents";
export const CREAMBOW_BUY_QUESTIONNAIRE = "/CreambowBuy";
export const CREAMBOW_CREATE_SIMULATION = "/PurchasedCreambow";
export const CREAMBOW_PAYMENT = "/CreambowPayment";
export const CREAMBOW_SIMULATION_DETAILS = "/CreambowSimulationDetails";
export const CREAMBOW_STANDINGS = "/CreambowStandings";
export const CREAMBOW_REGISTERED_STUDENTS = "/CreambowRegisteredStudents";
