import { generateDiscountCodeStatus } from "../../utilities/discountCodeUtils";
import { confirmPaymentStatuses, discountCodeStatuses } from "../../utilities/storeStatusDictionary";
import * as actions from "./action-types";

export const initialState = {
  numberOfStudents: 0,
  isInstitution: true,
  knowsDateSelected: true,
  advanceSimunation: true,
  simulationName: "",
  paymentId: "",
  purchasedSimulationName: "",
  loadingCreateSimulation: null,
  simulationCreatedData: null,
  createdSimulationMessage: "simulationAddedSuccessfully",
  createSimulationErrorMessage: "",
  discountCode: {
    status: discountCodeStatuses.initial,
    discountCode: "",
    discountCodeMessage: "",
    discountedPrice: null,
  },
  confirmPayment: {
    status: confirmPaymentStatuses.initial,
    errorMessage: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.CHECK_STORAGE_INFORMATION: {
      const { discountCode, discountedPrice } = action.payload.discountCode || initialState.discountCode;

      return {
        ...state,
        ...action.payload,
        discountCode: {
          ...state.discountCode,
          discountCode: discountCode,
          discountedPrice: discountedPrice,
          status: generateDiscountCodeStatus(discountCode, discountedPrice),
        },
      };
    }
    case actions.UPDATE_INSTITUTION_STUDENTS_NUMBER:
      return {
        ...state,
        ...action.payload,
      };
    case actions.UPDATE_SIMULATION_INFORMATION:
      return {
        ...state,
        ...action.payload,
      };
    case actions.UPDATE_TRANSACTION_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actions.RESET_QUESTIONNAIRE_STORE:
      return {
        ...initialState,
      };
    case actions.SIMUNATION_CREATE_SIMULATION:
      return {
        ...initialState,
        loadingCreateSimulation: true,
        createSimulationErrorMessage: "",
        activationCode: "",
      };
    case actions.SIMUNATION_CREATE_SIMULATION_SUCCESSFUL:
      return {
        ...initialState,
        loadingCreateSimulation: false,
        simulationCreatedData: action.payload,
      };
    case actions.SIMUNATION_CREATE_SIMULATION_FAILED:
      return {
        ...initialState,
        loadingCreateSimulation: false,
        createSimulationErrorMessage: action.payload,
      };
    case actions.CHECK_STORAGE_CREATED_SIMUNATION:
      return {
        ...initialState,
        simulationCreatedData: action.payload,
      };
    case actions.VALIDATE_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: {
          status: discountCodeStatuses.loading,
          discountCode: "",
          discountCodeMessage: "",
          discountedPrice: null,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_SUCCESSFUL: {
      const { discountCode, discountedPrice } = action.payload;
      return {
        ...state,
        discountCode: {
          status: generateDiscountCodeStatus(discountCode, discountedPrice),
          discountCode: discountCode,
          discountCodeMessage: "",
          discountedPrice: discountedPrice,
        },
      };
    }
    case actions.VALIDATE_DISCOUNT_CODE_FAILED:
      return {
        ...state,
        discountCode: {
          ...initialState.discountCode,
          discountCodeMessage: action.payload.discountCodeMessage,
        },
      };
    case actions.CONFIRM_PAYMENT_START: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.loading,
          errorMessage: "",
        },
        payment: {
          paymentSummaryId: null,
        },
      };
    }
    case actions.CONFIRM_PAYMENT_FAIL: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.fail,
          errorMessage: action.payload,
        },
        payment: {
          paymentSummaryId: null,
        },
      };
    }
    case actions.CONFIRM_PAYMENT_SUCCESS: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.success,
          errorMessage: "",
        },
        paymentId: action.payload.paymentId,
      };
    }
    case actions.RESET_DISCOUNT_CODE: {
      return {
        ...state,
        discountCode: { ...initialState.discountCode },
      };
    }
    default:
      return state;
  }
}
