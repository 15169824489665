import React from "react";
import { Modal } from "semantic-ui-react";
import { Line } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { getModalPlotDatasets } from "../utilities/DashBoardUtilities";

const GraphModal = (props) => {
  const { openGraphModal, handleGraphModal, plot, translate, selectedTeams } = props;
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const isPhone = screenHeight > screenWidth;

  const hideToolTipAndCloseModal = () => {
    const tooltipElement = document.getElementById("chartjs-tooltip");
    if (tooltipElement) {
      tooltipElement.style.display = "none";
    }
    handleGraphModal();
  };

  const closeButton = (
    <button onClick={hideToolTipAndCloseModal} className="closeChartModalButton">
      <FontAwesomeIcon size="2x" icon={faWindowClose} />
    </button>
  );

  const plotData = getModalPlotDatasets(plot, selectedTeams, translate);
  const data = {
    labels: plotData.labels,
    datasets: plotData.datasets,
    options: plotData.options,
  };

  const modalSizes = {
    width: screenWidth * 0.9,
    height: screenHeight * 0.9,
  };

  return (
    <Modal
      style={{ width: modalSizes.width, height: modalSizes.height }}
      open={openGraphModal}
      onClose={hideToolTipAndCloseModal}
      closeIcon={closeButton}>
      <Modal.Content>
        <Line data={data} options={data.options} height={isPhone ? 250 : null} />
      </Modal.Content>
    </Modal>
  );
};

export default GraphModal;
