import * as Sentry from "@sentry/react";

export const sentryReportEvent = (message, tag, extras) => {
  Sentry.withScope((scope) => {
    scope.setTag(tag.name, tag.data);
    if (extras) {
      extras.forEach((extra) => {
        scope.setExtra(extra.title, extra.value);
      });
    }
    Sentry.captureMessage(message);
  });
};

export const sentryReportException = (error, tag, extras) => {
  Sentry.withScope((scope) => {
    scope.setTag(tag.name, tag.data);
    if (extras) {
      extras.forEach((extra) => {
        scope.setExtra(extra.title, extra.value);
      });
    }
    Sentry.captureException(error);
  });
};
