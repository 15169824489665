import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DashBoardsComponent from "./DashBoardsComponent";
import "./dashBoardsStyles.css";
import { getUrlSearchParam } from "../../services/NavigationService";

class CrisisDashboards extends Component {
  state = {
    requestedSimulationName: getUrlSearchParam(),
  };

  render() {
    const { history, location } = this.props;

    const translate = this.props.t;
    let dashBoardsComponent = null;

    dashBoardsComponent = <DashBoardsComponent translate={translate} history={history} location={location} />;

    return <>{dashBoardsComponent}</>;
  }
}
export default withTranslation(
  "DashBoardsScreen",
  "CrisisDashboards",
  "StandingsScreen",
  "Common"
)(withRouter(CrisisDashboards));
