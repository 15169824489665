import React from "react";
import { Button } from "semantic-ui-react";
import "./buttonStyles.css";

const NavigationButton = (props) => {
  const goToPage = () => {
    props.history.push(props.route);
  };

  let className = `bottomArea`;
  if (typeof props.className === "string") {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <Button basic onClick={() => (props.route ? goToPage() : props.action())} disabled={props.disabled}>
        {props.label}
      </Button>
    </div>
  );
};

export default NavigationButton;
