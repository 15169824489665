import React from "react";
import { Header } from "semantic-ui-react";
import styles from "./StudentJoinTeamComponent.module.css";

const StudentJoinTeamComponent = (props) => {
  const translate = props.translate;
  return (
    <div>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingInformation}>
          <div className={styles.loader} />
          <Header as="h2" icon textAlign="center">
            <Header.Subheader>{translate("joiningTeam")}</Header.Subheader>
          </Header>
        </div>
      </div>
    </div>
  );
};

export default StudentJoinTeamComponent;
