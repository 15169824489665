import React from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import HelpHoverComponent from "./HelpHoverComponent";
import "./dropDown.css";

const DropDownComponent = (props) => {
  const { onSelection, label, selectError, options, text, placeholder, value, helpMessage, labelAlignment, id } = props;

  let selection = value;
  let descriptionLabel = null;
  let helpHoverComponent = null;

  const onSelectOption = (event, { value }) => {
    selection = value;
    onSelection(value);
  };

  let dropDownContainerClasses = "dropDownContainer";
  if (selectError) {
    dropDownContainerClasses += " errorDropdownIndicator";
  }

  if (helpMessage && helpMessage.length > 0) {
    helpHoverComponent = <HelpHoverComponent helpMessage={helpMessage} />;
  }

  if (label) {
    let labelClassNames = "labelContainer";
    if (labelAlignment === "left") {
      labelClassNames += " leftLabelAlignment";
    }

    descriptionLabel = (
      <div className={labelClassNames}>
        <label className="specialLabel"> {label}</label>
        {helpHoverComponent}
      </div>
    );
  }

  return (
    <Form.Field>
      <div id={id}>
        {descriptionLabel}
        <div className={dropDownContainerClasses}>
          <Dropdown
            className="customDropdown"
            disabled={options.length === 0}
            scrolling
            options={options}
            placeholder={placeholder}
            onChange={(e, { value }) => onSelectOption(e, { value })}
            icon={
              <FontAwesomeIcon
                className="icon"
                icon={faChevronDown}
                size="lg"
                color={selectError ? "#912d2b" : "#0379ba"}
              />
            }
            value={selection}
            text={text}
          />
        </div>
      </div>
    </Form.Field>
  );
};

export default DropDownComponent;
