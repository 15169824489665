import * as API_PATHS from "../../constants/FetchApi.js";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import { sendPostRequest } from "../../services/FetchService.js";
import { removeStorage } from "../../services/StorageService";
import * as actionTypes from "./action-types";

export const supplyChainJoinSimulation = (joinSimulationRequest) => {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_START });
    try {
      const response = await sendPostRequest(API_PATHS.SUPPLY_CHAIN_JOIN_SIMULATION, joinSimulationRequest);
      if (!response.data.outcome) {
        dispatch({ type: actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_FAIL, payload: response.data.message });
        return;
      }
      removeStorage(LOCALSTORAGE.supplyChainJoinSimulationId);
      dispatch({ type: actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_SUCCESS, payload: response.data.payload });
    } catch (err) {
      dispatch({ type: actionTypes.SUPPLY_CHAIN_JOIN_SIMULATION_FAIL, payload: err });
    }
  };
};
