import React from "react";
import * as CONSTANT from "../../constants/UniversalConstants";

const VersionComponent = () => {
  const location = window.location.origin;
  const isDevelop = process.env.NODE_ENV === CONSTANT.ENV_DEVELOPMENT;
  const isLocal = location === CONSTANT.DEV_LOCATION;
  let version = null;
  if (isLocal || isDevelop) {
    version = <div>Version: {CONSTANT.APP_VERSION_NUMBER}</div>;
  }

  return <div className="versionLabel">{version}</div>;
};

export default VersionComponent;
