import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Divider } from "semantic-ui-react";
import { discountCodeStatuses } from "../../utilities/storeStatusDictionary";
import InputComponent from "../helpers/InputComponent";
import MessagesComponent from "../helpers/MessagesComponent";

class DiscountCodeModal extends Component {
  state = {
    discountCode: "",
    retryActivated: false,
    errorEmptyInput: false,
  };

  handleInputChange = (_, data) => {
    this.setState({
      discountCode: data,
      errorEmptyInput: data.length === 0,
    });
  };

  handleClose = (status) => {
    this.setState({
      discountCode: "",
    });
    this.props.handleDiscountModal(status);
  };

  validateDiscountCode = () => {
    const { validateDiscountCode, paymentData } = this.props;
    const { discountCode } = this.state;

    if (discountCode === "") {
      this.setState({
        errorEmptyInput: true,
        errorInputMessage: "writeValidCode",
      });
      return;
    }

    validateDiscountCode({
      code: discountCode,
      numberOfLicenses: paymentData.numberOfLicenses,
    });
  };

  render() {
    const { discountCode, errorEmptyInput } = this.state;
    const { t: translate } = this.props;
    const { status, discountCodeMessage } = this.props.discountCodeStatus;
    let actionButton = null;
    let inputComponent = null;
    let loader = null;
    let messagesComponent = null;

    const displaySuccessMessage = [discountCodeStatuses.activated, discountCodeStatuses.activatedPaid].includes(status);
    const displayErrorMessage = status !== discountCodeStatuses.loading && discountCodeMessage.length > 0;
    if (displaySuccessMessage || displayErrorMessage) {
      const message = displaySuccessMessage ? "discountSuccess" : discountCodeMessage;
      messagesComponent = (
        <>
          <Divider hidden />
          <MessagesComponent messageType={displaySuccessMessage} message={translate(message)} />
        </>
      );
    }

    if (
      [discountCodeStatuses.loading, discountCodeStatuses.activated, discountCodeStatuses.activatedPaid].includes(
        status
      ) === false
    ) {
      inputComponent = (
        <InputComponent
          type="text"
          placeholder={translate("yourCode")}
          handleChange={this.handleInputChange}
          onError={errorEmptyInput}
          value={discountCode}
        />
      );
    }

    if (status === discountCodeStatuses.loading) {
      loader = <div className="loader" />;
    }

    actionButton = (
      <Button
        disabled={status !== discountCodeStatuses.initial}
        content={translate("validate")}
        onClick={this.validateDiscountCode}
      />
    );

    return (
      <Modal size="tiny" open={this.props.open}>
        <Modal.Header>{translate("discountCode")}</Modal.Header>
        <Modal.Content>
          <>
            {loader}
            {inputComponent}
            {messagesComponent}
          </>
        </Modal.Content>
        <Modal.Actions>
          {actionButton}
          <Button
            content={
              [discountCodeStatuses.activated, discountCodeStatuses.activatedPaid].includes(status)
                ? translate("Common:accept")
                : translate("Common:cancel")
            }
            onClick={() => this.handleClose(status)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation(["DiscountCodeModal", "Common"])(DiscountCodeModal);
