import React from "react";
import { Form, Checkbox } from "semantic-ui-react";
import HelpHoverComponent from "./HelpHoverComponent";
import "./input.css";

const InputToggleComponent = (props) => {
  const { id, label, helpMessage, onChangeInputToggle } = props;

  const onChangeToggle = (event) => {
    const name = event.target.id;
    const value = event.target.checked;
    const rangeChanged = {
      [name]: value,
    };
    onChangeInputToggle(rangeChanged);
  };

  let helpHoverComponent = null;

  if (helpMessage && helpMessage.length > 0) {
    helpHoverComponent = <HelpHoverComponent helpMessage={"helpMessage"} />;
  }
  const toggleComponent = (
    <div className="labelContainer">
      <label className="specialLabel">
        {label} {helpHoverComponent}{" "}
      </label>
      <Checkbox id={id} toggle onChange={onChangeToggle} />
    </div>
  );

  return (
    <Form.Field>
      <div className="inputToggleContainer">{toggleComponent}</div>
    </Form.Field>
  );
};
export default InputToggleComponent;
