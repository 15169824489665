import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as ROUTES from "../../constants/Routes";
import * as CONSTANT from "../../constants/UniversalConstants";

const ActionButtons = (props) => {
  const translate = props.translate;
  const requestedSimulation = props.simulationDetails.name;
  const simulationId = props.simulationDetails.id;
  let component = null;
  let actionButtons = [];
  var actionsDictionary = {};

  const navigateTo = (route, searchParam) => {
    props.history.push({
      pathname: route,
      search: searchParam,
    });
  };

  const registeredStudentsAction = () => navigateTo(ROUTES.CRISIS_REGISTERED_STUDENTS, requestedSimulation);
  const standingsAction = () => navigateTo(ROUTES.CRISIS_STANDINGS, requestedSimulation);
  const createNextRunAction = () => navigateTo(ROUTES.CRISIS_CREATE_NEXT_RUN, simulationId);

  actionsDictionary["modifySimulationAction"] = props.handleModifyModal;
  actionsDictionary["registeredStudentsAction"] = registeredStudentsAction;
  actionsDictionary["standingsAction"] = standingsAction;
  actionsDictionary["handleAdvanceModal"] = props.handleAdvanceModal;
  actionsDictionary["handleShareModal"] = props.handleShareModal;
  actionsDictionary["dashboardsAction"] = props.dashboardsAction;
  actionsDictionary["documentationAction"] = props.openDocumentationModal;
  actionsDictionary["createNextRunAction"] = createNextRunAction;
  actionsDictionary["backAction"] = () => props.history.push(ROUTES.HOME);

  if (props.detailsLoaded) {
    actionButtons = buildActionButtons(props.simulationDetails);
  }

  if (props.isMobile) {
    component = (
      <Dropdown className="onlyMobile" trigger={<FontAwesomeIcon color="#494848" icon={faBars} size="2x" />}>
        <Dropdown.Menu direction="left">
          {actionButtons.map((button) => {
            const buttonName = translate(button.name).toUpperCase();
            return (
              <Dropdown.Item key={button.key} className="dropDownItem" onClick={actionsDictionary[button.action]}>
                {buttonName}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    component = (
      <div id="simulationDetailsActionButtons" className="buttonsContainer">
        {actionButtons.map((button) => {
          const buttonName = translate(button.name).toUpperCase();
          return (
            <Button key={button.key} basic className="actionButton" onClick={actionsDictionary[button.action]}>
              {buttonName}
            </Button>
          );
        })}
      </div>
    );
  }
  return component;
};

const buildActionButtons = (details) => {
  var actionButtons = [
    ...crisisButtons.map((value) => {
      return { ...value };
    }),
  ];
  if (!details.professorManagedProgress || details.status === CONSTANT.FINISHED) {
    const buttonIndex = actionButtons.findIndex((element) => element.key === "advanceSimulation");
    actionButtons[buttonIndex].hide = true;
  }
  if (
    (details.status !== CONSTANT.RUNNING && details.status !== CONSTANT.FINISHED) ||
    details.hasANextIteration === true
  ) {
    const nextRunButtonIndex = actionButtons.findIndex((element) => element.key === "createNextRun");
    actionButtons[nextRunButtonIndex].hide = true;
  }

  if (details.professorManagedProgress && details.status === CONSTANT.AWAITING_INITIALIZATION) {
    const advanceButtonIndex = actionButtons.findIndex((element) => element.key === "advanceSimulation");
    actionButtons[advanceButtonIndex].hide = true;
    const startButtonIndex = actionButtons.findIndex((element) => element.key === "startSimulation");
    actionButtons[startButtonIndex].hide = false;
  }

  const showModifyConditions = [CONSTANT.AWAITING_INITIALIZATION];
  if (showModifyConditions.includes(details.status)) {
    const modifyButtonIndex = actionButtons.findIndex((element) => element.key === "modifySimulation");
    actionButtons[modifyButtonIndex].hide = false;
  }

  return actionButtons.filter((button) => !button.hide);
};

const crisisButtons = [
  {
    key: "modifySimulation",
    name: "CrisisModifyModal:ModifySimulation",
    action: "modifySimulationAction",
    hide: true,
  },
  {
    key: "advanceSimulation",
    name: "AdvanceSimulationModal:advanceSimulation",
    action: "handleAdvanceModal",
    hide: false,
  },
  {
    key: "startSimulation",
    name: "AdvanceSimulationModal:startSimulation",
    action: "handleAdvanceModal",
    hide: true,
  },
  {
    key: "standings",
    name: "standings",
    action: "standingsAction",
  },
  {
    key: "registeredStudents",
    name: "registeredStudents",
    action: "registeredStudentsAction",
  },
  {
    key: "shareSimulation",
    name: "shareSimulation",
    action: "handleShareModal",
    hide: false,
  },
  {
    key: "dashboards",
    name: "dashboards",
    action: "dashboardsAction",
    hide: false,
  },
  {
    key: "createNextRun",
    name: "CrisisDetailScreen:createNextRun",
    action: "createNextRunAction",
    hide: false,
  },
  {
    key: "Documentation",
    name: "Common:Documents",
    action: "documentationAction",
    hide: false,
  },
  {
    key: "back",
    name: "Common:back",
    action: "backAction",
    hide: false,
  },
];

export default ActionButtons;
