import * as actions from "./action-types";

export const initialState = {
  loadingTutorials: null,
  tutorials: null,
  tutorialsErrorMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_TUTORIALS:
      return {
        ...state,
        loadingTutorials: true,
        tutorials: null,
        tutorialsErrorMessage: "",
      };
    case actions.GET_TUTORIALS_SUCCESSFULL:
      return {
        ...state,
        loadingTutorials: null,
        tutorialsErrorMessage: "",
        tutorials: action.payload,
      };
    case actions.GET_TUTORIALS_FAILED:
      return {
        ...state,
        tutorials: null,
        loadingTutorials: null,
        tutorialsErrorMessage: action.payload,
      };
    default:
      return state;
  }
}
