import React from "react";

const CustomTableHeader = (props) => {
  const { translate, tableTitle, tableColumnNames } = props;
  let columns = null;

  if (typeof tableColumnNames === "object") {
    columns = tableColumnNames.map((columnName) => (
      <div key={columnName} className="rowValue headerCustomTable">
        {translate(columnName)}
      </div>
    ));
  }
  return (
    <div className="body">
      <div className="sectionName" />
      <div className="sectionConfigurations">
        <div className="row">
          <div className="rowItem headerCustomTable">{tableTitle}</div>
          {columns}
        </div>
      </div>
    </div>
  );
};

export default CustomTableHeader;
