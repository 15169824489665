import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";
import { confirmPaymentStatuses } from "../../utilities/storeStatusDictionary";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";

export default function PaymentVerificationModal(props) {
  const { state, retryAction } = props;
  const { t, i18n } = useTranslation(["PaymentVerificationModal"]);
  let content;
  let actions;

  if (state.status === confirmPaymentStatuses.loading) {
    content = <LoadingComponent />;
  }

  if (state.status === confirmPaymentStatuses.fail) {
    const errorText = i18n.exists(state.errorMessage) ? t(state.errorMessage) : t("defaultErrorMessage");
    content = <MessagesComponent negative message={errorText} />;
    actions =
      typeof retryAction === "function" ? (
        <Modal.Actions>
          <Button onClick={retryAction}>{t("retry")}</Button>
        </Modal.Actions>
      ) : null;
  }

  return (
    <Modal open>
      <Modal.Header>{t("title")}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      {actions}
    </Modal>
  );
}
