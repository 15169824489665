import React, { Component } from "react";
import { connect } from "react-redux";
import { checkIfStudentHasPaid } from "../../store/Medica/actions";
import {
  checkStorageInformation,
  medicaResetDiscountCode,
  updateTransactionData,
  validateDiscountCode,
} from "../../store/MedicaQuestionnaire/actions";
import Payment from "../payment";
import * as ROUTES from "../../constants/Routes";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import * as API_PATHS from "../../constants/FetchApi.js";
import * as CONSTANTS from "../../constants/UniversalConstants";
import { getStorage, getStorageOrDefault, setStorage } from "../../services/StorageService";

class PaymentMedica extends Component {
  getSuccessRoute() {
    const { role } = this.props.user;
    if (role === CONSTANTS.ROLES.PROFESSOR) return ROUTES.PURCHASED_MEDICA;

    const activationCode = getStorage(LOCAL_STORAGE.activationCode);
    if (activationCode) return ROUTES.STUDENT_CREATE_TEAM;

    const teamCode = getStorage(LOCAL_STORAGE.teamCode);
    if (teamCode) return ROUTES.STUDENT_JOIN_TEAM;
  }

  getSimulationId() {
    return getStorageOrDefault(LOCAL_STORAGE.simulationId);
  }

  resetDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const medicaQuestionnaire = getStorage(LOCAL_STORAGE.medicaQuestionnaire);
    delete medicaQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.medicaQuestionnaire, medicaQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const successRoute = this.getSuccessRoute();
    const {
      discountCodeStatus,
      studentPayment,
      studentHasAlreadyPaid,
      questionnaire,
      checkStorageInformation,
      checkIfStudentHasPaid,
      updateTransactionData,
      validateDiscountCode,
    } = this.props;

    const productPrice = process.env.REACT_APP_STRIPE_PRICE;
    const simulationId = this.getSimulationId();

    return (
      <Payment
        simulatorType={"Medica"}
        studentPayment={studentPayment}
        simulationId={simulationId}
        studentHasAlreadyPaid={studentHasAlreadyPaid}
        discountCodeStatus={discountCodeStatus}
        productPrice={productPrice}
        questionnaire={questionnaire}
        checkStorageInformation={checkStorageInformation}
        resetDiscountCode={this.resetDiscountCode}
        checkIfStudentHasPaid={checkIfStudentHasPaid}
        updateTransactionData={updateTransactionData}
        validateDiscountCode={validateDiscountCode}
        paymentIntentAPI={API_PATHS.MEDICA_GET_PAYMENT_INTENT}
        successRoute={successRoute}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkStorageInformation: () => dispatch(checkStorageInformation()),
  checkIfStudentHasPaid: (simulationId) => dispatch(checkIfStudentHasPaid(simulationId)),
  updateTransactionData: (data) => dispatch(updateTransactionData(data)),
  validateDiscountCode: (discountCode) => dispatch(validateDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(medicaResetDiscountCode()),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      language: state.user.userProfile.language,
    },
    studentHasAlreadyPaid: {
      loading: state.medica.checkingIfStudentHasPaid,
      studentHasPaid: state.medica.studentHasPaid,
      errorMessage: state.medica.checkStudentHasPaidError,
    },
    discountCodeStatus: state.medicaQuestionnaire.discountCode,
    questionnaire: {
      numberOfStudents: state.medicaQuestionnaire.numberOfStudents,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMedica);
