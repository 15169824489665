import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as CRISIS_CONSTANTS from "../../constants/CrisisConstants";
import CalendarComponent from "../helpers/CalendarComponent";
import DateTimeSelector from "../helpers/DateTimeSelector";
import * as CONSTANT from "../../constants/UniversalConstants";
import InputComponent from "../helpers/InputComponent";
import DropDownComponent from "../helpers/DropDownComponent";
import { Checkbox } from "semantic-ui-react";

import "./crisisRunAgain.css";

class NextRunFormComponent extends Component {
  state = {
    dateToModify: "",
    openCalendar: false,
  };

  handleFormChange = (id, data) => {
    this.props.formChanged(id, data);
  };

  gameModeOptions = [
    {
      key: 1,
      text: this.props.t(`CrisisCommon:${CRISIS_CONSTANTS.OneStationUnknownPeers}`),
      value: CRISIS_CONSTANTS.OneStationUnknownPeers,
    },
    {
      key: 2,
      text: this.props.t(`CrisisCommon:${CRISIS_CONSTANTS.OneStationKnownPeers}`),
      value: CRISIS_CONSTANTS.OneStationKnownPeers,
    },
    {
      key: 3,
      text: this.props.t(`CrisisCommon:${CRISIS_CONSTANTS.AllStationsKnownPeers}`),
      value: CRISIS_CONSTANTS.AllStationsKnownPeers,
    },
  ];

  advanceSimulationOptions = [
    {
      key: 1,
      text: this.props.t("Common:yes"),
      value: true,
    },
    {
      key: 2,
      text: this.props.t("Common:no"),
      value: false,
    },
  ];

  handleCalendar = (action) => {
    this.setState({
      dateToModify: action,
      openCalendar: !this.state.openCalendar,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    const formValues = this.checkWithDatesSelected();
    this.props.handleContinue(formValues);
  }

  render() {
    const translate = this.props.t;
    let calendarComponent = null;
    const { formData } = this.props;
    let selectedDate = null;

    if (this.state.dateToModify === "startDate") {
      selectedDate = formData.startTime.Value;
    } else {
      selectedDate = formData.endTime.Value;
    }

    if (this.state.openCalendar) {
      calendarComponent = (
        <div className="calendarPosition">
          <CalendarComponent
            translate={translate}
            handleCalendar={this.handleCalendar}
            dateToModify={this.state.dateToModify}
            onDateClick={(value) => this.props.setDate(value, this.state.dateToModify)}
            selectedDate={selectedDate}
            language={this.props.language}
          />
        </div>
      );
    }

    const dateSelectors = formData.professorAdvance.value ? null : (
      <div>
        <DateTimeSelector
          label={translate("ModifyModal:startDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "startDate" })}
          dateToSet={CONSTANT.START_DATE}
          settingHour={this.props.setStartHour}
          date={formData.startTime.value.date}
          hour={formData.startTime.value.hour}
          hourFormat={formData.startTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />

        <DateTimeSelector
          label={translate("ModifyModal:endDate")}
          handleCalendar={this.handleCalendar}
          onFocus={() => this.setState({ dateToModify: "endDate" })}
          dateToSet={CONSTANT.END_DATE}
          settingHour={this.props.setEndHour}
          date={formData.endTime.value.date}
          hour={formData.endTime.value.hour}
          hourFormat={formData.endTime.value.hourFormat}
          language={this.props.language}
          labelAlignment="left"
        />
      </div>
    );

    return (
      <>
        {calendarComponent}
        <InputComponent
          label={translate("Purchased:simulationName")}
          id="simulationName"
          specialLabel={true}
          disabled
          type="text"
          value={this.props.simulationName}
          labelAlignment="left"
        />

        <DropDownComponent
          id="professorAdvance"
          label={translate("Purchased:professorAdvanceSimulation")}
          helpMessage={translate("Purchased:medicaProfessorAdvanceSimulationHelp")}
          options={this.advanceSimulationOptions}
          placeholder={translate("pleaseChooseOne")}
          onSelection={this.handleFormChange.bind(this, "professorAdvance")}
          value={formData.professorAdvance.value}
          labelAlignment="left"
        />
        {dateSelectors}
        <DropDownComponent
          id="gameMode"
          label={translate("CrisisPurchased:GameModeTitle")}
          options={this.gameModeOptions}
          onSelection={this.handleFormChange.bind(this, "gameMode")}
          value={formData.gameMode.value}
          labelAlignment="left"
        />
        <div className="create-next-crisis-simulation__game-mode--help">
          <p>{translate("CrisisCommon:GameModeHelp")}</p>
          <ul className="create-crisis-simulation__game-mode-help-list">
            <li>{translate("CrisisCommon:OneStationUnknownPeersHelp")}</li>
            <li>{translate("CrisisCommon:OneStationKnownPeersHelp")}</li>
            <li>{translate("CrisisCommon:AllStationsKnownPeersHelp")}</li>
          </ul>
        </div>
        <div className="crisis-_teams-stay-the-same__toggle">
          <label className="specialLabel">{translate("scrambleTeamMembers")}</label>
          <Checkbox
            toggle
            checked={!formData.teamsStayTheSame.value}
            onChange={(e, data) => this.handleFormChange("teamsStayTheSame", !data.checked)}
          />
        </div>
      </>
    );
  }
}

export default withTranslation(["CrisisRunAgain", "Purchased", "Common", "CrisisCommon"])(NextRunFormComponent);
