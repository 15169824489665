import { faChevronDown, faChevronRight, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import memoizeOne from "memoize-one";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container, Table } from "semantic-ui-react";
import { getUrlSearchParam } from "../../services/NavigationService";
import { abogadosGetSimulationStandings } from "../../store/AbogadosSimulationDetails/actions";
import HeaderComponent from "../header/HeaderComponent";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import "./abogadosStandings.css";
import { getStorage } from "../../services/StorageService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";

export class abogadosStandings extends PureComponent {
  state = {
    expandedRows: [],
    requestedSimulationName: getUrlSearchParam(),
    column: null,
    direction: null,
  };

  componentDidMount() {
    const requestedSimulationId = getStorage(LOCALSTORAGE.requestedSimulation);
    this.props.abogadosGetSimulationStandings(requestedSimulationId);
  }

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    let newColumn = clickedColumn;
    let newDirection = null;

    if (column !== clickedColumn) {
      newDirection = "asc";
    }

    if (column === clickedColumn && direction === "asc") {
      newDirection = "desc";
    }

    if (column === clickedColumn && direction === "desc") {
      newColumn = null;
      newDirection = null;
    }

    this.setState({
      column: newColumn,
      direction: newDirection,
    });
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItem(item) {
    const translate = this.props.t;
    const clickCallback = () => this.handleRowClick(item.position);
    const itemRows = [
      <Table.Row textAlign="center" onClick={clickCallback} key={"row-data-" + item.position}>
        <Table.Cell collapsing>{this.renderItemCaret(item.position)}</Table.Cell>
        <Table.Cell>{item.position}</Table.Cell>
        <Table.Cell>{item.teamName}</Table.Cell>
        <Table.Cell>$ {item.cash.toFixed(2)}</Table.Cell>
      </Table.Row>,
    ];

    if (this.state.expandedRows.includes(item.position)) {
      itemRows.push(
        <Table.Row key={"row-expanded-" + item.position}>
          <Table.Cell colSpan="10">
            <Table textAlign="center" basic>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell className="team-members-table__header-cell">
                    {translate("Team Member")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="team-members-table__header-cell">{translate("Email")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{item.teamMembers.students.map(this.renderItemDetails.bind(this))}</Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      );
    }

    return itemRows;
  }

  renderItemCaret(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <FontAwesomeIcon fixedWidth={true} className="icon" icon={faChevronDown} size="lg" color={"#0379ba"} />;
    } else {
      return <FontAwesomeIcon fixedWidth={true} className="icon" icon={faChevronRight} size="lg" color={"#0379ba"} />;
    }
  }

  renderItemDetails(item, index) {
    return (
      <Table.Row key={`team_member_${index}`} columns={2}>
        <Table.Cell>
          <span>{item.name}</span>
        </Table.Cell>

        <Table.Cell>
          <span>{item.email}</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  standingsTableData = (teamStandings, teamMembersList) => {
    const standingsTable = [];

    teamStandings.forEach((element) => {
      const teamName = element.teamName;
      const teamMembers = teamMembersList.find((x) => x.teamName === teamName);
      standingsTable.push({
        position: element.position,
        teamName,
        cash: element.cash,
        teamMembers,
      });
    });
    return standingsTable;
  };

  memoizedStandingsTableData = memoizeOne(this.standingsTableData);

  render() {
    const translate = this.props.t;
    const standingsState = this.props.standings;

    let bodyContent;
    let sortedStandingsData;

    if (standingsState.success) {
      const standingsTableData = this.memoizedStandingsTableData(
        standingsState.simulationStandings,
        standingsState.teamMembers
      );

      sortedStandingsData = standingsTableData;
      if (this.state.column && this.state.direction) {
        sortedStandingsData = _.orderBy(standingsTableData, [this.state.column], this.state.direction);
      }
    }

    if (standingsState.success && sortedStandingsData.length > 0) {
      const standingsTableRows = sortedStandingsData.map(this.renderItem.bind(this));
      const arrowsIcon = <FontAwesomeIcon className="tableIcon" icon={faSort} size="lg" color="#FFF" />;

      bodyContent = (
        <Table selectable unstackable sortable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell />
              <Table.HeaderCell onClick={this.handleSort("position")}>
                <>
                  {translate("Position")}
                  {arrowsIcon}
                </>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={this.handleSort("teamName")}>
                <>
                  {translate("Team")}
                  {arrowsIcon}
                </>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={this.handleSort("cash")}>
                <>
                  {translate("Total")}
                  {arrowsIcon}
                </>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{standingsTableRows}</Table.Body>
        </Table>
      );
    }

    if (standingsState.success && sortedStandingsData.length === 0) {
      bodyContent = <MessagesComponent info message={translate("EmptyStandings")} />;
    }

    if (standingsState.loading) {
      bodyContent = <LoadingComponent message={translate("StandingsScreen:loadingStandings")} />;
    }

    if (!standingsState.loading && !standingsState.success) {
      bodyContent = <MessagesComponent message={translate(standingsState.errorMessage)} />;
    }

    return (
      <Container>
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent
          title={translate("StandingsScreen:standings")}
          subtitle={this.state.requestedSimulationName}
          hasHelp
          helpMessage={translate("StandingsScreen:standingsHelpMessage")}
        />
        {bodyContent}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  standings: state.abogadosSimulationDetails.standings,
  simulationId: state.abogadosSimulationDetails.simulationDetails.id,
});

const mapDispatchToProps = (dispatch) => ({
  abogadosGetSimulationStandings: (simulationId) => dispatch(abogadosGetSimulationStandings(simulationId)),
});

const HOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(["CrisisStandings", "StandingsScreen", "Common"]),
  withRouter
);

export default HOC(abogadosStandings);
