export const GET_MEDICA_DETAILS = "Medica/GET_MEDICA_DETAILS";
export const GET_MEDICA_DETAILS_SUCCESSFUL = "Medica/GET_MEDICA_DETAILS_SUCCESSFUL";
export const GET_MEDICA_DETAILS_FAILED = "Medica/GET_MEDICA_DETAILS_FAILED";
export const GET_MEDICA_STANDINGS = "Medica/GET_MEDICA_STANDINGS";
export const GET_MEDICA_STANDINGS_SUCCESSFUL = "Medica/GET_MEDICA_STANDINGS_SUCCESSFUL";
export const GET_MEDICA_STANDINGS_FAILED = "Medica/GET_MEDICA_STANDINGS_FAILED";
export const GET_MEDICA_REGISTERED_STUDENTS = "Medica/GET_MEDICA_REGISTERED_STUDENTS";
export const GET_MEDICA_REGISTERED_STUDENTS_SUCCESSFUL = "Medica/GET_MEDICA_REGISTERED_STUDENTS_SUCCESSFUL";
export const GET_MEDICA_REGISTERED_STUDENTS_FAILED = "Medica/GET_MEDICA_REGISTERED_STUDENTS_FAILED";
export const GET_MEDICA_TEAMS = "Medica/GET_MEDICA_TEAMS";
export const GET_MEDICA_TEAMS_SUCCESSFUL = "Medica/GET_MEDICA_TEAMS_SUCCESSFUL";
export const GET_MEDICA_TEAMS_FAILED = "Medica/GET_MEDICA_TEAMS_FAILED";
export const CREATE_MEDICA_TEAM = "Medica/CREATE_MEDICA_TEAM";
export const CREATE_MEDICA_TEAM_SUCCESSFUL = "Medica/CREATE_MEDICA_TEAM_SUCCESSFUL";
export const CREATE_MEDICA_TEAM_FAILED = "Medica/CREATE_MEDICA_TEAM_FAILED";
export const JOIN_MEDICA_TEAM = "Medica/JOIN_MEDICA_TEAM";
export const JOIN_MEDICA_TEAM_SUCCESSFUL = "Medica/JOIN_MEDICA_TEAM_SUCCESSFUL";
export const JOIN_MEDICA_TEAM_FAILED = "Medica/JOIN_MEDICA_TEAM_FAILED";
export const ADVANCE_MEDICA_SIMULATION = "Medica/ADVANCE_MEDICA_SIMULATION";
export const ADVANCE_MEDICA_SIMULATION_SUCCESSFUL = "Medica/ADVANCE_MEDICA_SIMULATION_SUCCESSFUL";
export const ADVANCE_MEDICA_SIMULATION_FAILED = "Medica/ADVANCE_MEDICA_SIMULATION_FAILED";
export const GET_PROFESSOR_SCENARIOS = "Medica/GET_PROFESSOR_SCENARIOS";
export const GET_PROFESSOR_SCENARIOS_SUCCESSFUL = "Medica/GET_PROFESSOR_SCENARIOS_SUCCESSFUL";
export const GET_PROFESSOR_SCENARIOS_FAILED = "Medica/GET_PROFESSOR_SCENARIOS_FAILED";
export const CHECK_STUDENT_PAYMENT = "Medica/CHECK_STUDENT_PAYMENT";
export const CHECK_STUDENT_PAYMENT_SUCCESSFUL = "Medica/CHECK_STUDENT_PAYMENT_SUCCESSFUL";
export const CHECK_STUDENT_PAYMENT_FAILED = "Medica/CHECK_STUDENT_PAYMENT_FAILED";
export const RESET_ADVANCE_SIMULATION_PROPS = "Medica/RESET_ADVANCE_SIMULATION_PROPS";
export const SEND_PUSH_NOTIFICATION = "Medica/SEND_PUSH_NOTIFICATION";
export const SEND_PUSH_NOTIFICATION_SUCCESSFUL = "Medica/SEND_PUSH_NOTIFICATION_SUCCESSFUL";
export const SEND_PUSH_NOTIFICATION_FAILED = "Medica/SEND_PUSH_NOTIFICATION_FAILED";
export const GET_MEDICA_PERFORMANCE_DATA = "Medica/GET_MEDICA_PERFORMANCE_DATA";
export const GET_MEDICA_PERFORMANCE_DATA_SUCCESSFUL = "Medica/GET_MEDICA_PERFORMANCE_DATA_SUCCESSFUL";
export const GET_MEDICA_PERFORMANCE_DATA_FAILED = "Medica/GET_MEDICA_PERFORMANCE_DATA_FAILED";
export const RESET_PUSH_NOTIFICATION_STATE = "Medica/RESET_PUSH_NOTIFICATION_STATE";
export const RUN_AGAIN = "Medica/RUN_AGAIN";
export const RUN_AGAIN_SUCCESSFUL = "Medica/RUN_AGAIN_SUCCESSFUL";
export const RUN_AGAIN_FAILED = "Medica/RUN_AGAIN_FAILED";
export const GET_MEDICA_DOCUMENTATION = "Medica/GET_MEDICA_DOCUMENTATION";
export const GET_MEDICA_DOCUMENTATION_SUCCESSFUL = "Medica/GET_MEDICA_DOCUMENTATION_SUCCESSFUL";
export const GET_MEDICA_DOCUMENTATION_FAILED = "Medica/GET_MEDICA_DOCUMENTATION_FAILED";
export const GET_MEDICA_DECISIONS_DATA = "Medica/GET_MEDICA_DECISIONS_DATA";
export const GET_MEDICA_DECISIONS_DATA_SUCCESSFUL = "Medica/GET_MEDICA_DECISIONS_DATA_SUCCESSFUL";
export const GET_MEDICA_DECISIONS_DATA_FAILED = "Medica/GET_MEDICA_DECISIONS_DATA_FAILED";
export const RESET_CREATE_TEAM_STORE = "Medica/RESET_CREATE_TEAM_STORE";
export const RESET_RUN_AGAIN_STORE = "Medica/RESET_RUN_AGAIN_STORE";
export const MEDICA_GET_DECISION_HISTORY_START = "Medica/MEDICA_GET_DECISION_HISTORY_START";
export const MEDICA_GET_DECISION_HISTORY_FAIL = "Medica/MEDICA_GET_DECISION_HISTORY_FAIL";
export const MEDICA_GET_DECISION_HISTORY_SUCCESS = "Medica/MEDICA_GET_DECISION_HISTORY_SUCCESS";

export const GET_MEDICA_SUGGESTIONS = "Medica/GET_MEDICA_SUGGESTIONS";
export const GET_MEDICA_SUGGESTIONS_SUCCESSFUL = "Medica/GET_MEDICA_SUGGESTIONS_SUCCESSFUL";
export const GET_MEDICA_SUGGESTIONS_FAILED = "Medica/GET_MEDICA_SUGGESTIONS_FAILED";
