import React, { Component } from "react";
import { connect } from "react-redux";
import Payment from "../payment";
import * as ROUTES from "../../constants/Routes";
import * as LOCAL_STORAGE from "../../constants/LocalStorage";
import * as API_PATHS from "../../constants/FetchApi.js";
import {
  checkIfStudentHasPaid,
  restoreSupplyChainPurchaseInformationFromStorage,
  supplyChainResetDiscountCode,
  supplyChainValidateDiscountCode,
  updateTransactionSupplyChainData,
} from "../../store/SupplyChainBuySimulation/actions";
import { getStorage, getStorageOrDefault, setStorage } from "../../services/StorageService";

class PaymentSupplyChain extends Component {
  getSuccessRoute(studentPayment) {
    if (studentPayment) return ROUTES.SUPPLY_CHAIN_JOIN_SIMULATION;
    return ROUTES.SUPPLY_CHAIN_CREATE_SIMULATION;
  }

  getSimulationId() {
    return getStorageOrDefault(LOCAL_STORAGE.supplyChainJoinSimulationId);
  }

  resetDiscountCode = () => {
    const { resetDiscountCode } = this.props;
    const supplychainQuestionnaire = getStorage(LOCAL_STORAGE.supplychainQuestionnaire);
    delete supplychainQuestionnaire.discountCode;
    setStorage(LOCAL_STORAGE.supplychainQuestionnaire, supplychainQuestionnaire);
    resetDiscountCode();
  };

  render() {
    const {
      discountCodeStatus,
      studentPayment,
      studentHasAlreadyPaid,
      questionnaire,
      checkStorageInformation,
      checkIfStudentHasPaid,
      updateTransactionData,
      validateDiscountCode,
    } = this.props;

    const successRoute = this.getSuccessRoute(studentPayment);
    const paymentIntentAPI = API_PATHS.SUPPLY_CHAIN_GET_PAYMENT_INTENT;
    const productPrice = process.env.REACT_APP_SUPPLY_CHAIN_PRICE;
    const simulationId = this.getSimulationId();

    return (
      <Payment
        simulatorType={"Supply Grain"}
        studentPayment={studentPayment}
        simulationId={simulationId}
        studentHasAlreadyPaid={studentHasAlreadyPaid}
        discountCodeStatus={discountCodeStatus}
        questionnaire={questionnaire}
        checkStorageInformation={checkStorageInformation}
        checkIfStudentHasPaid={checkIfStudentHasPaid}
        updateTransactionData={updateTransactionData}
        validateDiscountCode={validateDiscountCode}
        resetDiscountCode={this.resetDiscountCode}
        productPrice={productPrice}
        paymentIntentAPI={paymentIntentAPI}
        successRoute={successRoute}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  validateDiscountCode: (discountCode) => dispatch(supplyChainValidateDiscountCode(discountCode)),
  resetDiscountCode: () => dispatch(supplyChainResetDiscountCode()),
  checkStorageInformation: () => dispatch(restoreSupplyChainPurchaseInformationFromStorage()),
  updateTransactionData: (transactionData) => dispatch(updateTransactionSupplyChainData(transactionData)),
  checkIfStudentHasPaid: (simulationId) => dispatch(checkIfStudentHasPaid(simulationId)),
});

const mapStateToProps = (state) => {
  return {
    user: {
      role: state.user.userProfile.role,
      language: state.user.userProfile.language,
    },
    studentHasAlreadyPaid: state.supplychainBuySimulation.studentHasAlreadyPaid,
    discountCodeStatus: state.supplychainBuySimulation.discountCode,
    questionnaire: {
      numberOfStudents: state.supplychainBuySimulation.buyForm.numberOfStudents,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSupplyChain);
