import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Container } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import HeaderComponent from "../header/HeaderComponent";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import CreateNewScenarioComponent from "./CreateNewScenarioComponent";
import CreateNewScenarioModal from "../modals/CreateNewScenarioModal";
import { professorCreateNewScenario, resetProfessorCreateNewScenarioStore } from "../../store/Scenario/actions";
import { getProfessorScenarioList } from "../../store/Scenario/actions";
import "./createNewScenario.css";
import { sentryReportEvent } from "../../services/SentryReportService";

class CreateNewScenarioScreen extends Component {
  state = {
    openCreateNewScenarioModal: false,
    scenarioData: {},
  };

  componentDidMount() {
    sentryReportEvent("Professor Scenario Page Viewed", "", []);
    this.validateProfessorScenarioList();
  }

  validateProfessorScenarioList = () => {
    const translate = this.props.t;
    const { professorScenarioList, getProfessorScenarioList, language } = this.props;

    if (!professorScenarioList) {
      getProfessorScenarioList(language, translate);
    }
  };

  handleCreateScenarioModal = (scenarioData) => {
    this.setState((state, props) => ({
      openCreateNewScenarioModal: !state.openCreateNewScenarioModal,
      scenarioData: scenarioData,
    }));
  };

  render() {
    const translate = this.props.t;
    const {
      professorCreateNewScenario,
      scenario,
      history,
      resetProfessorCreateNewScenarioStore,
      professorScenarioList,
      getProfessorScenarioList,
    } = this.props;
    const { openCreateNewScenarioModal, scenarioData } = this.state;
    let createNewScenarioModal = null;

    if (openCreateNewScenarioModal) {
      createNewScenarioModal = (
        <CreateNewScenarioModal
          translate={translate}
          openCreateNewScenarioModal={openCreateNewScenarioModal}
          scenarioData={scenarioData}
          handleCreateScenarioModal={this.handleCreateScenarioModal}
          professorCreateNewScenario={professorCreateNewScenario}
          scenario={scenario}
          history={history}
          resetProfessorCreateNewScenarioStore={resetProfessorCreateNewScenarioStore}
        />
      );
    }

    return (
      <Container>
        {createNewScenarioModal}
        <HeaderComponent translate={translate} showBackButton={true} />
        <MainTitleComponent title={translate("createNewScenario")} />
        <CreateNewScenarioComponent
          translate={translate}
          handleCreateScenarioModal={this.handleCreateScenarioModal}
          professorScenarioList={professorScenarioList}
          getProfessorScenarioList={getProfessorScenarioList}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  professorCreateNewScenario: (scenarioData) => dispatch(professorCreateNewScenario(scenarioData)),
  resetProfessorCreateNewScenarioStore: () => dispatch(resetProfessorCreateNewScenarioStore()),
  getProfessorScenarioList: (language, translate) => dispatch(getProfessorScenarioList(language, translate)),
});

const mapStateToProps = (state) => {
  return {
    scenario: {
      loadingCreateNewScenario: state.scenario.loadingCreateNewScenario,
      createNewScenarioSuccess: state.scenario.createNewScenarioSuccess,
      createNewScenarioMessage: state.scenario.createNewScenarioMessage,
    },
    professorScenarioList: state.scenario.professorScenarioList,
    professorScenarioLisrErrorMessage: state.scenario.professorScenarioLisrErrorMessage,
    language: state.user.userProfile.language,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("CreateNewScenarioScreen", "ScenarioManagerScreen", "Common")(withRouter(CreateNewScenarioScreen))
);
