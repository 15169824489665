import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import CurrentDayRefreshSection from "./CurrentDayRefreshSection";
import DashboardSectionRow from "./DashBoardSectionRow";

class DashBoardSection extends Component {
  shouldComponentUpdate(nextProps) {
    const { selectedTeams, selectedGraphs } = this.props;
    if (nextProps && nextProps.selectedTeams && nextProps.selectedGraphs) {
      if (nextProps.selectedTeams === selectedTeams && nextProps.selectedGraphs === selectedGraphs) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { t: translate, selectedTeams, currentSimulationDay, handleGraphModal, sections } = this.props;
    let currentDayRefreshSection = null;
    const dashboadRow = sections.map((section) => (
      <DashboardSectionRow
        name={translate(section.name)}
        section={section}
        selectedTeams={selectedTeams}
        handleGraphModal={handleGraphModal}
      />
    ));

    if (currentSimulationDay) {
      currentDayRefreshSection = (
        <CurrentDayRefreshSection translate={translate} currentSimulationDay={currentSimulationDay} />
      );
    }

    return (
      <>
        {currentDayRefreshSection}
        {dashboadRow}
      </>
    );
  }
}

export default withTranslation("SupplyChainCommon")(DashBoardSection);
