import * as actions from "./action-types";

export const initialState = {
  loadingScenarioInformation: null,
  scenarioData: null,
  scenarioInformationErrorMessage: "",
  loadingProfessorScenarioList: null,
  professorScenarioList: null,
  professorScenarioListErrorMessage: "",
  loadingCreateNewScenario: null,
  createNewScenarioSuccess: null,
  createNewScenarioMessage: "",
  loadingProfessorDeleteScenario: null,
  professorDeleteScenarioSuccess: null,
  professorDeleteScenarioMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_SCENARIO_INFORMATION:
      return {
        ...state,
        loadingScenarioInformation: true,
        scenarioData: null,
        scenarioInformationErrorMessage: "",
      };
    case actions.GET_SCENARIO_INFORMATION_SUCCESSFULL:
      return {
        ...state,
        loadingScenarioInformation: false,
        scenarioInformationErrorMessage: "",
        scenarioData: action.payload,
      };
    case actions.GET_SCENARIO_INFORMATION_FAILED:
      return {
        ...state,
        professorScenarioList: null,
        loadingScenarioInformation: false,
        scenarioInformationErrorMessage: action.payload,
      };
    case actions.GET_PROFESSOR_SCENARIO_LIST:
      return {
        ...state,
        loadingProfessorScenarioList: true,
        professorScenarioList: null,
        professorScenarioListErrorMessage: "",
      };
    case actions.GET_PROFESSOR_SCENARIO_LIST_SUCCESSFULL:
      return {
        ...state,
        loadingProfessorScenarioList: false,
        professorScenarioListErrorMessage: "",
        professorScenarioList: action.payload,
      };
    case actions.GET_PROFESSOR_SCENARIO_LIST_FAILED:
      return {
        ...state,
        professorScenarioList: null,
        loadingProfessorScenarioList: false,
        professorScenarioListErrorMessage: action.payload,
      };
    case actions.PROFESSOR_CREATE_NEW_SCENARIO:
      return {
        ...state,
        loadingCreateNewScenario: true,
        createNewScenarioSuccess: null,
        createNewScenarioMessage: null,
      };
    case actions.PROFESSOR_CREATE_NEW_SCENARIO_SUCCESSFULL:
      return {
        ...state,
        loadingCreateNewScenario: false,
        createNewScenarioSuccess: true,
        createNewScenarioMessage: action.payload,
      };
    case actions.PROFESSOR_CREATE_NEW_SCENARIO_FAILED:
      return {
        ...state,
        loadingCreateNewScenario: false,
        createNewScenarioSuccess: false,
        createNewScenarioMessage: action.payload,
      };
    case actions.RESET_PROFESSOR_CREATE_NEW_SCENARIO_STORE:
      return {
        ...state,
        loadingCreateNewScenario: null,
        createNewScenarioSuccess: null,
        createNewScenarioMessage: "",
      };
    case actions.PROFESSOR_DELETE_SCENARIO:
      return {
        ...state,
        loadingProfessorDeleteScenario: true,
        professorDeleteScenarioSuccess: null,
        professorDeleteScenarioMessage: "",
      };
    case actions.PROFESSOR_DELETE_SCENARIO_SUCCESSFULL:
      return {
        ...state,
        loadingProfessorDeleteScenario: false,
        professorDeleteScenarioSuccess: true,
        professorDeleteScenarioMessage: action.payload,
      };
    case actions.PROFESSOR_DELETE_SCENARIO_FAILED:
      return {
        ...state,
        loadingProfessorDeleteScenario: false,
        professorDeleteScenarioSuccess: null,
        professorDeleteScenarioMessage: action.payload,
      };
    case actions.RESET_PROFESSOR_DELETE_SCENARIO_STORE:
      return {
        ...state,
        loadingProfessorDeleteScenario: null,
        professorDeleteScenarioSuccess: null,
        professorDeleteScenarioMessage: "",
      };
    default:
      return state;
  }
}
