import React, { Component } from "react";
import { List, Image } from "semantic-ui-react";
import simunationLogo from "../../assets/simunation-logo.png";
import SimulationTitle from "./SimulationTitle";
import HelpHoverComponent from "../helpers/HelpHoverComponent";

class SimunationDetailComponent extends Component {
  render() {
    const translate = this.props.translate;
    const simulationDetails = this.props.simulationDetails.payload;
    const loadingDetails = this.props.loadingDetails;
    let listDetails = [];
    let detailBoxStyles = "detailsDataLoading";
    if (!loadingDetails && simulationDetails) {
      detailBoxStyles = "detailsDataCharged";
      listDetails = [
        {
          key: 1,
          item: translate("activationCode"),
          content: simulationDetails.activationCode,
          hasHelp: false,
        },
        {
          key: 2,
          item: translate("AdvanceSimulationModal:currentYear"),
          content: simulationDetails.mostAdvancedTeamYear,
          hasHelp: !simulationDetails.professorManagedProgress,
        },
      ];
    } else {
      listDetails = [
        {
          key: 1,
          item: translate("activationCode"),
          content: "",
        },
        {
          key: 2,
          item: translate("AdvanceSimulationModal:currentYear"),
          content: "",
        },
      ];
    }
    return (
      <div>
        <SimulationTitle title={simulationDetails && !loadingDetails ? simulationDetails.name : ""} />
        <div className="detailsContainer">
          <div className="simunationIcon">
            <Image className="simunationLogo" src={simunationLogo} size="small" />
          </div>
          <div className={`detailsData ${detailBoxStyles}`}>
            <p>{translate("simulationDetails").toUpperCase()}</p>
            <List>
              {listDetails.map((detail) => {
                let helpHoverComponent = null;
                if (detail.hasHelp) {
                  helpHoverComponent = <HelpHoverComponent helpMessage={translate("simunationCurrentDayHelp")} />;
                }
                return (
                  <List.Item key={detail.key}>
                    {loadingDetails === true ? (
                      <div className="animated-background" />
                    ) : (
                      <div>
                        <strong>{detail.item}: </strong>
                        {detail.content}
                        {helpHoverComponent}
                      </div>
                    )}
                  </List.Item>
                );
              })}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

export default SimunationDetailComponent;
