import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import MainTitleComponent from "../titles/mainTitle/MainTitleComponent";
import AdvancedOptions from "./AdvancedOptions";
import LoadingComponent from "../helpers/LoadingComponent";
import MessagesComponent from "../helpers/MessagesComponent";
import ScenarioComparison from "./ScenarioComparison";
import { getScenarioInformation } from "../../store/Scenario/actions";
import "./medicaAdvancedOptions.css";

class MedicaAdvancedOptions extends Component {
  componentDidMount() {
    const { getScenarioInformation, scenarioInformation } = this.props;
    if (!scenarioInformation.scenarioData) {
      getScenarioInformation();
    }
  }

  render() {
    const { t, customScenarios, advancedOptionsChanged, advancedOptions, scenarioInformation, hideSuggestionsToggle } =
      this.props;
    const translate = t;

    const { loadingScenarioInformation, scenarioData, scenarioInformationErrorMessage } = scenarioInformation;

    let loadingComponent = null;
    let messagesComponent = null;
    let scenarioComparison = null;

    if (loadingScenarioInformation) {
      loadingComponent = <LoadingComponent message={translate("loadingScenarioComparison")} />;
    } else {
      if (scenarioInformationErrorMessage !== "") {
        messagesComponent = <MessagesComponent message={translate(scenarioInformationErrorMessage)} />;
      }

      if (scenarioData && scenarioData.length > 0) {
        scenarioComparison = <ScenarioComparison scenarioData={scenarioData} translate={translate} />;
      }
    }

    return (
      <div className="scenarioInformation">
        <AdvancedOptions
          translate={translate}
          hideSuggestionsToggle={hideSuggestionsToggle}
          advancedOptionsChanged={advancedOptionsChanged}
          advancedOptions={advancedOptions}
          customScenarios={customScenarios}
        />
        <div className="scenarioInformationContent">
          <MainTitleComponent title={translate("scenarioComparison")} />
          {scenarioComparison}
          {messagesComponent}
          {loadingComponent}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getScenarioInformation: () => dispatch(getScenarioInformation()),
});

const mapStateToProps = (state) => {
  return {
    scenarioInformation: state.scenario,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation("MedicaAdvancedOptions", "Common")(MedicaAdvancedOptions)
);
