import * as actions from "./action-types";
import * as simunationService from "../../services/SimunationService";

export const getSimulationDetails = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_SIMUNATION_DETAILS });
    const simulationDetailsResponse = simunationService.getSimunationDetail(simulationId);
    simulationDetailsResponse
      .then((details) => {
        if (details) {
          const data = simunationService.prepareCustomResponse(details, actions.GET_SIMUNATION_DETAILS, "");
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchDetails",
          };
          dispatch({
            type: actions.GET_SIMUNATION_DETAILS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const advanceSimunationAction = (simulationId) => {
  return async function (dispatch) {
    dispatch({ type: actions.ADVANCE_SIMUNATION });
    const advanceSimunationResponse = simunationService.advanceSimunationAction(simulationId);
    advanceSimunationResponse
      .then((response) => {
        if (response) {
          const data = simunationService.prepareCustomResponse(response, actions.ADVANCE_SIMUNATION, "");
          dispatch(data);
        }
      })
      .catch((error) => {
        let response = "";
        if (error.code === 500) {
          response = {
            message: "errorSimultaneousChangeRequest",
          };
        } else {
          response = {
            message: "errorAdvancingSimulation",
          };
        }
        dispatch({
          type: actions.ADVANCE_SIMUNATION_FAILED,
          payload: response,
        });
      });
  };
};

export const resetAdvancedSimunationData = () => {
  return async function (dispatch) {
    dispatch({ type: actions.RESET_ADVANCE_SIMUNATION_DATA });
  };
};

export const getStandings = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_SIMUNATION_STANDINGS });
    const standingsResponse = simunationService.getStandings(simulationName);
    standingsResponse
      .then((standings) => {
        if (standings) {
          const data = simunationService.prepareCustomListResponse(
            standings,
            actions.GET_SIMUNATION_STANDINGS,
            "notStandingsList",
            true
          );
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchStandings",
          };
          dispatch({
            type: actions.GET_SIMUNATION_STANDINGS_FAILED,
            payload: response,
          });
        }
      });
  };
};

export const getRegisteredStudents = (simulationName) => {
  return async function (dispatch) {
    dispatch({ type: actions.GET_SIMUNATION_REGISTERED_STUDENTS });
    const studentsResponse = simunationService.getRegisteredStudents(simulationName);
    studentsResponse
      .then((students) => {
        if (students) {
          const data = simunationService.prepareCustomListResponse(
            students,
            actions.GET_SIMUNATION_REGISTERED_STUDENTS,
            "notStudentList",
            false
          );
          dispatch(data);
        }
      })
      .catch((error) => {
        if (error) {
          const response = {
            message: "errorFetchStudents",
          };
          dispatch({
            type: actions.GET_SIMUNATION_REGISTERED_STUDENTS_FAILED,
            payload: response,
          });
        }
      });
  };
};
