import { generateDiscountCodeStatus } from "../../utilities/discountCodeUtils";
import { confirmPaymentStatuses, discountCodeStatuses } from "../../utilities/storeStatusDictionary";
import * as actions from "./action-types";

export const initialState = {
  buyForm: {
    numberOfStudents: 0,
  },
  createSimulation: {
    loading: false,
    errorMessage: "",
    simulationId: "",
    simulationCreated: false,
    shortLink: null,
  },
  payment: {
    paymentSummaryId: "",
  },
  discountCode: {
    status: discountCodeStatuses.initial,
    discountedPrice: null,
    discountCodeMessage: "",
    discountCode: "",
  },
  studentHasAlreadyPaid: {
    loading: false,
    studentHasPaid: null,
    errorMessage: "",
  },
  confirmPayment: {
    status: confirmPaymentStatuses.initial,
    errorMessage: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.COMMIT_PURCHASE_FORM_DATA:
      return {
        ...state,
        buyForm: { ...action.payload },
      };
    case actions.RESTORE_STORAGE_PURCHASE_INFORMATION:
      const { discountCode, discountedPrice } = action.payload;
      return {
        ...state,
        buyForm: {
          numberOfStudents: action.payload.numberOfStudents,
        },
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId ?? "",
        },
        discountCode: {
          ...state.discountCode,
          status: generateDiscountCodeStatus(discountCode, discountedPrice),
          discountCode: discountCode ?? "",
          discountedPrice: discountedPrice ?? null,
        },
      };
    case actions.UPDATE_TRANSACTION_DATA:
      return {
        ...state,
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_START:
      return {
        ...state,
        createSimulation: {
          ...state.createSimulation,
          errorMessage: "",
          loading: true,
          shortLink: null,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_SUCCESS:
      return {
        ...state,
        createSimulation: {
          loading: false,
          simulationId: action.payload.simulationId,
          errorMessage: "",
          simulationCreated: true,
          shortLink: action.payload.shortLink,
        },
      };
    case actions.CREATE_SIMULATION_REQUEST_FAIL:
      return {
        ...state,
        createSimulation: {
          ...state.createSimulation,
          loading: false,
          errorMessage: action.payload,
          shortLink: null,
        },
      };
    case actions.RESET_CREATE_SIMULATION_DEFAULTS:
      return {
        ...state,
        createSimulation: { ...initialState["createSimulation"] },
      };
    case actions.RESET_SUPPLYCHAIN_BUY_SIMULATION_DEFAULTS:
      return {
        ...initialState,
      };
    case actions.VALIDATE_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          status: discountCodeStatuses.loading,
          discountCodeMessage: "",
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        discountCode: {
          ...state.discountCode,
          status: discountCodeStatuses.activated,
          discountCodeMessage: "",
          discountedPrice: action.payload.discountedPrice,
          discountCode: action.payload.discountCode,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: {
          paymentSummaryId: action.payload.paymentSummaryId,
        },
        discountCode: {
          ...state.discountCode,
          status: discountCodeStatuses.activatedPaid,
          discountCodeMessage: "",
          discountedPrice: action.payload.discountedPrice,
          discountCode: action.payload.discountCode,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_FAILED:
      return {
        ...state,
        discountCode: {
          ...initialState.discountCode,
          discountCodeMessage: action.payload,
        },
      };
    case actions.VALIDATE_DISCOUNT_CODE_RESET:
      return {
        ...state,
        discountCode: {
          ...initialState.discountCode,
        },
      };
    case actions.CHECK_STUDENT_PAYMENT:
      return {
        ...state,
        studentHasAlreadyPaid: {
          loading: true,
          studentHasPaid: null,
          errorMessage: "",
        },
      };
    case actions.CHECK_STUDENT_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        studentHasAlreadyPaid: {
          loading: false,
          studentHasPaid: action.payload,
          errorMessage: "",
        },
      };
    case actions.CHECK_STUDENT_PAYMENT_FAILED:
      return {
        ...state,
        studentHasAlreadyPaid: {
          loading: false,
          studentHasPaid: false,
          errorMessage: action.payload,
        },
      };
    case actions.CONFIRM_PAYMENT_START: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.loading,
          errorMessage: "",
        },
        payment: {
          paymentSummaryId: null,
        },
      };
    }
    case actions.CONFIRM_PAYMENT_FAIL: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.fail,
          errorMessage: action.payload,
        },
        payment: {
          paymentSummaryId: null,
        },
      };
    }
    case actions.CONFIRM_PAYMENT_SUCCESS: {
      return {
        ...state,
        confirmPayment: {
          status: confirmPaymentStatuses.success,
          errorMessage: "",
        },
        payment: {
          paymentSummaryId: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
