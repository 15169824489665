import _ from "lodash";
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Table, Button } from "semantic-ui-react";

class ScenarioManagerComponent extends Component {
  constructor(props) {
    super(props);

    const professorScenarioList = this.props.professorScenarioList;

    this.state = {
      column: null,
      professorScenarioList: professorScenarioList,
      direction: null,
    };
  }

  handleSort = (clickedColumn) => () => {
    const { column, professorScenarioList, direction } = this.state;
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        professorScenarioList: _.sortBy(professorScenarioList, [clickedColumn]),
        direction: "ascending",
      });
      return;
    }
    this.setState({
      professorScenarioList: professorScenarioList.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const { deleteProfessorScenarioClick } = this.props;
    const translate = this.props.translate;
    const { column, direction, professorScenarioList } = this.state;

    const arrowsIcon = <FontAwesomeIcon className="tableIcon" icon={faSort} size="lg" color="#FFF" />;
    return (
      <div className="tableContainer" ref={(ref) => (this.topTable = ref)}>
        <Table unstackable sortable selectable className="profesorScenarioTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === "name" ? direction : null} onClick={this.handleSort("name")}>
                <div className="headerCell">
                  {translate("scenarioName").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "createdAt" ? direction : null}
                onClick={this.handleSort("createdAt")}>
                <div className="headerCell">
                  {translate("createdAt").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "lastUsedDate" ? direction : null}
                onClick={this.handleSort("lastUsedDate")}>
                <div className="headerCell">
                  {translate("lastUsedDate").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={column === "timesUsed" ? direction : null}
                onClick={this.handleSort("timesUsed")}>
                <div className="headerCell">
                  {translate("timesUsed").toUpperCase()}
                  {arrowsIcon}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="1"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {professorScenarioList.map((scenario, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell singleLine>{scenario.name}</Table.Cell>
                  <Table.Cell singleLine>{scenario.createdAt}</Table.Cell>
                  <Table.Cell singleLine>{scenario.lastUsedDate}</Table.Cell>
                  <Table.Cell singleLine textAlign="center">
                    {scenario.timesUsed}
                  </Table.Cell>
                  <Table.Cell collapsing className="buttonCell">
                    <Button className="actionButton" onClick={() => deleteProfessorScenarioClick(scenario)}>
                      <FontAwesomeIcon className="tableIcon trashIcon" icon={faTrash} size="lg" color="#0379ba" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default ScenarioManagerComponent;
