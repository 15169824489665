import React from "react";
import youtubeIconRed from "../../assets/youtube-icon-red.png";
import youtubeIconGrey from "../../assets/youtube-icon-grey.png";

const VideoIframe = (props) => {
  const { url, thumbnailURL, title, handleVideoViewerModal } = props;

  return (
    <>
      <img src={thumbnailURL} alt={title} width="100%"></img>
      <button
        className="videoOverlay"
        onClick={() => handleVideoViewerModal(url)}
        onMouseOver={(event) => {
          event.currentTarget.children[0].src = youtubeIconRed;
          event.currentTarget.children[0].style.opacity = "1";
        }}
        onMouseOut={(event) => {
          event.currentTarget.children[0].src = youtubeIconGrey;
          event.currentTarget.children[0].style.opacity = "0.8";
        }}>
        <img className="youtubeIcon" src={youtubeIconGrey} alt="youtube" width="25%" />
      </button>
    </>
  );
};

export default VideoIframe;
